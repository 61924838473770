.table_search {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.table_search .input_icons {
  position: relative;
  width: 25%;
}
.table_search .input_icons img {
  left: 5px;
}
.table_search .input_icons input {
  background: #f3f5f6;
  padding: 14px;
  font-size: 16px;
  border: none;
  outline: none;
  font-weight: 400;
  border-radius: 8px;
  padding-left: 45px;
}
.table_search button {
  font-size: 16px;
  font-weight: 400;
  border: 1px solid #3d4143;
  color: #3d4143;
  border-radius: 8px;
  padding: 14px 30px;
  background: white;
  margin-left: 10px;
}
.table_search .GA2-btn {
  background-color: #005cb9;
  color: white;
  border: none;
}
.table > :not(:first-child) {
  border-top: 1px solid #e8eeec !important;
}

.table thead tr th {
  font-size: 16px;
  font-weight: 500;
  color: #b3b7bb;
  padding: 15px 0;
}
.table tbody tr td {
  font-size: 16px;
  font-weight: 400;
  color: #2d3648;
  padding: 15px 0;
}
.table tbody tr th {
  color: #2f7fd1;
  font-size: 16px;
  font-weight: 400;
  vertical-align: middle;
}
.table tr {
  margin-left: 20px;
}
.table .green-text {
  color: #27ae60;
}
.pagination-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  /* flex-direction: row-reverse; */
}

#wrapper {
  overflow-x: auto;
}

#wrapper th {
  min-width: 150px;
}

td img {
  cursor: pointer;
}

/* Listing */

.table_search {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.table_search .input_icons {
  position: relative;
  width: 25%;
}
.table_search .input_icons img {
  left: 5px;
}
.table_search .input_icons input {
  background: #f3f5f6;
  padding: 14px;
  font-size: 16px;
  border: none;
  outline: none;
  font-weight: 400;
  border-radius: 8px;
  padding-left: 45px;
}
.table_search button {
  font-size: 16px;
  font-weight: 400;
  border: 1px solid #3d4143;
  color: #3d4143;
  border-radius: 8px;
  padding: 14px 30px;
  background: white;
  margin-left: 10px;
}
.table_search .GA2-btn {
  background-color: #005cb9;
  color: white;
  border: none;
}
.table > :not(:first-child) {
  border-top: 1px solid #e8eeec !important;
}

.table thead tr th {
  font-size: 16px;
  font-weight: 500;
  color: #b3b7bb;
  padding: 15px 0;
}
.table tbody tr td {
  font-size: 16px;
  font-weight: 400;
  color: #2d3648;
  padding: 15px 0;
}
.table tbody tr th {
  color: #2f7fd1;
  font-size: 16px;
  font-weight: 400;
  vertical-align: middle;
}
.table tr {
  margin-left: 20px;
}
.table .green-text {
  color: #27ae60;
}
.pagination-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  /* flex-direction: row-reverse; */
}

#wrapper {
  overflow-x: auto;
}

#wrapper th {
  min-width: 150px;
}

td img {
  cursor: pointer;
}

.input_field {
  text-align: left;
}

.scoring-system label {
  width: 100%;
}

.template-btns {
  margin: 20px 0;
}

.template-btns button.btn.btn-primary {
  margin-right: 10px;
}

.main_container.section {
  margin-top: 15px;
}

.popup-inner {
  overflow-y: visible;
}
