.userlogin{

    flex: 5;
 
    
 }
 .pageHeaderTitle{color:#ffffff !important;}
 .pageHeaderDescription{color:#ffffff !important;}
 .pageHeaderLogin{}
 .userLoginContainer{
     display: flex;
     align-items: center;
     justify-content: center;
     height: 100vh;
     background-color:#ffffff;
   
 }

 .userlogin .userLoginContainer {
    align-items: center;
 }

 .rememberMe{color: #1D2124;font-style: normal;
    font-weight: 400;
    font-size: 16px;font-family: "Poppins", sans-serif !important;}
 .userloginLeft{
 
     background-color: #EDF3F6;
     padding: 0px 0px;
    flex: 2;
     align-items: center;
     justify-content: center;
     align-content: center;
     display: flex;
     flex-direction: column;
     height: 100vh;
 }
 
 
 .userloginLeft span{
     display: flex;
     height: 100vh;
     align-items: center;
width:100%;
     flex-direction: column;
 }



 

.logoadmintop{
   justify-content: left;
    width: 100%;
    height: 120px;
    
}

.userloginLeft span .logoadmintop img{

     height: 80px;
     margin: 20px;
     
 }
 


 .userloginLeft span .logoadminbottom{
     margin: 20px;
     /* justify-content: center; */
     align-items: center;
     justify-content: center;
    
     
 }
 
 .userloginLeft span .logoadminbottom img{
 
      max-width: 800px;
      justify-content: center;
      width: 100%;
      
  }
  

 
 
 
 .userLoginRight{
    padding: 0px 60px;
    background-color: #ffffff;
    flex: 2;
    align-items: center;
    justify-content: center;
    align-content: center;
    display: flex;
    flex-direction: column;
    height: 100vh;
 }
 
 
 .login-wrapper {
     display: flex;
     flex-direction: column;
     align-items: center;
 }
 
 .loginForm{
     /* display: flex; 
     flex-wrap: wrap; */
     max-width: 600px;
  width: 100%;
 }
 .loginItem{
     width: 100%;
     display: flex;
     flex-direction: column;
     margin-top: 10px;
     margin-right: 20px;
 }
 .loginItem>label{
     margin-bottom: 10px;
     font-weight: 500;
     color: #1D2124;
     font-size: 16px;
 }
 .loginItem>input{
    height: 50px;
    padding: 5px 5px 5px 10px;
    border: 1px solid #cfd2db;
    border-radius: 5px;
    background-color: #f9fafb;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
 }
 
 
 .loginButton{
     width: 100%;
     border-radius: 8px;
     background-color: #005CB9;
     border: none;
     margin-top:30px;
     cursor: pointer;
     padding:7px 10px;
     color: white;
     height: 56px;
     font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 24px;
 }
 
 .resetoptionsContainer{
 display: flex;
 justify-content: space-between;
 align-items:center ;
 }
 
 .resetoptionsLeft{
     color: #1d2740;
     font-size: 12px;
     padding-top: 5px;
     margin-left: 5px;
     align-content: center;
     justify-content: center;
 
 
 }

 .resetoptionsLeft input[type="checkbox"]{

height:20px;
width: auto;


}
 
 .forgotpasswordbtn{
     color: #1D2124;
     font-family: 'Poppins';
     font-style: normal;
     font-weight: 400;
     font-size: 16px;
     cursor: pointer;
     margin-right: 5px;
     border: none;
     background-color: transparent;
     padding:5px 0px 0px 0px;
 }


 .needsanaccount{
    color: #444A6D;
    font-size: 14px;
    /* cursor: pointer; */
    margin-right: 5px;
    margin-top: 20px;
    border: none;
    background-color: transparent;
    padding:5px 0px 0px 0px;
    text-align: center;
    width: 100%;
}


.needsanaccount span{
    color: #005CB9;
    cursor: pointer;
}

 .resetoptionsRight{
     color: #1d2740;
     font-size: 12px;
     padding-top: 5px;
     cursor: pointer;
     margin-right: 5px;
 }
 
 .resetDescription{
     color:gray;
     font-size: 14px;
     font-weight: 300;
     padding-left: 15px;
     padding-top: 5px !important;
 }
 .pageHeaderDescriptionRegistered{
    background-color: #22c55d!important;
    padding: 8px 5px 8px 5px !important;
    display: flex !important;
    align-items: center !important;
    border: none !important;
    border-radius: 5px !important;
    color: white !important;
    width: auto;
    text-decoration: none !important;
    margin-top: 0px !important;
 }

 .logoMobile{display: none;}

 .google-btn {
    width: 100%;
    height: 46px;
    border-radius: 8px;
    box-shadow: 0 3px 4px 0 rgba(0,0,0,.25);
    border:1px solid #4f86ec;
align-content: center;
    max-width: 400px;
    background-color: #4f86ec;
    padding: 1px;
    cursor: pointer;
    
 }
    .google-icon-wrapper {
      position: absolute;

      width: 46px;
      height: 42px;
      max-width: 46px;
      border-radius: 8px;
      background-color: #ffffff;

    }
    .google-icon {
      width: 26px;
      height:26px;
      margin: 8px auto;
      display: block;
    }
    .btn-text {
        float: right;
     height: 42px;
        color: #ffffff;
        width: 100%;
        font-size: 16px;
        /* font-family: "Manrope"; */
        font-weight: 400;
        max-width: 550px;
        text-align: center;
        line-height: 42px;
        cursor: pointer;
      }
     
 @media only screen and (max-width: 768px){
    .userloginLeft{display: none;}
    .logoMobile{display: block;}
    .userLoginRight{padding:20px 15px 0px 15px;}
    .pageHeaderTitle{color:#444A6D !important; text-align: center;}
    .pageHeaderDescription{display: none;}
    .pageHeaderLogin{justify-content: center !important;margin: 20px 0px 20px 0px !important;}
    .logoMobile img{width:250px;}
    .userLoginContainer{align-items: baseline;}

}



@media only screen and (max-width: 1000px){
    .userloginLeft{}
    .userloginLeft span .logoadminbottom img{width:100%; max-width: 300px;}
    .logoMobile{display: block;}
    .userLoginRight{padding:20px 40px 0px 40px;}
    .pageHeaderTitle{color:#444A6D !important; text-align: center;}
    .pageHeaderDescription{display: none;}
    .pageHeaderLogin{justify-content: center !important;margin: 20px 0px 20px 0px !important;}
    .logoMobile img{width:250px;}
    .userLoginContainer{align-items: baseline;}

}


@media only screen and (max-width: 1400px){
    .userloginLeft{}
    .logoMobile{display: block;}
    .userLoginRight{padding:20px 60px 0px 60px;}
    .pageHeaderTitle{color:#444A6D !important; text-align: center;}
    .pageHeaderDescription{display: none;}
    .pageHeaderLogin{justify-content: center !important;margin: 20px 0px 20px 0px !important;}
    .logoMobile img{width:250px;}
    .userLoginContainer{align-items: baseline;}

}