.usersignup{

    flex: 5;
 
    
 }

 .err {
    margin: 4px 0 0 0;
    font-size: 16px;
    color: #e64646;
  }
  


 .showpasswordImg{
    width: 25px;
    position: absolute;
    top: 59%;
    right: 17px;
 }


 .verifypasswordImg {
    width: 25px;
    position: absolute;
    top: 59%;
    right: 17px;
    height: 17px
 }
 
 .userSignupContainerMain{
     display: flex;
     align-items: baseline;
     justify-content: center;
     height: 100vh;
     background-color:#ffffff;
   
 }
 
 .usersignupLeft{
 
     background-color: #DDEEF4;
     padding: 0px 0px;
    flex: 2;
     align-items: center;
     justify-content: center;
     align-content: center;
     display: flex;
     flex-direction: column;
 }
 
 
 .usersignupLeft span{
     display: flex;
     height: 100vh;
     align-items: center;
width:100%;
     flex-direction: column;
     justify-content: start;
 }



 

.logoadmintop{
   justify-content: left;
    width: 100%;
    height: 120px;
    
}

.usersignupLeft span .logoadmintop img{

     height: 80px;
     margin: 20px;
     
 }
 


 .usersignupLeft span .logoadminbottom{
     margin: 20px;
     /* justify-content: center; */
     align-items: center;
     justify-content: center;
    
     
 }
 
 .usersignupLeft span .logoadminbottom img{
 
     
      justify-content: center;
      width: 100%;
      
  }
  

 
 
 
 .userSignupRightMain{
    padding: 0px 60px;
    background-color: #ffffff;
    flex: 2;
    align-items: center;
    justify-content: center;
    align-content: center;
    display: flex;
    flex-direction: column;
 }
 
 
 .signup-wrapper {
     display: flex;
     flex-direction: column;
     align-items: center;
 }
 
 .signupFormMain{
     /* display: flex; 
     flex-wrap: wrap; */
     max-width: 600px;
     width: 100%;
     flex-direction: column;
     display: flex;
     justify-content: center;
     align-items: center;
 }
 .signupItem{
     position:relative;
     width: 100%;
     display: flex;
     flex-direction: column;
     margin-top: 10px;
     margin-right: 20px;
 }
 .signupItem>label{
     margin-bottom: 10px;
     font-weight: 500;
     color: #444A6D;
     font-size: 16px;
 }
 .signupItem>input{
    height: 50px;
    padding: 5px 5px 5px 10px;
    border: 1px solid #cfd2db;
    border-radius: 5px;
    background-color: #ffffff;
    font-size: 16px;
    font-weight: 300;
 }
 
 
 .signupButtonMain{
     width: 100%;
     border-radius: 5px;
     background-color: #00AEEF;
     border: none;
     margin-top:30px;
     cursor: pointer;
     font-weight: 600;
     padding:7px 10px;
     color: white;
     height: 46px;
     font-size: 18px;
 }
 
 .resetoptionsContainer{
 display: flex;
 justify-content: space-between;
 align-items:center ;
 }
 
 .resetoptionsLeft{
     color: #1d2740;
     font-size: 12px;
     padding-top: 5px;
     margin-left: 5px;
     align-content: center;
     justify-content: center;
 
 
 }

 .resetoptionsLeft input[type="checkbox"]{

height:20px;
margin-right: 5px;
width: auto;
display: inline-block;


}
 
 .forgotpasswordbtn{
    color: #1D2124;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    cursor: pointer;
    margin-right: 5px;
    border: none;
    background-color: transparent;
    padding:5px 0px 0px 0px;
 }




.needsanaccount{
    color: #444A6D;
        font-size: 14px;
        cursor: pointer;
        margin-right: 5px;
        margin-top: 30px;
        border: none;
        background-color: transparent;
        padding: 0;
        text-align: center;
        width: 100%;
        display: block;
    }


.needsanaccount span{
    color: #005CB9;
    
}



 
 
 .resetoptionsRight{
     color: #1d2740;
     font-size: 12px;
     padding-top: 5px;
     cursor: pointer;
     margin-right: 5px;
 }
 
 .resetDescription{
     color:gray;
     font-size: 14px;
     font-weight: 300;
     padding-left: 15px;
     padding-top: 5px !important;
 }

 .pageHeaderSignup{width:100%; max-width:600px; margin:20px 0px;}

 @media only screen and (max-width: 768px){
    .usersignupLeft{display: none;}
    .logoMobile{display: block !important;}
    .userSignupRightMain{padding:20px 15px 0px 15px;}
    .pageHeaderTitle{color:#444A6D !important;
         text-align: center;}
         .makeStyles-menuButton-3{margin-right:5px !important;}
    .pageHeaderDescription{display: none;}
    .pageHeaderLogin{justify-content: center !important;margin: 20px 0px 20px 0px !important;}
    .logoMobile img{width:250px;}
    .userLoginContainer{align-items: baseline;}
    .signupItem>input{height:40px; font-size:14px;}
    .css-13cymwt-control{min-height: 30px !important;}
    .signupItem>label{font-size: 14px;margin-bottom: 5px;}
    .pageHeaderSignup{margin: 10px 0px;}
    .userSignupContainerMain{align-items: baseline;}

}


@media only screen and (max-width: 1000px){
    .usersignupLeft span .logoadminbottom img{max-width: 350px; width: 100%;}
    .usersignupLeft img{width:450px; margin-top: 50px;}
    .usersignupLeft{align-items: start;}
    .logoadmintop{height: 100px;}
    .usersignupLeft span .logoadmintop img{height: auto; width: 250px; display: block; margin: 20px auto;}
    .logoMobile{display: none;}
    .makeStyles-menuButton-3{margin-right:5px !important;}
    .userSignupRightMain{padding:20px 60px 0px 60px;}
    .pageHeaderTitle{color:#444A6D !important; text-align: center;}
    .pageHeaderDescription{display: none;}
    .pageHeaderLogin{justify-content: center !important;margin: 20px 0px 20px 0px !important;}
    .logoMobile img{width:250px;}
    .userLoginContainer{align-items: baseline;}
    .signupItem>input{height:40px; font-size:14px;}
    .css-13cymwt-control{min-height: 30px !important;}
    .signupItem>label{font-size: 14px;margin-bottom: 5px;}
    .pageHeaderSignup{margin: 10px 0px;}
    .userSignupContainerMain{align-items: start;}
    .usersignupLeft span{justify-content: start;}

}




@media only screen and (max-width: 1400px){
    .usersignupLeft span .logoadminbottom img{max-width: 450px; width: 100%;}
    .usersignupLeft img{width:450px; margin-top: 50px;}
    .usersignupLeft{align-items: start;}
    .logoadmintop{height: 100px;}
    .usersignupLeft span .logoadmintop img{height: auto; width: 250px; display: block; margin: 20px auto;}
    .logoMobile{display: none;}
    .userSignupRightMain{padding:20px 60px 0px 60px;}
    .pageHeaderTitle{color: #ffffff !important;
        font-size: clamp(16px, 0.8vw, 24px) !important;}
        .makeStyles-menuButton-3{margin-right:5px !important;}
    .pageHeaderDescription{display: none;}
    .pageHeaderLogin{justify-content: center !important;margin: 20px 0px 20px 0px !important;}
    .logoMobile img{width:250px;}
    .userLoginContainer{align-items: baseline;}
    .signupItem>input{height:40px; font-size:14px;}
    .css-13cymwt-control{min-height: 30px !important;}
    .signupItem>label{font-size: 14px;margin-bottom: 5px;}
    .pageHeaderSignup{margin: 10px 0px;}
    .userSignupContainerMain{align-items: start;}
    .usersignupLeft span{justify-content: start;}

}