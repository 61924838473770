/*********** CSS TABLE OF CONTENTS *******************

|--- 01.Component -> { Header }
|--- 01.Component -> { Menu }
|--- 01.Component -> { Button }
|--- 01.Component -> { Lists }
|--- 01.Component -> { Background }
|--- 01.Component -> { Form }
|--- 01.Component -> { Cards }
|--- 01.Component -> { Modal }
|--- 01.Component -> { Tab }
|--- 01.Component -> { Acccordion }
|--- 01.Component -> { Sidebar }
|--- THEME CUSTOM STYLES

******************************************************/
/*=== Media Query ===*/
@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
body[data-theme='dark'], .dark-bg, .dark-mode-texts {
  --bg:                 #19191b;
  --bg-2:                 #19191b;
  --bg-3:                 #19191b;
  --bg-4:                 #19191b;
  --bg-5:                 #19191b;
  --bg-6:                 #19191b;
  --bg-7:                 #19191b;
  --bg-8:                 #19191b;
  --bg-9:                 #19191b;
  --color-headings:       #fff;
  --color-headings-2:     #fff;
  --color-headings-3:     #fff;
  --color-texts:          rgba(255,255,255, 0.7);
  --color-texts-2:        rgba(255,255,255, 1);
  --color-texts-3:        rgba(255,255,255, 0.7);
  --border-color:         rgba(255,255,255, 0.08);
  --border-color-2:       rgba(255,255,255, 0.08);
  --border-color-3:       rgba(255,255,255, 0.08);
  --border-color-4:       rgba(255,255,255, 0.08);
  --border-color-5:       rgba(255,255,255, 0.08);
  --border-color-6:       rgba(255,255,255, 0.08);
}

body[data-theme='light'], .light-bg, .light-mode-texts {
  --bg:                    #fff;
  --bg-2:                  #f8f9fc;
  --bg-3:                  #f3f4f6;
  --bg-4:                  rgba(169, 210, 255, 0.1);
  --bg-5:                  #f2f5fb;
  --bg-6:                  #f9fafc;
  --bg-7:                  #fdfdfd;
  --bg-8:                  #e6f8f9;
  --bg-9:                  rgba(230, 248, 249, 0.4);
  --color-headings:      #25373f;
  --color-headings-2:      #262729;
  --color-headings-3:      #000;
  --color-texts:        rgba(37, 55, 63, 0.7);
  --color-texts-2:       #3b4656;
  --color-texts-3:      rgba(38, 39, 41, 0.7);
  --border-color:       rgba(128, 138, 142, 0.2);
  --border-color-2:       rgba(37, 55, 63, 0.1);
  --border-color-3:      rgba(38, 39, 41, 0.1);
  --border-color-4:      rgba(181, 181, 181, 0.2);
  --border-color-5:     rgba(38, 39, 41, 0.3);
  --border-color-6:     rgba(130, 130, 136, 0.3);
}

body {
  --bg:                    #fff;
  --bg-2:                  #f8f9fc;
  --bg-3:                  #f3f4f6;
  --bg-4:                  rgba(169, 210, 255, 0.1);
  --bg-5:                  #f2f5fb;
  --bg-6:                  #f9fafc;
  --bg-7:                  #fdfdfd;
  --bg-8:                  #e6f8f9;
  --bg-9:                  rgba(230, 248, 249, 0.4);
  --color-headings:      #25373f;
  --color-headings-2:      #262729;
  --color-headings-3:      #000;
  --color-texts:        rgba(37, 55, 63, 0.7);
  --color-texts-2:       #3b4656;
  --color-texts-3:      rgba(38, 39, 41, 0.7);
  --border-color:       rgba(128, 138, 142, 0.2);
  --border-color-2:       rgba(37, 55, 63, 0.1);
  --border-color-3:      rgba(38, 39, 41, 0.1);
  --border-color-4:      rgba(181, 181, 181, 0.2);
  --border-color-5:     rgba(38, 39, 41, 0.3);
  --border-color-6:     rgba(130, 130, 136, 0.3);
}

p {
  color: var(--color-texts);
}

.default-logo, .default-shape {
  display: block;
}



.footerlogo {
  max-width:300px;
}


.light-version-logo, .light-shape {
  display: none;
}

.dark-version-logo, .dark-shape {
  display: none;
}

.light-version-logo.default-logo {
  display: block;
}

.dark-version-logo.default-logo {
  display: block;
}

body[data-theme='dark'] .dark-version-logo, body[data-theme='dark'] .dark-shape, .dark-mode-texts .dark-version-logo, .dark-mode-texts .dark-shape {
  display: block;
}

body[data-theme='dark'] .light-version-logo, .dark-mode-texts .light-version-logo {
  display: none;
}

body[data-theme='dark'] .light-version-logo.default-logo, .dark-mode-texts .light-version-logo.default-logo {
  display: none;
}

body[data-theme='dark'] .light-shape, .dark-mode-texts .light-shape {
  display: none;
}

body[data-theme='dark'] .light-shape.default-shape, .dark-mode-texts .light-shape.default-shape {
  display: none;
}

body[data-theme='dark'] .light-mode-texts .light-version-logo, body[data-theme='dark'] .light-mode-texts .light-shape, .dark-mode-texts .light-mode-texts .light-version-logo, .dark-mode-texts .light-mode-texts .light-shape {
  display: block;
}

body[data-theme='dark'] .light-mode-texts .dark-version-logo, .dark-mode-texts .light-mode-texts .dark-version-logo {
  display: none;
}

body[data-theme='dark'] .light-mode-texts .dark-version-logo.default-logo, .dark-mode-texts .light-mode-texts .dark-version-logo.default-logo {
  display: none;
}

body[data-theme='dark'] .light-mode-texts .dark-shape, .dark-mode-texts .light-mode-texts .dark-shape {
  display: none;
}

body[data-theme='dark'] .light-mode-texts .dark-shape.default-shape, .dark-mode-texts .light-mode-texts .dark-shape.default-shape {
  display: none;
}

body[data-theme='light'] .light-version-logo, body[data-theme='light'] .light-shape, .light-mode-texts .light-version-logo, .light-mode-texts .light-shape {
  display: block;
}

body[data-theme='light'] .dark-version-logo, .light-mode-texts .dark-version-logo {
  display: none;
}

body[data-theme='light'] .dark-version-logo.default-logo, .light-mode-texts .dark-version-logo.default-logo {
  display: none;
}

body[data-theme='light'] .dark-shape, .light-mode-texts .dark-shape {
  display: none;
}

body[data-theme='light'] .dark-shape.default-shape, .light-mode-texts .dark-shape.default-shape {
  display: none;
}

body[data-theme='light'] .dark-mode-texts .dark-version-logo, body[data-theme='light'] .dark-mode-texts .dark-shape, .light-mode-texts .dark-mode-texts .dark-version-logo, .light-mode-texts .dark-mode-texts .dark-shape {
  display: block;
}

body[data-theme='light'] .dark-mode-texts .light-version-logo, .light-mode-texts .dark-mode-texts .light-version-logo {
  display: none;
}

body[data-theme='light'] .dark-mode-texts .light-version-logo.default-logo, .light-mode-texts .dark-mode-texts .light-version-logo.default-logo {
  display: none;
}

body[data-theme='light'] .dark-mode-texts .light-shape, .light-mode-texts .dark-mode-texts .light-shape {
  display: none;
}

body[data-theme='light'] .dark-mode-texts .light-shape.default-shape, .light-mode-texts .dark-mode-texts .light-shape.default-shape {
  display: none;
}

.bg-default {
  background: var(--bg);
}

.bg-default-1 {
  background: var(--bg);
}

.bg-default-2 {
  background: var(--bg-2);
}

.bg-default-3 {
  background: var(--bg-3);
}

.bg-default-3white {
  background: #ffffff;
}

.bg-default-4 {
  background: var(--bg-4);
}

.bg-default-5 {
  background: var(--bg-5);
}

.bg-default-6 {
  background: var(--bg-6);
}

.text-default-color {
  color: var(--color-texts);
}

.text-default-color-2 {
  color: var(--color-texts-2);
}

.text-default-color-3 {
  color: var(--color-texts-3);
}

.heading-default-color {
  color: var(--color-headings);
}

.heading-default-color-2 {
  color: var(--color-headings-2);
}

.border-default-color {
  border-color: var(--border-color);
}

.border-default-color-2 {
  border-color: var(--border-color-2) !important;
}

.border-default-color-3 {
  border-color: var(--border-color-3) !important;
}

/*=== Media Query ===*/
.h-45 {
  height: 45px !important;
}

/* .h-100vh {
  height: 100vh;
} */

.w-140 {
  width: 140px !important;
}

.min-w-163 {
  min-width: 163px !important;
}

.rounded-10 {
  border-radius: 10px !important;
}

.rounded-25 {
  border-radius: 25px !important;
}

.rounded-50 {
  border-radius: 50px !important;
}

.rounded-55 {
  border-radius: 55px !important;
}

.rounded-56 {
  border-radius: 56px !important;
}

.bg-default-3 {
  background-color: var(--bg-3);
}

.bg-default-4 {
  background-color: var(--bg-4);
}

.bg-default-7 {
  background-color: var(--bg-7);
}

.bg-default-8 {
  background-color: var(--bg-8);
}

.bg-default-9 {
  background-color: var(--bg-9);
}

.hover-link--readical-red:hover {
  color: #fd346e !important;
}

.hover-link--yellow:hover {
  color: #fcdc00 !important;
}

.hover-link--primary:hover {
  color: #ff5722 !important;
}

.hover-link--java-2:hover {
  color: #1de2cf !important;
}

.hover-link--electric-violet-3:hover {
  color: #6001d3 !important;
}

.border-shark {
  border-color: #262729;
}

.review-star {
  margin-bottom: 40px;
  display: flex;
  font-size: 28px;
  color: #ff971d;
}

.review-star__single {
  color: inherit;
  margin-right: 5px;
  font-size: inherit;
  transition: 0.4s;
}

.review-star__single:hover, .review-star__single.active {
  text-decoration: none;
  color: rgba(37, 55, 63, 0.2);
}

.reviw-star--size-2 {
  font-size: 15px;
}

.section-title .sub-heading--electric-violet-3:before {
  background-color: #6001d3 !important;
}

.section-title .sub-heading--electric-violet-2:before {
  background-color: #5034fc !important;
}

@media (min-width: 768px) {
  .vh-md-100 {
    /* height: 100vh; */
  }
}

.section-tl-shape {
  position: relative;
}

.section-tl-shape .shape {
  position: absolute;
  right: 0;
  top: 0;
  width: 8%;
}
.logos img{max-width:1200px;}
.shadow--primary {
  box-shadow: -12px 12px 50px rgba(253, 52, 110, 0.3);
}

.shadow--primary-2 {
  box-shadow: 4px 4px 56px rgba(255, 87, 34, 0.3);
}

.shadow--primary-3 {
  box-shadow: 4px 4px 65px rgba(255, 87, 34, 0.5);
}

.shadow--primary-4 {
  box-shadow: 0 4px 4px rgba(253, 100, 52, 0.3);
}

.shadow--primary-5 {
  box-shadow: 0 10px 20px rgba(255, 87, 34, 0.25);
}

.shadow--primary-6 {
  box-shadow: -12px 12px 50px rgba(255, 87, 34, 0.3);
}

.shadow--primary-7 {
  box-shadow: 0px 10px 20px rgba(255, 87, 34, 0.25);
}

.shadow--electric-violet-2 {
  box-shadow: -12px 12px 50px rgba(96, 1, 211, 0.3);
}

.shadow--electric-violet-2-2 {
  box-shadow: 4px 4px 56px rgba(80, 52, 252, 0.3);
}

.shadow--electric-violet-2-3 {
  box-shadow: 0 4px 4px rgba(96, 1, 211, 0.3);
}

.shadow--electric-violet-2-4 {
  box-shadow: -12px 20px 50px rgba(80, 52, 252, 0.3);
}

.shadow--electric-violet-3 {
  box-shadow: 4px 4px 50px rgba(80, 52, 252, 0.45);
}

.shadow--electric-violet-4 {
  box-shadow: 0 10px 20px rgba(96, 1, 211, 0.25);
}

.shadow--electric-violet-3-2 {
  box-shadow: -12px 12px 50px rgba(96, 1, 211, 0.3);
}

.shadow--flamingo {
  box-shadow: 4px 4px 50px rgba(239, 67, 57, 0.45);
}

.shadow--egg-blue {
  box-shadow: 0 4px 45px rgba(10, 191, 188, 0.45);
}

.shadow--egg-blue-2 {
  box-shadow: -12px 12px 50px rgba(10, 191, 188, 0.3);
}
.w-200{width: 130px;}
.shadow--blue-ribbon-2 {
  box-shadow: 0 20px 20px rgba(0, 65, 255, 0.2);
}

.shadow--orange {
  box-shadow: 0 20px 20px #ffe8cd;
}

.shadow--torch-red {
  box-shadow: 0 20px 20px rgba(255, 30, 56, 0.2);
}

.shadow--torch-red-2 {
  box-shadow: -12px 12px 50px rgba(255, 30, 56, 0.3);
}

.shadow--torch-red-3 {
  box-shadow: 0 10px 20px rgba(255, 30, 56, 0.25);
}

.shadow--torch-red-4 {
  box-shadow: -12px 20px 50px rgba(255, 30, 56, 0.3);
}

.shadow--readical-red {
  box-shadow: 0 4px 44px rgba(249, 58, 112, 0.4);
}

.shadow--readical-red-2 {
  box-shadow: 0 4px 4px rgba(253, 52, 110, 0.3);
}

.shadow--readical-red-3 {
  box-shadow: 0 10px 20px rgba(253, 52, 110, 0.25);
}

.shadow--readical-red-4 {
  box-shadow: 0 20px 20px rgba(253, 52, 110, 0.3);
}

.shadow--java-2 {
  box-shadow: 0 20px 20px #d1fffb;
}

.shadow--yellow {
  box-shadow: 0 20px 20px rgba(252, 220, 0, 0.2);
}

.shadow--icon-readical-red {
  box-shadow: 0 20px 20px #fedbe5;
}

.shadow--icon-yellow {
  box-shadow: 0 20px 20px #fff9d3;
}

.shadow--icon-primary {
  box-shadow: 0 20px 20px #ffd9cd;
}

.shadow--icon-java-2 {
  box-shadow: 0 20px 20px #d1fffb;
}

.shadow--bittersweet {
  box-shadow: 0 4px 4px rgba(253, 52, 110, 0.3);
}

.shadow--bittersweet-2 {
  box-shadow: 0 10px 20px rgba(255, 114, 114, 0.6);
}

.shadow--niagara {
  box-shadow: 0 4px 4px #82c4c3;
}

.shadow--niagara-2 {
  box-shadow: 0 10px 20px rgba(9, 154, 151, 0.6);
}

.shadow--niagara-3 {
  box-shadow: -8px 10px 50px rgba(9, 154, 151, 0.3);
}

.bg-op1__readical-red {
  background-color: rgba(253, 52, 110, 0.1);
}

.bg-op1__electric-violet-3 {
  background-color: rgba(96, 1, 211, 0.1);
}

.bg-op1__java-2 {
  background-color: rgba(29, 226, 207, 0.1);
}

.bg-op1__yellow {
  background-color: rgba(252, 220, 0, 0.1);
}

.bg-op1__yellow-3 {
  background-color: rgba(255, 215, 0, 0.1);
}

.bg-op1__bittersweet {
  background-color: rgba(255, 114, 114, 0.1);
}

.bg-op1__niagara {
  background-color: rgba(9, 154, 151, 0.1);
}

.bg-op1__coral {
  background-color: rgba(255, 141, 82, 0.1);
}

.bg-op1__lavender {
  background-color: rgba(190, 121, 223, 0.1);
}
.site-header{background-color: #ffffff;}
.content-skew-img {
  width: 100%;
  position: relative;
  overflow: hidden;
  transform-origin: top left;
  margin: 0 10px;
  border-radius: 5px;
  margin-bottom: 40px;
}

@media (min-width: 576px) {
  .content-skew-img {
    width: 50%;
    transform: skewX(-15deg);
  }
}

@media (min-width: 992px) {
  .content-skew-img {
    margin-bottom: 0;
    width: 40%;
  }
}

.content-skew-img img {
  width: 100%;
  transform-origin: top left;
  height: 100%;
  position: relative;
  top: 0;
}

@media (min-width: 576px) {
  .content-skew-img img {
    left: -60%;
    width: 200%;
    transform: skewX(15deg);
  }
}

.card-hover__electric-violet-3:hover:hover {
  box-shadow: 0 8px 45px rgba(96, 1, 211, 0.25);
  border-radius: 10px;
  background-color: #6001d3;
}

.card-hover__electric-violet-3:hover:hover .card--services-l6__icon {
  background-color: #fff !important;
}

.card-hover__electric-violet-3:hover:hover .card--services-l6__heading {
  color: #fff;
}

.card-hover__electric-violet-3:hover:hover .card--services-l6__content {
  color: rgba(255, 255, 255, 0.9);
}

.card-hover__readical-red:hover:hover {
  box-shadow: 0 8px 45px rgba(96, 1, 211, 0.25);
  border-radius: 10px;
  background-color: #fd346e;
}

.card-hover__readical-red:hover:hover .card--services-l6__icon {
  background-color: #fff !important;

}

.card-hover__readical-red:hover:hover .card--services-l6__heading {
  color: #fff;
}

.card-hover__readical-red:hover:hover .card--services-l6__content {
  color: rgba(255, 255, 255, 0.9);
}

.card-hover__java-2:hover:hover {
  box-shadow: 0 8px 45px rgba(96, 1, 211, 0.25);
  border-radius: 10px;
  background-color: #1de2cf;
}

.card-hover__java-2:hover:hover .card--services-l6__icon {
  background-color: #fff !important;
}

.card-hover__java-2:hover:hover .card--services-l6__heading {
  color: #fff;
}

.card-hover__java-2:hover:hover .card--services-l6__content {
  color: rgba(255, 255, 255, 0.9);
}

.card-hover__yellow:hover:hover {
  box-shadow: 0 8px 45px rgba(96, 1, 211, 0.25);
  border-radius: 10px;
  background-color: #fcdc00;
}

.card-hover__yellow:hover:hover .card--services-l6__icon {
  background-color: #fff !important;
}

.card-hover__yellow:hover:hover .card--services-l6__heading {
  color: #fff;
}

.card-hover__yellow:hover:hover .card--services-l6__content {
  color: rgba(255, 255, 255, 0.9);
}

.card-hover__bittersweet:hover:hover {
  box-shadow: 0 8px 45px rgba(96, 1, 211, 0.25);
  border-radius: 10px;
  background-color: #ff7272;
}

.card-hover__bittersweet:hover:hover .card--services-l6__icon {
  background-color: #fff !important;
}

.card-hover__bittersweet:hover:hover .card--services-l6__heading {
  color: #fff;
}

.card-hover__bittersweet:hover:hover .card--services-l6__content {
  color: rgba(255, 255, 255, 0.9);
}

.card-hover__niagara:hover:hover {
  box-shadow: 0 8px 45px rgba(96, 1, 211, 0.25);
  border-radius: 10px;
  background-color: #099a97;
}

.card-hover__niagara:hover:hover .card--services-l6__icon {
  background-color: #fff !important;
}

.card-hover__niagara:hover:hover .card--services-l6__heading {
  color: #fff;
}

.card-hover__niagara:hover:hover .card--services-l6__content {
  color: rgba(255, 255, 255, 0.9);
}

.card-hover__lavender:hover:hover {
  box-shadow: 0 8px 45px rgba(96, 1, 211, 0.25);
  border-radius: 10px;
  background-color: #be79df;
}

.card-hover__lavender:hover:hover .card--services-l6__icon {
  background-color: #fff !important;
}

.card-hover__lavender:hover:hover .card--services-l6__heading {
  color: #fff;
}

.card-hover__lavender:hover:hover .card--services-l6__content {
  color: rgba(255, 255, 255, 0.9);
}

.card-hover__coral:hover:hover {
  box-shadow: 0 8px 45px rgba(96, 1, 211, 0.25);
  border-radius: 10px;
  background-color: #ff8d52;
}

.card-hover__coral:hover:hover .card--services-l6__icon {
  background-color: #fff !important;
}

.card-hover__coral:hover:hover .card--services-l6__heading {
  color: #fff;
}

.card-hover__coral:hover:hover .card--services-l6__content {
  color: rgba(255, 255, 255, 0.9);
}

.hover-text--blue-ribbon-2:hover,
a.hover-text--blue-ribbon-2:hover {
  color: #0041ff;
}

.hover-text--orange:hover,
a.hover-text--orange:hover {
  color: #ff5200;
}

.hover-text--torch-red:hover,
a.hover-text--torch-red:hover {
  color: #ff1e38;
}

.hover-text--java-2:hover,
a.hover-text--java-2:hover {
  color: #1de2cf;
}

.hover-text--yellow:hover,
a.hover-text--yellow:hover {
  color: #fcdc00;
}

.hover-text--electric-violet-3:hover,
a.hover-text--electric-violet-3:hover {
  color: #6001d3;
}

.btn-outline-primary:hover {
  color: #fff !important;
}

.btn-outline-readical-red:hover {
  color: #fff !important;
}

.card-hover-bg__electric-violet-3:hover .card--services-l9__icon {
  background-color: #6001d3 !important;
  color: #fff;
  box-shadow: -6px 8px 10px rgba(96, 1, 211, 0.25);
}

.card-hover-bg__electric-violet-3:hover .card--services-l9__icon i {
  color: #fff !important;
}

.card-hover-bg__readical-red:hover .card--services-l9__icon {
  background-color: #fd346e !important;
  color: #fff;
  box-shadow: -6px 8px 10px rgba(253, 52, 110, 0.25);
}

.card-hover-bg__readical-red:hover .card--services-l9__icon i {
  color: #fff !important;
}

.card-hover-bg__java-2:hover .card--services-l9__icon {
  background-color: #1de2cf !important;
  color: #fff;
  box-shadow: -6px 8px 10px rgba(29, 226, 207, 0.25);
}

.card-hover-bg__java-2:hover .card--services-l9__icon i {
  color: #fff !important;
}

.card-hover-bg__yellow-3:hover .card--services-l9__icon {
  background-color: #ffd700 !important;
  color: #fff;
  box-shadow: -6px 8px 10px rgba(255, 215, 0, 0.25);
}

.card-hover-bg__yellow-3:hover .card--services-l9__icon i {
  color: #fff !important;
}

@-webkit-keyframes rotation {
  0% {
    transform: scale(0.6);
  }
  50% {
    transform: scale(0.8) rotate(-45deg);
  }
  100% {
    transform: scale(1) rotate(180deg);
  }
}

@keyframes rotation {
  0% {
    transform: scale(0.6);
  }
  50% {
    transform: scale(0.8) rotate(-45deg);
  }
  100% {
    transform: scale(1) rotate(180deg);
  }
}

@-webkit-keyframes animateMark {
  0% {
    background-size: 0% 6px;
  }
  100% {
    background-size: 100% 6px;
  }
}

@keyframes animateMark {
  0% {
    background-size: 0% 6px;
  }
  100% {
    background-size: 100% 6px;
  }
}

@-webkit-keyframes sonarWave {
  from {
    opacity: 0.4;
  }
  to {
    transform: scale(2.5);
    opacity: 0;
  }
}

@keyframes sonarWave {
  from {
    opacity: 0.4;
  }
  to {
    transform: scale(2.5);
    opacity: 0;
  }
}

.btn {
  border-radius: 3px;
  font-size: 15px;
  font-weight: 500;
  height: 50px;
  min-width: 137px;
  line-height: 28px;
  padding: 5px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.btn.btn--medium {
  width: 137px;
  height: 50px;
}

.btn.btn--medium-2 {
  width: 142px;
  height: 56px;
}

.btn.btn--medium-3 {
  width: 164px;
  height: 56px;
}

.btn.btn--medium-4 {
  min-width: 158px;
  height: 42px;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 22px;
}

.btn.btn--medium-5 {
  min-width: 177px;
  height: 56px;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 22px;
}

.btn.btn--lg {
  min-width: 172px;
  height: 50px;
  border-radius: 5px;
}

.btn.btn--lg-2 {
  min-width: 180px;
  height: 56px;
  border-radius: 5px;
  font-size: 15px;
}

.btn.btn--lg-3 {
  min-width: 183px;
  height: 56px;
  border-radius: 5px;
  font-size: 15px;
}

.btn.btn--xl {
  min-width: 194px;
  height: 45px;
}

.btn.btn--xxl {
  min-width: 194px;
  height: 50px !important;
  border-radius: 5px;
  line-height: 24px;
  background-color: #005CB9;
  border:1px solid #005CB9;
  line-height: 35px;
}


.trialBTN{
  min-width: 194px;
  height: 60px !important;
  border-radius: 5px;
}


.btn.btn--xxxl {
  min-width: 250px;
  margin: 20px auto 0px auto;
  height: 70px !important;
  background: #005CB9;
  color: #ffffff;
border-radius: 10px !important;
/* font-family: 'Manrope'; */
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 28px;
border: 0px;}

.justify-content-center{align-items: center;}
.btn-align{justify-content: space-between;     display: flex; align-items: center; margin-top: 20px;}

.btn.btn--xxxl.white {
  min-width: 250px;
  margin: 0px auto 0px auto  !important;
  height: 70px !important;
  background: #ffffff;
  color: #005CB9;
border-radius: 10px !important;
/* font-family: 'Manrope'; */
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 28px;
border: 0px;}



.btn.btn--190 {
  width: 190px;
  height: 60px;
}

.btn.btn--btn-header-7 {
  min-width: 172px;
  height: 42px;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: normal;
}

.btn.btn--header-inner {
  min-width: 125px;
  height: 42px;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: normal;
}

.btn.squer--btn {
  min-width: 44px;
  height: 45px;
  border-radius: 10px;
  background-color: rgba(38, 39, 41, 0.3);
  color: #fff;
}

.btn.with-icon i {
  margin-left: -5px;
  margin-right: 3px;
  font-size: 16px;
  margin-top: 2px;
}

.btn.with-icon-2 i {
  margin-left: -5px;
  margin-right: 7px;
  font-size: 21px;
}

.btn-reset {
  background: transparent;
  border: 0;
}

button:focus {
  outline: none;
}

.btn-link {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 28px;
  padding: 0;
  margin: 0;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.btn-link--2 {
  min-width: -webkit-fit-content !important;
  min-width: -moz-fit-content !important;
  min-width: fit-content !important;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 20px 0 !important;
  height: 30px;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 30px;
  text-align: left !important;
  display: inline-flex;
  align-items: center;
}

.btn-link--2.with--line {
  border-bottom: 2px solid var(--border-color);
}

.btn-link--2.with--line.border--primary {
  border-color: #ff5722;
}

.bookmark-button:before {
  content: "\f02e";
  color: inherit;
  font-size: inherit;
  font-weight: 400;
  font-family: "Font Awesome 5 Free";
  display: inline-block;
}

.bookmark-button:hover:before, .bookmark-button.clicked:before {
  font-weight: 900;
  color: green;
}

.btn-primary {
  color: #000;
  background-color: #ef5864;
  border-color: #ef5864;
}

.btn-primary:hover {
  color: #000;
  background-color: #ef5864;
  border-color: #ef5864;
}

.btn-check:focus + .btn-primary, .btn-primary:focus {
  color: #000;
  background-color: #ff7043;
  border-color: #ff6838;
  box-shadow: 0 0 0 0.2rem rgba(217, 74, 29, 0.5);
}

.btn-check:checked + .btn-primary,
.btn-check:active + .btn-primary, .btn-primary:active, .btn-primary.active,
.show > .btn-primary.dropdown-toggle {
  color: #000;
  background-color: #ff794e;
  border-color: #ff6838;
}

.btn-check:checked + .btn-primary:focus,
.btn-check:active + .btn-primary:focus, .btn-primary:active:focus, .btn-primary.active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(217, 74, 29, 0.5);
}

.btn-primary:disabled, .btn-primary.disabled {
  color: #000;
  background-color: #ff5722;
  border-color: #ff5722;
}

.btn-black {
  color: #fff;
  background-color: #000;
  border-color: #000;
}

.btn-black:hover {
  color: #fff;
  background-color: black;
  border-color: black;
}

.btn-check:focus + .btn-black, .btn-black:focus {
  color: #fff;
  background-color: black;
  border-color: black;
  box-shadow: 0 0 0 0.2rem rgba(38, 38, 38, 0.5);
}

.btn-check:checked + .btn-black,
.btn-check:active + .btn-black, .btn-black:active, .btn-black.active,
.show > .btn-black.dropdown-toggle {
  color: #fff;
  background-color: black;
  border-color: black;
}

.btn-check:checked + .btn-black:focus,
.btn-check:active + .btn-black:focus, .btn-black:active:focus, .btn-black.active:focus,
.show > .btn-black.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 38, 38, 0.5);
}

.btn-black:disabled, .btn-black.disabled {
  color: #fff;
  background-color: #000;
  border-color: #000;
}

.btn-white {
  color: #000;
  background-color: #fff;
  border-color: #fff;
}

.btn-white:hover {
  color: #000;
  background-color: white;
  border-color: white;
}

.btn-check:focus + .btn-white, .btn-white:focus {
  color: #000;
  background-color: white;
  border-color: white;
  box-shadow: 0 0 0 0.2rem rgba(217, 217, 217, 0.5);
}

.btn-check:checked + .btn-white,
.btn-check:active + .btn-white, .btn-white:active, .btn-white.active,
.show > .btn-white.dropdown-toggle {
  color: #000;
  background-color: white;
  border-color: white;
}

.btn-check:checked + .btn-white:focus,
.btn-check:active + .btn-white:focus, .btn-white:active:focus, .btn-white.active:focus,
.show > .btn-white.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(217, 217, 217, 0.5);
}

.btn-white:disabled, .btn-white.disabled {
  color: #000;
  background-color: #fff;
  border-color: #fff;
}

.btn-readical-red {
  color: #000;
  background-color: #fd346e;
  border-color: #fd346e;
}

.btn-readical-red:hover {
  color: #000;
  background-color: #fd5284;
  border-color: #fd487d;
}

.btn-check:focus + .btn-readical-red, .btn-readical-red:focus {
  color: #000;
  background-color: #fd5284;
  border-color: #fd487d;
  box-shadow: 0 0 0 0.2rem rgba(215, 44, 94, 0.5);
}

.btn-check:checked + .btn-readical-red,
.btn-check:active + .btn-readical-red, .btn-readical-red:active, .btn-readical-red.active,
.show > .btn-readical-red.dropdown-toggle {
  color: #000;
  background-color: #fd5d8b;
  border-color: #fd487d;
}

.btn-check:checked + .btn-readical-red:focus,
.btn-check:active + .btn-readical-red:focus, .btn-readical-red:active:focus, .btn-readical-red.active:focus,
.show > .btn-readical-red.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(215, 44, 94, 0.5);
}

.btn-readical-red:disabled, .btn-readical-red.disabled {
  color: #000;
  background-color: #fd346e;
  border-color: #fd346e;
}

.btn-torch-red {
  color: #000;
  background-color: #ff1e38;
  border-color: #ff1e38;
}

.btn-torch-red:hover {
  color: #000;
  background-color: #ff4056;
  border-color: #ff354c;
}

.btn-check:focus + .btn-torch-red, .btn-torch-red:focus {
  color: #000;
  background-color: #ff4056;
  border-color: #ff354c;
  box-shadow: 0 0 0 0.2rem rgba(217, 26, 48, 0.5);
}

.btn-check:checked + .btn-torch-red,
.btn-check:active + .btn-torch-red, .btn-torch-red:active, .btn-torch-red.active,
.show > .btn-torch-red.dropdown-toggle {
  color: #000;
  background-color: #ff4b60;
  border-color: #ff354c;
}

.btn-check:checked + .btn-torch-red:focus,
.btn-check:active + .btn-torch-red:focus, .btn-torch-red:active:focus, .btn-torch-red.active:focus,
.show > .btn-torch-red.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(217, 26, 48, 0.5);
}

.btn-torch-red:disabled, .btn-torch-red.disabled {
  color: #000;
  background-color: #ff1e38;
  border-color: #ff1e38;
}

.btn-purple-heart {
  color: #fff;
  background-color: #6a26da;
  border-color: #6a26da;
}

.btn-purple-heart:hover {
  color: #fff;
  background-color: #5a20b9;
  border-color: #551eae;
}

.btn-check:focus + .btn-purple-heart, .btn-purple-heart:focus {
  color: #fff;
  background-color: #5a20b9;
  border-color: #551eae;
  box-shadow: 0 0 0 0.2rem rgba(128, 71, 224, 0.5);
}

.btn-check:checked + .btn-purple-heart,
.btn-check:active + .btn-purple-heart, .btn-purple-heart:active, .btn-purple-heart.active,
.show > .btn-purple-heart.dropdown-toggle {
  color: #fff;
  background-color: #551eae;
  border-color: #501da4;
}

.btn-check:checked + .btn-purple-heart:focus,
.btn-check:active + .btn-purple-heart:focus, .btn-purple-heart:active:focus, .btn-purple-heart.active:focus,
.show > .btn-purple-heart.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(128, 71, 224, 0.5);
}

.btn-purple-heart:disabled, .btn-purple-heart.disabled {
  color: #fff;
  background-color: #6a26da;
  border-color: #6a26da;
}

.btn-electric-violet-2 {
  color: #fff;
  background-color: #5034fc;
  border-color: #5034fc;
}

.btn-electric-violet-2:hover {
  color: #fff;
  background-color: #442cd6;
  border-color: #402aca;
}

.btn-check:focus + .btn-electric-violet-2, .btn-electric-violet-2:focus {
  color: #fff;
  background-color: #442cd6;
  border-color: #402aca;
  box-shadow: 0 0 0 0.2rem rgba(106, 82, 252, 0.5);
}

.btn-check:checked + .btn-electric-violet-2,
.btn-check:active + .btn-electric-violet-2, .btn-electric-violet-2:active, .btn-electric-violet-2.active,
.show > .btn-electric-violet-2.dropdown-toggle {
  color: #fff;
  background-color: #402aca;
  border-color: #3c27bd;
}

.btn-check:checked + .btn-electric-violet-2:focus,
.btn-check:active + .btn-electric-violet-2:focus, .btn-electric-violet-2:active:focus, .btn-electric-violet-2.active:focus,
.show > .btn-electric-violet-2.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(106, 82, 252, 0.5);
}

.btn-electric-violet-2:disabled, .btn-electric-violet-2.disabled {
  color: #fff;
  background-color: #5034fc;
  border-color: #5034fc;
}

.btn-electric-violet-3 {
  color: #fff;
  background-color: #6001d3;
  border-color: #6001d3;
}

.btn-electric-violet-3:hover {
  color: #fff;
  background-color: #5201b3;
  border-color: #4d01a9;
}

.btn-check:focus + .btn-electric-violet-3, .btn-electric-violet-3:focus {
  color: #fff;
  background-color: #5201b3;
  border-color: #4d01a9;
  box-shadow: 0 0 0 0.2rem rgba(120, 39, 218, 0.5);
}

.btn-check:checked + .btn-electric-violet-3,
.btn-check:active + .btn-electric-violet-3, .btn-electric-violet-3:active, .btn-electric-violet-3.active,
.show > .btn-electric-violet-3.dropdown-toggle {
  color: #fff;
  background-color: #4d01a9;
  border-color: #48019e;
}

.btn-check:checked + .btn-electric-violet-3:focus,
.btn-check:active + .btn-electric-violet-3:focus, .btn-electric-violet-3:active:focus, .btn-electric-violet-3.active:focus,
.show > .btn-electric-violet-3.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(120, 39, 218, 0.5);
}

.btn-electric-violet-3:disabled, .btn-electric-violet-3.disabled {
  color: #fff;
  background-color: #6001d3;
  border-color: #6001d3;
}

.btn-bittersweet {
  color: #000;
  background-color: #ff7272;
  border-color: #ff7272;
}

.btn-bittersweet:hover {
  color: #000;
  background-color: #ff8787;
  border-color: #ff8080;
}

.btn-check:focus + .btn-bittersweet, .btn-bittersweet:focus {
  color: #000;
  background-color: #ff8787;
  border-color: #ff8080;
  box-shadow: 0 0 0 0.2rem rgba(217, 97, 97, 0.5);
}

.btn-check:checked + .btn-bittersweet,
.btn-check:active + .btn-bittersweet, .btn-bittersweet:active, .btn-bittersweet.active,
.show > .btn-bittersweet.dropdown-toggle {
  color: #000;
  background-color: #ff8e8e;
  border-color: #ff8080;
}

.btn-check:checked + .btn-bittersweet:focus,
.btn-check:active + .btn-bittersweet:focus, .btn-bittersweet:active:focus, .btn-bittersweet.active:focus,
.show > .btn-bittersweet.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(217, 97, 97, 0.5);
}

.btn-bittersweet:disabled, .btn-bittersweet.disabled {
  color: #000;
  background-color: #ff7272;
  border-color: #ff7272;
}

.btn-niagara {
  color: #000;
  background-color: #099a97;
  border-color: #099a97;
}

.btn-niagara:hover {
  color: #000;
  background-color: #2ea9a7;
  border-color: #22a4a1;
}

.btn-check:focus + .btn-niagara, .btn-niagara:focus {
  color: #000;
  background-color: #2ea9a7;
  border-color: #22a4a1;
  box-shadow: 0 0 0 0.2rem rgba(8, 131, 128, 0.5);
}

.btn-check:checked + .btn-niagara,
.btn-check:active + .btn-niagara, .btn-niagara:active, .btn-niagara.active,
.show > .btn-niagara.dropdown-toggle {
  color: #000;
  background-color: #3aaeac;
  border-color: #22a4a1;
}

.btn-check:checked + .btn-niagara:focus,
.btn-check:active + .btn-niagara:focus, .btn-niagara:active:focus, .btn-niagara.active:focus,
.show > .btn-niagara.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(8, 131, 128, 0.5);
}

.btn-niagara:disabled, .btn-niagara.disabled {
  color: #000;
  background-color: #099a97;
  border-color: #099a97;
}

.btn-yellow {
  color: #000;
  background-color: #fcdc00;
  border-color: #fcdc00;
}

.btn-yellow:hover {
  color: #000;
  background-color: #fce126;
  border-color: #fce01a;
}

.btn-check:focus + .btn-yellow, .btn-yellow:focus {
  color: #000;
  background-color: #fce126;
  border-color: #fce01a;
  box-shadow: 0 0 0 0.2rem rgba(214, 187, 0, 0.5);
}

.btn-check:checked + .btn-yellow,
.btn-check:active + .btn-yellow, .btn-yellow:active, .btn-yellow.active,
.show > .btn-yellow.dropdown-toggle {
  color: #000;
  background-color: #fde333;
  border-color: #fce01a;
}

.btn-check:checked + .btn-yellow:focus,
.btn-check:active + .btn-yellow:focus, .btn-yellow:active:focus, .btn-yellow.active:focus,
.show > .btn-yellow.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(214, 187, 0, 0.5);
}

.btn-yellow:disabled, .btn-yellow.disabled {
  color: #000;
  background-color: #fcdc00;
  border-color: #fcdc00;
}

.btn-shark {
  color: #fff;
  background-color: #262729;
  border-color: #262729;
}

.btn-shark:hover {
  color: #fff;
  background-color: #202123;
  border-color: #1e1f21;
}

.btn-check:focus + .btn-shark, .btn-shark:focus {
  color: #fff;
  background-color: #202123;
  border-color: #1e1f21;
  box-shadow: 0 0 0 0.2rem rgba(71, 71, 73, 0.5);
}

.btn-check:checked + .btn-shark,
.btn-check:active + .btn-shark, .btn-shark:active, .btn-shark.active,
.show > .btn-shark.dropdown-toggle {
  color: #fff;
  background-color: #1e1f21;
  border-color: #1d1d1f;
}

.btn-check:checked + .btn-shark:focus,
.btn-check:active + .btn-shark:focus, .btn-shark:active:focus, .btn-shark.active:focus,
.show > .btn-shark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(71, 71, 73, 0.5);
}

.btn-shark:disabled, .btn-shark.disabled {
  color: #fff;
  background-color: #262729;
  border-color: #262729;
}

.btn-link-water {
  color: #000;
  background-color: #f2f5fb;
  border-color: #f2f5fb;
}

.btn-link-water:hover {
  color: #000;
  background-color: #f4f7fc;
  border-color: #f3f6fb;
}

.btn-check:focus + .btn-link-water, .btn-link-water:focus {
  color: #000;
  background-color: #f4f7fc;
  border-color: #f3f6fb;
  box-shadow: 0 0 0 0.2rem rgba(206, 208, 213, 0.5);
}

.btn-check:checked + .btn-link-water,
.btn-check:active + .btn-link-water, .btn-link-water:active, .btn-link-water.active,
.show > .btn-link-water.dropdown-toggle {
  color: #000;
  background-color: #f5f7fc;
  border-color: #f3f6fb;
}

.btn-check:checked + .btn-link-water:focus,
.btn-check:active + .btn-link-water:focus, .btn-link-water:active:focus, .btn-link-water.active:focus,
.show > .btn-link-water.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(206, 208, 213, 0.5);
}

.btn-link-water:disabled, .btn-link-water.disabled {
  color: #000;
  background-color: #f2f5fb;
  border-color: #f2f5fb;
}

.btn-egg-blue {
  color: #000;
  background-color: #0abfbc;
  border-color: #0abfbc;
}

.btn-egg-blue:hover {
  color: #000;
  background-color: #2fc9c6;
  border-color: #23c5c3;
}

.btn-check:focus + .btn-egg-blue, .btn-egg-blue:focus {
  color: #000;
  background-color: #2fc9c6;
  border-color: #23c5c3;
  box-shadow: 0 0 0 0.2rem rgba(9, 162, 160, 0.5);
}

.btn-check:checked + .btn-egg-blue,
.btn-check:active + .btn-egg-blue, .btn-egg-blue:active, .btn-egg-blue.active,
.show > .btn-egg-blue.dropdown-toggle {
  color: #000;
  background-color: #3bccc9;
  border-color: #23c5c3;
}

.btn-check:checked + .btn-egg-blue:focus,
.btn-check:active + .btn-egg-blue:focus, .btn-egg-blue:active:focus, .btn-egg-blue.active:focus,
.show > .btn-egg-blue.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(9, 162, 160, 0.5);
}

.btn-egg-blue:disabled, .btn-egg-blue.disabled {
  color: #000;
  background-color: #0abfbc;
  border-color: #0abfbc;
}

.btn-outline-primary {
  color: #ff5722;
  border-color: #ff5722;
}

.btn-outline-primary:hover {
  color: #000;
  background-color: #ff5722;
  border-color: #ff5722;
}

.btn-check:focus + .btn-outline-primary, .btn-outline-primary:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 87, 34, 0.5);
}

.btn-check:checked + .btn-outline-primary,
.btn-check:active + .btn-outline-primary, .btn-outline-primary:active, .btn-outline-primary.active, .btn-outline-primary.dropdown-toggle.show {
  color: #000;
  background-color: #ff5722;
  border-color: #ff5722;
}

.btn-check:checked + .btn-outline-primary:focus,
.btn-check:active + .btn-outline-primary:focus, .btn-outline-primary:active:focus, .btn-outline-primary.active:focus, .btn-outline-primary.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 87, 34, 0.5);
}

.btn-outline-primary:disabled, .btn-outline-primary.disabled {
  color: #ff5722;
  background-color: transparent;
}

.btn-outline-black {
  color: #000;
  border-color: #000;
}

.btn-outline-black:hover {
  color: #fff;
  background-color: #000;
  border-color: #000;
}

.btn-check:focus + .btn-outline-black, .btn-outline-black:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
}

.btn-check:checked + .btn-outline-black,
.btn-check:active + .btn-outline-black, .btn-outline-black:active, .btn-outline-black.active, .btn-outline-black.dropdown-toggle.show {
  color: #fff;
  background-color: #000;
  border-color: #000;
}

.btn-check:checked + .btn-outline-black:focus,
.btn-check:active + .btn-outline-black:focus, .btn-outline-black:active:focus, .btn-outline-black.active:focus, .btn-outline-black.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
}

.btn-outline-black:disabled, .btn-outline-black.disabled {
  color: #000;
  background-color: transparent;
}

.btn-outline-white {
  color: #fff;
  border-color: #fff;
}

.btn-outline-white:hover {
  color: #000;
  background-color: #fff;
  border-color: #fff;
}

.btn-check:focus + .btn-outline-white, .btn-outline-white:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-check:checked + .btn-outline-white,
.btn-check:active + .btn-outline-white, .btn-outline-white:active, .btn-outline-white.active, .btn-outline-white.dropdown-toggle.show {
  color: #000;
  background-color: #fff;
  border-color: #fff;
}

.btn-check:checked + .btn-outline-white:focus,
.btn-check:active + .btn-outline-white:focus, .btn-outline-white:active:focus, .btn-outline-white.active:focus, .btn-outline-white.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-outline-white:disabled, .btn-outline-white.disabled {
  color: #fff;
  background-color: transparent;
}

.btn-outline-readical-red {
  color: #fd346e;
  border-color: #fd346e;
}

.btn-outline-readical-red:hover {
  color: #000;
  background-color: #fd346e;
  border-color: #fd346e;
}

.btn-check:focus + .btn-outline-readical-red, .btn-outline-readical-red:focus {
  box-shadow: 0 0 0 0.2rem rgba(253, 52, 110, 0.5);
}

.btn-check:checked + .btn-outline-readical-red,
.btn-check:active + .btn-outline-readical-red, .btn-outline-readical-red:active, .btn-outline-readical-red.active, .btn-outline-readical-red.dropdown-toggle.show {
  color: #000;
  background-color: #fd346e;
  border-color: #fd346e;
}

.btn-check:checked + .btn-outline-readical-red:focus,
.btn-check:active + .btn-outline-readical-red:focus, .btn-outline-readical-red:active:focus, .btn-outline-readical-red.active:focus, .btn-outline-readical-red.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.2rem rgba(253, 52, 110, 0.5);
}

.btn-outline-readical-red:disabled, .btn-outline-readical-red.disabled {
  color: #fd346e;
  background-color: transparent;
}

.btn-outline-torch-red {
  color: #ff1e38;
  border-color: #ff1e38;
}

.btn-outline-torch-red:hover {
  color: #000;
  background-color: #ff1e38;
  border-color: #ff1e38;
}

.btn-check:focus + .btn-outline-torch-red, .btn-outline-torch-red:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 30, 56, 0.5);
}

.btn-check:checked + .btn-outline-torch-red,
.btn-check:active + .btn-outline-torch-red, .btn-outline-torch-red:active, .btn-outline-torch-red.active, .btn-outline-torch-red.dropdown-toggle.show {
  color: #000;
  background-color: #ff1e38;
  border-color: #ff1e38;
}

.btn-check:checked + .btn-outline-torch-red:focus,
.btn-check:active + .btn-outline-torch-red:focus, .btn-outline-torch-red:active:focus, .btn-outline-torch-red.active:focus, .btn-outline-torch-red.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 30, 56, 0.5);
}

.btn-outline-torch-red:disabled, .btn-outline-torch-red.disabled {
  color: #ff1e38;
  background-color: transparent;
}

.btn-outline-purple-heart {
  color: #6a26da;
  border-color: #6a26da;
}

.btn-outline-purple-heart:hover {
  color: #fff;
  background-color: #6a26da;
  border-color: #6a26da;
}

.btn-check:focus + .btn-outline-purple-heart, .btn-outline-purple-heart:focus {
  box-shadow: 0 0 0 0.2rem rgba(106, 38, 218, 0.5);
}

.btn-check:checked + .btn-outline-purple-heart,
.btn-check:active + .btn-outline-purple-heart, .btn-outline-purple-heart:active, .btn-outline-purple-heart.active, .btn-outline-purple-heart.dropdown-toggle.show {
  color: #fff;
  background-color: #6a26da;
  border-color: #6a26da;
}

.btn-check:checked + .btn-outline-purple-heart:focus,
.btn-check:active + .btn-outline-purple-heart:focus, .btn-outline-purple-heart:active:focus, .btn-outline-purple-heart.active:focus, .btn-outline-purple-heart.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.2rem rgba(106, 38, 218, 0.5);
}

.btn-outline-purple-heart:disabled, .btn-outline-purple-heart.disabled {
  color: #6a26da;
  background-color: transparent;
}

.btn-outline-electric-violet-2 {
  color: #5034fc;
  border-color: #5034fc;
}

.btn-outline-electric-violet-2:hover {
  color: #fff;
  background-color: #5034fc;
  border-color: #5034fc;
}

.btn-check:focus + .btn-outline-electric-violet-2, .btn-outline-electric-violet-2:focus {
  box-shadow: 0 0 0 0.2rem rgba(80, 52, 252, 0.5);
}

.btn-check:checked + .btn-outline-electric-violet-2,
.btn-check:active + .btn-outline-electric-violet-2, .btn-outline-electric-violet-2:active, .btn-outline-electric-violet-2.active, .btn-outline-electric-violet-2.dropdown-toggle.show {
  color: #fff;
  background-color: #5034fc;
  border-color: #5034fc;
}

.btn-check:checked + .btn-outline-electric-violet-2:focus,
.btn-check:active + .btn-outline-electric-violet-2:focus, .btn-outline-electric-violet-2:active:focus, .btn-outline-electric-violet-2.active:focus, .btn-outline-electric-violet-2.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.2rem rgba(80, 52, 252, 0.5);
}

.btn-outline-electric-violet-2:disabled, .btn-outline-electric-violet-2.disabled {
  color: #5034fc;
  background-color: transparent;
}

.btn-outline-electric-violet-3 {
  color: #6001d3;
  border-color: #6001d3;
}

.btn-outline-electric-violet-3:hover {
  color: #fff;
  background-color: #6001d3;
  border-color: #6001d3;
}

.btn-check:focus + .btn-outline-electric-violet-3, .btn-outline-electric-violet-3:focus {
  box-shadow: 0 0 0 0.2rem rgba(96, 1, 211, 0.5);
}

.btn-check:checked + .btn-outline-electric-violet-3,
.btn-check:active + .btn-outline-electric-violet-3, .btn-outline-electric-violet-3:active, .btn-outline-electric-violet-3.active, .btn-outline-electric-violet-3.dropdown-toggle.show {
  color: #fff;
  background-color: #6001d3;
  border-color: #6001d3;
}

.btn-check:checked + .btn-outline-electric-violet-3:focus,
.btn-check:active + .btn-outline-electric-violet-3:focus, .btn-outline-electric-violet-3:active:focus, .btn-outline-electric-violet-3.active:focus, .btn-outline-electric-violet-3.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.2rem rgba(96, 1, 211, 0.5);
}

.btn-outline-electric-violet-3:disabled, .btn-outline-electric-violet-3.disabled {
  color: #6001d3;
  background-color: transparent;
}

.btn-outline-bittersweet {
  color: #ff7272;
  border-color: #ff7272;
}

.btn-outline-bittersweet:hover {
  color: #000;
  background-color: #ff7272;
  border-color: #ff7272;
}

.btn-check:focus + .btn-outline-bittersweet, .btn-outline-bittersweet:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 114, 114, 0.5);
}

.btn-check:checked + .btn-outline-bittersweet,
.btn-check:active + .btn-outline-bittersweet, .btn-outline-bittersweet:active, .btn-outline-bittersweet.active, .btn-outline-bittersweet.dropdown-toggle.show {
  color: #000;
  background-color: #ff7272;
  border-color: #ff7272;
}

.btn-check:checked + .btn-outline-bittersweet:focus,
.btn-check:active + .btn-outline-bittersweet:focus, .btn-outline-bittersweet:active:focus, .btn-outline-bittersweet.active:focus, .btn-outline-bittersweet.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 114, 114, 0.5);
}

.btn-outline-bittersweet:disabled, .btn-outline-bittersweet.disabled {
  color: #ff7272;
  background-color: transparent;
}

.btn-outline-niagara {
  color: #099a97;
  border-color: #099a97;
}

.btn-outline-niagara:hover {
  color: #000;
  background-color: #099a97;
  border-color: #099a97;
}

.btn-check:focus + .btn-outline-niagara, .btn-outline-niagara:focus {
  box-shadow: 0 0 0 0.2rem rgba(9, 154, 151, 0.5);
}

.btn-check:checked + .btn-outline-niagara,
.btn-check:active + .btn-outline-niagara, .btn-outline-niagara:active, .btn-outline-niagara.active, .btn-outline-niagara.dropdown-toggle.show {
  color: #000;
  background-color: #099a97;
  border-color: #099a97;
}

.btn-check:checked + .btn-outline-niagara:focus,
.btn-check:active + .btn-outline-niagara:focus, .btn-outline-niagara:active:focus, .btn-outline-niagara.active:focus, .btn-outline-niagara.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.2rem rgba(9, 154, 151, 0.5);
}

.btn-outline-niagara:disabled, .btn-outline-niagara.disabled {
  color: #099a97;
  background-color: transparent;
}

.btn-outline-yellow {
  color: #fcdc00;
  border-color: #fcdc00;
}

.btn-outline-yellow:hover {
  color: #000;
  background-color: #fcdc00;
  border-color: #fcdc00;
}

.btn-check:focus + .btn-outline-yellow, .btn-outline-yellow:focus {
  box-shadow: 0 0 0 0.2rem rgba(252, 220, 0, 0.5);
}

.btn-check:checked + .btn-outline-yellow,
.btn-check:active + .btn-outline-yellow, .btn-outline-yellow:active, .btn-outline-yellow.active, .btn-outline-yellow.dropdown-toggle.show {
  color: #000;
  background-color: #fcdc00;
  border-color: #fcdc00;
}

.btn-check:checked + .btn-outline-yellow:focus,
.btn-check:active + .btn-outline-yellow:focus, .btn-outline-yellow:active:focus, .btn-outline-yellow.active:focus, .btn-outline-yellow.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.2rem rgba(252, 220, 0, 0.5);
}

.btn-outline-yellow:disabled, .btn-outline-yellow.disabled {
  color: #fcdc00;
  background-color: transparent;
}

.btn-outline-shark {
  color: #262729;
  border-color: #262729;
}

.btn-outline-shark:hover {
  color: #fff;
  background-color: #262729;
  border-color: #262729;
}

.btn-check:focus + .btn-outline-shark, .btn-outline-shark:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 39, 41, 0.5);
}

.btn-check:checked + .btn-outline-shark,
.btn-check:active + .btn-outline-shark, .btn-outline-shark:active, .btn-outline-shark.active, .btn-outline-shark.dropdown-toggle.show {
  color: #fff;
  background-color: #262729;
  border-color: #262729;
}

.btn-check:checked + .btn-outline-shark:focus,
.btn-check:active + .btn-outline-shark:focus, .btn-outline-shark:active:focus, .btn-outline-shark.active:focus, .btn-outline-shark.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 39, 41, 0.5);
}

.btn-outline-shark:disabled, .btn-outline-shark.disabled {
  color: #262729;
  background-color: transparent;
}

.btn-outline-link-water {
  color: #f2f5fb;
  border-color: #f2f5fb;
}

.btn-outline-link-water:hover {
  color: #000;
  background-color: #f2f5fb;
  border-color: #f2f5fb;
}

.btn-check:focus + .btn-outline-link-water, .btn-outline-link-water:focus {
  box-shadow: 0 0 0 0.2rem rgba(242, 245, 251, 0.5);
}

.btn-check:checked + .btn-outline-link-water,
.btn-check:active + .btn-outline-link-water, .btn-outline-link-water:active, .btn-outline-link-water.active, .btn-outline-link-water.dropdown-toggle.show {
  color: #000;
  background-color: #f2f5fb;
  border-color: #f2f5fb;
}

.btn-check:checked + .btn-outline-link-water:focus,
.btn-check:active + .btn-outline-link-water:focus, .btn-outline-link-water:active:focus, .btn-outline-link-water.active:focus, .btn-outline-link-water.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.2rem rgba(242, 245, 251, 0.5);
}

.btn-outline-link-water:disabled, .btn-outline-link-water.disabled {
  color: #f2f5fb;
  background-color: transparent;
}

.btn-outline-egg-blue {
  color: #0abfbc;
  border-color: #0abfbc;
}

.btn-outline-egg-blue:hover {
  color: #000;
  background-color: #0abfbc;
  border-color: #0abfbc;
}

.btn-check:focus + .btn-outline-egg-blue, .btn-outline-egg-blue:focus {
  box-shadow: 0 0 0 0.2rem rgba(10, 191, 188, 0.5);
}

.btn-check:checked + .btn-outline-egg-blue,
.btn-check:active + .btn-outline-egg-blue, .btn-outline-egg-blue:active, .btn-outline-egg-blue.active, .btn-outline-egg-blue.dropdown-toggle.show {
  color: #000;
  background-color: #0abfbc;
  border-color: #0abfbc;
}

.btn-check:checked + .btn-outline-egg-blue:focus,
.btn-check:active + .btn-outline-egg-blue:focus, .btn-outline-egg-blue:active:focus, .btn-outline-egg-blue.active:focus, .btn-outline-egg-blue.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.2rem rgba(10, 191, 188, 0.5);
}

.btn-outline-egg-blue:disabled, .btn-outline-egg-blue.disabled {
  color: #0abfbc;
  background-color: transparent;
}

.check-input-control {
  margin-bottom: 20px;
}

.check-input-control input:checked ~ .checkbox {
  background: #ff5722 !important;
  border-color: #ff5722;
}

.check-input-control input:checked ~ .checkbox::after {
  border-color: #ff5722;
  background-color: #ff5722;
}

.check-input-control .checkbox {
  position: relative;
  line-height: 1;
  min-width: 14px;
  max-width: 14px;
  min-height: 14px;
  max-height: 14px;
  border-radius: 2px;
  border: 1px solid gray;
  display: inline-block;
  cursor: pointer;
}

.check-input-control .checkbox:after {
  content: "";
  color: black;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 2px;
  border: 1px solid #e5e5e5;
  background-color: #ffffff;
}

/* Newsletter One */
.newsletter-form__text {
  margin-top: 15px;
  margin-bottom: 0;
  color: rgba(38, 39, 41, 0.7);
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: 28px;
}

.newsletter-form .btn {
  width: 100%;
  height: 70px;
  border-radius: 10px;
}

@media (min-width: 480px) {
  .newsletter-form .btn {
    width: 172px;
  }
}

.newsletter-form input {
  width: 100%;
  border-radius: 10px;
  border: 2px solid rgba(128, 138, 142, 0.2);
  color: rgba(37, 55, 63, 0.7);
  font-size: 16px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 28px;
  padding: 5px 25px;
  margin-bottom: 10px;
  height: 70px;
}

@media (min-width: 480px) {
  .newsletter-form input {
    margin-right: 7px;
    margin-bottom: 0;
  }
}

/* Newsletter Two */
.newsletter--footer {
  max-width: 531px;
  margin-top: 45px;
  margin-bottom: 50px;
}

@media (min-width: 992px) {
  .newsletter--footer {
    margin-bottom: 155px;
  }
}

.newsletter--footer input {
  transition: 0.4s;
  background-color: rgba(255, 255, 255, 0.2);
  font-size: 16px;
  font-weight: 400;
  letter-spacing: normal;
  width: 100%;
  border: none !important;
  padding: 0 25px;
}

.newsletter--footer input::-moz-placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.newsletter--footer input:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.newsletter--footer input::placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.newsletter--footer input:focus {
  background-color: #fff !important;
}

.newsletter--footer input:focus::-moz-placeholder {
  color: #000;
}

.newsletter--footer input:focus:-ms-input-placeholder {
  color: #000;
}

.newsletter--footer input:focus::placeholder {
  color: #000;
}

.newsletter-form--l2,
.newsletter-form--countdown {
  border-radius: 75px;
  max-width: 516px;
}

@media (min-width: 480px) {
  .newsletter-form--l2,
  .newsletter-form--countdown {
    background-color: #f2f5fb;
    border: 2px solid #f8f9fc;
  }
}

.newsletter-form--l2 input,
.newsletter-form--countdown input {
  margin-left: 5px;
}

@media (min-width: 480px) {
  .newsletter-form--l2 input,
  .newsletter-form--countdown input {
    border: none;
    background-color: transparent;
  }
}

.newsletter-form--l2 input:focus,
.newsletter-form--countdown input:focus {
  background-color: transparent !important;
}

.newsletter-form--l2 input:focus::-moz-placeholder, .newsletter-form--countdown input:focus::-moz-placeholder {
  color: #000;
}

.newsletter-form--l2 input:focus:-ms-input-placeholder, .newsletter-form--countdown input:focus:-ms-input-placeholder {
  color: #000;
}

.newsletter-form--l2 input:focus::placeholder,
.newsletter-form--countdown input:focus::placeholder {
  color: #000;
}

.newsletter-form--l2 .btn,
.newsletter-form--countdown .btn {
  height: 50px !important;
}

@media (min-width: 480px) {
  .newsletter-form--l2 .btn,
  .newsletter-form--countdown .btn {
    margin-right: 10px;
  }
}

/*
  Home 3 : Footer
---------------------------------*/
.contact-form .form-floating label,
.contact-form-inner-2 .form-floating label {
  padding-left: 25px;
  font-size: 15px;
  min-height: 45px;
  padding-top: 0;
  padding-bottom: 0;
  color: rgba(38, 39, 41, 0.5);
}

.contact-form .form-floating textarea,
.contact-form-inner-2 .form-floating textarea {
  min-height: 175px;
}

.contact-form .form-floating textarea ~ label,
.contact-form-inner-2 .form-floating textarea ~ label {
  margin-top: 20px;
}

.contact-form .form-floating input ~ label,
.contact-form-inner-2 .form-floating input ~ label {
  display: flex;
  align-items: center;
}

.contact-form .form-control,
.contact-form-inner-2 .form-control {
  background: #fff;
  padding-left: 25px;
  padding-right: 25px;
  border: 0;
  font-size: 15px;
}

.contact-form input.form-control,
.contact-form-inner-2 input.form-control {
  border-radius: 500px;
  background: #fff;
  color: #262729;
  height: 45px !important;
}

.contact-form input.form-control::-moz-placeholder, .contact-form-inner-2 input.form-control::-moz-placeholder {
  color: transparent;
}

.contact-form input.form-control:-ms-input-placeholder, .contact-form-inner-2 input.form-control:-ms-input-placeholder {
  color: transparent;
}

.contact-form input.form-control::placeholder,
.contact-form-inner-2 input.form-control::placeholder {
  color: transparent;
}

.contact-form textarea,
.contact-form-inner-2 textarea {
  min-height: 175px;
  border-radius: 15px;
}

.contact-form .form-check input.form-check-input,
.contact-form-inner-2 .form-check input.form-check-input {
  min-width: 20px;
  min-height: 20px;
  max-width: 20px;
  max-height: 20px;
  border-radius: 3px;
  border: 0;
}

.contact-form .form-check input.form-check-input:checked[type="checkbox"],
.contact-form-inner-2 .form-check input.form-check-input:checked[type="checkbox"] {
  background-color: var(--bs-secondary) !important;
  box-shadow: none;
}

.contact-form .form-check input.form-check-input:focus,
.contact-form-inner-2 .form-check input.form-check-input:focus {
  box-shadow: none;
}

.contact-form .form-check .form-check-label,
.contact-form-inner-2 .form-check .form-check-label {
  margin-left: 8px;
  font-size: 13px;
}

/*
  Home 4 : Contact Form
---------------------------------*/
.contact-section__form-heading {
  font-size: 21px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 30px;
  color: var(--color-headings-2);
  margin-bottom: 28px;
}

.contact-section .form-control,
.contact-section .form-check-input {
  border: 1px solid var(--border-color-3) !important;
}

.newsletter-form--rounded {
  position: relative;
}

.newsletter-form--rounded .form-control {
  height: 45px;
  border-radius: 50px;
  border: 1px solid rgba(38, 39, 41, 0.2);
  background-color: #fff;
  color: rgba(38, 39, 41, 0.8);
}

.newsletter-form--rounded .form-control::-moz-placeholder {
  color: rgba(38, 39, 41, 0.5);
}

.newsletter-form--rounded .form-control:-ms-input-placeholder {
  color: rgba(38, 39, 41, 0.5);
}

.newsletter-form--rounded .form-control::placeholder {
  color: rgba(38, 39, 41, 0.5);
}

.newsletter-form--rounded .btn-icon {
  width: 30px;
  height: 30px;
  box-shadow: 0 10px 30px rgba(253, 52, 110, 0.3);
  background-color: #ff5722;
  color: #fff;
  border-radius: 500px;
  position: absolute;
  right: 10px;
  top: 40%;
  border: 0;
  transform: translateY(-50%);
}

@media (min-width: 480px) {
  .newsletter-form--rounded .btn-icon {
    margin-bottom: 0;
    top: 50%;
    right: 16px;
  }
}

/*
  Home 6 : Promo Secion
---------------------------------*/
.promo--l6-newsletter {
  border-radius: 56px;
}

@media (min-width: 480px) {
  .promo--l6-newsletter {
    background-color: #fd5284;
  }
}

.promo--l6-newsletter input {
  background-color: #fd5284;
  border-radius: 56px;
  height: 60px;
  border: none;
  color: #fff !important;
}

@media (min-width: 480px) {
  .promo--l6-newsletter input {
    background-color: transparent;
  }
}

.promo--l6-newsletter input:placeholder {
  color: #fff !important;
  border-radius: 56px;
  background-color: #fd5284;
}

.promo--l6-newsletter .btn {
  min-width: 140px;
  height: 50px;
  border-radius: 25px;
  box-shadow: 0 8px 55px rgba(96, 1, 211, 0.4);
}

@media (min-width: 480px) {
  .promo--l6-newsletter .btn {
    margin-right: 4px;
  }
}

.newsletter--l7 form {
  text-align: right;
}

.newsletter--l7 form input {
  width: 100%;
  border: none;
  height: 56px;
  border-radius: 50px;
  background-color: #fff;
  margin-bottom: 20px;
  padding: 0 20px;
}

.newsletter--l7 form input:focus {
  outline: none;
  box-shadow: none;
}

.newsletter--l7 form .btn {
  width: 150px;
  height: 56px;
  border-radius: 50px;
  color: #fff;
  text-align: right;
  margin-right: auto;
}

/*
  Home 6 : Promo Secion
---------------------------------*/
.contact-section--inner-2 .contact-form input,
.contact-section--inner-2 .contact-form textarea,
.contact-section--inner-2 .contact-form .form-check-input {
  background-color: transparent !important;
}

.contact-section--inner-2 .form-check-label {
  font-size: 15px !important;
  font-weight: 400;
  line-height: 28px;
  color: var(--color-texts-3);
}

/* Search Form */
.search-form {
  background-color: #fff;
  border-radius: 9px;
  padding: 0 40px;
}

.search-form form {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.search-form form input {
  height: 75px;
  border: none;
  width: 100%;
  border-radius: 9px;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 30px;
  color: rgba(38, 39, 41, 0.7);
}

.search-form form input:focus {
  outline: none;
  box-shadow: none;
}

.search-form form button {
  border: none;
  background-color: transparent;
  color: #fd346e;
  font-size: 21px;
}

.search-form form button:focus {
  outline: none;
  box-shadow: none;
}

.newsletter-form--countdown {
  max-width: inherit;
}

@media (min-width: 480px) {
  .newsletter-form--countdown {
    background-color: #fff;
  }
}

.newsletter--inner-career form {
  text-align: center;
  border-radius: 50px;
  padding: 0 10px;
}

@media (min-width: 480px) {
  .newsletter--inner-career form {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    text-align: left;
  }
}

.newsletter--inner-career form .form-control {
  background-color: #fff !important;
  display: flex;
  align-items: center;
  border: none;
  height: 72px;
  border-radius: 50px;
  margin-bottom: 10px;
}

@media (min-width: 480px) {
  .newsletter--inner-career form .form-control {
    background-color: transparent;
    margin-bottom: 0;
  }
}

.newsletter--inner-career form .form-control i {
  margin-right: 10px;
}

.newsletter--inner-career form .form-control input {
  height: 100%;
  background-color: transparent;
  border: none;
  width: 100%;
  display: block;
}

.newsletter--inner-career form .form-control input:focus {
  border: none;
  outline: none;
}

/*
  Home 9 : Promo Sections
---------------------------------*/
.promo--l9-newsletter form {
  border-radius: 35px;
}

@media (min-width: 480px) {
  .promo--l9-newsletter form {
    background-color: rgba(255, 255, 255, 0.4);
  }
}

.promo--l9-newsletter form input {
  height: 58px;
}

@media (min-width: 480px) {
  .promo--l9-newsletter form input {
    background-color: transparent;
  }
}

.promo--l9-newsletter form input:focus {
  background-color: transparent;
}

@media (min-width: 480px) {
  .promo--l9-newsletter form input::-moz-placeholder {
    color: rgba(255, 255, 255, 0.6);
  }
  .promo--l9-newsletter form input:-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.6);
  }
  .promo--l9-newsletter form input::placeholder {
    color: rgba(255, 255, 255, 0.6);
  }
}

.promo--l9-newsletter form .btn {
  min-width: 50px;
  max-width: 50px;
  min-height: 50px;
  max-height: 50px;
  border-radius: 50%;
}

.newsletter--l4 form {
  text-align: center;
  border-radius: 50px;
  padding: 0 10px;
}

@media (min-width: 480px) {
  .newsletter--l4 form {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    text-align: left;
  }
}

.newsletter--l4 form .form-control {
  background-color: #fff !important;
  display: flex;
  align-items: center;
  border: none;
  height: 72px;
  border-radius: 50px;
  margin-bottom: 10px;
}

@media (min-width: 480px) {
  .newsletter--l4 form .form-control {
    background-color: transparent;
    margin-bottom: 0;
  }
}

.newsletter--l4 form .form-control i {
  margin-right: 10px;
  font-weight: 300;
  font-size: 18px;
}

.newsletter--l4 form .form-control input {
  height: 100%;
  background-color: transparent;
  border: none;
  width: 100%;
  display: block;
}

.newsletter--l4 form .form-control input:focus {
  border: none;
  outline: none;
}

.newsletter--l4 form .btn {
  min-width: 174px;
  height: 56px;
  border-radius: 50px;
}
.containerBrands{
  max-width: 1320px;
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;}
.newsletter--l4__text {
  color: #828288;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 30px;
  margin-top: 25px;
}

/*Preloader css*/
div#loading {
  width: 100%;
  /* height: 100vh; */
  position: fixed;
  z-index: 9999999;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
}

div#loading img {
  z-index: 9999;
  width: 25%;
}

@media (min-width: 576px) {
  div#loading img {
    width: 17%;
  }
}

@media (min-width: 768px) {
  div#loading img {
    width: 15%;
  }
}

@media (min-width: 992px) {
  div#loading img {
    width: 7%;
  }
}

.preloader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  /* height: 100vh; */
  background-color: #ffffff !important;
}

.preloader img {
  width: 500px;
}

/*Header Css StaRT*/
.site-navbar {
  display: flex;
  position: relative;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

@media (min-width: 992px) {
  .site-navbar {
    flex-flow: row nowrap;
    justify-content: flex-start;
    padding-top: 0px;
  }
}

.site-navbar .menu-block-wrapper {
  flex-grow: 1;
  align-items: center;
}

@media (min-width: 992px) {
  .site-navbar .menu-block-wrapper {
    display: flex !important;
    flex-basis: auto;
  }
}

.site-header--absolute {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
}

.site-header--sticky:not(.mobile-sticky-enable) {
  position: absolute !important;
  top: 0;
  right: 0;
  width: 100%;
  z-index: 999;
}

@media (min-width: 992px) {
  .site-header--sticky:not(.mobile-sticky-enable) {
    position: fixed !important;
    transition: 0.4s;
  }
  .site-header--sticky:not(.mobile-sticky-enable).scrolling {
    transform: translateY(-100%);
    transition: 0.4s;
  }
  .site-header--sticky:not(.mobile-sticky-enable).reveal-header {
    transform: translateY(0%);
    box-shadow: 0 12px 34px -11px rgba(65, 62, 101, 0.1);
    z-index: 1000;
  }
  .site-header--sticky:not(.mobile-sticky-enable).reveal-header a {
    color: #262729;
  }
  .site-header--sticky:not(.mobile-sticky-enable).reveal-header .btn-link {
    color: var(--color-headings-2);
  }
}

.site-header--sticky.mobile-sticky-enable {
  top: 0;
  right: 0;
  width: 100%;
  z-index: 999;
  position: fixed !important;
  transition: 0.4s;
}

.site-header--sticky.mobile-sticky-enable.scrolling {
  transform: translateY(-100%);
  transition: 0.4s;
}

.site-header--sticky.mobile-sticky-enable.reveal-header {
  transform: translateY(0%);
  box-shadow: 0 12px 34px -11px rgba(65, 62, 101, 0.1);
  z-index: 9999;
  height: 100%;
  background-color: transparent !important;
}

.site-header--sticky.mobile-sticky-enable.reveal-header .site-navbar {
  background-color: #fff;
  padding: 15px 10px;
  border-radius: 5px;
  box-shadow: 0 12px 34px -11px rgba(65, 62, 101, 0.1);
}

@media (min-width: 768px) {
  .site-header--sticky.mobile-sticky-enable.reveal-header {
    height: auto;
  }
}

.site-header--menu-center .container {
  position: relative;
}

.site-header--menu-center .menu-block-wrapper {
  position: static;
}

@media (min-width: 992px) {
  .site-header--menu-center .menu-block {
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 992px) {
  .site-header--menu-left .container-fluid .sub-menu.megamenu {
    left: 50%;
    transform: translateX(-50%) translateY(10px);
  }
}

@media (min-width: 992px) {
  .site-header--menu-left .container-fluid .nav-item.has-megamenu:hover > .sub-menu {
    transform: translateX(-50%) translateY(-2px);
    left: 50%;
  }
}

.site-header--menu-left .menu-block .site-menu-main {
  justify-content: center;
  padding-left: 15px;
}

@media (min-width: 1200px) {
  .site-header--menu-left .menu-block {
    width: 100%;
  }
}

.site-header--menu-right .menu-block {
  margin-left: auto;
}

@media (min-width: 992px) {
  .site-header--menu-right > .container-fluid .sub-menu.megamenu {
    left: 100%;
    transform: translateX(-100%) translateY(10px);
  }
}

@media (min-width: 992px) {
  .site-header--menu-right > .container-fluid .nav-item.has-megamenu:hover > .sub-menu {
    transform: translateX(-100%) translateY(-10px);
    left: 100%;
  }
}

@media (min-width: 480px) {
  .header-btns {
    margin-right: 15px;
  }
  .header-btns a:first-child {
    margin-right: 30px;
  }
}

@media (min-width: 992px) {
  .header-btns {
    margin-right: 0;
  }

  .header-btns a:first-child {
    margin-right: 30px;
  }
}

.dynamic-sticky-bg.reveal-header {
  background: var(--bg);
}

.dynamic-sticky-bg.dark-mode-texts .menu-block.active .nav-link-item {
  color: #262729 !important;
}

.dynamic-sticky-bg.dark-mode-texts .menu-social-share li a {
  color: var(--color-headings-2);
}

.sticky-bg-white.reveal-header {
  background: #fff;
}

.sticky-bg-white.reveal-header .dark-version-logo {
  display: none !important;
}

.sticky-bg-white.reveal-header .light-version-logo {
  display: block !important;
}

.sticky-bg-white.reveal-header .menu-sep-left {
  position: relative;
}

.sticky-bg-white.reveal-header .menu-sep-left::before {
  content: "";
  border-left: rgba(38, 39, 41, 0.5) !important;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 2px;
  height: 26px;
  z-index: 9;
}

.notification-block {
  position: relative;
}

.notification-block .count {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: -4px;
  margin-right: -5px;
}

body[data-theme="dark"] .reveal-header .menu-social-share li a,
.dark-mode-texts .reveal-header .menu-social-share li a {
  color: #fff;
  transition: 0.4s;
}

body[data-theme="dark"] .reveal-header .menu-social-share li a:hover,
.dark-mode-texts .reveal-header .menu-social-share li a:hover {
  color: #5034fc;
}

body[data-theme="dark"] .reveal-header .nav-link-item,
.dark-mode-texts .reveal-header .nav-link-item {
  color: #262729 !important;
}

@media (min-width: 992px) {
  body[data-theme="dark"] .reveal-header .nav-link-item,
  .dark-mode-texts .reveal-header .nav-link-item {
    color: var(--color-headings-2) !important;
  }
}

@media (min-width: 992px) {
  .row-lg {
    display: flex;
  }
}

@-webkit-keyframes slideLeft {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}

@keyframes slideLeft {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}

@-webkit-keyframes slideRight {
  0% {
    opacity: 1;
    transform: translateX(0%);
  }
  100% {
    opacity: 0;
    transform: translateX(100%);
  }
}

@keyframes slideRight {
  0% {
    opacity: 1;
    transform: translateX(0%);
  }
  100% {
    opacity: 0;
    transform: translateX(100%);
  }
}

.menu-block-wrapper {
  position: relative;
}

.site-menu-main {
  margin-bottom: 0;
  padding-left: 0;
}

@media (min-width: 992px) {
  .site-menu-main {
    display: flex;
    margin-bottom: 0;
    padding-left: 0;
  }
}

.site-menu-main li {
  list-style: none;
}

.site-menu-main ul {
  list-style: none;
  margin-left: 0;
}

.site-menu-main a {
  text-decoration: none;
  padding-top: 25px !important;
    padding-bottom: 23px !important;
    padding-left: 24px !important;
    padding-right: 24px !important;
}

.site-menu-main .nav-item {
  display: inherit;
}

.site-menu-main .nav-link-item {
  color: var(--color-headings-2);
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.6px;
  line-height: 1.75;
  position: relative;
}

@media (min-width: 992px) {
  .site-menu-main .nav-link-item {
    padding-top: 25px !important;
    padding-bottom: 23px !important;
    padding-left: 18px !important;
    padding-right: 18px !important;
  }
}

@media (min-width: 1200px) {
  .site-menu-main .nav-link-item {
    padding-top: 25px !important;
    padding-bottom: 23px !important;
    padding-left: 24px !important;
    padding-right: 24px !important;
  }
}

.site-menu-main .sub-menu {
  position: absolute;
  z-index: 500;
  background-color: #fff;
  box-shadow: -2px 2px 70px -25px rgba(0, 0, 0, 0.3);
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 0;
  padding-right: 0;
  transition: opacity 0.3s ease, top 0.3s ease, margin-top 0.3s ease, background-color 0.3s ease;
  margin-top: 25px;
  opacity: 0;
  visibility: hidden;
  z-index: -999;
}

@media (min-width: 992px) {
  .site-menu-main .sub-menu {
    top: 100%;
    min-width: 227px;
    max-width: 227px;
  }
}

@media (min-width: 992px) {
  .site-menu-main .sub-menu .sub-menu {
    top: 0 !important;
    left: 100%;
  }
}

.site-menu-main .sub-menu--item {
  color: #262729 !important;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 1.75;
  transition: 0.4s;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

@media (min-width: 992px) {
  .site-menu-main .sub-menu--item {
    padding-top: 0;
    padding-bottom: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0);
  }
}

.site-menu-main .sub-menu--item > a {
  color: inherit;
  transition: 0.4s;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 15px;
  padding-bottom: 15px;
}

@media (min-width: 992px) {
  .site-menu-main .sub-menu--item > a {
    transition: 0.4s;
    padding-top: 7px;
    padding-bottom: 10px;
    padding-left: 25px;
    padding-right: 25px;
  }
}

.site-menu-main .sub-menu--item > a > i {
  margin-top: 8px;
  min-width: 10px;
  max-width: 10px;
  min-height: 10px;
  max-height: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.site-menu-main .sub-menu--item:hover > a {
  color: #ff5722;
}

@media (min-width: 992px) {
  .site-menu-main .sub-menu--item:first-child a {
    padding-top: 0;
    padding-bottom: 7px;
  }
}

.site-menu-main .sub-menu--item:last-child {
  border: none;
  padding-top: 0;
  padding-bottom: 0;
}

.site-menu-main .sub-menu--item:last-child a {
  padding-bottom: 0;
}

@media (min-width: 992px) {
  .site-menu-main .sub-menu.megamenu {
    background-color: #fff;
  }
}

.site-menu-main .sub-menu.megamenu .dropdown-image-block {
  max-height: 336px;
}

@media (min-width: 320px) and (max-width: 992px) {
  .site-menu-main .sub-menu.megamenu {
    border: 0;
  }
}

@media (min-width: 992px) {
  .site-menu-main .sub-menu.megamenu {
    padding-top: 30px;
    padding-bottom: 15px;
    padding-left: 40px;
    padding-right: 40px;
    min-width: 925px;
    top: 100%;
    left: 50%;
    transform: translateX(-50%) translateY(10px);
    will-change: transform;
    top: 100%;
    box-shadow: 0 42px 54px rgba(0, 0, 0, 0.09);
    border: 1px solid #e7e9ed;
    border-radius: 8px;
    visibility: hidden;
    z-index: -99;
    opacity: 0;
    pointer-events: none;
  }
  .site-menu-main .sub-menu.megamenu.megadropdown-center {
    transform: translate(-50%, 10px);
  }
  .site-menu-main .sub-menu.megamenu.megadropdown-right {
    right: 0%;
    transform: translate(-12%, 10px);
  }
  .site-menu-main .sub-menu.megamenu.megadropdown-left {
    left: 0%;
    transform: translate(-16%, 10px);
  }
}

@media (min-width: 1200px) {
  .site-menu-main .sub-menu.megamenu {
    min-width: 1100px;
  }
  .site-menu-main .sub-menu.megamenu.megadropdown-center {
    transform: translate(-50%, 10px);
  }
  .site-menu-main .sub-menu.megamenu.megadropdown-right {
    right: 0%;
    transform: translate(-12%, 10px);
  }
  .site-menu-main .sub-menu.megamenu.megadropdown-left {
    left: 0%;
    transform: translate(-13%, 10px);
  }
}

@media (min-width: 1366px) {
  .site-menu-main .sub-menu.megamenu {
    min-width: 1300px;
  }
  .site-menu-main .sub-menu.megamenu.megadropdown-center {
    transform: translate(-50%, -10px);
  }
  .site-menu-main .sub-menu.megamenu.megadropdown-right {
    right: 0%;
    transform: translate(-12%, 0);
  }
  .site-menu-main .sub-menu.megamenu.megadropdown-left {
    left: 0%;
    transform: translate(-12%, 0);
  }
}

.site-menu-main .sub-menu.megamenu .single-dropdown-block .mega-drop-menu-item {
  padding-top: 14px;
  padding-bottom: 14px;
  display: block;
}

.site-menu-main .sub-menu.megamenu .single-dropdown-block h3 {
  color: rgba(38, 39, 41, 0.5) !important;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 1.75;
  transition: 0.4s;
  margin-left: 10px;
  padding-top: 12px;
  text-transform: uppercase;
}

@media (min-width: 992px) {
  .site-menu-main .sub-menu.megamenu .single-dropdown-block h3 {
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: 15px;
  }
}

.site-menu-main .sub-menu.megamenu .single-dropdown-block .mega-drop-menu-item {
  padding-top: 8px;
  padding-bottom: 8px;
  display: block;
}

.site-menu-main .sub-menu.megamenu .single-dropdown-block a {
  color: #262729 !important;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 1.75;
  transition: 0.4s;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

@media (min-width: 992px) {
  .site-menu-main .sub-menu.megamenu .single-dropdown-block a {
    padding-top: 0;
    padding-bottom: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0);
  }
}

.site-menu-main .sub-menu.megamenu .single-dropdown-block a:hover {
  color: #ff5722;
}

.site-menu-main .sub-menu.megamenu .mega-drop-menu-item {
  padding-top: 2.5px;
  padding-bottom: 2.5px;
  font-size: 16px;
  font-weight: 700;
  transition: 0.4s;
  color: #000;
}

@media (min-width: 320px) and (max-width: 992px) {
  .site-menu-main .sub-menu.megamenu .mega-drop-menu-item {
    color: var(--color-headings);
    border-top: 1px solid var(--border-color-3);
    padding-left: 20px;
    padding-bottom: 13px;
    padding-top: 13px;
  }
}

@media (min-width: 992px) {
  .site-menu-main .sub-menu.megamenu .mega-drop-menu-item {
    padding-left: 10px;
    padding-right: 10px;
  }
  .site-menu-main .sub-menu.megamenu .mega-drop-menu-item:hover {
    color: #ff5722 !important;
  }
}

@media (min-width: 320px) and (max-width: 992px) {
  .site-menu-main .sub-menu.megamenu [class*="col-"] {
    padding-left: 0;
    padding-right: 0;
  }
  .site-menu-main .sub-menu.megamenu [class*="row-"] {
    margin-left: 0;
    margin-right: 0;
  }
}

@media (min-width: 992px) {
  li.nav-item-has-children:not(.has-megamenu) {
    position: relative;
  }
}

@media (min-width: 992px) {
  li.nav-item-has-children:hover > .sub-menu {
    top: 100%;
    margin-top: 0;
    visibility: visible;
    opacity: 1;
    z-index: 99;
    pointer-events: visible;
    border-radius: 0px 0px 15px 15px;
    border-top: 3px solid #ff5722;
  }
}

.site-menu-main > li .sub-menu > ul > li > a {
  display: inline-block;
  padding: 10px 0;
  font-size: 15px;
  color: #555;
  transition: color 0.3s ease;
  text-decoration: none;
  text-transform: capitalize;
}

.site-menu-main > li .sub-menu.mega-menu {
  left: 50%;
  transform: translateX(-50%);
}

.mobile-menu-head,
.mobile-menu-trigger {
  display: none;
}

/*responsive*/
@media (max-width: 991px) {
  .site-header .mobile-menu-trigger {
    display: flex;
    height: 30px;
    width: 30px;
    margin-left: 15px;
    cursor: pointer;
    align-items: center;
    justify-content: center;
  }
  .site-header .mobile-menu-trigger span {
    display: block;
    height: 2px;
    background-color: var(--color-headings-2);
    width: 24px;
    position: relative;
  }
  .site-header .mobile-menu-trigger span:before,
  .site-header .mobile-menu-trigger span:after {
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--color-headings-2);
  }
  .dark-mode-texts .mobile-menu-trigger span {
    background-color: #fff;
  }
  .dark-mode-texts .mobile-menu-trigger span:before,
  .dark-mode-texts .mobile-menu-trigger span:after {
    background-color: #fff;
  }
  .site-header .mobile-menu-trigger span:before {
    top: -6px;
  }
  .site-header .mobile-menu-trigger span:after {
    top: 6px;
  }
  .site-header .item-right {
    align-items: center;
  }
  .site-header .menu-block {
    position: fixed;
    width: 320px;
    background-color: #ffffff;
    left: 0;
    top: 0;
    height: 100%;
    overflow: hidden;
    transform: translate(-100%);
    transition: all 0.5s ease;
    z-index: 1099;
  }
  .site-header .menu-block.active {
    transform: translate(0%);
  }
  .site-menu-main > li {
    line-height: 1;
    margin: 0;
    display: block;
  }
  .site-menu-main > li > a {
    line-height: 50px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 15px;
    padding-bottom: 15px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
}

@media (max-width: 991px) and (min-width: 992px) {
  .site-menu-main > li > a {
    display: block;
  }
}

@media (max-width: 991px) {
  .site-menu-main > li > a i {
    position: absolute;
    top: 5px;
    right: 0;
    transform: rotate(-90deg);
    min-width: 50px;
    max-width: 50px;
    min-height: 50px;
    max-height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .site-header .menu-block .mobile-menu-head {
    display: flex;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 501;
    position: sticky;
    background-color: #ffffff;
    top: 0;
  }
  .site-header .menu-block .mobile-menu-head .go-back {
    height: 50px;
    width: 50px;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    cursor: pointer;
    line-height: 50px;
    text-align: center;
    color: #000000;
    font-size: 16px;
    display: none;
  }
  .site-header .menu-block .mobile-menu-head.active .go-back {
    display: block;
  }
  .site-header .menu-block .mobile-menu-head .current-menu-title {
    font-size: 15px;
    font-weight: 500;
    color: #000000;
    visibility: hidden;
  }
  .site-header .menu-block .mobile-menu-head.active .current-menu-title {
    visibility: visible;
  }
  .site-header .menu-block .mobile-menu-head .mobile-menu-close {
    height: 50px;
    width: 50px;
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    cursor: pointer;
    line-height: 50px;
    text-align: center;
    color: #000000;
    font-size: 25px;
  }
  .site-header .menu-block .site-menu-main {
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .site-menu-main > li .sub-menu.mega-menu,
  .site-menu-main > li .sub-menu {
    visibility: visible;
    opacity: 1;
    position: absolute;
    box-shadow: none;
    margin: 0;
    padding: 15px;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding-top: 50px;
    max-width: none;
    min-width: auto;
    display: none;
    transform: translateX(0%);
    overflow-y: auto;
    overflow-x: hidden;
  }
  .site-menu-main > li .sub-menu.active {
    display: block;
    z-index: 99;
  }
  .site-menu-main > li .sub-menu > ul > li > a,
  .site-menu-main > li .sub-menu.mega-menu > .list-item > ul > li > a {
    display: block;
  }
  .site-menu-main > li .sub-menu.mega-menu > .list-item > ul {
    margin-bottom: 15px;
  }
  .menu-overlay {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1098;
    visibility: hidden;
    opacity: 0;
    transition: all 0.5s ease;
  }
  .menu-overlay.active {
    visibility: visible;
    opacity: 1;
  }
}

/*Custom Css for menu*/
@media (min-width: 992px) {
  .has-megamenu ul {
    margin-left: auto;
  }
  .site-menu-main a {
    display: flex;
    align-items: center;
  }
  .site-menu-main i {
    margin-left: 15px;
    font-size: 15px;
  }
}

@media (min-width: 1200px) and (min-width: 992px) {
  .site-header--menu-center .menu-block-wrapper {
    margin-left: auto;
    margin-right: auto;
  }
}

.nav-item-has-children a {
  display: flex;
  justify-content: space-between;
}

@media (min-width: 992px) {
  .reveal-header .site-navbar {
    padding-top: 0;
    padding-bottom: 0;
  }
}

@media (min-width: 992px) {
  .reveal-header .nav-link-item {
    color: var(--color-headings-2) !important;
  }
}

@media (min-width: 992px) {
  .reveal-header li.nav-item-has-children:hover > .sub-menu {
    top: 100%;
  }
  .reveal-header li.nav-item-has-children:hover > .megamenu {
    top: 100%;
  }
}

.menu-block.active .nav-link-item {
  color: #262729 !important;
}

@media (min-width: 480px) {
  .d-xs-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .menu-block-3 .megadropdown-center {
    transform: translate(-50%, 0) !important;
  }
}

@media (min-width: 1200px) {
  .menu-block-3 .megadropdown-center {
    transform: translate(-40%, 0) !important;
  }
}

@media (min-width: 1366px) {
  .menu-block-3 .megadropdown-center {
    transform: translate(-40%, 0) !important;
  }
}

@media (min-width: 1366px) {
  .menu-block-4 .megadropdown-center {
    transform: translate(-45%, 0) !important;
  }
}

@media (min-width: 1366px) {
  .menu-block-5 .megadropdown-center {
    transform: translate(-49%, 0) !important;
  }
}

.card--content {
  border: none;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 30px;
  padding-right: 30px;
  box-shadow: 0 24px 94px rgba(239, 67, 57, 0.4);
  border-radius: 5px;
  width: 70%;
}

@media (min-width: 480px) {
  .card--content {
    margin-left: auto;
  }
}

@media (min-width: 992px) {
  .card--content {
    padding-top: 40px;
    padding-bottom: 40px;
    padding-left: 53px;
    padding-right: 30px;
  }
}

.card--content .card-head {
  margin-bottom: 12px;
}

.card--content .card-head__count {
  font-size: 40px;
  font-weight: 500;
  letter-spacing: -2px;
  line-height: 1.1;
  margin-bottom: 0;
  margin-right: 12px;
}

@media (min-width: 576px) {
  .card--content .card-head__count {
    font-size: 50px;
  }
}

@media (min-width: 768px) {
  .card--content .card-head__count {
    font-size: 60px;
  }
}

@media (min-width: 992px) {
  .card--content .card-head__count {
    font-size: 70px;
  }
}

.card--content .card-head__icon {
  min-width: 38px;
  max-width: 38px;
  min-height: 38px;
  max-height: 38px;
  border-radius: 50%;
  background-color: #25373f;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 13px;
}

.card--content .card-body__description {
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 1.5;
}

@media (min-width: 576px) {
  .card--content .card-body__description {
    font-size: 18px;
  }
}

@media (min-width: 768px) {
  .card--content .card-body__description {
    font-size: 21px;
  }
}

@media (min-width: 992px) {
  .card--content .card-body__description {
    font-size: 24px;
  }
}

.card--content .card-body__description:last-child {
  margin-bottom: 0;
}

.card--content-2 {
  box-shadow: 0 24px 94px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  z-index: 1;
  padding-bottom: 22px;
  border: none;
}

@media (min-width: 992px) {
  .card--content-2 {
    margin: 0 15px;
  }
}

.card--content-2__currency {
  font-weight: 500;
  letter-spacing: normal;
  line-height: 1.47619;
  color: #25373f;
  font-size: 32px;
}

@media (min-width: 768px) {
  .card--content-2__currency {
    font-size: 35px;
  }
}

@media (min-width: 992px) {
  .card--content-2__currency {
    font-size: 42px;
  }
}

.card--content-2__text {
  color: rgba(37, 55, 63, 0.7);
  font-size: 18px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 1.66666;
  margin-bottom: 0;
}

.card--content-2 .card--content-single {
  margin-left: 55px;
  margin-right: 55px;
  padding-bottom: 35px;
  padding-top: 30px;
}

.card--content-2 .card--content-single--highlight {
  box-shadow: 0 10px 20px rgba(34, 204, 116, 0.25);
  border-radius: 10px;
  margin-bottom: 0;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 20px;
  padding-bottom: 30px;
  margin: 0 22px;
  background-color: #fff;
}

.card--content-2 .card--content-single--highlight h2 {
  color: #fff;
}

.card--content-2 .card--content-single--highlight p {
  color: #fff;
}

/*
  Home 3 : Testimonial Area
---------------------------------*/
.card-testimonial--l3 {
  background: #fff;
  border: 0;
  color: #262729;
  padding-left: 40px;
  padding-right: 40px;
  border-radius: 15px;
  transition: 0.4s;
}

.card-testimonial--l3 .card-user-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 20px;
  margin-top: 29px;
  border-top: 1px solid rgba(38, 39, 41, 0.1);
}

.card-testimonial--l3 .card-user-block .card-body__user,
.card-testimonial--l3 .card-user-block .review-star {
  padding-bottom: 15px;
}

.card-testimonial--l3 .card-user-block .card-body__user {
  padding-right: 15px;
}

.card-testimonial--l3 .card-image {
  width: 70px;
  height: 70px;
  border-radius: 500px;
  margin-top: -35px;
  width: 100%;
}

.card-testimonial--l3 .card-body {
  padding-top: 15px;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 15px;
}

.card-testimonial--l3 .card-body p {
  color: inherit;
}

.card-testimonial--l3 .card-description {
  color: inherit;
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
}

.card-testimonial--l3 .card-title {
  color: inherit;
  font-size: 15px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: normal;
  line-height: 26px;
  text-align: left;
  margin-bottom: 3px;
}

.card-testimonial--l3 .card-text--ext {
  color: inherit;
  opacity: 0.7;
  color: #fff;
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  margin-bottom: 0;
}

.card-testimonial--l3:hover {
  background-color: #5034fc;
  color: #fff;
}

@media (min-width: 992px) {
  .card-testimonial--l3:hover {
    box-shadow: 0 23px 45px rgba(80, 52, 252, 0.25);
  }
}

.card-testimonial--l3:hover .card-user-block {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.card--service {
  border-radius: 10px;
  background-color: #ffffff;
  padding-top: 40px;
  padding-left: 45px;
  padding-right: 35px;
  padding-bottom: 20px;
  margin-bottom: 24px;
  transition: 0.4s;
  display: inline-block;
  width: 100%;
  min-height: 265px;
  border-color: transparent;
}

.card--service:hover {
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.08);
}

.card--service__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 48px;
  max-width: 48px;
  min-height: 48px;
  max-height: 48px;
  border-radius: 10px;
  margin-bottom: 30px;
  font-size: 16px;
}

.card--service__title {
  color: #262729;
  font-size: 21px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 30px;
  display: block;
  height: 52px;
  margin-bottom: 26px;
  transition: 0.4s;
}

.card--service__content {
  opacity: 0.7;
  color: #262729;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 28px;
}

.card--service-more {
  border-radius: 10px;
  background-color: #ffffff;
  padding-top: 40px;
  padding-left: 45px;
  padding-right: 45px;
  padding-bottom: 20px;
  margin-bottom: 24px;
  transition: 0.4s;
  display: inline-block;
  width: 100%;
  min-height: 265px;
  position: relative;
}

.card--service-more:before {
  content: "";
  background-image: url(../images/services-more-shape.png);
  position: absolute;
  top: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  right: -60%;
}


.bg-white-bottom {

  background-repeat: no-repeat;
  background-size: contain;
  background-color: #ffffff;
  background-position: right bottom;
}


.bg-white-top {

  background-repeat: no-repeat;
  background-size: contain;
  background-color: #ffffff;
  background-color: #ffffff;
  background-position: left top;
}



.card--service-more .card--service__title {
  color: var(--color-headings);
  font-size: 28px;
  letter-spacing: normal;
  line-height: 1.57142;
  height: 40px;
}

.card--service-more .card--service__text {
  color: var(--color-headings);
  margin-bottom: 0;
}

.card--service-more .btn span i {
  color: #fff;
}

/*
  Home 4 : Services Area
---------------------------------*/
.card--services-l4 {
  border: none;
  padding-top: 35px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 51px;
  margin-bottom: 25px;
  border-radius: 15px;
  transition: 0.4s;
}

@media (min-width: 576px) {
  .card--services-l4 {
    padding-left: 60px;
    padding-right: 60px;
  }
}

@media (min-width: 768px) {
  .card--services-l4 {
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media (min-width: 992px) {
  .card--services-l4 {
    padding-left: 60px;
    padding-right: 60px;
  }
}

.card--services-l4__icon {
  min-width: 60px;
  max-width: 60px;
  min-height: 60px;
  max-height: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: 400;
  color: #fff;
  margin-bottom: 30px;
}

.card--services-l4__heading {
  font-size: 21px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 30px;
  color: #262729;
}

.card--services-l4__content {
  font-size: 15px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 1.86666;
  margin-bottom: 26px;
  color: rgba(38, 39, 41, 0.7);
}

.card--services-l4 .btn-link {
  font-size: 15px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: normal;
  line-height: 26px;
  display: flex;
  align-items: center;
  color: #262729;
  transition: 0.4s;
}

.card--services-l4 .btn-link i {
  margin-left: 10px;
}

.card--services-l4:hover {
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.08);
}

/*
  Home 4 : Pricing Table
---------------------------------*/
.card--table-single {
  padding-bottom: 45px;
  border: none;
  border-radius: 15px;
  transition: 0.4s;
  margin-bottom: 25px;
}

.card--table-single__list {
  margin-bottom: 38px;
  padding-left: 30px;
  padding-right: 30px;
}

.card--table-single__list li {
  color: rgba(38, 39, 41, 0.7);
  font-size: 16px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 30px;
  margin-bottom: 5px;
}

.card--table-single .table-top {
  padding-bottom: 40px;
  padding-top: 35px;
  padding-left: 30px;
  padding-right: 30px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.card--table-single .table-top__title {
  font-size: 24px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 40px;
  margin-bottom: 8px;
  color: #262729;
}

.card--table-single .table-top__text {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 30px;
  color: rgba(38, 39, 41, 0.7);
}

.card--table-single .table-top .card--price {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin-top: 35px;
}

.card--table-single .table-top .card--price__price {
  color: #262729;
  font-size: 70px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: -1px;
  margin-bottom: 0;
  transition: 0.4s;
}

.card--table-single .table-top .card--price__price-time, .card--table-single .table-top .card--price__currency {
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: normal;
  color: #262729;
  transition: 0.4s;
}

.card--table-single .table-top .card--price__currency {
  margin-top: 8px;
}

.card--table-single .btn {
  max-width: 180px;
  height: 56px;
  border-radius: 50px;
  margin: 0 auto;
  opacity: 0.7 !important;
  transition: 0.4s;
}

.card--table-single:hover {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
}

.card--table-single:hover .btn {
  opacity: 1 !important;
}

.card--table-single:hover .card--price__price, .card--table-single:hover .card--price__price-time, .card--table-single:hover .card--price__currency {
  color: #6001d3;
}

/*
  Home 4 : Testimonial
---------------------------------*/
.card-testimonial--l4 {
  background: #fff;
  border: 0;
  color: #262729;
  border-radius: 15px;
  transition: 0.4s;
  margin: 0 20px;
}

@media (min-width: 576px) {
  .card-testimonial--l4 {
    margin: 0 0;
    margin-right: 30px;
  }
}

.card-testimonial--l4 .card-image {
  min-width: 50px;
  max-width: 50px;
  min-height: 50px;
  max-height: 50px;
  border-radius: 500px;
  width: 100%;
  margin-right: 10px;
  margin-bottom: 10px;
}

@media (min-width: 480px) {
  .card-testimonial--l4 .card-image {
    margin-bottom: 0;
  }
}

.card-testimonial--l4 .card-image img {
  width: 100%;
}

.card-testimonial--l4 .card-icon {
  color: #6001d3;
  font-size: 36px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: normal;
  transition: 0.4s;
}

.card-testimonial--l4 .card-body {
  padding-top: 30px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 30px;
}

@media (min-width: 576px) {
  .card-testimonial--l4 .card-body {
    padding-top: 45px;
    padding-left: 45px;
    padding-right: 45px;
    padding-bottom: 40px;
  }
}

.card-testimonial--l4 .card-body__top {
  margin-bottom: 20px;
}

.card-testimonial--l4 .card-body p {
  color: inherit;
  margin-bottom: 0;
}

.card-testimonial--l4 .card-description {
  color: inherit;
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
}

.card-testimonial--l4 .card-title {
  font-size: 15px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 1.73333;
  color: #262729;
  margin-bottom: 0;
  transition: 0.4s;
}

.card-testimonial--l4 .card-text--ext {
  font-size: 13px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 22px;
  color: rgba(38, 39, 41, 0.7);
}

.card-testimonial--l4:hover {
  background-color: #5034fc;
  color: #fff;
}

.card-testimonial--l4:hover .card-title,
.card-testimonial--l4:hover .card-icon {
  color: #fff;
}

@media (min-width: 992px) {
  .card-testimonial--l4:hover {
    box-shadow: 0 23px 45px rgba(80, 52, 252, 0.25);
  }
}

.card-testimonial--l4:hover .card-user-block {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

/*
  Home 5 : Services Area
---------------------------------*/
.card--services-l5 {
  border: none;
  padding-top: 35px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 31px;
  margin-bottom: 25px;
  border-radius: 15px;
  transition: 0.4s;
}

@media (min-width: 480px) {
  .card--services-l5 {
    padding-top: 35px;
    padding-left: 60px;
    padding-right: 60px;
    padding-bottom: 51px;
  }
}

@media (min-width: 768px) {
  .card--services-l5 {
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 31px;
    margin-bottom: 25px;
  }
}

@media (min-width: 992px) {
  .card--services-l5 {
    padding-top: 35px;
    padding-left: 60px;
    padding-right: 60px;
    padding-bottom: 51px;
  }
}

.card--services-l5__icon {
  min-width: 60px;
  max-width: 60px;
  min-height: 60px;
  max-height: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: 400;
  color: #fff;
  margin-bottom: 15px;
}

.card--services-l5__heading {
  font-size: 24px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 1.666666;
  color: #262729;
  margin-bottom: 17px;
}

.card--services-l5__content {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 30px;
  margin-bottom: 20px;
  color: rgba(38, 39, 41, 0.7);
}

.card--services-l5 .btn-link {
  font-size: 15px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: normal;
  line-height: 26px;
  display: flex;
  align-items: center;
  color: #262729;
  transition: 0.4s;
}

.card--services-l5 .btn-link i {
  margin-left: 10px;
}

.card--services-l5:hover {
  box-shadow: -3px 25px 50px rgba(23, 3, 48, 0.12);
}

/*
  Home 5 : Pricing Table
---------------------------------*/
.table-section--l5 .card--table-single,
.table-section--l6 .card--table-single,
.table-section--inner-2 .card--table-single {
  transition: 0.4s;
}

.table-section--l5 .card--table-single:hover,
.table-section--l6 .card--table-single:hover,
.table-section--inner-2 .card--table-single:hover {
  box-shadow: -3px 25px 50px rgba(23, 3, 48, 0.12);
}

.table-section--l5 .card--table-single .table-top,
.table-section--l6 .card--table-single .table-top,
.table-section--inner-2 .card--table-single .table-top {
  padding-bottom: 25px;
}

.table-section--l5 .card--table-single .table-top__title,
.table-section--l6 .card--table-single .table-top__title,
.table-section--inner-2 .card--table-single .table-top__title {
  color: #fff;
  font-size: 32px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: normal;
  line-height: 1.3125;
  margin-bottom: 18px;
}

.table-section--l5 .card--table-single .table-top__text,
.table-section--l6 .card--table-single .table-top__text,
.table-section--inner-2 .card--table-single .table-top__text {
  color: rgba(255, 255, 255, 0.7);
  font-size: 18px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 32px;
  margin-bottom: 0;
}

.table-section--l5 .card--table-single .table-top .card--price,
.table-section--l6 .card--table-single .table-top .card--price,
.table-section--inner-2 .card--table-single .table-top .card--price {
  position: relative;
}

.table-section--l5 .card--table-single .table-top .card--price::before,
.table-section--l6 .card--table-single .table-top .card--price::before,
.table-section--inner-2 .card--table-single .table-top .card--price::before {
  content: "";
  position: absolute;
  left: 50%;
  top: -15px;
  background-color: rgba(255, 255, 255, 0.3);
  width: 190px;
  height: 1px;
  transform: translateX(-50%);
}

.table-section--l5 .card--table-single .table-top .card--price__currency, .table-section--l5 .card--table-single .table-top .card--price__price-time,
.table-section--l6 .card--table-single .table-top .card--price__currency,
.table-section--l6 .card--table-single .table-top .card--price__price-time,
.table-section--inner-2 .card--table-single .table-top .card--price__currency,
.table-section--inner-2 .card--table-single .table-top .card--price__price-time {
  color: #fff;
  font-size: 13px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: normal;
  line-height: 22px;
}

.table-section--l5 .card--table-single .table-top .card--price__currency,
.table-section--l6 .card--table-single .table-top .card--price__currency,
.table-section--inner-2 .card--table-single .table-top .card--price__currency {
  margin-top: 20px !important;
}

.table-section--l5 .card--table-single .table-top .card--price__price-time,
.table-section--l6 .card--table-single .table-top .card--price__price-time,
.table-section--inner-2 .card--table-single .table-top .card--price__price-time {
  margin-bottom: 9px;
}

.table-section--l5 .card--table-single .table-top .card--price__price,
.table-section--l6 .card--table-single .table-top .card--price__price,
.table-section--inner-2 .card--table-single .table-top .card--price__price {
  color: #fff;
  padding-top: 0px;
}

.table-section--l5 .card--table-single__list,
.table-section--l6 .card--table-single__list,
.table-section--inner-2 .card--table-single__list {
  margin-bottom: 22px;
  margin-top: 45px;
}

.table-section--l5 .card--table-single__list li,
.table-section--l6 .card--table-single__list li,
.table-section--inner-2 .card--table-single__list li {
  font-size: 18px;
  font-weight: 400;
  letter-spacing: normal;
  margin-bottom: 13px;
}

.table-section--l5 .card--table-single .btn,
.table-section--l6 .card--table-single .btn,
.table-section--inner-2 .card--table-single .btn {
  opacity: 1 !important;
}

.table-section--l5 .card--table-single .btn:hover,
.table-section--l6 .card--table-single .btn:hover,
.table-section--inner-2 .card--table-single .btn:hover {
  opacity: 0.7;
}

/*
  Home 5 : Testimonial Area
---------------------------------*/
.card-testimonial--l5 {
  background: #fff;
  border: 0;
  color: #262729;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 25px;
  padding-bottom: 0;
  border-radius: 15px;
  transition: 0.4s;
  margin: 0 15px;
}

@media (min-width: 992px) {
  .card-testimonial--l5 {
    padding-left: 45px;
    padding-right: 45px;
    padding-top: 38px;
    padding-bottom: 10px;
  }
}

@media (min-width: 1200px) {
  .card-testimonial--l5 {
    margin: 0;
  }
}

.card-testimonial--l5 .card-user-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 15px;
}

.card-testimonial--l5 .card-user-block .card-body__user,
.card-testimonial--l5 .card-user-block .review-star {
  padding-bottom: 15px;
}

.card-testimonial--l5 .card-user-block .card-body__user {
  padding-right: 15px;
}

.card-testimonial--l5 .card-image {
  min-width: 60px;
  max-width: 60px;
  min-height: 60px;
  max-height: 60px;
  border-radius: 500px;
  width: 100%;
}

.card-testimonial--l5 .card-body {
  padding-top: 25px;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 15px;
}

.card-testimonial--l5 .card-body .card-description {
  font-size: 18px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 1.777777;
  margin-bottom: 20px;
  color: inherit;
}

@media (min-width: 1200px) {
  .card-testimonial--l5 .card-body .card-description {
    padding-right: 30px;
  }
}

.card-testimonial--l5 .card-body .card-title {
  color: inherit;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 1.388888;
  margin-bottom: 0px;
}

.card-testimonial--l5 .card-body .card-text--ext {
  color: inherit;
  opacity: 0.7;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.866666;
  margin-bottom: 0;
}

.card-testimonial--l5 .card-body .review-star i {
  color: #fcdc00;
}

.card-testimonial--l5:hover {
  box-shadow: -3px 25px 50px rgba(23, 3, 48, 0.12);
}

/*
  Home 5 : Table Area
---------------------------------*/
.table-section--l5 .table-top .card--price__currency,
.table-section--inner-2 .table-top .card--price__currency {
  margin-top: 17px !important;
}

.table-section--l5 .table-top .card--price__price,
.table-section--inner-2 .table-top .card--price__price {
  font-size: 70px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 1.3;
}

.table-section--l5 .table-top .card--price__price-time,
.table-section--inner-2 .table-top .card--price__price-time {
  margin-bottom: 20px !important;
}

.table-section--l5 .card--table-single__list li,
.table-section--inner-2 .card--table-single__list li {
  line-height: 1.77777;
}

.table-section--l5 .btn:hover,
.table-section--inner-2 .btn:hover {
  opacity: 1 !important;
  transform: translateY(-5px);
}

/*
  Home 6 : Services Area
---------------------------------*/
.card--services-l6 {
  border: none;
  padding-top: 40px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 33px;
  margin-bottom: 25px;
  border-radius: 15px;
  transition: 0.4s;
  background-color: transparent;
}

.card--services-l6__icon {
  min-width: 65px;
  max-width: 65px;
  min-height: 65px;
  max-height: 65px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: 400;
  color: #fff;
  margin-bottom: 40px;
  transition: 0.4s;
}

.card--services-l6__heading {
  font-size: 21px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 30px;
  color: var(--color-headings);
  transition: 0.4s;
  margin-bottom: 15px;
}

.card--services-l6__content {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 1.86666;
  margin-bottom: 30px;
  color: var(--color-texts-3);
  transition: 0.4s;
  margin-bottom: 0;
}

/*
  Home 6 : Pricing Table
---------------------------------*/
.table-section--l6 .card--table-single .table-top {
  padding-left: 0;
  padding-right: 0;
}

.table-section--l6 .card--table-single .table-top__title {
  font-size: 24px !important;
  line-height: 40px !important;
  margin-bottom: 2px;
}

.table-section--l6 .card--table-single .table-top .card--price {
  position: relative;
}

.table-section--l6 .card--table-single .table-top .card--price::before {
  content: "";
  position: absolute;
  left: 50%;
  top: -20px;
  background-color: rgba(255, 255, 255, 0.3);
  width: 100% !important;
  height: 1px;
  transform: translateX(-50%);
}

.table-section--l6 .card--table-single .list-checked li {
  position: relative;
  text-align: left;
  padding-left: 55px;
}

.table-section--l6 .card--table-single .list-checked li:before {
  content: "\f00c";
  font-family: "Font Awesome 5 Free";
  position: absolute;
  top: 0;
  left: 20px;
  font-weight: 700;
  color: #fd346e;
}

.table-section--l6 .card--table-single:hover {
  box-shadow: 0 4px 55px rgba(0, 0, 0, 0.2);
}

.table-section--l6 .card--table-single .btn:hover {
  transform: translateY(-5px);
}

/*
  Home 6 : Testimonial
---------------------------------*/
.testimonial-section--l6 .card-testimonial--l4 .card-icon {
  color: #fd346e;
}

.testimonial-section--l6 .card-testimonial--l4:hover .card-icon {
  color: #fff;
}

/*
  Home 8 : Testimonial
---------------------------------*/
.testimonial-section--l8 .card-testimonial--l4 .card-icon {
  color: #099a97;
}

.testimonial-section--l8 .card-testimonial--l4:hover .card-icon {
  color: #fff;
}

/*
  Home 8 : Pricing Table
---------------------------------*/
.table-section--l8 .list-checked--bittersweet li:before {
  color: #ff7272 !important;
}

.table-section--l8 .list-checked--niagara li:before {
  color: #099a97 !important;
}

/*
 Career Details
---------------------------------*/
.card-jobs {
  box-shadow: 0 4px 56px rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  border: 1px solid rgba(181, 181, 181, 0.2);
  background-color: #fff;
  padding: 30px 30px;
}

@media (min-width: 576px) {
  .card-jobs {
    padding: 26px 50px;
  }
}

.card-jobs .card-jobs__content__heading {
  font-size: 32px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 1.38095;
  color: #262729;
  margin-bottom: 10px;
}

@media (min-width: 768px) {
  .card-jobs .card-jobs__content__heading {
    font-size: 35px;
    margin-bottom: 20px;
  }
}

@media (min-width: 992px) {
  .card-jobs .card-jobs__content__heading {
    font-size: 42px;
  }
}

.card-jobs .card-jobs__content__details {
  margin: 0;
  padding: 0;
}

.card-jobs .card-jobs__content__details li a {
  font-size: 18px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 32px;
  color: rgba(38, 39, 41, 0.7);
  margin-right: 15px;
}

.card-jobs .card-jobs__content__details li a i {
  margin-right: 5px;
}

/*
  Home 9 : Services Area
---------------------------------*/
.card--services-l9 {
  background-color: transparent;
  border: none;
  padding-top: 40px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 33px;
  margin-bottom: 25px;
  border-radius: 15px;
  transition: 0.4s;
}

@media (min-width: 576px) {
  .card--services-l9 {
    padding-left: 5px;
    padding-right: 5px;
  }
}

@media (min-width: 768px) {
  .card--services-l9 {
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media (min-width: 992px) {
  .card--services-l9 {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (min-width: 1200px) {
  .card--services-l9 {
    padding-left: 5px;
    padding-right: 5px;
  }
}

@media (min-width: 1600px) {
  .card--services-l9 {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.card--services-l9__icon {
  min-width: 65px;
  max-width: 65px;
  min-height: 65px;
  max-height: 65px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: 400;
  color: #fff;
  margin-bottom: 40px;
  transition: 0.4s;
}

.card--services-l9__icon i {
  transition: 0.4s;
}

.card--services-l9__heading {
  font-size: 24px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 40px;
  color: var(--color-headings);
  transition: 0.4s;
  margin-bottom: 15px;
}

.card--services-l9__content {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 30px;
  color: var(--color-texts-3);
  transition: 0.4s;
  margin-bottom: 0;
}

.testimonial-section--l9 .card {
  margin-top: 30px;
  margin-right: 30px;
  border: none;
  border-radius: 15px;
  position: relative;
  z-index: 0;
}

.testimonial-section--l9 .card-body {
  padding-top: 55px;
  padding-bottom: 25px;
  padding-left: 40px;
}

.testimonial-section--l9 .card__icon {
  max-width: 60px;
  min-width: 60px;
  max-height: 60px;
  min-height: 60px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background-color: #5034fc;
  border-radius: 50%;
  font-size: 20px;
  position: absolute;
  top: -30px;
  z-index: 9;
}

.testimonial-section--l9 .card__content {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 30px;
  margin-bottom: 25px;
  color: #262729;
}

.testimonial-section--l9 .card__meta__image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 15px;
}

.testimonial-section--l9 .card__meta__image img {
  width: 100%;
}

.testimonial-section--l9 .card__meta__title {
  font-size: 18px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 25px;
  margin-bottom: 0;
  color: #262729;
}

.testimonial-section--l9 .card__meta__text {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 30px;
  margin-bottom: 0;
  color: rgba(38, 39, 41, 0.7);
}

.widget {
  display: flex;
}

.widget-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.widget-icon--circle {
  border-radius: 500px;
}

/*
  Home 1 : widget -- content-section
-------------------------------------*/
.widget--contact {
  display: flex;
}

.widget--contact .widget-icon {
  min-width: 55px;
  min-height: 55px;
  max-width: 55px;
  max-height: 55px;
  margin-right: 30px;
  background-color: rgba(38, 39, 41, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 21px;
  color: #fff;
}

.widget--contact .widget-body--title {
  color: #fff;
  font-size: 15px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: normal;
  line-height: 26px;
  text-align: left;
  margin-bottom: 5px;
}

.widget--contact .widget-body p {
  opacity: 0.7;
  color: #fff;
  font-size: 13px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  margin-bottom: 10px;
}

.widget--contact.active .widget-icon {
  color: #5034fc;
  background-color: #fff;
}

/*
  Home 1 : widget -- Testimonial Section
------------------------------------------*/
.widget--testimonial {
  flex-direction: column;
}

.widget--testimonial__description {
  font-size: 18px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 34px;
  margin-bottom: 50px;
  color: var(--color-headings);
  margin-right: 50px;
}

@media (min-width: 992px) {
  .widget--testimonial__description {
    margin-right: 5px;
  }
}

@media (min-width: 1200px) {
  .widget--testimonial__description {
    font-size: 21px;
  }
}

.widget--profile {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
}

.widget--profile__image {
  margin-right: 15px;
  min-width: 67px;
  max-width: 67px;
  min-height: 67px;
  max-height: 67px;
  border-radius: 50%;
}

.widget--profile__name {
  font-size: 21px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 1.619;
  margin-bottom: 0;
}

.widget--profile__position {
  margin-bottom: 0;
  font-weight: 400;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 1.75;
}

/*
  Home 1 : widget -- Counter Section
------------------------------------------*/
.widget--counter {
  margin-bottom: 30px;
  flex-direction: column;
}

@media (min-width: 992px) {
  .widget--counter {
    margin-bottom: 0;
  }
}

.widget--counter__title {
  font-size: 35px;
  font-weight: 500;
  letter-spacing: -1px;
  line-height: 1.13461;
  margin-bottom: 25px;
}

@media (min-width: 768px) {
  .widget--counter__title {
    font-size: 42px;
  }
}

@media (min-width: 992px) {
  .widget--counter__title {
    font-size: 52px;
  }
}

.widget--counter__text {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 1.5;
}

@media (min-width: 768px) {
  .widget--counter__text {
    font-size: 21px;
  }
}

@media (min-width: 992px) {
  .widget--counter__text {
    font-size: 24px;
  }
}

/*
  Home 2 : widget -- Feature Section
------------------------------------------*/
.widget--feature-2 {
  flex-direction: column;
}

@media (min-width: 480px) {
  .widget--feature-2 {
    flex-direction: row;
    align-items: center;
  }
}

.widget--feature-2__icon {
  min-width: 69px;
  max-width: 69px;
  min-height: 69px;
  max-height: 69px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

@media (min-width: 480px) {
  .widget--feature-2__icon {
    margin-right: 30px;
    margin-bottom: 0;
  }
}

.widget--feature-2__title {
  font-size: 21px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: normal;
  line-height: 1.619;
}

.widget--feature-2__content {
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: 1.75;
}

.widgets--l3 {
  padding-top: 40px;
}

@media (min-width: 992px) {
  .widgets--l3 {
    padding-top: 75px;
  }
}

.widget--content-l3 {
  position: relative;
  flex-direction: column;
  text-align: center;
  margin-bottom: 40px;
}

@media (min-width: 768px) {
  .widget--content-l3 {
    padding: 0 60px;
  }
}

@media (min-width: 992px) {
  .widget--content-l3 {
    padding: 0 30px;
  }
}

@media (min-width: 1200px) {
  .widget--content-l3 {
    padding: 0 60px;
  }
}

@media (min-width: 1600px) {
  .widget--content-l3 {
    padding: 0 90px;
  }
}

.widget--content-l3:before {
  position: absolute;
  right: 0;
  top: 15%;
  transform: translateX(50%);
  width: 200px;
  height: 1px;
  background-color: #262729;
  opacity: 0.1;
}

@media (min-width: 768px) {
  .widget--content-l3:before {
    content: "";
  }
}

@media (min-width: 1200px) {
  .widget--content-l3:before {
    width: 250px;
  }
}

.widget--content-l3__circle-content {
  min-width: 65px;
  max-width: 65px;
  min-height: 65px;
  max-height: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin: 0 auto;
  margin-bottom: 45px;
  font-size: 21px;
  font-weight: 500;
  line-height: 30px;
}

.widget--content-l3__title {
  font-size: 21px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 1.42857;
  margin-bottom: 25px;
}

.widget--content-l3__text {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 1.875;
  margin-bottom: 0;
}

.widget--service {
  margin-bottom: 30px;
  flex-direction: column;
}

@media (min-width: 992px) {
  .widget--service {
    margin-bottom: 0;
  }
}

.widget--service .widget__icon {
  min-width: 65px;
  min-height: 65px;
  max-width: 65px;
  max-height: 65px;
  border-radius: 50%;
  position: relative;
  margin-bottom: 65px;
}

.widget--service .widget__icon img {
  position: absolute;
  bottom: -18%;
  right: -22%;
}

.widget--service .widget__icon--golden-tainoi {
  background-color: #ffd166;
}

.widget--service .widget__icon--ice-cold {
  background-color: #96f7d2;
}

.widget--service .widget__icon--anakiwaap {
  background-color: #a9d2ff;
}

.widget--service .widget__heading {
  font-size: 21px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 34px;
  margin-bottom: 16px;
}

.widget--service .widget__description {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 28px;
}

/*
  Home 4 : widget -- Content One Section
------------------------------------------*/
.widgets__single {
  margin-bottom: 30px;
}

.widgets__single__heading {
  font-size: 21px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 30px;
  color: var(--color-headings-2);
}

.widgets__single__icon {
  min-width: 60px;
  max-width: 60px;
  min-height: 60px;
  max-height: 60px;
  border-radius: 50%;
  background-color: #ff1e38;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 25px;
  box-shadow: 0 4px 65px rgba(249, 58, 112, 0.4);
}

.widgets__single__content {
  font-size: 15px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 28px;
  color: var(--color-texts-3);
}

/*
  Home 4 : widget -- Content Two Section
------------------------------------------*/
.widget--content-l4 {
  display: block;
  margin-bottom: 30px;
  transition: 0.4s;
}

@media (min-width: 992px) {
  .widget--content-l4 {
    margin-bottom: 0;
  }
}

@media (min-width: 1600px) {
  .widget--content-l4 {
    margin-left: 80px;
  }
}

.widget--content-l4 .widget--content-l3__circle-content {
  margin: 0 0;
  margin-bottom: 35px;
  transition: 0.4s;
}

.widget--content-l4__title {
  font-size: 21px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 30px;
  margin-bottom: 25px;
}

.widget--content-l4__text {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 30px;
  color: var(--color-texts-3);
}

.widget--content-l4:hover .widget--content-l3__circle-content, .widget--content-l4.active .widget--content-l3__circle-content {
  background-color: #ff1e38 !important;
  color: #fff !important;
}

/*
  Home 5 : widget -- Content One Section
------------------------------------------*/
.widgets--content-1-l5 {
  margin-bottom: 30px;
}

@media (min-width: 1600px) {
  .widgets--content-1-l5 {
    margin-bottom: 53px;
  }
}

.widgets--content-1-l5 .widgets__title {
  font-size: 21px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 30px;
  color: #ff5722;
  margin-bottom: 13px;
}

.widgets--content-1-l5 .widgets__content {
  font-size: 15px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 30px;
}

@media (min-width: 1600px) {
  .widgets--content-1-l5 .widgets__content {
    font-size: 16px;
  }
}

/*
  Home 6 : widget -- About Us Section
------------------------------------------*/
.widget--about {
  display: block;
}

.widgets--about-l6 {
  margin-bottom: 30px;
}

@media (min-width: 1600px) {
  .widgets--about-l6 {
    margin-bottom: 60px;
  }
}

.widgets--about-l6 .widgets__icon {
  min-width: 60px;
  max-width: 60px;
  min-height: 60px;
  max-height: 60px;
  background-color: #ffffff;
  color: #fd346e;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-right: 15px;
  transition: 0.4s;
}

.widgets--about-l6 .widgets__title {
  font-size: 21px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 30px;
  color: var(--color-headings-2);
}

.widgets--about-l6 .widgets__text {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 30px;
}

.widgets--about-l6:hover .widgets__icon {
  background-color: #fd346e;
  color: #fff;
}

.widgets-align-right .widgets__icon {
  order: 2 !important;
  margin-right: 0;
  margin-left: 15px;
}

.widgets-align-right .widgets__content {
  order: 1 !important;
  text-align: right;
}

/*
  Home 6 : widget -- Content One Section
------------------------------------------*/
.widget--content-l6 {
  display: block;
  margin-bottom: 30px;
}

@media (min-width: 992px) {
  .widget--content-l6 {
    margin-bottom: 0;
  }
}

.widget--content-l6 .widget--content-l6__circle {
  margin: 0 0;
  margin-bottom: 32px;
  font-size: 20px;
  min-width: 50px;
  max-width: 50px;
  min-height: 50px;
  max-height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.widget--content-l6__title {
  font-size: 21px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 30px;
  margin-bottom: 5px;
}

.widget--content-l6__text {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 30px;
  color: var(--color-texts-3);
}

/*
  Home 7 : widget -- Services Sections
------------------------------------------*/
.service-area--l7 .widgets {
  background: #F3F4F6;
  margin-bottom: 25px;
  border-radius: 15px;
  padding-left: 35px;
  padding-right: 30px;
  padding-top: 30px;
  padding-bottom: 30px;
}

@media (min-width: 768px) {
  .service-area--l7 .widgets {
    padding-left: 55px;
    padding-right: 45px;
    padding-top: 40px;
    padding-bottom: 45px;
  }
}

.service-area--l7 .widgets__img {
  margin-right: 30px;
}

.service-area--l7 .widgets__heading {
  font-size: 21px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: normal;
  line-height: 30px;
  color: #262729;
}

.service-area--l7 .widgets__text {
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 32px;
  color: rgba(38, 39, 41, 0.7);
}

/*
  Home 9 : widget -- Content One
------------------------------------------*/
.widget--content-l9 {
  position: relative;
  flex-direction: column;
  margin-bottom: 40px;
}

@media (min-width: 992px) {
  .widget--content-l9 {
    margin-right: 30px;
  }
}

.widget--content-l9__circle-content {
  min-width: 57px;
  max-width: 57px;
  min-height: 57px;
  max-height: 57px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-bottom: 45px;
  font-size: 21px;
  font-weight: 500;
  line-height: 30px;
  transition: 0.4s;
}

.widget--content-l9__title {
  font-size: 21px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 1.42857;
  margin-bottom: 25px;
}

.widget--content-l9__text {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 1.875;
  margin-bottom: 0;
}

.widget--content-l9:hover .widget--content-l9__circle-content, .widget--content-l9.active .widget--content-l9__circle-content {
  background-color: #ffd700 !important;
}

/*
  Home 1 : Content Section Two
---------------------------------*/
.content-image-group--l1_2 .content-image-group__image-1 {
  position: relative;
}

.content-image-group--l1_2 .content-image-group__image-1 {
  position: absolute;
  left: 0;
  bottom: 50%;
  width: 40%;
  transform: translateY(50%);
}

@media (min-width: 480px) {
  .content-image-group--l1_2 .content-image-group__image-1 {
    left: -15%;
  }
}

@media (min-width: 992px) {
  .content-image-group--l1_2 .content-image-group__image-1 {
    left: -15%;
  }
}

@media (min-width: 1200px) {
  .content-image-group--l1_2 .content-image-group__image-1 {
    left: -12%;
  }
}

.content-image-group--l1_2 .content-image-group__image-2 {
  position: absolute;
  right: -6%;
  top: -4%;
  width: 35%;
}

@media (min-width: 576px) {
  .content-image-group--l1_2 .content-image-group__image-2 {
    right: -6%;
    top: -6%;
  }
}

@media (min-width: 768px) {
  .content-image-group--l1_2 .content-image-group__image-2 {
    right: -7%;
    top: -7%;
  }
}

@media (min-width: 992px) {
  .content-image-group--l1_2 .content-image-group__image-2 {
    top: -6%;
    right: -4%;
  }
}

/* ==== Footer One === */
.cta-section--l1 {
  position: relative;
  padding-top: 50px;
  z-index: 0;
  border-radius: 0;
}

@media (min-width: 768px) {
  .cta-section--l1 {
    padding-top: 70px;
  }
}

@media (min-width: 992px) {
  .cta-section--l1 {
    padding-top: 120px;
  }
}

.cta-section--l1 .footer-widgets__title {
  opacity: 0.7;
}

.cta-section--l1 .newsletter-form {
  max-width: 521px;
}

.footer-copyright--l1 {
  margin-top: 16px;
  border-top: 1px solid var(--border-color);
}

@media (min-width: 768px) {
  .footer-copyright--l1 {
    margin-top: 70px;
  }
}

@media (min-width: 992px) {
  .footer-copyright--l1 {
    margin-top: 95px;
  }
}

.newsletter--l1-2 {
  margin-top: 45px;
  margin-bottom: 50px;
}

@media (min-width: 992px) {
  .newsletter--l1-2 {
    margin-bottom: 155px;
  }
}

.newsletter--l1-2 input {
  transition: 0.4s;
  background-color: rgba(255, 255, 255, 0.2);
  font-size: 16px;
  font-weight: 400;
  letter-spacing: normal;
  width: 100%;
  border: none;
  padding: 0 25px;
}

.newsletter--l1-2 input::-moz-placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.newsletter--l1-2 input:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.newsletter--l1-2 input::placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.newsletter--l1-2 input:focus {
  background-color: #fff !important;
}

.newsletter--l1-2 input:focus::-moz-placeholder {
  color: #000;
}

.newsletter--l1-2 input:focus:-ms-input-placeholder {
  color: #000;
}

.newsletter--l1-2 input:focus::placeholder {
  color: #000;
}

.newsletter--l1-2 .btn {
  width: 100%;
  height: 70px;
  border-radius: 10px;
}

@media (min-width: 480px) {
  .newsletter--l1-2 .btn {
    width: 172px;
  }
}

.footer-widgets {
  margin-bottom: 40px;
}

@media (min-width: 992px) {
  .footer-widgets {
    margin-bottom: 0;
  }
}

.footer-widgets__title {
  color: var(--color-texts);
  font-size: 16px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 28px;
  margin-bottom: 25px;
}

.footer-widgets__text {
  margin-top: 38px;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 30px;
  color: rgba(248, 249, 252, 0.7);
}

.footer-widgets--address {
  margin-top: 35px !important;
}

.footer-widgets--address li {
  margin-bottom: 20px;
}

.footer-widgets__list {
  padding: 0;
  margin: 0;
}

.footer-widgets__list li {
  list-style: none;
  color: var(--color-headings);
  display: flex;
}

.footer-widgets__list li a {
  color: var(--color-headings);
  font-size: 16px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 2.75;
  transition: 0.4s;
}

.footer-widgets__list li a:hover {
  text-decoration: underline;
}

.footer-widgets__list li i {
  margin-right: 5px;
  margin-top: 13px;
  font-size: 20px;
  width: 35px;
}

.footer-widgets__list li span {
  display: block;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 1.75;
}

.copyright {
  padding-top: 10px;
  padding-bottom: 10px;
}

.copyright__text {
  margin-bottom: 0;
  opacity: 0.7;
  color: #fff;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 28px;
}

.footer-social-share {
  padding-top: 11px;
  padding-bottom: 30px;
}

.footer-social-share ul {
  margin-bottom: 0;
}

.footer-social-share ul li a {
  position: relative;
  margin: 0 8px;
  color: var(--color-headings);
  transition: 0.4s;
}

.footer-social-share ul li a:hover {
  color: #ff5722;
}

.footer-social-share ul li a::before {
  content: ".";
  position: absolute;
  left: 0;
  bottom: 0;
  transform: translateX(-20px);
}

.footer-social-share ul li:first-child a:before {
  display: none;
}

.footer-social-share.dot-right ul li a::before {
  transform: translateX(-12px);
}


.footer-section {
 background-color: #FBFBFB;
}


.footer-section .footer-top{padding:30px 0px;}

/* Footer 2 */
@media (min-width: 992px) {
  .footer-section--2 {
    padding-bottom: 120px;
  }
}

.footer-widgets--l2 .footer-widgets__text {
  color: var(--color-texts-2);
  opacity: 0.7;
}

.footer-widgets--l2 .footer-widgets__title {
  color: var(--color-texts-2);
  opacity: 0.4;
}

.footer-widgets--l2 .footer-widgets__list li a {
  color: var(--color-texts-2);
}

.footer-social-share--1 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.footer-social-share--1 ul li a {
  margin-left: 20px;
}

.footer-social-share--1.dot-right ul li a::before {
  transform: translateX(-20px);
}

.payment-gatway {
  padding-top: 40px;
}

.footer-social-share--2 ul li a {
  margin-left: 0;
}

.footer-social-share--2.dot-right ul li a::before {
  left: 7px;
  bottom: -2px;
  transform: translateX(-13px) !important;
}

/* Footer 2
________________________________ */
.footer-widgets--l2 h4 {
  opacity: 0.4;
}

/*
  Home 3 : Footer Section
---------------------------------*/
.footer-section--l3 {
  padding-top: 60px;
}

@media (min-width: 576px) {
  .footer-section--l3 {
    padding-top: 90px;
  }
}

@media (min-width: 992px) {
  .footer-section--l3 {
    padding-top: 130px;
  }
}

.footer-section--l3 .footer-block-title {
  color: var(--color-headings-2);
  font-size: 18px;
  font-weight: 500;
  line-height: 25px;
  padding-bottom: 20px;
}

@media (min-width: 1200px) {
  .footer-section--l3 .footer-list-block--2 {
    padding-right: 50px;
  }
}

.footer-widgets__list--l3 {
  margin-top: -8px;
}

.footer-widgets__list--l3 li {
  color: var(--color-texts-3);
  font-family: Rubik;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: 28px;
  text-align: left;
}

.footer-widgets__list--l3 li a {
  color: var(--color-texts-3);
}

.footer-widgets__list--l3__address {
  margin-top: 0px;
}

.footer-widgets__list--l3__address li {
  padding-bottom: 20px;
  word-break: break-word;
}

@media (min-width: 1200px) {
  .footer-brand-block--l3 {
    padding-right: 60px;
  }
}

.footer-brand-block--l3 .brand-logo {
  margin-bottom: 38px;
}

.footer-brand-block--l3__text {
  margin-bottom: 35px;
  color: #828288;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 1.875;
}

.footer-insta-gallery {
  margin-bottom: -17px;
  margin-left: -8.5px;
  margin-right: -8.5px;
}

.footer-insta-gallery > div {
  padding-left: 8.5px;
  padding-right: 8.5px;
  padding-bottom: 17px;
}

.footer-social-share--rounded {
  display: inline-flex;
  list-style: none;
  margin: 0 -10px;
  padding: 0;
}

.footer-social-share--rounded > li,
.footer-social-share--rounded div {
  padding: 0 10px;
}

.footer-social-share--rounded a {
  min-width: 24px;
  min-height: 24px;
  border-radius: 500px;
  color: var(--color-headings-2);
  font-size: 15px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: 0.4s;
}

.footer-social-share--rounded a:hover {
  box-shadow: 0 10px 20px rgba(253, 52, 110, 0.25);
  background-color:#e1251b;
  color: #fff;
}
.footer-social-share--rounded a img {
 max-width: 40px;
}
.copyright-block--l3 {
  padding-top: 35px;
  padding-bottom: 40px;
}

.copyright-text--l3 {
  /* font-family: 'Manrope'; */
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  /* identical to box height, or 140% */
  
  
  /* Black/Light :active */
  
  color: #B2B2B1;
}

@media (min-width: 480px) {
  .copyright-text--l3 {
    margin-bottom: 0;
  }
}

/*
  Home 4 : Footer Section
---------------------------------*/
.footer-section--l4 .brand-logo img,
.footer-section--l9 .brand-logo img {
  display: inline-flex;
}

.footer-menu ul {
  padding: 0;
  margin: 0;
}

.footer-menu ul li {
  display: inline-flex;
  align-items: center;
}

.footer-menu ul li a {
  color: var(--color-headings-2);
  font-size: 13px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 22px;
  display: inline-flex;
  align-items: center;
  padding: 5px 20px;
}

@media (min-width: 768px) {
  .footer-menu ul li a {
    margin-right: -20%;
  }
}

/*
  Home 5 : Footer Section
---------------------------------*/
.footer-section--l5 {
  padding-top: 80px;
  padding-bottom: 40px;
}

.footer-section--l5 .brand-logo {
  margin-bottom: 25px;
}

.footer-section--l5 .brand-logo img {
  text-align: center;
  margin: 0 auto;
}

.footer-section--l5 .footer-brand-block__text {
  font-size: 15px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 28px;
  color: var(--color-texts-3);
}

.footer-section--l5 .footer-menu ul li a {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 30px;
  color: var(--color-headings-2);
  transition: 0.4s;
}

.footer-section--l5 .footer-menu ul li a:hover {
  color: #ff5722;
}

.footer-section--l5 .footer-social-share {
  margin-bottom: 60px;
}

.footer-section--l5 .footer-social-share li a {
  min-width: 36px;
  max-width: 36px;
  min-height: 36px;
  max-height: 36px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  color: #262729;
}

.footer-section--l5 .footer-social-share li a:hover {
  box-shadow: 0 2px 2px #e32900;
  background-color: #ff5722;
  color: #fff;
}

.footer-section--l5 .copyright-block--l3 {
  padding-top: 30px;
}

.footer-section--l5 .copyright-block--l3 .copyright-text--l3 {
  color: #828288;
}

/* Footer 7 */
.footer-section--7,
.footer-inner-1 {
  padding-top: 60px;
  padding-bottom: 20px;
}

@media (min-width: 768px) {
  .footer-section--7,
  .footer-inner-1 {
    padding-top: 80px;
  }
}

@media (min-width: 992px) {
  .footer-section--7,
  .footer-inner-1 {
    padding-top: 100px;
    padding-bottom: 20px;
  }
}

.footer-section--7 .copyright,
.footer-inner-1 .copyright {
  padding-top: 25px;
}

.footer-section--7 .copyright p,
.footer-inner-1 .copyright p {
  font-size: 13px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: 22px;
}

@media (min-width: 992px) {
  .footer-section--7 .footer-top,
  .footer-inner-1 .footer-top {
    padding-bottom: 95px;
  }
}

.footer-widgets--l7 .footer-widgets__title {
  color: var(--color-headings-2);
  font-weight: 500;
}

.footer-widgets--l7 .footer-widgets__text {
  color: var(--color-texts-3);
}

.footer-widgets--l7 .footer-widgets__list li a,
.footer-widgets--l7 .footer-widgets__list li span {
  color: var(--color-texts-3) !important;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 1.866666;
}

.footer-widgets--l7 .footer-widgets__list li {
  margin-bottom: 15px;
}

.footer-widgets--l7 .footer-widgets__list li i {
  margin-top: 5px;
}

.footer-widgets--l7 .footer-social-share {
  margin-right: 70px;
}

.footer-widgets--l7 .footer-social-share li {
  display: inline-block;
}

.footer-widgets--l7 .footer-social-share li a {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 25px;
  min-height: 25px;
  padding-left: 0;
}

.footer-inner-1 .footer-social-share--rounded li a:hover {
  background-color: transparent !important;
  color: #5034fc;
  box-shadow: none;
}

.footer-inner-2 .footer-menu ul {
  margin-top: 25px;
}

@media (min-width: 768px) {
  .footer-inner-2 .footer-menu ul {
    margin-top: 0;
  }
}

.footer-inner-2 .footer-menu ul li a {
  font-size: 15px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 26px;
  color: var(--color-heading-2);
  transition: 0.4s;
}

.footer-inner-2 .footer-menu ul li a:hover {
  color: #ff5722;
}

.footer-inner-2 .footer-social-share li a:hover {
  background-color: transparent;
  box-shadow: none;
  color: #ff5722;
}

.footer-section--l9 .footer-social-share li a:hover {
  background-color: transparent;
  box-shadow: none;
  color: #fcdc00;
}

.footer-map {
  position: relative;
}

.footer-map .card {
  background-color: #6001d3;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  padding: 10px 10px;
  max-width: 142px;
  height: 77px;
  border-radius: 5px;
}

.footer-map .card p {
  margin-bottom: 0;
  color: #fff;
  font-size: 11px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 20px;
}

.footer-map .card--map-1 {
  top: 20%;
  left: -6%;
  transform: scale(0.7);
}

@media (min-width: 480px) {
  .footer-map .card--map-1 {
    left: 0;
  }
}

@media (min-width: 576px) {
  .footer-map .card--map-1 {
    top: 25%;
    left: 3%;
    transform: scale(1);
  }
}

@media (min-width: 768px) {
  .footer-map .card--map-1 {
    left: 8%;
  }
}

.footer-map .card--map-2 {
  bottom: -30px;
  right: -30px;
  transform: scale(0.7);
}

@media (min-width: 480px) {
  .footer-map .card--map-2 {
    bottom: -5%;
    right: 0;
  }
}

@media (min-width: 576px) {
  .footer-map .card--map-2 {
    bottom: -13%;
    right: 0;
    transform: scale(1);
  }
}

@media (min-width: 768px) {
  .footer-map .card--map-2 {
    bottom: -3%;
  }
}

@media (min-width: 992px) {
  .footer-map .card--map-2 {
    bottom: 7%;
    right: 5%;
    padding: 10px 15px;
  }
}

.footer-map .card--triangle-up::before {
  content: "";
  position: absolute;
  top: -7px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 7px solid #6001d3;
}

/*
  Home 1 : Counter Section
------------------------------------------*/
.counter-widgets-wrapper {
  padding-top: 45px;
  padding-bottom: 20px;
}

@media (min-width: 768px) {
  .counter-widgets-wrapper {
    padding-top: 70px;
    padding-bottom: 40px;
  }
}

@media (min-width: 992px) {
  .counter-widgets-wrapper {
    padding-top: 125px;
    padding-bottom: 111px;
  }
}

.counter-widgets {
  box-shadow: 0 24px 50px rgba(96, 1, 211, 0.2);
  border-radius: 15px;
  background-color: rgba(80, 52, 252, 0.9);
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-top: 50px;
  padding-bottom: 20px;
  padding-left: 0px;
  padding-right: 0px;
}

.counter-widgets__single {
  border: none !important;
  position: relative;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 30px;
  text-align: center;
  width: 100%;
  margin-bottom: 30px;
  flex: 0 0 100%;
}

@media (min-width: 480px) {
  .counter-widgets__single {
    width: 50%;
    flex: 0 0 50%;
  }
}

@media (min-width: 768px) {
  .counter-widgets__single {
    padding: 0 45px;
    width: calc(33.3333% - 10px);
    flex: 0 0 calc(33.3333% - 10px);
  }
}

@media (min-width: 992px) {
  .counter-widgets__single {
    padding-left: 0px;
    padding-right: 0px;
  }
}

@media (min-width: 1600px) {
  .counter-widgets__single {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (min-width: 320px) {
  .counter-widgets__single::before {
    content: "";
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 2px;
    height: 90px;
    background-color: rgba(255, 255, 255, 0.1);
  }
}

.counter-widgets__single:nth-child(2)::before {
  content: "";
  background-color: transparent;
}

@media (min-width: 768px) {
  .counter-widgets__single:nth-child(2)::before {
    content: "";
    background-color: rgba(255, 255, 255, 0.1);
  }
}

.counter-widgets__single:last-child::before {
  content: "";
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 2px;
  height: 68px;
  background-color: transparent;
}

.counter-widgets__single .counter-content__heading {
  font-size: 28px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 1.4761;
}

@media (min-width: 768px) {
  .counter-widgets__single .counter-content__heading {
    font-size: 32px;
  }
}

@media (min-width: 992px) {
  .counter-widgets__single .counter-content__heading {
    font-size: 35px;
  }
}

@media (min-width: 1200px) {
  .counter-widgets__single .counter-content__heading {
    font-size: 42px;
  }
}

.counter-widgets__single .counter-content__text {
  font-size: 13px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 30px;
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .counter-widgets__single .counter-content__text {
    font-size: 16px;
  }
}

@media (min-width: 992px) {
  .counter-widgets__single .counter-content__text {
    font-size: 18px;
  }
}

/*
  Home 4 : Counter Section
------------------------------------------*/
.about-us-counter--l4 .counter-widgets {
  background-color: transparent;
  box-shadow: none;
  padding: 0;
  margin: 0;
  justify-content: space-between;
  text-align: inherit;
}

.about-us-counter--l4 .counter-widgets__single {
  padding: 0;
}

@media (min-width: 480px) {
  .about-us-counter--l4 .counter-widgets__single:before {
    content: "";
    width: 2px;
    height: 67px;
    background-color: rgba(0, 0, 0, 0.1);
  }
}

.about-us-counter--l4 .counter-widgets__single:nth-child(2):before {
  content: "";
  background-color: transparent;
}

@media (min-width: 768px) {
  .about-us-counter--l4 .counter-widgets__single:nth-child(2):before {
    content: "";
    width: 2px;
    height: 67px;
    background-color: rgba(0, 0, 0, 0.1);
  }
}

.about-us-counter--l4 .counter-widgets__single:last-child:before {
  content: "";
  background-color: transparent;
}

.about-us-counter--l4 .counter-content__heading {
  color: #ff1e38;
  font-size: 45px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 1.2;
  text-transform: uppercase;
}

.about-us-counter--l4 .counter-content__text {
  font-size: 15px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 1.8666666;
}

/*
  Home 7 : About us
------------------------------------------*/
.about-us-section--l7 .counter-widgets__single {
  position: relative;
}

.about-us-section--l7 .counter-widgets__single::before {
  content: "";
  background-color: transparent;
}

@media (min-width: 480px) {
  .about-us-section--l7 .counter-widgets__single::before {
    content: "";
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 2px;
    height: 68px;
    background-color: #fd346e;
  }
}

@media (min-width: 576px) {
  .about-us-section--l7 .counter-widgets__single {
    width: 50%;
    flex: 0 0 50%;
  }
}

@media (min-width: 992px) {
  .about-us-section--l7 .counter-widgets__single {
    width: 33.3333%;
    flex: 0 0 33.3333%;
  }
}

.about-us-section--l7 .counter-widgets__single:nth-child(2)::before {
  background-color: transparent;
}

@media (min-width: 992px) {
  .about-us-section--l7 .counter-widgets__single:nth-child(2)::before {
    background-color: #fd346e;
  }
}

.about-us-section--l7 .counter-widgets__single:last-child::before {
  content: "";
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 2px;
  height: 68px;
  background-color: transparent;
}

.about-us-section--l7 .counter-widgets__single .counter-content__heading {
  color: #fd346e;
  font-size: 32px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 42px;
}

.about-us-section--l7 .counter-widgets__single .counter-content__text {
  font-size: 18px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 32px;
}

/* Section Title */
h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  color: var(--color-headings);
}

.section-title__heading {
  font-weight: 500;
  letter-spacing: -2px;
  line-height: 1.1;
  font-size: 35px;
}

@media (min-width: 576px) {
  .section-title__heading {
    font-size: 40px;
  }
}

@media (min-width: 768px) {
  .section-title__heading {
    font-size: 50px;
  }
}

@media (min-width: 992px) {
  .section-title__heading {
    font-size: 60px;
  }
}

.section-title__description {
  font-size: 18px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 1.61904;
  margin-top: 15px;
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .section-title__description {
    font-size: 21px;
  }
}

@media (min-width: 992px) {
  .section-title__description {
    margin-top: 0;
  }
}

/*
 =======> Home 2
---------------------------------*/
.section-title--2__heading {
  font-size: 35px;
  font-weight: 500;
  letter-spacing: -2px;
  line-height: 1.20689;
  margin-bottom: 22px;
}


.section-title--3__heading {
  font-size: 24px;
  font-weight: 500;
  letter-spacing: -2px;
  line-height: 1.20689;
  margin-bottom: 22px;
}



@media (min-width: 480px) {
  .section-title--2__heading {
    font-size: 38px;
  }
}

@media (min-width: 768px) {
  .section-title--2__heading {
    font-size: 48px;
  }
}

@media (min-width: 992px) {
  .section-title--2__heading {
    /* font-family: 'Manrope'; */
font-style: normal;
font-weight: 500;
font-size: 42px;
line-height: 76px;text-align: center;

/* Greys/Blue Grey/900 */

color: #2C2126;

  }



  .section-title--3__heading {
    /* font-family: 'Manrope'; */
font-style: normal;
font-weight: 500;
font-size: 58px;
line-height: 76px;text-align: center;

/* Greys/Blue Grey/900 */

color: #2C2126;

  }



}




.section-title--2__description {
  font-size: 18px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: 1.66666;
  color: #ffffff;
}

.text-highlight {
  color: #ff5722;
  min-height: 50px;
  display: inline-block;
}

.text-highlight.text-highlight--underline {
  border-bottom: 6px solid #ff5722;
}

.typed-cursor {
  color: #ff5722;
}

/*
 =======> Home 3
---------------------------------*/
.section-title--l3 .section-title__sub-heading {
  color: #ff5722;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 1.75;
}

.section-title--l3 .section-title__heading {
  font-weight: 500;
  letter-spacing: normal;
  line-height: 1.2;
  font-size: 30px;
  color: var(--color-headings-2);
}

@media (min-width: 768px) {
  .section-title--l3 .section-title__heading {
    font-size: 38px;
  }
}

@media (min-width: 992px) {
  .section-title--l3 .section-title__heading {
    font-size: 45px;
  }
}

.section-title--l3 .section-title__description {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 1.875;
  color: var(--color-texts-3);
}

/*
 =======> Home 4

---------------------------------*/
.section-title--l4 .section-title__sub-heading {
  color: #ff5722;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 26px;
}

.section-title--l4 .section-title__heading {
  font-size: 32px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 1.3;
  color: var(--color-headings-2);
}

@media (min-width: 768px) {
  .section-title--l4 .section-title__heading {
    font-size: 38px;
  }
}

@media (min-width: 992px) {
  .section-title--l4 .section-title__heading {
    font-size: 48px;
  }
}

.section-title--l4 .section-title__description {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 1.875;
  color: var(--color-texts-3);
}

/*
 =======> Home 5

---------------------------------*/
.section-title--l5 .section-title__sub-heading {
  color: #ff5722;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 28px;
  position: relative;
  padding-left: 60px;
  display: inline-block;
}

.section-title--l5 .section-title__sub-heading::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  bottom: 9px;
  width: 41px;
  height: 1px;
  background-color: #ff5722;
}

.section-title--l5 .section-title__heading {
  font-size: 32px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 1.38095;
  color: var(--color-headings-2);
}

@media (min-width: 768px) {
  .section-title--l5 .section-title__heading {
    font-size: 33px;
  }
}

@media (min-width: 1600px) {
  .section-title--l5 .section-title__heading {
    font-size: 42px;
  }
}

.section-title--l5 .section-title__description {
  font-size: 18px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 1.77777;
  color: var(--color-texts-3);
}

/*
 =======> Home 6

---------------------------------*/
.section-title--l6 .section-title__sub-heading {
  color: #ff5722;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 25px;
}

.section-title--l6 .section-title__heading {
  font-size: 32px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 1.3;
  color: var(--color-headings-2);
}

@media (min-width: 768px) {
  .section-title--l6 .section-title__heading {
    font-size: 38px;
  }
}

@media (min-width: 992px) {
  .section-title--l6 .section-title__heading {
    font-size: 48px;
  }
}

.section-title--l6 .section-title__description {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 1.875;
  color: var(--color-texts-3);
}

/*
  Home 7
---------------------------------*/
.section-title--l7 .section-title__sub-heading,
.section-title--l8 .section-title__sub-heading {
  color: #ff5722;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 1.75;
}

.section-title--l7 .section-title__heading,
.section-title--l8 .section-title__heading {
  font-size: 32px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 1.3;
  color: var(--color-headings-2);
}

@media (min-width: 768px) {
  .section-title--l7 .section-title__heading,
  .section-title--l8 .section-title__heading {
    font-size: 38px;
  }
}

@media (min-width: 992px) {
  .section-title--l7 .section-title__heading,
  .section-title--l8 .section-title__heading {
    font-size: 48px;
  }
}

.section-title--l7 .section-title__description,
.section-title--l8 .section-title__description {
  font-size: 18px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 1.77777;
  color: var(--color-texts-3);
}

.heading-bottom-line {
  position: relative;
}

.heading-bottom-line::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 210px;
  height: 1px;
  background-color: #fd346e;
}

.heading-bottom-line--right {
  position: relative;
}

.heading-bottom-line--right:before {
  content: "";
  position: absolute;
  right: 30%;
  bottom: 0;
  width: 210px;
  height: 1px;
  background-color: #fd346e;
}

@media (min-width: 992px) {
  .heading-bottom-line--right:before {
    right: 0;
  }
}

/*
  Home 7
---------------------------------*/
.section-title--l8 .section-title__sub-heading {
  font-size: 18px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 1.866666;
}

/*
  Terms Area
---------------------------------*/
.terms-area .section-title {
  margin-bottom: 20px;
}

@media (min-width: 992px) {
  .terms-area .section-title {
    margin-bottom: 42px;
  }
}

.terms-area .section-title__heading {
  font-size: 32px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 1.4423;
  color: var(--color-headings-2);
}

@media (min-width: 576px) {
  .terms-area .section-title__heading {
    font-size: 35px;
  }
}

@media (min-width: 768px) {
  .terms-area .section-title__heading {
    font-size: 42px;
  }
}

@media (min-width: 992px) {
  .terms-area .section-title__heading {
    font-size: 52px;
  }
}

/*
  Terms Area
---------------------------------*/
.page-title-content {
  position: relative;
  padding-top: 60px;
  padding-bottom: 60px;
}

@media (min-width: 768px) {
  .page-title-content {
    padding-top: 75px;
    padding-bottom: 80px;
  }
}

@media (min-width: 992px) {
  .page-title-content {
    padding-top: 102px;
    padding-bottom: 100px;
  }
}

.page-title-content__heading {
  font-size: 32px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 1.4423;
  margin-bottom: 17px;
}

@media (min-width: 576px) {
  .page-title-content__heading {
    font-size: 35px;
  }
}

@media (min-width: 768px) {
  .page-title-content__heading {
    font-size: 42px;
  }
}

@media (min-width: 992px) {
  .page-title-content__heading {
    font-size: 52px;
  }
}

.page-title-content__text {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 1.61904;
}

@media (min-width: 576px) {
  .page-title-content__text {
    font-size: 18px;
  }
}

@media (min-width: 992px) {
  .page-title-content__text {
    font-size: 21px;
  }
}

.page-title-content .shape {
  position: absolute;
  right: 0;
  bottom: 30%;
  width: 8%;
  display: none;
}

@media (min-width: 768px) {
  .page-title-content .shape {
    display: block;
  }
}

/*
 =======> inner page
---------------------------------*/
.section-title--innerpage {
  text-align: center;
}

@media (min-width: 992px) {
  .section-title--innerpage {
    text-align: left;
  }
}

.section-title--innerpage .section-title__sub-heading {
  color: #ff5722;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 1.75;
}

.section-title--innerpage .section-title__heading {
  font-weight: 500;
  letter-spacing: normal;
  line-height: 1.2;
  font-size: 30px;
  color: var(--color-headings-2);
}

@media (min-width: 768px) {
  .section-title--innerpage .section-title__heading {
    font-size: 35px;
  }
}

@media (min-width: 992px) {
  .section-title--innerpage .section-title__heading {
    font-size: 45px;
  }
}

.section-title--innerpage .section-title__description {
  font-size: 18px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 1.777777;
  color: var(--color-texts-3);
}

@media (min-width: 992px) {
  .form-block .section-title {
    padding-bottom: 40px;
  }
}

.form-block .contact-form .form-control {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 30px;
}

/*
 =======> Home 9

---------------------------------*/
.section-title--l9 .section-title__sub-heading {
  color: #ff5722;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 1.75;
  position: relative;
  padding-left: 60px;
  margin-bottom: 20px;
  display: inline-block;
}

.section-title--l9 .section-title__sub-heading::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  bottom: 9px;
  width: 41px;
  height: 1px;
  background-color: #ff5722;
}

.section-title--l9 .section-title__heading {
  font-size: 32px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 1.2;
  margin-bottom: 30px;
  color: var(--color-headings-2);
}

@media (min-width: 768px) {
  .section-title--l9 .section-title__heading {
    font-size: 38px;
  }
}

@media (min-width: 1600px) {
  .section-title--l9 .section-title__heading {
    font-size: 45px;
  }
}

.section-title--l9 .section-title__description {
  font-size: 18px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 1.77777;
  margin-bottom: 25px;
  color: var(--color-texts-3);
}

[data-pricing-dynamic][data-value-active="yearly"] .dynamic-value:before {
  display: inline-block;
  content: attr(data-yearly);
}

[data-pricing-dynamic][data-value-active="yearly"] [data-pricing-trigger] {
  background: #ff5722;
}

[data-pricing-dynamic][data-value-active="yearly"] [data-pricing-trigger] span {
  left: calc(100% - 33px);
}

[data-pricing-dynamic][data-value-active="monthly"] .dynamic-value:before {
  display: inline-block;
  content: attr(data-monthly);
}

.dynamic-value:before {
  display: inline-block;
  content: attr(data-active);
}

.static-value:before {
  display: inline-block;
  content: attr(data-active);
}

.pricing-btn .btn-toggle {
  position: relative;
  width: 46px !important;
  height: 26px;
  border-radius: 15px;
  border: none;
  background-color: #fff !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
}

.pricing-btn .btn-toggle:focus {
  border: none;
  outline: none;
  box-shadow: none;
  background-color: #fff !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
}

.pricing-btn .btn-toggle:checked {
  background-color: #5034fc !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.pricing-btn label {
  font-size: 18px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 25px;
  color: var(--color-headings-2);
  margin: 0 15px;
}

.pricing-btn .badge {
  margin-bottom: 0;
  color: #262729;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 96px;
  height: 30px;
  border-radius: 15px;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 22px;
}

/*
  Home 1 : Feature Tab
------------------------------*/
@media (min-width: 992px) {
  .nav-tab--feature {
    margin-bottom: -50px;
  }
}

.nav-tab--feature .nav-item {
  margin-bottom: 30px;
  border: none;
  background-color: transparent;
}

@media (min-width: 992px) {
  .nav-tab--feature .nav-item {
    margin-bottom: 50px;
  }
}

.nav-tab--feature .nav-item.active {
  background: #fff;
}

.nav-tab--feature .nav-item.active .widget-text {
  color: #2b59ff;
  opacity: 1;
}

.nav-tab--feature .nav-item.active i {
  opacity: 1;
  color: #ff5722;
}

/*
  Home 2 : Feature Tab
------------------------------*/
.nav-tab--feature-2 {
  text-align: center;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-around;
  min-width: 51%;
  transition: 0.4s;
  border-bottom: 1px solid #e1251b;
  margin-bottom: 60px;
  margin-left: -10px;
}

@media (min-width: 992px) {
  .nav-tab--feature-2 {
    margin-bottom: 115px;
    justify-content: space-between;
  }
}

.nav-tab--feature-2 .nav-link {
  border: none;
  font-size: 22px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 1.66666;
  display: inline-flex;
  color: #888587;
  padding: 18px 0;
  transition: 0.4s;
  margin-right: 10px;
  border-bottom: 2px solid transparent;
  justify-content: space-around;
}

.nav-tab--feature-2 .nav-link.active {
  background-color: transparent;
  color: #e1251b;
  border-bottom: 4px solid #e1251b;
}

.nav-tab--feature-2 .nav-link:last-child {
  margin-right: 0;
}

.widget {
  display: flex;
}

.widget-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.widget-icon--circle {
  border-radius: 500px;
}

/*
  Home 1 : widget -- content-section
-------------------------------------*/
.widget--contact {
  display: flex;
}

.widget--contact .widget-icon {
  min-width: 55px;
  min-height: 55px;
  max-width: 55px;
  max-height: 55px;
  margin-right: 30px;
  background-color: rgba(38, 39, 41, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 21px;
  color: #fff;
}

.widget--contact .widget-body--title {
  color: #fff;
  font-size: 15px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: normal;
  line-height: 26px;
  text-align: left;
  margin-bottom: 5px;
}

.widget--contact .widget-body p {
  opacity: 0.7;
  color: #fff;
  font-size: 13px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  margin-bottom: 10px;
}

.widget--contact.active .widget-icon {
  color: #5034fc;
  background-color: #fff;
}

/*
  Home 1 : widget -- Testimonial Section
------------------------------------------*/
.widget--testimonial {
  flex-direction: column;
}

.widget--testimonial__description {
  font-size: 18px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 34px;
  margin-bottom: 50px;
  color: var(--color-headings);
  margin-right: 50px;
}

@media (min-width: 992px) {
  .widget--testimonial__description {
    margin-right: 5px;
  }
}

@media (min-width: 1200px) {
  .widget--testimonial__description {
    font-size: 21px;
  }
}

.widget--profile {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
}

.widget--profile__image {
  margin-right: 15px;
  min-width: 67px;
  max-width: 67px;
  min-height: 67px;
  max-height: 67px;
  border-radius: 50%;
}

.widget--profile__name {
  font-size: 21px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 1.619;
  margin-bottom: 0;
}

.widget--profile__position {
  margin-bottom: 0;
  font-weight: 400;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 1.75;
}

/*
  Home 1 : widget -- Counter Section
------------------------------------------*/
.widget--counter {
  margin-bottom: 30px;
  flex-direction: column;
}

@media (min-width: 992px) {
  .widget--counter {
    margin-bottom: 0;
  }
}

.widget--counter__title {
  font-size: 35px;
  font-weight: 500;
  letter-spacing: -1px;
  line-height: 1.13461;
  margin-bottom: 25px;
}

@media (min-width: 768px) {
  .widget--counter__title {
    font-size: 42px;
  }
}

@media (min-width: 992px) {
  .widget--counter__title {
    font-size: 52px;
  }
}

.widget--counter__text {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 1.5;
}

@media (min-width: 768px) {
  .widget--counter__text {
    font-size: 21px;
  }
}

@media (min-width: 992px) {
  .widget--counter__text {
    font-size: 24px;
  }
}

/*
  Home 2 : widget -- Feature Section
------------------------------------------*/
.widget--feature-2 {
  flex-direction: column;
}

@media (min-width: 480px) {
  .widget--feature-2 {
    flex-direction: row;
    align-items: center;
  }
}

.widget--feature-2__icon {
  min-width: 69px;
  max-width: 69px;
  min-height: 69px;
  max-height: 69px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

@media (min-width: 480px) {
  .widget--feature-2__icon {
    margin-right: 30px;
    margin-bottom: 0;
  }
}

.widget--feature-2__title {
  font-size: 21px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: normal;
  line-height: 1.619;
}

.widget--feature-2__content {
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: 1.75;
}

.widgets--l3 {
  padding-top: 40px;
}

@media (min-width: 992px) {
  .widgets--l3 {
    padding-top: 75px;
  }
}

.widget--content-l3 {
  position: relative;
  flex-direction: column;
  text-align: center;
  margin-bottom: 40px;
}

@media (min-width: 768px) {
  .widget--content-l3 {
    padding: 0 60px;
  }
}

@media (min-width: 992px) {
  .widget--content-l3 {
    padding: 0 30px;
  }
}

@media (min-width: 1200px) {
  .widget--content-l3 {
    padding: 0 60px;
  }
}

@media (min-width: 1600px) {
  .widget--content-l3 {
    padding: 0 90px;
  }
}

.widget--content-l3:before {
  position: absolute;
  right: 0;
  top: 15%;
  transform: translateX(50%);
  width: 200px;
  height: 1px;
  background-color: #262729;
  opacity: 0.1;
}

@media (min-width: 768px) {
  .widget--content-l3:before {
    content: "";
  }
}

@media (min-width: 1200px) {
  .widget--content-l3:before {
    width: 250px;
  }
}

.widget--content-l3__circle-content {
  min-width: 65px;
  max-width: 65px;
  min-height: 65px;
  max-height: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin: 0 auto;
  margin-bottom: 45px;
  font-size: 21px;
  font-weight: 500;
  line-height: 30px;
}

.widget--content-l3__title {
  font-size: 21px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 1.42857;
  margin-bottom: 25px;
}

.widget--content-l3__text {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 1.875;
  margin-bottom: 0;
}

.widget--service {
  margin-bottom: 30px;
  flex-direction: column;
}

@media (min-width: 992px) {
  .widget--service {
    margin-bottom: 0;
  }
}

.widget--service .widget__icon {
  min-width: 65px;
  min-height: 65px;
  max-width: 65px;
  max-height: 65px;
  border-radius: 50%;
  position: relative;
  margin-bottom: 65px;
}

.widget--service .widget__icon img {
  position: absolute;
  bottom: -18%;
  right: -22%;
}

.widget--service .widget__icon--golden-tainoi {
  background-color: #ffd166;
}

.widget--service .widget__icon--ice-cold {
  background-color: #96f7d2;
}

.widget--service .widget__icon--anakiwaap {
  background-color: #a9d2ff;
}

.widget--service .widget__heading {
  font-size: 21px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 34px;
  margin-bottom: 16px;
}

.widget--service .widget__description {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 28px;
}

/*
  Home 4 : widget -- Content One Section
------------------------------------------*/
.widgets__single {
  margin-bottom: 30px;
}

.widgets__single__heading {
  font-size: 21px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 30px;
  color: var(--color-headings-2);
}

.widgets__single__icon {
  min-width: 60px;
  max-width: 60px;
  min-height: 60px;
  max-height: 60px;
  border-radius: 50%;
  background-color: #ff1e38;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 25px;
  box-shadow: 0 4px 65px rgba(249, 58, 112, 0.4);
}

.widgets__single__content {
  font-size: 15px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 28px;
  color: var(--color-texts-3);
}

/*
  Home 4 : widget -- Content Two Section
------------------------------------------*/
.widget--content-l4 {
  display: block;
  margin-bottom: 30px;
  transition: 0.4s;
}

@media (min-width: 992px) {
  .widget--content-l4 {
    margin-bottom: 0;
  }
}

@media (min-width: 1600px) {
  .widget--content-l4 {
    margin-left: 80px;
  }
}

.widget--content-l4 .widget--content-l3__circle-content {
  margin: 0 0;
  margin-bottom: 35px;
  transition: 0.4s;
}

.widget--content-l4__title {
  font-size: 21px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 30px;
  margin-bottom: 25px;
}

.widget--content-l4__text {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 30px;
  color: var(--color-texts-3);
}

.widget--content-l4:hover .widget--content-l3__circle-content, .widget--content-l4.active .widget--content-l3__circle-content {
  background-color: #ff1e38 !important;
  color: #fff !important;
}

/*
  Home 5 : widget -- Content One Section
------------------------------------------*/
.widgets--content-1-l5 {
  margin-bottom: 30px;
}

@media (min-width: 1600px) {
  .widgets--content-1-l5 {
    margin-bottom: 53px;
  }
}

.widgets--content-1-l5 .widgets__title {
  font-size: 21px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 30px;
  color: #ff5722;
  margin-bottom: 13px;
}

.widgets--content-1-l5 .widgets__content {
  font-size: 15px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 30px;
}

@media (min-width: 1600px) {
  .widgets--content-1-l5 .widgets__content {
    font-size: 16px;
  }
}

/*
  Home 6 : widget -- About Us Section
------------------------------------------*/
.widget--about {
  display: block;
}

.widgets--about-l6 {
  margin-bottom: 30px;
}

@media (min-width: 1600px) {
  .widgets--about-l6 {
    margin-bottom: 60px;
  }
}

.widgets--about-l6 .widgets__icon {
  min-width: 60px;
  max-width: 60px;
  min-height: 60px;
  max-height: 60px;
  background-color: #ffffff;
  color: #fd346e;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-right: 15px;
  transition: 0.4s;
}

.widgets--about-l6 .widgets__title {
  font-size: 21px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 30px;
  color: var(--color-headings-2);
}

.widgets--about-l6 .widgets__text {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 30px;
}

.widgets--about-l6:hover .widgets__icon {
  background-color: #fd346e;
  color: #fff;
}

.widgets-align-right .widgets__icon {
  order: 2 !important;
  margin-right: 0;
  margin-left: 15px;
}

.widgets-align-right .widgets__content {
  order: 1 !important;
  text-align: right;
}

/*
  Home 6 : widget -- Content One Section
------------------------------------------*/
.widget--content-l6 {
  display: block;
  margin-bottom: 30px;
}

@media (min-width: 992px) {
  .widget--content-l6 {
    margin-bottom: 0;
  }
}

.widget--content-l6 .widget--content-l6__circle {
  margin: 0 0;
  margin-bottom: 32px;
  font-size: 20px;
  min-width: 50px;
  max-width: 50px;
  min-height: 50px;
  max-height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.widget--content-l6__title {
  font-size: 21px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 30px;
  margin-bottom: 5px;
}

.widget--content-l6__text {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 30px;
  color: var(--color-texts-3);
}

/*
  Home 7 : widget -- Services Sections
------------------------------------------*/
.service-area--l7 .widgets {
  background: #F3F4F6;
  margin-bottom: 25px;
  border-radius: 15px;
  padding-left: 35px;
  padding-right: 30px;
  padding-top: 30px;
  padding-bottom: 30px;
}

@media (min-width: 768px) {
  .service-area--l7 .widgets {
    padding-left: 55px;
    padding-right: 45px;
    padding-top: 40px;
    padding-bottom: 45px;
  }
}

.service-area--l7 .widgets__img {
  margin-right: 30px;
}

.service-area--l7 .widgets__heading {
  font-size: 21px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: normal;
  line-height: 30px;
  color: #262729;
}

.service-area--l7 .widgets__text {
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 32px;
  color: rgba(38, 39, 41, 0.7);
}

/*
  Home 9 : widget -- Content One
------------------------------------------*/
.widget--content-l9 {
  position: relative;
  flex-direction: column;
  margin-bottom: 40px;
}

@media (min-width: 992px) {
  .widget--content-l9 {
    margin-right: 30px;
  }
}

.widget--content-l9__circle-content {
  min-width: 57px;
  max-width: 57px;
  min-height: 57px;
  max-height: 57px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-bottom: 45px;
  font-size: 21px;
  font-weight: 500;
  line-height: 30px;
  transition: 0.4s;
}

.widget--content-l9__title {
  font-size: 21px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 1.42857;
  margin-bottom: 25px;
}

.widget--content-l9__text {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 1.875;
  margin-bottom: 0;
}

.widget--content-l9:hover .widget--content-l9__circle-content, .widget--content-l9.active .widget--content-l9__circle-content {
  background-color: #ffd700 !important;
}

.slick-slide:focus {
  border: 0;
  box-shadow: none;
  outline: none;
}

@media (max-width: 991.98px) {
  .testimonial-mobile-slider--l3 .slick-slide {
    padding: 35px 11px 0;
  }
  .testimonial-mobile-slider--l3 .slick-dots {
    list-style: none;
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }
  .testimonial-mobile-slider--l3 .slick-dots li button {
    font-size: 0;
    border: 0;
    border-radius: 500px;
    background: rgba(0, 0, 0, 0.27);
    margin: 0 5px;
    width: 40px;
    position: relative;
    overflow: hidden;
    height: 4px;
  }
  .testimonial-mobile-slider--l3 .slick-dots li button::before {
    content: "";
    height: 100%;
    width: 0%;
    background: #ff5722;
    top: 0;
    right: 0;
    left: auto;
    position: absolute;
  }
  .testimonial-mobile-slider--l3 .slick-dots li button:focus {
    border: 0;
    box-shadow: none;
    outline: none;
  }
  .testimonial-mobile-slider--l3 .slick-dots li.slick-active button::before {
    left: 0;
    right: auto;
    width: 100%;
    transition: 3.5s linear;
  }
}

@media (min-width: 992px) {
  .skillbar-wrapper {
    padding-right: 15%;
  }
}

.skillbar {
  position: relative;
  display: inline-block;
  margin-bottom: 37px;
  width: 100%;
  border-radius: 3px;
  width: 100%;
}

.skillbar-lable {
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: normal;
  line-height: 25px;
  margin-bottom: 14px;
}

.skillbar-box {
  height: 2px;
  background-color: rgba(38, 39, 41, 0.1);
  border-radius: 3px;
  position: relative;
}

.skillbar-bar {
  height: 5px;
  width: 0px;
  background: #6adcfa;
  display: inline-block;
  border-radius: 15px;
  position: absolute;
  left: 0;
  top: -1.2px;
}

.skillbar-bar--java {
  background-color: #15cda8;
}

/* Sidebar */
.sidebar-area .widget {
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 4px 56px rgba(22, 22, 22, 0.05);
  margin-bottom: 25px;
  display: block;
  padding-top: 35px;
  padding-bottom: 40px;
  padding-left: 40px;
  padding-right: 35px;
}

.sidebar-area .widget__title {
  font-size: 21px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 30px;
  margin-bottom: 20px;
  color: #262729;
}

.sidebar-area .widget__search form {
  border: 1px solid rgba(62, 73, 243, 0.1);
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}

.sidebar-area .widget__search form i {
  font-size: 18px;
  letter-spacing: normal;
  line-height: 22px;
}

.sidebar-area .widget__search form input {
  border: none;
  height: 60px;
  width: 100%;
  padding-left: 20px;
}

.sidebar-area .widget__search form input:focus {
  outline: none;
}

.sidebar-area .widget__recent-post__single {
  border-bottom: 1px solid rgba(38, 39, 41, 0.1);
  padding-top: 20px;
  padding-bottom: 13px;
}

.sidebar-area .widget__recent-post__single:first-child {
  padding-top: 0;
}

.sidebar-area .widget__recent-post__single:last-child {
  border-bottom: 1px solid rgba(38, 39, 41, 0);
  padding-bottom: 0px;
}

.sidebar-area .widget__recent-post__single:hover a .widget__recent-post__title {
  color: #ff5722;
}

.sidebar-area .widget__recent-post__title {
  font-size: 18px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 25px;
  color: #262729;
  transition: 0.4s;
}

.sidebar-area .widget__recent-post__date {
  font-size: 15px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 28px;
  margin-bottom: 0;
  color: rgba(38, 39, 41, 0.7);
}

.sidebar-area .widget__tweets {
  padding: 0;
  margin: 0;
}

.sidebar-area .widget__tweets li {
  border-bottom: 1px solid rgba(38, 39, 41, 0.1);
  padding-top: 20px;
  padding-bottom: 13px;
  transition: 0.4s;
}

.sidebar-area .widget__tweets li span {
  color: #5034fc;
}

.sidebar-area .widget__tweets li:first-child {
  padding-top: 0;
}

.sidebar-area .widget__tweets li:last-child {
  border-bottom: 1px solid rgba(38, 39, 41, 0);
  padding-bottom: 0px;
  margin-bottom: 0;
}

.sidebar-area .widget__tweets li:hover a {
  color: #ff5722;
}

.sidebar-area .widget__tweets li a {
  font-size: 18px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 25px;
  color: #262729;
  margin-bottom: 5px;
  display: block;
}

.sidebar-area .widget__category ul li a {
  color: rgba(38, 39, 41, 0.7);
  margin-bottom: 17px;
}

.sidebar-area .widget__category ul li a h4 {
  font-size: 18px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 25px;
  color: #262729;
}

@media (min-width: 576px) {
  .sidebar-area .widget__category ul li a h4 {
    width: 75%;
  }
}

.sidebar-area .widget__category ul li a span {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 30px;
  color: rgba(38, 39, 41, 0.7);
  display: block;
  position: relative;
  text-align: right;
}

.sidebar-area .widget__category ul li a span:before {
  content: "";
  position: absolute;
  left: -100%;
  top: 50%;
  width: 51px;
  height: 1px;
  background-color: transparent;
}

@media (min-width: 576px) {
  .sidebar-area .widget__category ul li a span {
    width: 25%;
  }
  .sidebar-area .widget__category ul li a span:before {
    content: "";
    position: absolute;
    left: -100%;
    top: 50%;
    width: 51px;
    height: 1px;
    background-color: rgba(38, 39, 41, 0.1);
  }
}

.sidebar-area .widget__category ul li:last-child a {
  margin-bottom: 0;
}

.grid--l6 {
  margin: 0 auto;
}

.grid--l6__single {
  width: 100%;
  display: flex;
}

@media (min-width: 768px) {
  .grid--l6__single {
    width: calc(50% - 25px);
  }
}

@media (min-width: 992px) {
  .grid--l6__single {
    width: calc(33.3333% - 25px);
  }
}

@media (min-width: 992px) {
  .grid--l6__single--big-img {
    width: calc(66.6667% - 25px);
    margin-right: 12.5px;
  }
}

.grid--l6__single--half-img {
  width: 47%;
}

.grid--l6__single a {
  width: 100%;
}

.grid--half {
  margin: 0 auto;
}

.grid--half__single {
  width: 100%;
  display: flex;
  border-radius: 25px;
}

@media (min-width: 768px) {
  .grid--half__single {
    width: calc(50% - 25px);
    margin: 0 12.5px;
  }
}

.grid--half__single a {
  width: 100%;
}

.grid--half__single-no-gap {
  margin: 0 auto;
  width: 100%;
  display: flex;
  border-radius: 25px;
}

@media (min-width: 768px) {
  .grid--half__single-no-gap {
    width: 50%;
  }
}

.grid--half__single-no-gap a {
  width: 100%;
}

.grid--3__single {
  margin: 0 auto;
  width: 100%;
  display: flex;
  border-radius: 25px;
}

@media (min-width: 768px) {
  .grid--3__single {
    width: calc(50% - 25px);
    margin: 0 12.5px;
  }
}

@media (min-width: 992px) {
  .grid--3__single {
    width: calc(33.3333% - 25px);
    margin: 0 12.5px;
  }
}

.grid--3__single a {
  width: 100%;
}

.grid--4__single {
  margin: 0 auto;
  width: 100%;
  display: flex;
  border-radius: 25px;
}

@media (min-width: 768px) {
  .grid--4__single {
    width: calc(25% - 25px);
    margin: 0 12.5px;
  }
}

.grid--4__single a {
  width: 100%;
}

.grid--4__single .portfolio__hover__heading {
  font-size: 16px !important;
}

.grid--4__single .portfolio__hover__text {
  font-size: 13px !important;
}

.grid--4__single .portfolio__hover__link {
  display: none;
}

.grid--3__single--no-gap {
  margin: 0 auto;
  width: 100%;
  display: flex;
  border-radius: 25px;
}

@media (min-width: 768px) {
  .grid--3__single--no-gap {
    width: calc(33.3333%);
  }
}

.grid--3__single--no-gap a {
  width: 100%;
}

.grid--4__single--no-gap {
  margin: 0 auto;
  width: 100%;
  display: flex;
  border-radius: 25px;
}

@media (min-width: 768px) {
  .grid--4__single--no-gap {
    width: 25%;
  }
}

.grid--4__single--no-gap a {
  width: 100%;
}

.grid--4__single--no-gap .portfolio__hover__heading {
  font-size: 16px !important;
}

.grid--4__single--no-gap .portfolio__hover__text {
  font-size: 13px !important;
}

.grid--4__single--no-gap .portfolio__hover__link {
  display: none;
}

.no-gap {
  margin: 0 !important;
  padding: 0 !important;
}

.portfolio-grid-1__full .portfolio__single {
  min-height: 728px;
}

/*
  Home 1 : Testimonial 
---------------------------------*/
.testimonial-area {
  padding-top: 50px;
  padding-bottom: 20px;
}

@media (min-width: 768px) {
  .testimonial-area {
    padding-top: 70px;
    padding-bottom: 40px;
  }
}

@media (min-width: 992px) {
  .testimonial-area {
    padding-top: 128px;
    padding-bottom: 95px;
  }
}

.testimonial-slider--l1 .slick-list {
  margin: 0 -40px;
}

.testimonial-slider--l1 .slick-slide {
  margin: 0 40px;
}

.testimonial-slider--l1 .testimonial-slider__single-slide:focus {
  outline: none;
  box-shadow: none;
}

/*
  Home 3 : Testimonial 
---------------------------------*/
.testimonial-section--l3 {
  border-bottom: 1px solid rgba(38, 39, 41, 0.1);
  padding-top: 50px;
  padding-bottom: 35px;
}

@media (min-width: 576px) {
  .testimonial-section--l3 {
    padding-top: 75px;
    padding-bottom: 60px;
  }
}

@media (min-width: 992px) {
  .testimonial-section--l3 {
    padding-top: 120px;
    padding-bottom: 125px;
  }
}

@media (min-width: 576px) {
  .testimonial-section--l3 .section-title {
    padding-bottom: 30px;
  }
}

@media (min-width: 992px) {
  .testimonial-section--l3 .section-title {
    padding-bottom: 90px;
  }
}

.testimonial-section--l3 .section-title__sub-heading {
  margin-bottom: 30px;
}

/*
  Home 4 : Testimonial 
---------------------------------*/
.testimonial-section--l4,
.testimonial-section--l6,
.testimonial-section--l8,
.testimonial-inner-2 {
  border-bottom: 1px solid rgba(38, 39, 41, 0.1);
  padding-top: 50px;
  padding-bottom: 40px;
}

@media (min-width: 576px) {
  .testimonial-section--l4,
  .testimonial-section--l6,
  .testimonial-section--l8,
  .testimonial-inner-2 {
    padding-top: 55px;
    padding-bottom: 60px;
  }
}

@media (min-width: 768px) {
  .testimonial-section--l4,
  .testimonial-section--l6,
  .testimonial-section--l8,
  .testimonial-inner-2 {
    padding-top: 75px;
    padding-bottom: 80px;
  }
}

@media (min-width: 992px) {
  .testimonial-section--l4,
  .testimonial-section--l6,
  .testimonial-section--l8,
  .testimonial-inner-2 {
    padding-top: 130px;
    padding-bottom: 130px;
  }
}

@media (min-width: 576px) {
  .testimonial-section--l4 .section-title,
  .testimonial-section--l6 .section-title,
  .testimonial-section--l8 .section-title,
  .testimonial-inner-2 .section-title {
    padding-bottom: 30px;
  }
}

@media (min-width: 992px) {
  .testimonial-section--l4 .section-title,
  .testimonial-section--l6 .section-title,
  .testimonial-section--l8 .section-title,
  .testimonial-inner-2 .section-title {
    padding-bottom: 55px;
  }
}

.testimonial-section--l4 .section-title__sub-heading,
.testimonial-section--l6 .section-title__sub-heading,
.testimonial-section--l8 .section-title__sub-heading,
.testimonial-inner-2 .section-title__sub-heading {
  margin-bottom: 15px;
}

.testimonial-section--l4 .section-title__heading,
.testimonial-section--l6 .section-title__heading,
.testimonial-section--l8 .section-title__heading,
.testimonial-inner-2 .section-title__heading {
  font-size: 28px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 1.5;
}

@media (min-width: 992px) {
  .testimonial-section--l4 .section-title__heading,
  .testimonial-section--l6 .section-title__heading,
  .testimonial-section--l8 .section-title__heading,
  .testimonial-inner-2 .section-title__heading {
    font-size: 36px;
  }
}

@media (min-width: 768px) {
  .testimonial-section--l4 .testimonial-slider--l4,
  .testimonial-section--l4 .testimonial-slider--l8,
  .testimonial-section--l6 .testimonial-slider--l4,
  .testimonial-section--l6 .testimonial-slider--l8,
  .testimonial-section--l8 .testimonial-slider--l4,
  .testimonial-section--l8 .testimonial-slider--l8,
  .testimonial-inner-2 .testimonial-slider--l4,
  .testimonial-inner-2 .testimonial-slider--l8 {
    width: 140%;
  }
}

/*
  Home 5 : Testimonial 
---------------------------------*/
.testimonial-section--l5 {
  border-bottom: 1px solid rgba(38, 39, 41, 0.1);
  padding-top: 50px;
  padding-bottom: 35px;
}

@media (min-width: 576px) {
  .testimonial-section--l5 {
    padding-top: 55px;
    padding-bottom: 60px;
  }
}

@media (min-width: 768px) {
  .testimonial-section--l5 {
    padding-top: 75px;
    padding-bottom: 80px;
  }
}

@media (min-width: 992px) {
  .testimonial-section--l5 {
    padding-top: 115px;
    padding-bottom: 145px;
  }
}

@media (min-width: 576px) {
  .testimonial-section--l5 .section-title {
    padding-bottom: 30px;
  }
}

@media (min-width: 992px) {
  .testimonial-section--l5 .section-title {
    padding-bottom: 55px;
  }
}

.testimonial-section--l5 .section-title__sub-heading {
  margin-bottom: 18px;
}

/*
  Home 5 : Testimonial 
---------------------------------*/
.testimonial-section--l7,
.testimonial-section--inner-about-us {
  padding-top: 55px;
  padding-bottom: 40px;
}

@media (min-width: 768px) {
  .testimonial-section--l7,
  .testimonial-section--inner-about-us {
    padding-top: 75px;
    padding-bottom: 60px;
  }
}

@media (min-width: 992px) {
  .testimonial-section--l7,
  .testimonial-section--inner-about-us {
    padding-top: 135px;
    padding-bottom: 110px;
  }
}

@media (min-width: 992px) {
  .testimonial-section--l7 .section-title,
  .testimonial-section--inner-about-us .section-title {
    margin-bottom: 10px !important;
  }
}

.testimonial-section--l7 .section-title__sub-heading,
.testimonial-section--inner-about-us .section-title__sub-heading {
  margin-bottom: 20px;
}

@media (min-width: 992px) {
  .testimonial-section--l7 .section-title__heading,
  .testimonial-section--inner-about-us .section-title__heading {
    margin-bottom: 0;
  }
}

.testimonial-slider--l7 {
  position: relative;
}

.testimonial-slider--l7 .card--testimonial-l7 {
  margin: 15px 15px;
  border-radius: 15px;
  overflow: hidden;
  border: none;
  cursor: all-scroll;
}

@media (min-width: 480px) {
  .testimonial-slider--l7 .card--testimonial-l7 {
    margin: 49px 15px;
  }
}

.testimonial-slider--l7 .card--testimonial-l7:focus, .testimonial-slider--l7 .card--testimonial-l7:active {
  outline: none;
  box-shadow: none;
}

.testimonial-slider--l7 .card--testimonial-l7 .card__hover {
  position: absolute;
  left: 0;
  bottom: -50%;
  background: linear-gradient(360deg, #fd346e 0%, rgba(253, 52, 110, 0.69) 38.45%, rgba(253, 52, 110, 0) 79.35%, rgba(253, 52, 110, 0) 111.11%);
  width: 100%;
  height: 50%;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  transition: 0.5s;
}

.testimonial-slider--l7 .card--testimonial-l7 .card__hover .card__user-media {
  position: absolute;
  left: 0;
  bottom: 0;
  margin-left: 45px;
  margin-bottom: 10px;
}

.testimonial-slider--l7 .card--testimonial-l7 .card__hover .card__user-media__name {
  font-size: 21px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 30px;
  color: #fff;
  margin-bottom: 0;
}

.testimonial-slider--l7 .card--testimonial-l7 .card__hover .card__user-media__cat {
  font-size: 13px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: normal;
  line-height: 22px;
  margin-bottom: 0;
  color: rgba(255, 255, 255, 0.7);
}

.testimonial-slider--l7 .card--testimonial-l7 .card__hover .card__user-media .social-profile {
  margin-top: 15px;
}

.testimonial-slider--l7 .card--testimonial-l7:hover .card__hover {
  bottom: 0;
}

.testimonial-slider--l7 .card--testimonial-l7.slick-center {
  box-shadow: 0 8px 45px rgba(0, 0, 0, 0.3);
}

.testimonial-slider--l7 .slick-dots {
  list-style: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0;
  padding: 0;
  margin: 0 auto;
  width: 100%;
  margin-top: 10px;
}

.testimonial-slider--l7 .slick-dots li button {
  background-color: transparent;
  border: none;
  color: transparent;
  width: 22px;
  height: 5px;
  border-radius: 3px;
  background-color: #828288;
  margin: 0 5px;
  transition: 0.4s ease-in-out;
}

.testimonial-slider--l7 .slick-dots li.slick-active button {
  background-color: #fd346e;
}

.social-profile ul li a {
  color: #fff;
  padding-right: 20px;
  transition-duration: 0.4s;
}

.social-profile ul li a:hover {
  color: rgba(255, 255, 255, 0.7);
}

/*
  Home 8 : Testimonial 
---------------------------------*/
@media (min-width: 992px) {
  .testimonial-btn {
    margin-bottom: 30px !important;
  }
}

.testimonial-btn button {
  background-color: transparent;
  border: none;
  min-width: 50px;
  max-width: 50px;
  min-height: 50px;
  max-height: 50px;
  color: #099a97;
  border-radius: 50%;
  background-color: #fff;
  margin: 0 10px;
  transition: 0.4s;
}

.testimonial-btn button:hover {
  box-shadow: 0 4px 4px #067775;
  background-color: #099a97;
  color: #fff;
}

@media (min-width: 768px) {
  .testimonial-slider--l8 {
    width: 140%;
  }
}

/*
  Innerpage : Testimonial -1  
---------------------------------*/
.testimonial-mobile-slider--inner-1 .card-testimonial--l3 {
  margin-top: 45px;
  margin-bottom: 15px;
  margin-left: 15px;
  margin-right: 15px;
}

.testimonial-area--inner-1 {
  padding-top: 50px;
  padding-bottom: 50px;
}

@media (min-width: 768px) {
  .testimonial-area--inner-1 {
    padding-top: 70px;
    padding-bottom: 70px;
  }
}

@media (min-width: 992px) {
  .testimonial-area--inner-1 {
    padding-top: 115px;
    padding-bottom: 100px;
  }
}

.testimonial-area--inner-1 .buttons {
  margin-top: 25px;
}

@media (min-width: 992px) {
  .testimonial-area--inner-1 .buttons {
    margin-top: 35px;
  }
}

/*
  Innerpage : Testimonial -2  
---------------------------------*/
.testimonial-inner-2 .testimonial-btn button {
  color: #5034fc;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
}

.testimonial-inner-2 .testimonial-btn button:hover {
  box-shadow: 0 4px 4px #2f18be !important;
  background-color: #5034fc !important;
  color: #fff;
}

.testimonial-inner-2 .buttons {
  margin-top: 15px;
}

@media (min-width: 992px) {
  .testimonial-inner-2 .buttons {
    margin-top: 45px;
  }
}

.testimonial-inner-2 .section-title {
  padding-bottom: 20px;
}

@media (min-width: 992px) {
  .testimonial-inner-2 .section-title {
    padding-bottom: 50px;
  }
}

.testimonial-slider--inner-2 .card-testimonial--l4 {
  margin-top: 15px;
  margin-bottom: 15px;
  margin-left: 15px;
  margin-right: 15px;
}

.testimonial-section--inner-about-us .card--testimonial-l7 .card__hover {
  background-image: linear-gradient(360deg, #ff5722 0%, rgba(255, 87, 34, 0.69) 35%, rgba(255, 87, 34, 0) 71%, rgba(255, 87, 34, 0) 100%);
}

.testimonial-section--inner-about-us .slick-dots li.slick-active button {
  background-color: #ff5722;
}

.testimonial-section--l9 {
  padding-top: 60px;
  padding-bottom: 60px;
}

@media (min-width: 768px) {
  .testimonial-section--l9 {
    padding-top: 60px;
    padding-bottom: 80px;
  }
}

@media (min-width: 992px) {
  .testimonial-section--l9 {
    padding-top: 115px;
    padding-bottom: 120px;
  }
}

.testimonial-section--l9 .section-title {
  margin-bottom: 30px;
}

@media (min-width: 992px) {
  .testimonial-section--l9 .section-title {
    margin-bottom: 50px;
  }
}

.testimonial-section--l9 .section-title__sub-heading {
  padding-left: 0 !important;
}

.testimonial-section--l9 .section-title__sub-heading::before {
  display: none;
}

/*
  Home 1 : Welcome Section
------------------------------*/
.welcome-area--l1 {
  padding-top: 180px;
  padding-bottom: 55px;
}

@media (min-width: 576px) {
  .welcome-area--l1 {
    padding-top: 273px;
  }
}

@media (min-width: 768px) {
  .welcome-area--l1 {
    padding-top: 270px;
    padding-bottom: 60px;
  }
}

@media (min-width: 992px) {
  .welcome-area--l1 {
    padding-top: 210px;
    padding-bottom: 130px;
  }
}

@media (min-width: 1200px) {
  .welcome-area--l1 {
    padding-top: 140px;
    padding-bottom: 100px;
  }
}

@media (min-width: 1366px) {
  .welcome-area--l1 {
    padding-top: 216px;
    padding-bottom: 187px;
  }
}

.welcome-area--l1 .newsletter-form {
  max-width: 521px;
}

/* Welcome content */
.welcome-content--l1 {
  margin-top: 80px;
}

@media (min-width: 992px) {
  .welcome-content--l1 {
    margin-top: 0;
  }
}

.welcome-content__title {
  font-size: 50px;
  font-weight: 500;
  letter-spacing: -1px;
  line-height: 1.075;
  margin-bottom: 35px;
}

@media (min-width: 480px) {
  .welcome-content__title {
    font-size: 60px;
  }
}

@media (min-width: 768px) {
  .welcome-content__title {
    font-size: 70px;
  }
}

@media (min-width: 992px) {
  .welcome-content__title {
    font-size: 80px;
  }
}

.welcome-content__descriptions {
  color: rgba(38, 39, 41, 0.7);
  font-size: 18px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 34px;
  margin-bottom: 53px;
}

@media (min-width: 992px) {
  .welcome-content__descriptions {
    font-size: 21px;
  }
}

.welcome-content__terms-text {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 28px;
  margin-top: 18px;
  margin-bottom: 0;
}

.welcome-image-group-wrapper {
  position: relative;
  width: 85%;
  margin: 0 auto;
}

@media (min-width: 768px) {
  .welcome-image-group-wrapper {
    width: 80%;
  }
}

@media (min-width: 992px) {
  .welcome-image-group-wrapper {
    position: absolute;
    top: 55%;
    right: 2%;
    transform: translateY(-50%);
    width: 46%;
  }
}

@media (min-width: 1368px) {
  .welcome-image-group-wrapper {
    width: 46% !important;
    top: 50%;
    transform: translateY(-50%);
  }
}

@media (min-width: 1366px) {
  .welcome-image-group-wrapper {
    width: 40%;
    right: 5%;
    top: 55%;
  }
}

.welcome-image--group-01 {
  position: relative;
  width: 100%;
  margin: 0 auto;
  z-index: 1;
}

.welcome-image--group-01 img {
  width: 110%;
}

@media (min-width: 992px) {
  .welcome-image--group-01 {
    margin-left: auto;
    width: 80%;
  }
}

@media (min-width: 1200px) {
  .welcome-image--group-01 {
    margin-left: auto;
    width: 90%;
  }
}

@media (min-width: 1600px) {
  .welcome-image--group-01 {
    width: 670px;
    -o-object-fit: contain;
       object-fit: contain;
  }
}

.welcome-image--group-01__img-1 {
  position: absolute;
  left: -2%;
  top: -7%;
  width: 28%;
  box-shadow: 0 44px 54px rgba(37, 55, 63, 0.16);
  border-radius: 50%;
}

.welcome-image--group-01__img-2 {
  position: absolute;
  left: 45%;
  top: -30%;
  width: 33%;
  z-index: -1;
}

.welcome-image--group-01__img-3 {
  position: absolute;
  right: 12%;
  bottom: -12%;
  width: 28%;
  transform: translateX(-50%);
  z-index: -1;
}

.welcome-image--group-01__img-4 {
  position: absolute;
  left: 0;
  top: 50%;
  width: 23%;
  z-index: -1;
  transform: translateY(-50%);
}

.welcome-image--group-01__img-5 {
  position: absolute;
  left: 31%;
  top: -50%;
  transform: translateX(-50%);
  width: 24%;
}

.welcome-image--group-01__img-6 {
  position: absolute;
  right: -15%;
  top: 50%;
  width: 27%;
  transform: translateY(-50%);
  z-index: -1;
}

.welcome-image--group-01__img-7 {
  position: absolute;
  right: -9%;
  bottom: -9%;
  width: 32%;
  z-index: -1;
}

/*
  Home 2 : Welcome Section
------------------------------*/


.welcome-area--l20 {
  background-color: #005CB9;
  margin-top: 100px;
  padding-top:60px;
  padding-bottom: 0;
  position: relative;
  background-size: contain;
  background-position: top left;
  background-repeat: no-repeat;
  background-image: url(../images/bluebg.svg);
  
}


.welcome-area--l30 {
  background-color: #fcf4ec;
  padding-top: 110px;
  padding-bottom: 0;
  margin-top: 65px;
  background-size: contain;
  background-position: top left;
  background-repeat: no-repeat;
}

/* .marginbottom{margin-bottom: 100px;} */
.welcome-area--l2 {

  background-size: contain;
  background-position: top left;
  background-repeat: no-repeat;
  padding-top: 100px;
  padding-bottom: 0;
  position: relative;
  background-image: url(../images/bluebg.svg);
}

@media (min-width: 768px) {
  .welcome-area--l2 {
    padding-top: 140px;
    padding-bottom: 0;
  }
}

@media (min-width: 992px) {
  .welcome-area--l2 {
    padding-top: 150px;
    padding-bottom: 0;
  }
}

@media (min-width: 1200px) {
  .welcome-area--l2 {
    padding-top: 170px;
    padding-bottom: 0;
  }
}

@media (min-width: 1366px) {
  .welcome-area--l2 {
    padding-top: 160px;
    padding-bottom: 70px;
    background-color: #E1EEFA;
    
  }
}

.welcome-area--l2-shape-1 {
  position: absolute;
  right: -3.5%;
  bottom: 35%;
  z-index: 0;
  width: 9.7%;
}

.welcome-area--l2-shape-2 {
  position: absolute;
  left: -4%;
  bottom: 20%;
  z-index: 0;
  width: 10%;
}

.welcome-content--l2 {
}

.welcome-content--l2__heading {
  font-weight: 500;
  letter-spacing: -2px;
  line-height: 1.1;
  font-size: 40px;
  margin-bottom: 25px;
  z-index: 1;
  color: #ffffff;
  position: relative;
}

.welcome-content--l2__heading span.red {
  color: #ffffff;
}

@media (min-width: 480px) {
  .welcome-content--l2__heading {
    font-size: 50px;
  }
}

@media (min-width: 768px) {
  .welcome-content--l2__heading {
    font-size: 60px;
  }
}

@media (min-width: 992px) {
  .welcome-content--l2__heading {
    font-family: "Poppins";
font-style: normal;
font-weight: 700;
font-size: 70px;
line-height: 92px;
color: #ffffff;
text-align: left;
  }
}

.welcome-content--l2__descriptions {
  color: var(--color-texts-3);
  font-size: 18px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 34px;
  color: #ffffff;
}

@media (min-width: 768px) {
  .welcome-content--l2__descriptions {
    font-family: "Poppins";
font-style: normal;
font-weight: 400;
font-size: 22px;
line-height: 32px;
text-align: left;
color: #ffffff;


  }
}

.welcome-content--l2-shape {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
  width: 18.5%;
}

@media (min-width: 768px) {
  .welcome-content--l2-shape {
    left: 5%;
    top: -5%;
  }
}

@media (min-width: 992px) {
  .welcome-content--l2-shape {
    left: 6%;
    top: -22%;
  }
}

.welcome-image--l2 {
  margin-top: 50px;
}

@media (min-width: 992px) {
  .welcome-image--l2 {
    margin-top: 70px;
  }
}

/*
  Home 3 : Welcome Section
------------------------------*/
.welcome-area--l3 {
  padding-top: 125px;
  position: relative;
}

@media (min-width: 768px) {
  .welcome-area--l3 {
    padding-top: 115px;
    padding-bottom: 38px;
  }
}

@media (min-width: 992px) {
  .welcome-area--l3 {
    padding-top: 185px;
    padding-bottom: 80px;
  }
}

@media (min-width: 1200px) {
  .welcome-area--l3 {
    padding-top: 220px;
    padding-bottom: 180px;
  }
}

.welcome-content--l3 {
  margin-bottom: 40px;
  margin-top: 0;
}

@media (min-width: 768px) {
  .welcome-content--l3 {
    margin-top: 30px;
    margin-bottom: 40px;
  }
}

@media (min-width: 992px) {
  .welcome-content--l3 {
    margin-top: 0;
  }
}

.welcome-content--l3__sub-title {
  color: #ff5722;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.75;
}

@media (min-width: 992px) {
  .welcome-content--l3__sub-title {
    margin-bottom: 28px;
  }
}

.welcome-content--l3__title {
  font-size: 38px;
  font-weight: 500;
  letter-spacing: -0.5px;
  line-height: 1.2068;
  margin-bottom: 21px;
}

@media (min-width: 768px) {
  .welcome-content--l3__title {
    font-size: 48px;
  }
}

@media (min-width: 992px) {
  .welcome-content--l3__title {
    font-size: 58px;
  }
}

.welcome-content--l3__descriptions {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 1.875;
  margin-bottom: 10px;
}

.welcome-content--l3 .welcome-btn-group--l3 {
  padding-top: 15px;
  margin-bottom: -20px;
}

@media (min-width: 992px) {
  .welcome-content--l3 .welcome-btn-group--l3 {
    padding-top: 28px;
  }
}

.welcome-content--l3 .welcome-btn-group--l3 .btn {
  margin-bottom: 20px;
}

.welcome-content--l3 .welcome-btn-group--l3 .btn--lg-2 {
  min-width: 120px;
  height: 50px;
}

@media (min-width: 480px) {
  .welcome-content--l3 .welcome-btn-group--l3 .btn--lg-2 {
    min-width: 180px;
    height: 56px;
  }
}

.welcome-image-group-wrapper--l3 {
  position: relative;
  width: 100%;
}

@media (min-width: 768px) {
  .welcome-image-group-wrapper--l3 {
    position: absolute;
    width: 20%;
    bottom: 0;
    right: 11%;
  }
}

@media (min-width: 992px) {
  .welcome-image-group-wrapper--l3 {
    position: absolute;
    width: 100%;
    bottom: 0;
    right: -10%;
  }
}

@media (min-width: 1200px) {
  .welcome-image-group-wrapper--l3 {
    position: absolute;
    width: 100%;
    bottom: 0;
    right: 0%;
  }
}

@media (min-width: 992px) {
  .welcome-image-group-wrapper--l3 {
    max-width: 543px;
  }
}

@media (min-width: 1200px) {
  .welcome-image-group-wrapper--l3 {
    max-width: 695px;
  }
}

@media (min-width: 1400px) {
  .welcome-image-group-wrapper--l3 {
    max-width: 815px;
  }
}

.welcome-image-group--l3 {
  position: relative;
  max-width: 100%;
  z-index: 1;
}

@media (min-width: 992px) {
  .welcome-image-group--l3 {
    max-width: 350px;
  }
}

@media (min-width: 1200px) {
  .welcome-image-group--l3 {
    max-width: 450px;
  }
}

@media (min-width: 1400px) {
  .welcome-image-group--l3 {
    max-width: 525px;
  }
}

.welcome-image-group--l3__img-1 {
  position: absolute;
  right: -55%;
  bottom: 0;
  width: 100%;
  z-index: -1;
}

.welcome-image-group--l3__img-2 {
  position: absolute;
  left: 83%;
  top: 35%;
  width: 20%;
  z-index: -1;
}

@media (min-width: 1200px) {
  .welcome-image-group--l3__img-2 {
    max-width: 500px;
  }
}

.welcome-image-group--l3__img-3 {
  position: absolute;
  left: -10%;
  bottom: 8%;
  width: 61%;
  z-index: 1;
}

@media (min-width: 1200px) {
  .welcome-image-group--l3__img-3 {
    max-width: 500px;
  }
}

/*
  Home 4 : Welcome Section
------------------------------*/
.welcome-area--l4 {
  padding-top: 100px;
  padding-bottom: 20px;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  z-index: 1;
}

.welcome-area--l4::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 50%;
  height: 100%;
  z-index: -1;
}

@media (min-width: 480px) {
  .welcome-area--l4 {
    padding-top: 120px;
  }
}

@media (min-width: 768px) {
  .welcome-area--l4 {
    padding-top: 105px;
    padding-bottom: 34px;
  }
}

@media (min-width: 992px) {
  .welcome-area--l4 {
    padding-top: 150px;
    padding-bottom: 59px;
  }
}

@media (min-width: 1200px) {
  .welcome-area--l4 {
    padding-top: 200px;
    padding-bottom: 107px;
  }
}

@media (min-width: 1600px) {
  .welcome-area--l4 {
    padding-top: 290px;
    padding-bottom: 187px;
  }
}

.welcome-area--l4 .welcome-content {
  z-index: 9;
}

.welcome-area--l4 .newsletter--l4 {
  margin-top: 40px;
  max-width: 522px;
}

/*
  Home 5 : Welcome Section
------------------------------*/
.welcome-area--l5 {
  padding-top: 130px;
  padding-bottom: 40px;
  background-size: cover;
  background-position: right top;
  position: relative;
  z-index: 0;
}

@media (min-width: 768px) {
  .welcome-area--l5 {
    padding-top: 115px;
    padding-bottom: 60px;
  }
}

@media (min-width: 992px) {
  .welcome-area--l5 {
    padding-top: 205px;
    padding-bottom: 130px;
  }
}

@media (min-width: 1200px) {
  .welcome-area--l5 {
    padding-top: 235px;
    padding-bottom: 220px;
  }
}

.welcome-area--l5::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  -o-object-fit: cover;
     object-fit: cover;
}

.welcome-area--l5 .welcome-content--l3__sub-title {
  font-size: 15px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 26px;
}

.welcome-area--l5 .popup-btn {
  display: flex;
  align-items: center;
}

.welcome-area--l5 .popup-btn .play-btn {
  min-width: 50px;
  max-width: 50px;
  min-height: 50px;
  max-height: 50px;
  box-shadow: -12px 12px 50px rgba(255, 30, 56, 0.3);
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ff5722;
  margin-right: 20px;
}

.welcome-area--l5 .popup-btn .btn-text {
  color: #ff5722;
  transition: 0.4s;
  font-size: 13px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: normal;
  line-height: 22px;
}

.welcome-area--l5 .popup-btn:hover .btn-text {
  text-decoration: underline;
}

/*
  Home 6 : Welcome Section
------------------------------*/
.welcome-area--l6 {
  padding-top: 100px;
  padding-bottom: 20px;
  background-size: cover;
  background-position: right top;
  position: relative;
  z-index: 0;
}

@media (min-width: 768px) {
  .welcome-area--l6 {
    padding-top: 130px;
    padding-bottom: 40px;
  }
}

@media (min-width: 992px) {
  .welcome-area--l6 {
    padding-top: 80px;
    padding-bottom: 100px;
  }
}

/*
  Home 7 : Welcome Section
------------------------------*/
.welcome-area--l7 {
  padding-top: 125px;
  padding-bottom: 60px;
  background-size: cover;
  background-position: right top;
  position: relative;
  z-index: 0;
  z-index: 0;
}

@media (min-width: 768px) {
  .welcome-area--l7 {
    padding-top: 145px;
    padding-bottom: 80px;
  }
}

@media (min-width: 992px) {
  .welcome-area--l7 {
    padding-top: 260px;
    padding-bottom: 195px;
  }
}

.welcome-area--l7::before {
  content: "";
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: linear-gradient(261.26deg, rgba(253, 52, 110, 0.96) 14.9%, #b81c5c 43.58%, #0abfbc 89.94%);
  opacity: 0.7;
  position: absolute;
  z-index: -1;
}

.welcome-area--l7 .welcome-area___content {
  z-index: 1;
}

.welcome-area--l7__icon {
  min-width: 66px;
  max-width: 66px;
  min-height: 66px;
  max-height: 66px;
  border-radius: 50%;
  box-shadow: -12px 12px 50px rgba(253, 52, 110, 0.3);
  background-color: #fff;
  display: inline-flex;
  align-items: center;
  color: #fd346e;
  justify-content: center;
  margin-bottom: 38px;
  font-size: 21px;
}

.welcome-area--l7__heading {
  font-size: 35px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 1.2;
  margin-bottom: 25px;
}

@media (min-width: 480px) {
  .welcome-area--l7__heading {
    font-size: 40px;
  }
}

@media (min-width: 768px) {
  .welcome-area--l7__heading {
    font-size: 50px;
  }
}

@media (min-width: 992px) {
  .welcome-area--l7__heading {
    font-size: 60px;
  }
}

.welcome-area--l7__descriptions {
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: 1.619;
  margin-bottom: 55px;
}

@media (min-width: 768px) {
  .welcome-area--l7__descriptions {
    font-size: 18px;
  }
}

@media (min-width: 992px) {
  .welcome-area--l7__descriptions {
    font-size: 21px;
  }
}

/*
  Home 8 : Welcome Section
------------------------------*/
.welcome-area--l8 {
  background-color: rgba(21, 205, 168, 0.2);
  padding-top: 0;
  padding-top: 50px;
  padding-bottom: 60px;
}

@media (min-width: 768px) {
  .welcome-area--l8 {
    padding-top: 60px;
    padding-bottom: 80px;
  }
}

@media (min-width: 992px) {
  .welcome-area--l8 {
    padding-top: 110px;
    padding-bottom: 0px;
  }
}

@media (min-width: 1600px) {
  .welcome-area--l8 {
    padding-top: 35px;
    padding-bottom: 0px;
  }
}

.welcome-area--l8 .btn {
  margin-bottom: 20px;
}

.welcome-area--l8 .btn--lg-2 {
  min-width: 120px;
  height: 50px;
}

@media (min-width: 480px) {
  .welcome-area--l8 .btn--lg-2 {
    min-width: 180px;
    height: 56px;
  }
}

.hero-img {
  margin-top: -60px;
  margin-bottom: 70px;
}

@media (min-width: 992px) {
  .hero-img {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.hero-img .hero-l8-img-group {
  position: relative;
  margin-left: -50px;
}

@media (min-width: 480px) {
  .hero-img .hero-l8-img-group {
    margin-left: -100px;
  }
}

@media (min-width: 992px) {
  .hero-img .hero-l8-img-group {
    margin-bottom: 0;
  }
}

.hero-img .hero-l8-img-group__1 {
  position: relative;
  width: 95%;
  bottom: -80px;
}

@media (min-width: 992px) {
  .hero-img .hero-l8-img-group__1 {
    bottom: -110px;
  }
}

.hero-img .hero-l8-img-group__2 {
  width: 50%;
}

@media (min-width: 992px) {
  .welcome-content--l8 {
    padding-bottom: 100px;
  }
}

@media (min-width: 1600px) {
  .welcome-content--l8 {
    padding-bottom: 0;
  }
}

.welcome-content--l8__sub-title {
  font-size: 18px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 25px;
  margin-bottom: 15px;
}

.welcome-content--l8__title {
  font-size: 38px;
  font-weight: 500;
  letter-spacing: -0.5px;
  line-height: 1.2068;
  margin-bottom: 21px;
  margin-bottom: 30px;
}

@media (min-width: 768px) {
  .welcome-content--l8__title {
    font-size: 48px;
  }
}

@media (min-width: 992px) {
  .welcome-content--l8__title {
    font-size: 60px;
    font-weight: 500;
    letter-spacing: normal;
    line-height: 72px;
    color: #262729;
  }
}

.welcome-content--l8__descriptions {
  font-size: 18px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 32px;
  margin-bottom: 10px;
  color: rgba(38, 39, 41, 0.7);
}

.welcome-content--l8 .welcome-btn-group--l8 {
  padding-top: 28px;
  margin-bottom: -20px;
}

.welcome-content--l8 .welcome-btn-group--l8 .btn {
  margin-bottom: 20px;
}

/*
  Home 9 : Welcome Section
------------------------------*/
.welcome-area--l9 {
  padding-top: 60px;
  padding-bottom: 60px;
}

@media (min-width: 768px) {
  .welcome-area--l9 {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

@media (min-width: 992px) {
  .welcome-area--l9 {
    padding-top: 180px;
    padding-bottom: 180px;
  }
}

.welcome-area--l9 .welcome-content {
  margin-top: 30px;
}

@media (min-width: 992px) {
  .welcome-area--l9 .welcome-content {
    margin-top: 0;
  }
}

.welcome-area--l9 .welcome-content__heading {
  font-size: 35px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 1.2;
  margin-bottom: 25px;
}

@media (min-width: 480px) {
  .welcome-area--l9 .welcome-content__heading {
    font-size: 40px;
  }
}

@media (min-width: 768px) {
  .welcome-area--l9 .welcome-content__heading {
    font-size: 50px;
  }
}

@media (min-width: 992px) {
  .welcome-area--l9 .welcome-content__heading {
    font-size: 60px;
  }
}

.welcome-area--l9 .welcome-content__sub-heading {
  font-size: 21px;
  font-weight: 500;
  font-style: italic;
  letter-spacing: normal;
  line-height: 1.4285;
  margin-bottom: 20px;
}

.welcome-area--l9 .welcome-content__text {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 30px;
  margin-bottom: 0;
}

.welcome-area--l9 .welcome-content .welcome-btn-group {
  margin-top: 45px;
}

.welcome-area--l9 .welcome-content .welcome-btn-group .btn--lg-2 {
  min-width: 120px;
  height: 50px;
}

@media (min-width: 480px) {
  .welcome-area--l9 .welcome-content .welcome-btn-group .btn--lg-2 {
    min-width: 180px;
    height: 56px;
  }
}

.welcome-img--l9.video-box {
  box-shadow: 0 10px 56px rgba(0, 0, 0, 0.2);
  border-radius: 25px;
}

@media (min-width: 992px) {
  .welcome-img--l9.video-box {
    margin-left: 30px;
  }
}

@media (min-width: 1600px) {
  .welcome-img--l9.video-box {
    margin-left: 67px !important;
  }
}

.welcome-img--l9.video-box .video-btn {
  min-width: 60px;
  max-width: 60px;
  min-height: 60px;
  max-height: 60px;
}

/*
  Home 1 : Service Section
---------------------------------*/
.service-area--l1 {
  padding-top: 55px;
  padding-bottom: 5px;
  position: relative;
}

@media (min-width: 768px) {
  .service-area--l1 {
    padding-top: 70px;
    padding-bottom: 25px;
  }
}

@media (min-width: 992px) {
  .service-area--l1 {
    padding-top: 125px;
    padding-bottom: 113px;
  }
}

.service-shape {
  position: absolute;
}

.service-shape--l1 {
  top: 26%;
  left: -6%;
  width: 10%;
}

.service-items {
  margin-top: 50px;
}

@media (min-width: 992px) {
  .service-items {
    margin-top: 143px;
  }
}

/*
  Home 3 : Service Section
---------------------------------*/
.service-area--l3 {
  padding-top: 50px;
  padding-bottom: 36px;
}

@media (min-width: 768px) {
  .service-area--l3 {
    padding-top: 80px;
    padding-bottom: 65px;
  }
}

@media (min-width: 992px) {
  .service-area--l3 {
    padding-top: 120px;
    padding-bottom: 110px;
  }
}

.service-area--l3 .section-title {
  margin-bottom: 30px;
}

@media (min-width: 992px) {
  .service-area--l3 .section-title {
    margin-bottom: 69px;
  }
}

.service-area--l3 .section-title__sub-heading {
  margin-bottom: 27px;
}

.services-content {
  margin-top: 50px;
}

/*
  Home 4 : Service Section
---------------------------------*/
.service-area--l4,
.service-area--inner {
  padding-top: 50px;
  padding-bottom: 36px;
}

@media (min-width: 768px) {
  .service-area--l4,
  .service-area--inner {
    padding-top: 70px;
    padding-bottom: 55px;
  }
}

@media (min-width: 992px) {
  .service-area--l4,
  .service-area--inner {
    padding-top: 110px;
    padding-bottom: 100px;
  }
}

.service-area--l4 .section-title,
.service-area--inner .section-title {
  margin-bottom: 30px;
}

@media (min-width: 992px) {
  .service-area--l4 .section-title,
  .service-area--inner .section-title {
    margin-bottom: 55px;
  }
}

.service-area--l4 .section-title__sub-heading,
.service-area--inner .section-title__sub-heading {
  margin-bottom: 10px;
}

/*
  Home 5 : Service Section
---------------------------------*/
.service-area--l5 {
  padding-top: 60px;
  padding-bottom: 36px;
}

@media (min-width: 768px) {
  .service-area--l5 {
    padding-top: 80px;
    padding-bottom: 55px;
  }
}

@media (min-width: 992px) {
  .service-area--l5 {
    padding-top: 130px;
    padding-bottom: 105px;
  }
}

.service-area--l5 .section-title {
  margin-bottom: 40px;
}

@media (min-width: 480px) {
  .service-area--l5 .section-title {
    margin-right: 60px;
  }
}

@media (min-width: 480px) {
  .service-area--l5 .section-title {
    margin-right: 0px;
  }
}

@media (min-width: 992px) {
  .service-area--l5 .section-title {
    margin-bottom: 69px;
    margin-right: 60px;
  }
}

@media (min-width: 1200px) {
  .service-area--l5 .section-title {
    margin-right: 0;
  }
}

.service-area--l5 .section-title__sub-heading {
  margin-bottom: 27px;
}

@media (min-width: 992px) {
  .service-area--l5 .section-title__description {
    padding-right: 15px;
  }
}

@media (min-width: 1200px) {
  .service-area--l5 .section-title__description {
    padding-right: 0px;
  }
}

@media (min-width: 1600px) {
  .service-area--l5 .section-title__description {
    padding-right: 60px;
  }
}

/*
  Home 6 : Service Section
---------------------------------*/
.service-area--l6 {
  padding-top: 60px;
  padding-bottom: 36px;
}

@media (min-width: 768px) {
  .service-area--l6 {
    padding-top: 80px;
    padding-bottom: 55px;
  }
}

@media (min-width: 992px) {
  .service-area--l6 {
    padding-top: 125px;
    padding-bottom: 60px;
  }
}

.service-area--l6 .section-title {
  margin-bottom: 40px;
}

@media (min-width: 480px) {
  .service-area--l6 .section-title {
    margin-right: 60px;
  }
}

@media (min-width: 480px) {
  .service-area--l6 .section-title {
    margin-right: 0px;
  }
}

@media (min-width: 992px) {
  .service-area--l6 .section-title {
    margin-bottom: 69px;
    margin-right: 60px;
  }
}

@media (min-width: 1200px) {
  .service-area--l6 .section-title {
    margin-right: 0;
  }
}

.service-area--l6 .section-title__sub-heading {
  margin-bottom: 27px;
}

@media (min-width: 992px) {
  .service-area--l6 .section-title__description {
    padding-right: 15px;
  }
}

@media (min-width: 1200px) {
  .service-area--l6 .section-title__description {
    padding-right: 0px;
  }
}

@media (min-width: 1600px) {
  .service-area--l6 .section-title__description {
    padding-right: 60px;
  }
}

/*
  Home 7 : Service Section
---------------------------------*/
.service-area--l7 {
  padding-top: 55px;
  padding-bottom: 36px;
}

@media (min-width: 768px) {
  .service-area--l7 {
    padding-top: 75px;
    padding-bottom: 55px;
  }
}

@media (min-width: 992px) {
  .service-area--l7 {
    padding-top: 130px;
    padding-bottom: 105px;
  }
}

.service-area--l7 .section-title {
  margin-bottom: 40px;
}

@media (min-width: 992px) {
  .service-area--l7 .section-title {
    margin-bottom: 0px;
  }
}

.service-area--l7 .section-title__sub-heading {
  margin-bottom: 22px;
}

.service-area--l7 .section-title .btn {
  min-width: 168px;
}

@media (min-width: 1200px) {
  .service-area--l7 .services-right {
    margin-left: 35px;
  }
}

/*
  Home 8 : Service Section
---------------------------------*/
.service-area--l8 {
  padding-top: 55px;
  padding-bottom: 36px;
}

@media (min-width: 768px) {
  .service-area--l8 {
    padding-top: 75px;
    padding-bottom: 55px;
  }
}

@media (min-width: 992px) {
  .service-area--l8 {
    padding-top: 110px;
    padding-bottom: 115px;
  }
}

.service-area--l8 .section-title {
  margin-bottom: 40px;
}

@media (min-width: 992px) {
  .service-area--l8 .section-title {
    margin-bottom: 75px;
  }
}

.service-area--l8 .section-title__sub-heading {
  margin-bottom: 18px;
}

@media (min-width: 1200px) {
  .service-area--l8 .services-right {
    margin-left: 35px;
  }
}

.service-area--l8 .card--services-l6 {
  border-radius: 10px;
  background-color: rgba(242, 245, 251, 0.2);
}

/*
  Home 9 : Service Section
---------------------------------*/
.service-area--l9 {
  padding-top: 60px;
  padding-bottom: 36px;
}

@media (min-width: 768px) {
  .service-area--l9 {
    padding-top: 80px;
    padding-bottom: 55px;
  }
}

@media (min-width: 992px) {
  .service-area--l9 {
    padding-top: 125px;
    padding-bottom: 60px;
  }
}

.service-area--l9 .section-title {
  margin-bottom: 40px;
}

@media (min-width: 480px) {
  .service-area--l9 .section-title {
    margin-right: 60px;
  }
}

@media (min-width: 480px) {
  .service-area--l9 .section-title {
    margin-right: 0px;
  }
}

@media (min-width: 992px) {
  .service-area--l9 .section-title {
    margin-bottom: 69px;
    margin-right: 60px;
  }
}

@media (min-width: 1200px) {
  .service-area--l9 .section-title {
    margin-right: 0;
  }
}

.service-area--l9 .section-title__sub-heading {
  margin-bottom: 27px;
}

@media (min-width: 992px) {
  .service-area--l9 .section-title__description {
    padding-right: 15px;
  }
}

@media (min-width: 1200px) {
  .service-area--l9 .section-title__description {
    padding-right: 0px;
  }
}

@media (min-width: 1600px) {
  .service-area--l9 .section-title__description {
    padding-right: 60px;
  }
}

.services-area--l9-1 {
  padding-top: 60px;
  padding-bottom: 30px;
}

@media (min-width: 768px) {
  .services-area--l9-1 {
    padding-top: 80px;
    padding-bottom: 50px;
  }
}

@media (min-width: 992px) {
  .services-area--l9-1 {
    padding-top: 130px;
    padding-bottom: 130px;
  }
}

.services-area--l9-1 .section-title__description--highlight {
  padding-left: 30px;
  margin-left: 40px;
  position: relative;
}

.services-area--l9-1 .section-title__description--highlight::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background-color: #5034fc;
  width: 1px;
  height: 77px;
}

.services-area--l9-1 .services-content-box__list li {
  padding-left: 30px;
  margin-bottom: 25px;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 28px;
  color: var(--color-headings-2);
  position: relative;
}

.services-area--l9-1 .services-content-box__list li::before {
  content: "\f00c";
  font-family: 'Font Awesome 5 Free';
  position: absolute;
  left: 0;
  top: 0;
  font-weight: 700;
  color: #5034fc;
}

/*
  Home 1 : Feature Section
------------------------------*/
.feature-section {
  position: relative;
  overflow: hidden;
  padding-top: 55px;
  padding-bottom: 20px;
}

@media (min-width: 768px) {
  .feature-section {
    padding-top: 70px;
    padding-bottom: 40px;
  }
}

@media (min-width: 992px) {
  .feature-section {
    padding-top: 125px;
    padding-bottom: 105px;
  }
}

.feature-section .feature-area-tab {
  margin-top: 35px;
}

@media (min-width: 768px) {
  .feature-section .feature-area-tab {
    margin-top: 80px;
  }
}

@media (min-width: 992px) {
  .feature-section .feature-area-tab {
    margin-top: 148px;
  }
}

@media (min-width: 992px) {
  .feature-section .feature-area-tab .nav {
    padding-right: 20px;
  }
}

@media (min-width: 1200px) {
  .feature-section .feature-area-tab .nav {
    padding-right: 65px;
  }
}

@media (min-width: 1400px) {
  .feature-section .feature-area-tab .nav {
    padding-right: 110px;
  }
}

@media (min-width: 992px) {
  .feature-section .feature-area-tab .tab-content {
    padding-right: 20px;
  }
}

@media (min-width: 1200px) {
  .feature-section .feature-area-tab .tab-content {
    padding-left: 26px;
  }
}

.feature-section--l1__shape-group {
  position: absolute;
  right: 0;
  top: -7%;
  width: 30%;
  z-index: 1;
}

.feature-section--l1__shape-group .img-1 {
  width: 50%;
  position: absolute;
  top: 30%;
  right: -30%;
  z-index: -1;
}

.features-widgets {
  margin-top: 35px;
}

@media (min-width: 768px) {
  .features-widgets {
    margin-top: 80px;
  }
}

@media (min-width: 992px) {
  .features-widgets {
    margin-top: 135px;
  }
}

.widget--feature {
  font-size: 18px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 1.666666;
  margin-bottom: 20px !important;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  color: #fff;
  transition: 0.4s;
}

@media (min-width: 768px) {
  .widget--feature {
    width: 194px;
    height: 60px;
    margin-bottom: 55px !important;
  }
}

.widget--feature i {
  margin-right: 10px;
}

.widget--feature:hover, .widget--feature.active {
  background-color: #fff;
  color: #2b59ff;
  border-radius: 10px;
}

.widget--feature:hover i, .widget--feature.active i {
  color: #ff5722;
}

@media (min-width: 768px) {
  .features-content {
    padding-left: 40px;
  }
}

.features-content__item {
  margin-bottom: 35px;
}

.features-content__item__count {
  color: rgba(255, 255, 255, 0.3);
  font-size: 60px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: -2px;
  line-height: 1.1;
  margin-bottom: 25px;
}

@media (min-width: 992px) {
  .features-content__item__count {
    margin-bottom: 52px;
  }
}

.features-content__item__heading {
  font-size: 21px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 1.619047;
  margin-bottom: 25px;
}

.features-content__item__content {
  font-size: 18px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 1.66666;
  margin-bottom: 0;
}

/* ========== Home 2 =========== */
/*
  Home 2 : Feature Section
---------------------------------*/
.feature-area--l2 {
  padding-top: 35px;
  padding-bottom: 40px;
}

@media (min-width: 768px) {
  .feature-area--l2 {
    padding-top: 55px;
    padding-bottom: 60px;
  }
}

@media (min-width: 992px) {
  .feature-area--l2 {
    padding-top: 85px;
    padding-bottom: 127px;
  }
}

.feature-area--l2__image-group {
  position: relative;
  z-index: 1;
  box-shadow: 0 28px 34px rgba(37, 55, 63, 0.1);
}

@media (min-width: 992px) {
  .feature-area--l2__image-group {
    margin-right: 5px;
  }
}

@media (min-width: 1600px) {
  .feature-area--l2__image-group {
    margin-right: 25px;
  }
}

.feature-area--l2__image-group__img-1 {
  width: 20%;
  position: absolute;
  left: -10%;
  bottom: -10%;
  z-index: -1;
}

.feature-area--l2__image-group__img-1 img {
  width: 100%;
}

.feature-area--l2__content {
  margin-top: 50px;
}

@media (min-width: 768px) {
  .feature-area--l2__content {
    margin-top: 0;
  }
}

@media (min-width: 1600px) {
  .feature-area--l2__content {
    margin-left: 45px;
  }
}

.feature-area--l2__content__heading {
  font-size: 35px;
  font-weight: 500;
  letter-spacing: -2px;
  line-height: 1.20689;
  margin-bottom: 40px;
}

@media (min-width: 480px) {
  .feature-area--l2__content__heading {
    font-size: 38px;
  }
}

@media (min-width: 768px) {
  .feature-area--l2__content__heading {
    font-size: 48px;
  }
}

@media (min-width: 992px) {
  .feature-area--l2__content__heading {
    font-size: 58px;
  }
}

/*
  Home 3 : Feature Section
---------------------------------*/
.feature-section--l3 {
  padding-top: 50px;
  padding-bottom: 60px;
}

@media (min-width: 576px) {
  .feature-section--l3 {
    padding-top: 70px;
    padding-bottom: 80px;
  }
}

@media (min-width: 992px) {
  .feature-section--l3 {
    padding-top: 125px;
    padding-bottom: 135px;
  }
}

.feature-section--l3 .section-title {
  margin-bottom: 10px;
}

@media (min-width: 992px) {
  .feature-section--l3 .section-title {
    margin-bottom: 30px;
  }
}

.feature-section--l3 .section-title__sub-heading {
  margin-bottom: 20px;
}

.feature-section--l3 .section-title__heading {
  margin-bottom: 0px;
}

.feature-content--l3 {
  margin-top: 50px;
}

@media (min-width: 992px) {
  .feature-content--l3__image {
    margin-right: 30px;
  }
}

.feature-content--l3__list {
  margin-top: 10px;
  margin-bottom: 30px;
}

@media (min-width: 1200px) {
  .feature-content--l3__list {
    margin-bottom: 65px;
  }
}

.feature-content--l3__list li {
  position: relative;
  z-index: 1;
  padding-left: 30px;
  margin-bottom: 20px;
  color: var(--color-headings-2);
  font-size: 16px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 1.75;
}

.feature-content--l3__list li:before {
  content: "";
  position: absolute;
  width: 10px;
  height: 18px;
  background-color: #ff5722;
  left: 10px;
  top: 4px;
}

.feature-counter--l3 {
  position: relative;
}

@media (min-width: 992px) {
  .feature-counter--l3 {
    left: -25%;
    min-width: 120%;
  }
}

.content-image-group {
  position: relative;
  z-index: 1;
}

/*
  Home 1 : Content Section One
---------------------------------*/
.content-section--l1-1 {
  padding-top: 100px;
  padding-bottom: 50px;
}

@media (min-width: 768px) {
  .content-section--l1-1 {
    padding-top: 130px;
    padding-bottom: 75px;
  }
}

@media (min-width: 992px) {
  .content-section--l1-1 {
    padding-top: 195px;
    padding-bottom: 214px;
  }
}

.content-texts {
  padding-top: 80px;
}

@media (min-width: 768px) {
  .content-texts {
    padding-top: 0;
  }
}

.content-texts .content__heading {
  font-size: 35px;
  font-weight: 500;
  letter-spacing: -2px;
  line-height: 1.1;
  margin-bottom: 25px;
}

@media (min-width: 576px) {
  .content-texts .content__heading {
    font-size: 45px;
  }
}

@media (min-width: 768px) {
  .content-texts .content__heading {
    font-size: 50px;
  }
}

@media (min-width: 992px) {
  .content-texts .content__heading {
    font-size: 60px;
    margin-bottom: 35px;
  }
}

.content-texts .content__description {
  font-size: 18px;
  line-height: 1.66666;
}

.content-texts .content__description:last-child {
  margin-bottom: 0;
}

.content-texts--l1-1 {
  padding-top: 90px;
}

@media (min-width: 992px) {
  .content-texts--l1-1 {
    padding-left: 50px;
    padding-top: 0px;
    margin-left: 10px;
  }
}

@media (min-width: 1200px) {
  .content-texts--l1-1 {
    padding-left: 0px;
  }
}

@media (min-width: 992px) {
  .content-image-group--l1_1 {
    padding: 0 36px;
  }
}

.content-image-group--l1_1 .content-image-group__image-1 {
  position: absolute;
  top: -20px;
  left: -5px;
  width: 30%;
  z-index: -1;
}

@media (min-width: 480px) {
  .content-image-group--l1_1 .content-image-group__image-1 {
    top: -5%;
    left: -6%;
  }
}

@media (min-width: 992px) {
  .content-image-group--l1_1 .content-image-group__image-1 {
    left: 0;
  }
}

.content-image-group--l1_1 .content-image-group__image-2 {
  position: absolute;
  top: -11%;
  right: 20%;
  z-index: -1;
  width: 35%;
}

@media (min-width: 1200px) {
  .content-image-group--l1_1 .content-image-group__image-2 {
    right: 10%;
  }
}

.content-image-group--l1_1 .content-image-group__image-3 {
  position: absolute;
  bottom: 11%;
  left: -3%;
  z-index: -1;
  width: 35%;
}

@media (min-width: 480px) {
  .content-image-group--l1_1 .content-image-group__image-3 {
    left: -11%;
  }
}

@media (min-width: 992px) {
  .content-image-group--l1_1 .content-image-group__image-3 {
    left: -5%;
  }
}

.content-image-group--l1_1 .content-image-group__image-4 {
  position: absolute;
  bottom: -15%;
  right: -18%;
  transform: scale(0.6);
}

@media (min-width: 480px) {
  .content-image-group--l1_1 .content-image-group__image-4 {
    right: -80px;
  }
}

@media (min-width: 768px) {
  .content-image-group--l1_1 .content-image-group__image-4 {
    transform: scale(0.9);
    right: -40px;
  }
}

@media (min-width: 992px) {
  .content-image-group--l1_1 .content-image-group__image-4 {
    right: -40px;
    bottom: -80px;
  }
}

@media (min-width: 1200px) {
  .content-image-group--l1_1 .content-image-group__image-4 {
    transform: scale(1);
  }
}

/*
  Home 1 : Content Section Two
---------------------------------*/
.content-section--l1-2 {
  padding-top: 80px;
  padding-bottom: 50px;
}

@media (min-width: 768px) {
  .content-section--l1-2 {
    padding-top: 112px;
    padding-bottom: 75px;
  }
}

@media (min-width: 992px) {
  .content-section--l1-2 {
    padding-top: 165px;
    padding-bottom: 135px;
  }
}

.content-texts--l1-2 {
  padding-top: 40px;
}

@media (min-width: 992px) {
  .content-texts--l1-2 {
    padding-top: 0px;
  }
}

/*
  Home 2 : Content Section One
---------------------------------*/
.content-section--l2-1 {
  padding-top: 60px;
  padding-bottom: 60px;
}

@media (min-width: 768px) {
  .content-section--l2-1 {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

@media (min-width: 992px) {
  .content-section--l2-1 {
    padding-top: 132px;
    padding-bottom: 135px;
  }
}

.content-section--l2-1__shape {
  position: absolute;
  left: -3.8%;
  top: 12%;
  z-index: 0;
  width: 9%;
}

.content-image-group--l2-1 {
  position: relative;
  margin-bottom: 40px;
}

@media (min-width: 1200px) {
  .content-image-group--l2-1 {
    margin-left: 45px;
    margin-bottom: 0;
  }
}

.content-image-group--l2-1__img-1 {
  width: 38%;
  position: absolute;
  right: 13%;
  bottom: 0;
  z-index: -1;
}

.content-texts--l2-1 {
  padding-top: 0px;
}

.content-texts--l2-1__heading {
  font-size: 35px;
  font-weight: 500;
  letter-spacing: -2px;
  line-height: 1.20689;
  margin-bottom: 32px;

}

.content-texts--l2-1__heading2 {
  font-size: 32px;
  font-weight: 500;
  letter-spacing: -2px;
  line-height: 1.20689;
  margin-bottom: 32px;
}

@media (min-width: 480px) {
  .content-texts--l2-1__heading {
    font-size: 38px;
  }
}

@media (min-width: 768px) {
  .content-texts--l2-1__heading {
    font-size: 48px;
  }
}

@media (min-width: 992px) {
  .content-texts--l2-1__heading {
    font-size: 42px;
  }
}

.content-texts--l2-1__content {
  font-size: 18px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: 1.66666;
  margin-bottom: 0;
}


.content-texts--l2-3__content {
  font-size: 56px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: normal;
  line-height: 1.66666;
  margin-bottom: 0;
  color:#ffffff;
}

.content-texts--l2-1__button {
  margin-top: 22px;
}

.content-section--l2-2__wrapper {
  padding-top: 60px;
  padding-bottom: 60px;
}

@media (min-width: 768px) {
  .content-section--l2-2__wrapper {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

@media (min-width: 992px) {
  .content-section--l2-2__wrapper {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

.content-section--l2-2__shape-img {
  position: absolute;
  bottom: 10%;
  right: 10%;
  width: 3.4%;
  -webkit-animation: rotation 8s ease 0s infinite alternate none running;
          animation: rotation 8s ease 0s infinite alternate none running;
}

.content-image-group--l2-2 {
  position: relative;
  margin-bottom: 40px;
  z-index: 1;
}

@media (min-width: 1200px) {
  .content-image-group--l2-2 {
    margin-bottom: 0;
    margin-right: 55px;
  }
}

.content-image-group--l2-2__img-1 {
  width: 38%;
  position: absolute;
  left: -1%;
  top: -7%;
  z-index: -1;
}

/*
  Home 3 : Content Section One
---------------------------------*/
.content-section--l3-1 {
  padding-top: 60px;
  padding-bottom: 25px;
}

@media (min-width: 768px) {
  .content-section--l3-1 {
    padding-top: 80px;
    padding-bottom: 40px;
  }
}

@media (min-width: 992px) {
  .content-section--l3-1 {
    padding-top: 125px;
    padding-bottom: 120px;
  }
}

.content-section--l3-1 .section-title {
  padding-bottom: 20px;
}

@media (min-width: 992px) {
  .content-section--l3-1 .section-title {
    padding-bottom: 65px;
  }
}

.content-section--l3-1 .section-title__sub-heading {
  margin-bottom: 15px;
}

.content-img--l3-1 {
  padding-bottom: 35px;
}

@media (min-width: 992px) {
  .content-img--l3-1 {
    padding-bottom: 0;
  }
}

@media (min-width: 1200px) {
  .content-img--l3-1 {
    margin-left: 60px;
  }
}

/*
  Home 3 : Content Section Two
---------------------------------*/
.content-section--l3-2 {
  padding-top: 50px;
  padding-bottom: 10px;
}

@media (min-width: 768px) {
  .content-section--l3-2 {
    padding-top: 72px;
    padding-bottom: 32px;
  }
}

@media (min-width: 992px) {
  .content-section--l3-2 {
    padding-top: 115px;
    padding-bottom: 90px;
  }
}

.content-section--l3-2 .section-title {
  padding-bottom: 20px;
}

@media (min-width: 768px) {
  .content-section--l3-2 .section-title {
    padding-bottom: 30px;
  }
}

@media (min-width: 992px) {
  .content-section--l3-2 .section-title {
    padding-bottom: 50px;
  }
}

.content-section--l3-2 .section-title__sub-heading {
  margin-bottom: 20px;
}

@media (max-width: 991.98px) {
  .widgets-content--l3 .single-widget:nth-child(2) > div::before {
    opacity: 0;
  }
}

.widgets-content--l3 .single-widget:last-child > div::before {
  opacity: 0;
}

/*
  Home 4 : Content Section One
---------------------------------*/
.content-section--l4-1 {
  padding-top: 60px;
  padding-bottom: 7px;
}

@media (min-width: 768px) {
  .content-section--l4-1 {
    padding-top: 82px;
    padding-bottom: 27px;
  }
}

@media (min-width: 992px) {
  .content-section--l4-1 {
    padding-top: 123px;
    padding-bottom: 180px;
  }
}

.content-section--l4-1 .section-title {
  margin-bottom: 45px !important;
}

@media (min-width: 992px) {
  .content-section--l4-1 .section-title {
    margin-bottom: 65px !important;
  }
}

.content-section--l4-1 .section-title__sub-heading {
  padding-bottom: 15px;
}

.content-section--l4-1 .section-title__heading {
  padding-bottom: 13px;
}

.content-img--l4-1 {
  padding-bottom: 35px;
}

@media (min-width: 992px) {
  .content-img--l4-1 {
    padding-bottom: 0;
  }
}

@media (min-width: 1600px) {
  .content-img--l4-1 {
    margin-left: 120px;
  }
}

/*
  Home 4 : Content Section Two
---------------------------------*/
.content-section--l4-2 {
  padding-top: 53px;
  padding-bottom: 20px;
}

@media (min-width: 768px) {
  .content-section--l4-2 {
    padding-top: 72px;
    padding-bottom: 40px;
  }
}

@media (min-width: 992px) {
  .content-section--l4-2 {
    padding-top: 140px;
    padding-bottom: 123px;
  }
}

.content-section--l4-2 .section-title {
  margin-bottom: 45px;
}

@media (min-width: 992px) {
  .content-section--l4-2 .section-title {
    margin-bottom: 60px;
  }
}

@media (min-width: 1200px) {
  .content-section--l4-2 .section-title {
    margin-bottom: 0;
  }
}

.content-section--l4-2 .section-title__sub-heading {
  margin-bottom: 20px;
}

.content-section--l4-2 .section-title__heading {
  margin-bottom: 15px;
}

.content-section--l4-2 .section-title__description {
  margin-bottom: 33px;
}

/*
  Home 5 : Content Section One
---------------------------------*/
.content-section--l5-1,
.content-section--l8 {
  padding-top: 60px;
  padding-bottom: 20px;
}

@media (min-width: 768px) {
  .content-section--l5-1,
  .content-section--l8 {
    padding-top: 80px;
    padding-bottom: 40px;
  }
}

@media (min-width: 992px) {
  .content-section--l5-1,
  .content-section--l8 {
    padding-top: 70px;
    padding-bottom: 60px;
  }
}

.content-section--l5-1 .section-title__sub-heading,
.content-section--l8 .section-title__sub-heading {
  margin-bottom: 20px;
}

.content-section--l5-1 .section-title__heading,
.content-section--l8 .section-title__heading {
  margin-bottom: 15px;
}

.content-section--l5-1 .section-title__description,
.content-section--l8 .section-title__description {
  margin-bottom: 33px;
}

@media (min-width: 992px) {
  .content-section--l5-1 .section-title__description,
  .content-section--l8 .section-title__description {
    font-weight: 400;
    letter-spacing: normal;
    line-height: 32px;
    font-size: 16px;
  }
}

@media (min-width: 1600px) {
  .content-section--l5-1 .section-title__description,
  .content-section--l8 .section-title__description {
    font-size: 18px;
  }
}

@media (min-width: 1400px) {
  .content-section--l5-1 .content-img,
  .content-section--l8 .content-img {
    padding: 0 55px;
  }
}

@media (min-width: 480px) {
  .content-section--l5-1 .content-right,
  .content-section--l8 .content-right {
    margin-right: 70px;
  }
}

@media (min-width: 768px) {
  .content-section--l5-1 .content-right,
  .content-section--l8 .content-right {
    margin-right: 30px;
    margin-top: 40px;
  }
}

@media (min-width: 992px) {
  .content-section--l5-1 .content-right,
  .content-section--l8 .content-right {
    margin-top: 70px;
    margin-right: 0px;
  }
}

/*
  Home 6 : Content Section One
---------------------------------*/
.content-section--l5 {
  padding-top: 60px;
  padding-bottom: 20px;
}

@media (min-width: 768px) {
  .content-section--l5 {
    padding-top: 80px;
    padding-bottom: 40px;
  }
}

@media (min-width: 992px) {
  .content-section--l5 {
    padding-top: 70px;
    padding-bottom: 60px;
  }
}

.content-section--l5 .widgets-content--l6 {
  margin-top: 40px;
}

@media (min-width: 992px) {
  .content-section--l5 .widgets-content--l6 {
    margin-top: 73px;
  }
}

@media (min-width: 1200px) {
  .content-section--l5 .content-img {
    margin-left: 125px;
  }
}

/*
  Home 7 : Content Section One
---------------------------------*/
.content-section--l7 {
  padding-top: 60px;
  padding-bottom: 55px;
}

@media (min-width: 768px) {
  .content-section--l7 {
    padding-top: 80px;
    padding-bottom: 75px;
  }
}

@media (min-width: 992px) {
  .content-section--l7 {
    padding-top: 130px;
    padding-bottom: 130px;
  }
}

@media (min-width: 1200px) {
  .content-section--l7 {
    padding-top: 130px;
    padding-bottom: 135px;
  }
}

.content-section--l7 .section-title__sub-heading {
  margin-bottom: 20px;
}

@media (min-width: 992px) {
  .content-section--l7 .section-title__sub-heading {
    margin-bottom: 25px;
  }
}

.content-section--l7 .section-title__heading {
  margin-bottom: 40px;
}

@media (min-width: 992px) {
  .content-section--l7 .section-title__heading {
    margin-bottom: 60px;
  }
}

.content-section--l7 .content-img--2 {
  padding: 30px 0;
}

@media (min-width: 1200px) {
  .content-section--l7 .content-img--2 {
    padding-top: 75px;
    padding-bottom: 80px;
  }
}

.content-section--l7-2 {
  padding-top: 60px;
  padding-bottom: 55px;
}

@media (min-width: 768px) {
  .content-section--l7-2 {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

@media (min-width: 992px) {
  .content-section--l7-2 {
    padding-top: 130px;
    padding-bottom: 135px;
  }
}

.content-section--l7-2 .section-title__sub-heading {
  margin-bottom: 20px;
}

.content-section--l7-2 .section-title__heading {
  margin-bottom: 15px;
}

.content-section--l7-2 .section-title__description {
  margin-bottom: 0px;
}

@media (min-width: 576px) {
  .content-section--l7-2 .content-img {
    right: -15%;
  }
}

@media (min-width: 768px) {
  .content-section--l7-2 .content-img {
    right: -25%;
  }
}

@media (min-width: 1200px) {
  .content-section--l7-2 .content-img {
    right: -20%;
  }
}

/*
  Home 8 : Content Section One
---------------------------------*/
.content-section--8 {
  padding-top: 35px;
  padding-bottom: 5px;
}

@media (min-width: 768px) {
  .content-section--8 {
    padding-top: 75px;
    padding-bottom: 25px;
  }
}

@media (min-width: 992px) {
  .content-section--8 {
    padding-top: 120px;
    padding-bottom: 0px;
  }
}

.content-section--8 .widgets--about-l6 .widgets__icon {
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
  color: #ff7272 !important;
}

.content-section--8 .widgets--about-l6:hover .widgets__icon {
  background-color: #ff7272 !important;
  color: #fff !important;
}

.content-section--8 .content-img {
  margin-bottom: 30px;
  position: relative;
  z-index: 1;
  width: 80%;
  margin: 0 auto;
}

.content-section--8 .content-img .shape {
  position: absolute;
  top: 50%;
  left: 56%;
  z-index: -1;
  transform: translate(-50%, -50%);
  width: 90%;
}

.content-section--8 .content-img .shape img {
  width: 100%;
}

.content-section--8 .section-title {
  margin-bottom: 30px;
}

@media (min-width: 1200px) {
  .content-section--8 .section-title {
    margin-bottom: 0;
  }
}

.content-section--8 .section-title__sub-heading {
  margin-bottom: 18px;
}

.content-section--8 .section-title__heading {
  margin-bottom: 0;
}

.content-section--l8-2 {
  padding-top: 35px;
  padding-bottom: 25px;
}

@media (min-width: 768px) {
  .content-section--l8-2 {
    padding-top: 75px;
    padding-bottom: 45px;
  }
}

@media (min-width: 992px) {
  .content-section--l8-2 {
    padding-top: 80px;
    padding-bottom: 0px;
  }
}

.content-section--l8-2 .widgets-content--l8 {
  margin-top: 30px;
}

@media (min-width: 992px) {
  .content-section--l8-2 .widgets-content--l8 {
    margin-top: 68px;
  }
}

.content-section--l8-2 .content-img {
  position: relative;
  z-index: 1;
  width: 80%;
  margin: 0 auto;
}

.content-section--l8-2 .content-img .shape {
  position: absolute;
  top: 50%;
  left: 56%;
  z-index: -1;
  transform: translate(-50%, -50%);
  width: 90%;
}

.content-section--l8-2 .content-img .shape img {
  width: 100%;
}

@media (min-width: 992px) {
  .content-section--l8-2 .content-img {
    margin-left: 30px;
  }
}

.content-section--l8-2 .widget--content-l6__circle {
  box-shadow: 0 4px 4px #057d7b;
}

/*
  Home 9 : Content Section One
---------------------------------*/
.content-section--l9-1 {
  padding-top: 55px;
  padding-bottom: 10px;
}

@media (min-width: 768px) {
  .content-section--l9-1 {
    padding-top: 70px;
    padding-bottom: 32px;
  }
}

@media (min-width: 992px) {
  .content-section--l9-1 {
    padding-top: 65px;
    padding-bottom: 85px;
  }
}

.content-section--l9-1 .section-title {
  margin-bottom: 40px;
}

@media (min-width: 992px) {
  .content-section--l9-1 .section-title {
    margin-bottom: 80px;
  }
}

.content-section--l9-1 .section-title__sub-heading {
  padding-left: 0;
}

.content-section--l9-1 .section-title__sub-heading::before {
  display: none;
}

.contact-section--l3 {
  padding-bottom: 60px;
  padding-top: 60px;
}

@media (min-width: 576px) {
  .contact-section--l3 {
    padding-bottom: 80px;
    padding-top: 80px;
  }
}

@media (min-width: 992px) {
  .contact-section--l3 {
    padding-bottom: 120px;
    padding-top: 120px;
  }
}

@media (min-width: 992px) {
  .contact-section--l3 .contact-widget-box {
    margin-left: 30px;
  }
}

@media (min-width: 1200px) {
  .contact-section--l3 .contact-widget-box {
    margin-left: 95px;
    margin-right: 0;
  }
}

@media (min-width: 1400px) {
  .contact-section--l3 .contact-widget-box {
    margin-left: 100px;
    margin-right: 80px;
  }
}

.contact-section--l3 .section-title__sub-heading {
  margin-bottom: 10px;
}

.contact-widget-box {
  padding: 30px 30px 15px;
  background-color: #5034fc;
  border-radius: 15px;
}

@media (min-width: 576px) {
  .contact-widget-box {
    padding: 40px 50px 15px;
  }
}

.contact-widget-box .widget--contact {
  margin-bottom: 20px;
}

.contact-widget-box__title-block {
  padding-bottom: 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  padding-bottom: 30px;
  margin-bottom: 35px;
}

.contact-widget-box__title-block .widget-box__title {
  color: #ffffff;
  font-family: "Rubik", sans-serif;
  font-size: 32px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: normal;
  line-height: 42px;
  text-align: left;
}

.contact-widget-box__title-block .widget-box__paragraph {
  color: #ffffff;
  font-size: 15px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: 28px;
  text-align: left;
  margin-bottom: 0;
}

/*
  Home 4 : Footer
---------------------------------*/
.contact-section--l4,
.contact-section--inner-2 {
  padding-top: 60px;
}

@media (min-width: 576px) {
  .contact-section--l4,
  .contact-section--inner-2 {
    padding-bottom: 80px;
    padding-top: 80px;
  }
}

@media (min-width: 992px) {
  .contact-section--l4,
  .contact-section--inner-2 {
    padding-bottom: 120px;
    padding-top: 120px;
  }
}

@media (min-width: 992px) {
  .contact-section--l4 .contact-widget-box,
  .contact-section--inner-2 .contact-widget-box {
    margin-left: 30px;
  }
}

@media (min-width: 1200px) {
  .contact-section--l4 .contact-widget-box,
  .contact-section--inner-2 .contact-widget-box {
    margin-left: 95px;
    margin-right: 0;
  }
}

@media (min-width: 1400px) {
  .contact-section--l4 .contact-widget-box,
  .contact-section--inner-2 .contact-widget-box {
    margin-left: 100px;
    margin-right: 80px;
  }
}

.contact-section--l4 .section-title,
.contact-section--inner-2 .section-title {
  margin-bottom: 45px;
}

@media (min-width: 992px) {
  .contact-section--l4 .section-title,
  .contact-section--inner-2 .section-title {
    margin-bottom: 80px !important;
  }
}

.contact-section--l4 .section-title__sub-heading,
.contact-section--inner-2 .section-title__sub-heading {
  margin-bottom: 10px;
}

/*
  Contact -1
---------------------------------*/
.contact-section--l4 .contact-form .form-check-label {
  font-size: 15px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 28px;
}

/*
  Contact -1
---------------------------------*/
.contact-inner-1 {
  padding-top: 55px;
  padding-bottom: 60px;
}

@media (min-width: 768px) {
  .contact-inner-1 {
    padding-top: 75px;
    padding-bottom: 80px;
  }
}

@media (min-width: 992px) {
  .contact-inner-1 {
    padding-top: 130px;
    padding-bottom: 110px;
  }
}

.contact-inner-1 .contact-widget-box {
  padding: 40px 45px 15px;
}

/* ======= Brand Area ===== */
.brand-area--l2 {
  padding-top: 50px;
  padding-bottom: 0px;
}

@media (min-width: 768px) {
  .brand-area--l2 {
    padding-top: 100px;
    padding-bottom: 80px;
  }
}

@media (min-width: 992px) {
  .brand-area--l2 {
    padding-top: 123px;
    padding-bottom: 200px;
    background-image: url(../images/brandbg.svg);
    background-size: 100%;
  }
}

.brand-group-image-l2 {
  margin-left: -22px;
}

@media (min-width: 576px) {
  .brand-group-image-l2 {
    transform: scale(0.8);
  }
}

@media (min-width: 768px) {
  .brand-group-image-l2 {
    margin-top: 40px;
    transform: scale(1);
  }
}

@media (min-width: 992px) {
  .brand-group-image-l2 {
    margin-top: 100px;
  }
}

.brand-image-group--l2 {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 503px;
  transform: scale(0.9);
  margin-top: 30px;
  /* Images */
  /* Image Line */
}

@media (min-width: 768px) {
  .brand-image-group--l2 {
    transform: scale(0.9);
  }
}

@media (min-width: 992px) {
  .brand-image-group--l2 {
    transform: scale(1);
    margin-top: 90px;
  }
}

.brand-image-group--l2__single {
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.brand-image-group--l2__image--main {
  width: 5%;
  position: relative;
  border: 6px solid #ffffff;
  background-color: #ff5722;
  border-radius: 50%;
  z-index: 1;
}

.brand-image-group--l2__img-1 {
  position: absolute;
  left: 37%;
  top: 7%;
  width: 10%;
  z-index: 2;
}

@media (min-width: 480px) {
  .brand-image-group--l2__img-1 {
    left: 47%;
  }
}

.brand-image-group--l2__img-2 {
  position: absolute;
  right: -8%;
  top: 16%;
  width: 11%;
  z-index: 1;
}

@media (min-width: 768px) {
  .brand-image-group--l2__img-2 {
    right: -12%;
    top: 10%;
  }
}

.brand-image-group--l2__img-3 {
  position: absolute;
  right: -10%;
  top: 50%;
  transform: translateY(-50%);
  width: 11%;
  z-index: 1;
}

@media (min-width: 576px) and (max-width: 768px) {
  .brand-image-group--l2__img-3 {
    right: -9%;
  }
}

@media (min-width: 768px) {
  .brand-image-group--l2__img-3 {
    right: -28%;
  }
}

.brand-image-group--l2__img-4 {
  position: absolute;
  right: 0;
  bottom: 20%;
  width: 10%;
  z-index: 1;
}

.brand-image-group--l2__img-5 {
  position: absolute;
  left: 38%;
  bottom: 10%;
  width: 10%;
  z-index: 1;
}

@media (min-width: 480px) {
  .brand-image-group--l2__img-5 {
    left: 47%;
    bottom: 0;
  }
}

.brand-image-group--l2__img-6 {
  position: absolute;
  left: 0;
  bottom: 18%;
  width: 12%;
  z-index: 1;
}

@media (min-width: 480px) {
  .brand-image-group--l2__img-6 {
    bottom: 12%;
  }
}

.brand-image-group--l2__img-7 {
  position: absolute;
  left: -5%;
  top: 50%;
  transform: translateY(-50%);
  width: 10%;
  z-index: 1;
}

@media (min-width: 576px) and (max-width: 768px) {
  .brand-image-group--l2__img-7 {
    left: -7%;
  }
}

@media (min-width: 768px) {
  .brand-image-group--l2__img-7 {
    left: -22%;
  }
}

.brand-image-group--l2__img-8 {
  position: absolute;
  left: -7%;
  top: 16%;
  width: 10%;
  z-index: 1;
}

@media (min-width: 480px) {
  .brand-image-group--l2__img-8 {
    top: 10%;
  }
}

.brand-image-group--l2__line-1 {
  position: absolute;
  left: 48%;
  top: 26%;
  z-index: 0;
  width: 3.7%;
}

@media (min-width: 480px) {
  .brand-image-group--l2__line-1 {
    left: 52%;
  }
}

.brand-image-group--l2__line-2 {
  position: absolute;
  right: 0;
  top: 27%;
  width: 42%;
}

@media (min-width: 480px) {
  .brand-image-group--l2__line-2 {
    top: 22%;
    width: 40%;
  }
}

.brand-image-group--l2__line-3 {
  position: absolute;
  right: -9%;
  top: 50%;
  transform: translateY(-50%);
  width: 50%;
}

.brand-image-group--l2__line-4 {
  position: absolute;
  bottom: 29%;
  right: 13%;
  width: 33%;
}

.brand-image-group--l2__line-5 {
  position: absolute;
  right: 47%;
  bottom: 23%;
  width: 4%;
}

@media (min-width: 480px) {
  .brand-image-group--l2__line-5 {
    right: 45%;
    bottom: 15%;
  }
}

.brand-image-group--l2__line-6 {
  position: absolute;
  left: 9%;
  bottom: 29%;
  width: 35%;
}

@media (min-width: 480px) {
  .brand-image-group--l2__line-6 {
    bottom: 24%;
  }
}

.brand-image-group--l2__line-7 {
  position: absolute;
  left: -5%;
  top: 50%;
  transform: translateY(-50%);
  width: 47%;
}

.brand-image-group--l2__line-8 {
  position: absolute;
  left: 7%;
  top: 28%;
  width: 35%;
}

@media (min-width: 480px) {
  .brand-image-group--l2__line-8 {
    top: 22%;
  }
}

/*
  Home 3
---------------------------------*/
.pormo--l3 {
  position: relative;
  z-index: 1;
  padding-top: 50px;
  padding-bottom: 60px;
  background-position: center;
  background-size: cover;
}

@media (min-width: 768px) {
  .pormo--l3 {
    padding-top: 70px;
    padding-bottom: 80px;
  }
}

@media (min-width: 992px) {
  .pormo--l3 {
    padding-top: 120px;
    padding-bottom: 130px;
  }
}

.pormo--l3::before {
  content: "";
  background-color: rgba(80, 52, 252, 0.9);
  mix-blend-mode: multiply;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: -1;
}

.pormo--l3__heading {
  font-size: 45px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 1.2;
  margin-bottom: 20px;
}

.pormo--l3__text {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 1.75;
  color: var(--color-headings-2);
}

/*
  Home 4
---------------------------------*/
.promo-area--l4 {
  padding-top: 60px;
  padding-bottom: 60px;
}

@media (min-width: 768px) {
  .promo-area--l4 {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

@media (min-width: 992px) {
  .promo-area--l4 {
    padding-top: 130px;
    padding-bottom: 130px;
  }
}

.pormo--l4 {
  position: relative;
  z-index: 1;
  padding-top: 50px;
  padding-bottom: 60px;
  padding-left: 30px;
  padding-right: 30px;
  background-position: center;
  background-size: cover;
  border-radius: 25px;
}

@media (min-width: 768px) {
  .pormo--l4 {
    padding-top: 70px;
    padding-bottom: 80px;
    padding-left: 110px;
    padding-right: 100px;
  }
}

@media (min-width: 992px) {
  .pormo--l4 {
    padding-top: 80px;
    padding-bottom: 60px;
  }
}

.pormo--l4::before {
  content: "";
  background-color: #adadad;
  mix-blend-mode: multiply;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  border-radius: 25px;
  z-index: -1;
}

.pormo--l4 .section-title__sub-heading {
  margin-bottom: 15px;
}

.pormo--l4 .section-title__heading {
  color: #fff;
  margin-bottom: 12px;
}

.pormo--l4 .section-title__description {
  margin-bottom: 30px;
  color: #fff;
}

/*
  Home 5
---------------------------------*/
.promo-area--l5 {
  padding-top: 60px;
  padding-bottom: 60px;
  background-repeat: no-repeat;
  background-size: cover;
}

@media (min-width: 768px) {
  .promo-area--l5 {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

@media (min-width: 992px) {
  .promo-area--l5 {
    padding-top: 130px;
    padding-bottom: 130px;
  }
}

.pormo--l5-content {
  border-radius: 15px;
  background-color: rgba(80, 52, 252, 0.85);
  padding-top: 56px;
  padding-bottom: 56px;
  padding-left: 30px;
  padding-right: 30px;
}

.pormo--l5-content .badge--promo {
  font-size: 13px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 22px;
  border-radius: 15px;
  background-color: #fcdc00;
  color: #262729;
  min-width: 112px;
  height: 30px;
  margin-bottom: 22px;
}

.pormo--l5-content .section-title__heading {
  font-size: 36px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 1.5;
  margin-bottom: 13px;
}

.pormo--l5-content .section-title__description {
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 1.875;
  margin-bottom: 40px;
}

/*
  Home 6
---------------------------------*/
.promo-area--l6,
.promo-area--l8 {
  padding-top: 60px;
  padding-bottom: 60px;
  background-repeat: no-repeat;
  background-size: cover;
}

@media (min-width: 768px) {
  .promo-area--l6,
  .promo-area--l8 {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

@media (min-width: 992px) {
  .promo-area--l6,
  .promo-area--l8 {
    padding-top: 130px;
    padding-bottom: 130px;
  }
}

@media (min-width: 768px) {
  .promo-area--l6 .promo--l6-newsletter,
  .promo-area--l8 .promo--l6-newsletter {
    margin-right: 50px;
  }
}

@media (min-width: 992px) {
  .promo-area--l6 .promo--l6-newsletter,
  .promo-area--l8 .promo--l6-newsletter {
    margin-right: 56px;
  }
}

@media (min-width: 1200px) {
  .promo-area--l6 .promo--l6-newsletter,
  .promo-area--l8 .promo--l6-newsletter {
    margin-right: 76px;
  }
}

.pormo--l6-content,
.pormo--l8-content {
  padding-top: 65px;
  padding-bottom: 60px;
  padding-left: 30px;
  padding-right: 30px;
  border-radius: 25px;
}

@media (min-width: 576px) {
  .pormo--l6-content,
  .pormo--l8-content {
    padding-left: 60px;
    padding-right: 60px;
  }
}

@media (min-width: 768px) {
  .pormo--l6-content,
  .pormo--l8-content {
    padding-left: 80px;
    padding-right: 50px;
  }
}

.pormo--l6-content .section-title__sub-heading,
.pormo--l8-content .section-title__sub-heading {
  margin-bottom: 35px;
}

.pormo--l6-content .section-title__heading,
.pormo--l8-content .section-title__heading {
  font-size: 32px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: normal;
  line-height: 1.38095;
  margin-bottom: 47px;
}

@media (min-width: 768px) {
  .pormo--l6-content .section-title__heading,
  .pormo--l8-content .section-title__heading {
    font-size: 35px;
  }
}

@media (min-width: 992px) {
  .pormo--l6-content .section-title__heading,
  .pormo--l8-content .section-title__heading {
    font-size: 42px;
  }
}

/*
  Home 7
---------------------------------*/
.promo-area--l7,
.promo-area--inner-2 {
  padding-top: 35px;
  padding-bottom: 60px;
}

@media (min-width: 768px) {
  .promo-area--l7,
  .promo-area--inner-2 {
    padding-top: 55px;
    padding-bottom: 80px;
  }
}

@media (min-width: 992px) {
  .promo-area--l7,
  .promo-area--inner-2 {
    padding-top: 100px;
    padding-bottom: 130px;
  }
}

.pormo--l7 {
  position: relative;
  z-index: 1;
  padding-top: 50px;
  padding-bottom: 60px;
  padding-left: 30px;
  padding-right: 30px;
  background-position: center;
  background-size: cover;
  border-radius: 25px;
}

@media (min-width: 768px) {
  .pormo--l7 {
    padding-top: 70px;
    padding-bottom: 80px;
    padding-left: 110px;
    padding-right: 100px;
  }
}

@media (min-width: 992px) {
  .pormo--l7 {
    padding-top: 110px;
    padding-bottom: 100px;
  }
}

.pormo--l7::before {
  content: "";
  background: linear-gradient(261.26deg, rgba(253, 52, 110, 0.96) 14.9%, #b81c5c 43.58%, #0abfbc 89.94%);
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  border-radius: 25px;
  z-index: -1;
}

.pormo--l7 .section-title__sub-heading {
  margin-bottom: 15px;
}

.pormo--l7 .section-title__heading {
  color: #fff;
  margin-bottom: 17px;
}

.pormo--l7 .section-title__description {
  margin-bottom: 30px;
  color: #fff;
}

/*
  Home 8
---------------------------------*/
.promo-area--l8 {
  background-image: linear-gradient(300deg, #099a97 0%, #099a97 33%, #20aa8e 53%, #20aa8e 53%, #82c4c3 100%);
  position: relative;
}

@media (min-width: 1200px) {
  .promo-area--l8 .promo-image {
    position: absolute;
    bottom: 0;
    left: 0;
  }
}

@media (min-width: 1600px) {
  .promo-area--l8 .promo-image {
    left: 10%;
  }
}

.pormo--l8-content {
  background-image: linear-gradient(90deg, rgba(255, 255, 255, 0.87) 0%, rgba(255, 255, 255, 0.62) 29%, rgba(255, 255, 255, 0.2) 100%);
}

.pormo--l8-content .section-title__sub-heading {
  color: #262729;
  margin-bottom: 10px;
}

@media (min-width: 992px) {
  .pormo--l8-content .section-title__sub-heading {
    margin-bottom: 30px;
  }
}

.pormo--l8-content .section-title__heading {
  font-size: 32px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: normal;
  line-height: 1.38095;
  margin-bottom: 25px;
  color: #262729;
}

@media (min-width: 768px) {
  .pormo--l8-content .section-title__heading {
    font-size: 35px;
  }
}

@media (min-width: 992px) {
  .pormo--l8-content .section-title__heading {
    font-size: 42px;
    margin-bottom: 47px;
  }
}

@media (min-width: 480px) {
  .pormo--l8-content .promo--l6-newsletter {
    background-color: rgba(255, 255, 255, 0.4);
  }
}

.pormo--l8-content .promo--l6-newsletter form input {
  background-color: rgba(255, 255, 255, 0.4);
  color: #262729 !important;
}

@media (min-width: 480px) {
  .pormo--l8-content .promo--l6-newsletter form input {
    background-color: transparent !important;
  }
}

.pormo--l8-content .promo--l6-newsletter form input:placeholder {
  background-color: transparent;
  color: #ddd;
}

.pormo--l8-content .promo--l6-newsletter form .btn {
  box-shadow: none;
}

/*
  promo 2 inner page
---------------------------------*/
.promo-area--inner-2 {
  padding-top: 60px;
}

@media (min-width: 768px) {
  .promo-area--inner-2 {
    padding-top: 80px;
  }
}

@media (min-width: 992px) {
  .promo-area--inner-2 {
    padding-top: 120px;
    padding-bottom: 120px;
  }
}

/*
  About US- Promo Area
---------------------------------*/
.promo-area--inner-about {
  padding-top: 50px;
  padding-bottom: 60px;
  position: relative;
}

@media (min-width: 768px) {
  .promo-area--inner-about {
    padding-top: 70px;
    padding-bottom: 80px;
  }
}

@media (min-width: 992px) {
  .promo-area--inner-about {
    padding-top: 100px;
    padding-bottom: 120px;
  }
}

.promo-area--inner-about .promo-content__title {
  font-size: 32px;
  font-weight: 500;
  letter-spacing: -1px;
  line-height: 1.3;
}

@media (min-width: 768px) {
  .promo-area--inner-about .promo-content__title {
    font-size: 35px;
  }
}

@media (min-width: 992px) {
  .promo-area--inner-about .promo-content__title {
    font-size: 48px;
  }
}

.promo-area--inner-about .promo-content .btn {
  width: 255px;
}

.promo-area--inner-about .shape {
  position: absolute;
  right: 0;
  top: 0;
  width: 8%;
}

/*
 Home9 - Promo Area
---------------------------------*/
.promo-area--l9 {
  padding-top: 35px;
  padding-bottom: 60px;
}

@media (min-width: 768px) {
  .promo-area--l9 {
    padding-top: 55px;
    padding-bottom: 75px;
  }
}

@media (min-width: 992px) {
  .promo-area--l9 {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.promo-area--l9 .promo {
  position: relative;
  min-height: 306px;
  border-radius: 15px;
  padding: 30px 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 5px;
  margin-top: 25px;
}

@media (min-width: 768px) {
  .promo-area--l9 .promo {
    padding: 30px 70px;
  }
}

@media (min-width: 992px) {
  .promo-area--l9 .promo {
    top: -130px;
  }
}

.promo-area--l9 .promo-wrapper {
  width: 100%;
}

.promo-area--l9 .promo--box-1 {
  box-shadow: 0 4px 40px rgba(80, 52, 252, 0.3);
}

.promo-area--l9 .promo--box-2 {
  box-shadow: 0 4px 40px rgba(0, 0, 0, 0.2);
}

.promo-area--l9 .section-title__sub-heading {
  padding-left: 0 !important;
  color: var(--color-texts-3);
  margin-bottom: 0px;
}

@media (min-width: 992px) {
  .promo-area--l9 .section-title__sub-heading {
    margin-bottom: 17px;
  }
}

.promo-area--l9 .section-title__sub-heading::before {
  display: none;
}

.promo-area--l9 .section-title__heading {
  font-size: 36px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 54px;
  margin-bottom: 10px;
}

@media (min-width: 992px) {
  .promo-area--l9 .section-title__heading {
    margin-bottom: 25px;
  }
}

/*
  Services Page - Promo Area
---------------------------------*/
.promo-area--inner-service {
  padding-top: 50px;
  padding-bottom: 60px;
  position: relative;
}

@media (min-width: 768px) {
  .promo-area--inner-service {
    padding-top: 70px;
    padding-bottom: 80px;
  }
}

@media (min-width: 992px) {
  .promo-area--inner-service {
    padding-top: 100px;
    padding-bottom: 120px;
  }
}

.promo-area--inner-service .promo-content__title {
  font-size: 32px;
  font-weight: 500;
  letter-spacing: -1px;
  line-height: 1.3;
}

@media (min-width: 768px) {
  .promo-area--inner-service .promo-content__title {
    font-size: 35px;
  }
}

@media (min-width: 992px) {
  .promo-area--inner-service .promo-content__title {
    font-size: 48px;
  }
}

.promo-area--inner-service .promo-content .btn-2 {
  min-width: 215px;
}

.promo-area--inner-service .shape {
  position: absolute;
  right: 0;
  top: 0;
  width: 8%;
}

/*
  Home 2 : Cta section
---------------------------------*/
.cta-section--l2 {
  padding-top: 60px;
  padding-bottom: 80px;
}

@media (min-width: 768px) {
  .cta-section--l2 {
    padding-top: 80px;
  }
}

@media (min-width: 992px) {
  .cta-section--l2 {
    padding-top: 135px;
    padding-bottom: 130px;
  }
}

.cta-section--l2__button {
  z-index: 9;
}

.cta-section--l2__content {
  border-radius: 20px;
  padding: 40px 40px;
  overflow: hidden;
  box-shadow: 0 44px 34px rgba(37, 55, 63, 0.1);
  z-index: 0;
  position: relative;
}

@media (min-width: 768px) {
  .cta-section--l2__content {
    padding-top: 72px;
    padding-bottom: 80px;
    padding-left: 75px;
    padding-right: 95px;
  }
}

@media (min-width: 992px) {
  .cta-section--l2__content {
    padding-bottom: 75px;
  }
}

.cta-section--l2__content:before {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  background-position: right;
  width: 100%;
  height: 100%;
  background-size: cover;
  z-index: 0;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  display: none;
  z-index: -1;
}

@media (min-width: 768px) {
  .cta-section--l2__content:before {
    display: block;
    right: -25%;
    width: 110%;
  }
}

@media (min-width: 992px) {
  .cta-section--l2__content:before {
    right: -40%;
  }
}

@media (min-width: 1200px) {
  .cta-section--l2__content:before {
    width: 50%;
    right: 0;
  }
}

.cta-section--l2__content__heading {
  font-weight: 500;
  letter-spacing: -2px;
  line-height: 1.2083;
  font-size: 38px;
  margin-bottom: 40px;
  text-align: center;
}

@media (min-width: 480px) {
  .cta-section--l2__content__heading {
    padding: 0 30px;
  }
}

@media (min-width: 768px) {
  .cta-section--l2__content__heading {
    padding: 0;
  }
}

@media (min-width: 992px) {
  .cta-section--l2__content__heading {
    font-size: 48px;
    margin-bottom: 0;
    text-align: left;
  }
}

@media (min-width: 1200px) {
  .cta-section--l2__content__heading {
    padding-right: 40px;
  }
}

.cta-area--l6,
.cta-area--l8 {
  padding-top: 60px;
  padding-bottom: 60px;
}

@media (min-width: 768px) {
  .cta-area--l6,
  .cta-area--l8 {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

@media (min-width: 992px) {
  .cta-area--l6,
  .cta-area--l8 {
    padding-top: 110px;
    padding-bottom: 110px;
  }
}

.cta-area--l6 .section-title__description,
.cta-area--l8 .section-title__description {
  font-size: 18px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 32px;
  color: var(--color-texts-3);
}

.cta-area--l6 .button-group,
.cta-area--l8 .button-group {
  margin-top: 38px;
}

.cta-area--l6 .button-group .btn,
.cta-area--l8 .button-group .btn {
  margin: 0 8px;
  margin-bottom: 10px;
  font-size: 14px;
  min-width: 170px;
  height: 56px;
}

.cta-area--l8 {
  padding-top: 60px;
  padding-bottom: 30px;
}

@media (min-width: 768px) {
  .cta-area--l8 {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

@media (min-width: 992px) {
  .cta-area--l8 {
    padding-top: 110px;
    padding-bottom: 110px;
  }
}

.portfolio-area--l7,
.portfolio-area--inner-1,
.portfolio-area--inner-2,
.portfolio-area--related-post {
  padding-top: 55px;
  padding-bottom: 40px;
}

@media (min-width: 768px) {
  .portfolio-area--l7,
  .portfolio-area--inner-1,
  .portfolio-area--inner-2,
  .portfolio-area--related-post {
    padding-top: 75px;
    padding-bottom: 60px;
  }
}

@media (min-width: 992px) {
  .portfolio-area--l7,
  .portfolio-area--inner-1,
  .portfolio-area--inner-2,
  .portfolio-area--related-post {
    padding-top: 120px;
    margin-top: 0;
  }
}

.portfolio-area--l7 .section-title,
.portfolio-area--inner-1 .section-title,
.portfolio-area--inner-2 .section-title,
.portfolio-area--related-post .section-title {
  margin-bottom: 10px;
}

@media (min-width: 992px) {
  .portfolio-area--l7 .section-title,
  .portfolio-area--inner-1 .section-title,
  .portfolio-area--inner-2 .section-title,
  .portfolio-area--related-post .section-title {
    margin-bottom: 45px;
  }
}

.portfolio-area--l7 .section-title__sub-heading,
.portfolio-area--inner-1 .section-title__sub-heading,
.portfolio-area--inner-2 .section-title__sub-heading,
.portfolio-area--related-post .section-title__sub-heading {
  margin-bottom: 13px;
}

.portfolio-area--l7 a.portfolio__single,
.portfolio-area--inner-1 a.portfolio__single,
.portfolio-area--inner-2 a.portfolio__single,
.portfolio-area--related-post a.portfolio__single {
  position: relative;
  margin-bottom: 25px;
  border-radius: 15px;
  display: inline-block;
  margin-bottom: 25px;
}

.portfolio-area--l7 a.portfolio__single .portfolio__hover,
.portfolio-area--inner-1 a.portfolio__single .portfolio__hover,
.portfolio-area--inner-2 a.portfolio__single .portfolio__hover,
.portfolio-area--related-post a.portfolio__single .portfolio__hover {
  position: absolute;
  left: 50%;
  bottom: 18px;
  padding: 20px 15px;
  border-radius: 10px;
  width: 90%;
  transform: translateX(-50%);
  overflow: hidden;
  transition: all 0.7s ease-in-out;
  visibility: hidden;
  opacity: 0;
}

@media (min-width: 480px) {
  .portfolio-area--l7 a.portfolio__single .portfolio__hover,
  .portfolio-area--inner-1 a.portfolio__single .portfolio__hover,
  .portfolio-area--inner-2 a.portfolio__single .portfolio__hover,
  .portfolio-area--related-post a.portfolio__single .portfolio__hover {
    padding: 20px 35px;
  }
}

.portfolio-area--l7 a.portfolio__single .portfolio__hover__heading,
.portfolio-area--inner-1 a.portfolio__single .portfolio__hover__heading,
.portfolio-area--inner-2 a.portfolio__single .portfolio__hover__heading,
.portfolio-area--related-post a.portfolio__single .portfolio__hover__heading {
  font-size: 18px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 30px;
  margin-bottom: 0;
}

@media (min-width: 576px) {
  .portfolio-area--l7 a.portfolio__single .portfolio__hover__heading,
  .portfolio-area--inner-1 a.portfolio__single .portfolio__hover__heading,
  .portfolio-area--inner-2 a.portfolio__single .portfolio__hover__heading,
  .portfolio-area--related-post a.portfolio__single .portfolio__hover__heading {
    font-size: 21px;
  }
}

.portfolio-area--l7 a.portfolio__single .portfolio__hover__text,
.portfolio-area--inner-1 a.portfolio__single .portfolio__hover__text,
.portfolio-area--inner-2 a.portfolio__single .portfolio__hover__text,
.portfolio-area--related-post a.portfolio__single .portfolio__hover__text {
  font-size: 15px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 28px;
  margin-bottom: 0;
}

.portfolio-area--l7 a.portfolio__single:hover .portfolio__hover,
.portfolio-area--inner-1 a.portfolio__single:hover .portfolio__hover,
.portfolio-area--inner-2 a.portfolio__single:hover .portfolio__hover,
.portfolio-area--related-post a.portfolio__single:hover .portfolio__hover {
  visibility: visible;
  opacity: 1;
}

.portfolio-area--l7 .btn-link,
.portfolio-area--inner-1 .btn-link,
.portfolio-area--inner-2 .btn-link,
.portfolio-area--related-post .btn-link {
  font-weight: 500;
  font-size: 21px;
  line-height: 30px;
}

.portfolio-area--l7 .btn-link i,
.portfolio-area--inner-1 .btn-link i,
.portfolio-area--inner-2 .btn-link i,
.portfolio-area--related-post .btn-link i {
  margin: 0 10px;
  font-size: 18px;
}

.portfolio-menu button {
  padding-left: 20px;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 26px;
  color: var(--color-headings-2);
  transition: 0.4s;
  background-color: transparent;
  border: none;
}

.portfolio-menu button:hover {
  color: #fd346e;
}

/*
  Portfolio Area Inner Page 1
---------------------------------*/
.portfolio-area--inner-1 {
  position: relative;
}

@media (min-width: 992px) {
  .portfolio-area--inner-1 {
    padding-bottom: 120px;
  }
}

.portfolio-area--inner-1 .portfolio__single {
  margin-bottom: 25px;
}

.portfolio-area--inner-1 .shape {
  position: absolute;
  right: 0;
  top: 0;
  width: 8%;
}

.portfolio-details {
  padding-top: 45px;
}

@media (min-width: 768px) {
  .portfolio-details {
    padding-top: 65px;
  }
}

@media (min-width: 992px) {
  .portfolio-details {
    padding-top: 85px;
  }
}

.portfolio-content-box__heading {
  font-size: 32px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 54px;
  text-transform: uppercase;
  color: var(--color-headings-2);
  margin-bottom: 25px;
}

@media (min-width: 768px) {
  .portfolio-content-box__heading {
    font-size: 35px;
  }
}

@media (min-width: 992px) {
  .portfolio-content-box__heading {
    font-size: 45px;
  }
}

.portfolio-content-box__text {
  font-size: 18px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 32px;
  color: var(--color-texts-3);
  margin-bottom: 20px;
  margin-right: 10px;
}

.portfolio-content-box__list ul li {
  font-size: 18px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 25px;
  position: relative;
  padding-left: 30px;
  margin-bottom: 20px;
}

.portfolio-content-box__list ul li::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background-color: #ff5722;
}

.portfolio-content-box__list ul li span {
  color: var(--color-headings-2);
  margin-right: 15px;
}

/*
  Portfolio Area Inner Page 2
---------------------------------*/
.portfolio-area--inner-2 {
  padding-top: 15px;
  padding-bottom: 35px;
}

@media (min-width: 768px) {
  .portfolio-area--inner-2 {
    padding-top: 50px;
    padding-bottom: 70px;
  }
}

@media (min-width: 992px) {
  .portfolio-area--inner-2 {
    padding-top: 90px;
    padding-bottom: 130px;
  }
}

/*
  Portfolio Area Slider Details Page 2
---------------------------------*/
.portfolio-area--slider-details-2 {
  padding-top: 30px;
  padding-bottom: 80px;
}

@media (min-width: 768px) {
  .portfolio-area--slider-details-2 {
    padding-top: 50px;
    padding-bottom: 70px;
  }
}

@media (min-width: 992px) {
  .portfolio-area--slider-details-2 {
    padding-top: 90px;
    padding-bottom: 130px;
  }
}

/*
  Portfolio Area Inner Page 2
---------------------------------*/
.portfolio-details-area {
  padding-top: 60px;
}

@media (min-width: 768px) {
  .portfolio-details-area {
    padding-top: 80px;
  }
}

@media (min-width: 992px) {
  .portfolio-details-area {
    padding-top: 120px;
  }
}

/*
  Home 9 : Portfolio Area
---------------------------------*/
.portfolio-area--l9 {
  padding-top: 60px;
  padding-bottom: 60px;
}

@media (min-width: 768px) {
  .portfolio-area--l9 {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

@media (min-width: 992px) {
  .portfolio-area--l9 {
    padding-top: 115px;
    padding-bottom: 230px;
  }
}

.portfolio-area--l9 .section-title {
  margin-bottom: 35px !important;
}

@media (min-width: 992px) {
  .portfolio-area--l9 .section-title {
    margin-bottom: 70px !important;
  }
}

.portfolio-area--l9 .section-title__sub-heading {
  padding-left: 0 !important;
}

.portfolio-area--l9 .section-title__sub-heading::before {
  display: none;
}

.portfolio-img {
  border-radius: 15px;
  box-shadow: 0 4px 56px rgba(0, 0, 0, 0.1);
}

.portfolio-img__single {
  position: relative;
}

.portfolio-img__single img {
  border-radius: 15px;
}

.portfolio-img__single__shape {
  position: absolute;
  left: 0;
  bottom: 0;
}

.portfolio-img__single__shape .shape-content img {
  position: relative;
}

.portfolio-img__single__shape .shape-content h1 {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1;
  transform: translate(-50%, -50%);
  font-size: 36px;
  font-weight: 700;
  letter-spacing: normal;
  line-height: 54px;
  color: #fff;
}

.portfolio-content__sub-heading {
  color: var(--color-texts-3);
  margin-bottom: 28px;
  padding-left: 0 !important;
}

.portfolio-content__heading {
  font-size: 45px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 54px;
  text-transform: capitalize;
  color: var(--color-headings-2);
  margin-bottom: 13px;
}

.portfolio-content__heading-small {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 28px;
  color: var(--color-headings-2);
  margin-bottom: 25px;
  display: block;
}

.portfolio-content__text {
  font-size: 18px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 32px;
  margin-bottom: 45px;
}

@media (min-width: 992px) {
  .portfolio-details-slider .slick-list {
    width: 150%;
  }
}

.portfolio-details-slider__single {
  padding: 0 12px;
}

.portfolio-details-slider__single:focus {
  outline: none;
  box-shadow: none;
}

.portfolio-details-dots {
  width: 100%;
  height: 6px;
  bottom: -40px;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  position: relative;
}

.portfolio-details-dots li {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  list-style: none;
}

.portfolio-details-dots li button {
  padding: 0;
  width: 0;
  margin: 0;
  border: none;
  background-color: transparent;
  font-size: 0;
  transition: width 3s linear;
  transition-delay: 1.3s;
}

.portfolio-details-dots li.slick-active {
  display: block;
  width: 100%;
}

.portfolio-details-dots li.slick-active button {
  background: #5034fc !important;
  border-radius: 5px;
  height: 100%;
  width: 100%;
  transition: width 2s linear;
  transition-delay: 0.8s;
  opacity: 1;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
}

.portfolio-details--3 {
  padding-top: 45px;
  padding-bottom: 0px;
}

@media (min-width: 768px) {
  .portfolio-details--3 {
    padding-top: 80px;
    padding-bottom: 0px;
  }
}

@media (min-width: 992px) {
  .portfolio-details--3 {
    padding-top: 115px;
    padding-bottom: 0px;
  }
}

.portfolio-details--3 .portfolio-area--details-3 {
  padding-top: 60px !important;
}

@media (min-width: 768px) {
  .portfolio-details--3 .portfolio-area--details-3 {
    padding-top: 80px !important;
  }
}

@media (min-width: 992px) {
  .portfolio-details--3 .portfolio-area--details-3 {
    padding-top: 130px !important;
  }
}

@media (min-width: 1200px) {
  .portfolio-details--3 .portfolio-details {
    padding-right: 120px;
  }
}

.portfolio-details--3 .portfolio-details .portfolio-content-box__text {
  margin-bottom: 40px;
}

.about-us-section--l4 {
  padding-top: 60px;
  padding-bottom: 20px;
}

@media (min-width: 768px) {
  .about-us-section--l4 {
    padding-top: 80px;
    padding-bottom: 40px;
  }
}

@media (min-width: 992px) {
  .about-us-section--l4 {
    padding-top: 247px;
    padding-bottom: 129px;
  }
}

.about-us-section--l4 .section-title__sub-heading {
  margin-bottom: 15px;
}

.about-us-section--l4 .section-title__heading {
  margin-bottom: 25px !important;
}

.about-us-section--l4 .section-title__description {
  margin-bottom: 30px;
}

.about-us-section--l4 .about-us-counter--l4 {
  margin-top: 50px;
}

@media (min-width: 992px) {
  .about-us-section--l4 .about-us-counter--l4 {
    margin-right: 70px;
  }
}

.about-us__image-group-l4 {
  position: relative;
  width: 80%;
  margin-bottom: 125px;
}

@media (min-width: 1200px) {
  .about-us__image-group-l4 {
    width: 100%;
    margin-top: -135px;
  }
}

.about-us__image-group-l4__img-1 {
  position: absolute;
  right: -25%;
  bottom: -28%;
  width: 84%;
}

@media (min-width: 1200px) {
  .about-us-right {
    margin-top: -95px;
  }
}

.about-us-section--l5,
.video-section {
  padding-top: 60px;
  padding-bottom: 40px;
}

@media (min-width: 768px) {
  .about-us-section--l5,
  .video-section {
    padding-top: 80px;
    padding-bottom: 63px;
  }
}

@media (min-width: 992px) {
  .about-us-section--l5,
  .video-section {
    padding-top: 125px;
    padding-bottom: 130px;
  }
}

.about-us-section--l5 .section-title,
.video-section .section-title {
  margin-bottom: 42px;
}

.about-us-section--l5 .section-title__sub-heading,
.video-section .section-title__sub-heading {
  margin-bottom: 22px;
}

.about-us-section--l5 .section-title__heading,
.video-section .section-title__heading {
  margin-bottom: 15px !important;
}

.about-us-section--l5 .about-us-right,
.video-section .about-us-right {
  margin-top: 0;
}

@media (min-width: 992px) {
  .about-us-section--l5 .about-us-right,
  .video-section .about-us-right {
    margin-left: 10px;
  }
}

@media (min-width: 1200px) {
  .about-us-section--l5 .about-us-right,
  .video-section .about-us-right {
    margin-left: 70px;
  }
}

@media (min-width: 1600px) {
  .about-us-section--l5 .about-us-right,
  .video-section .about-us-right {
    margin-left: 0;
    margin-right: 50px;
  }
}

.about-us-section--l5 .about-us__image,
.video-section .about-us__image {
  position: relative;
  border-radius: 15px;
  margin-bottom: 10px;
}

@media (min-width: 992px) {
  .about-us-section--l5 .about-us__image,
  .video-section .about-us__image {
    margin-bottom: 0;
  }
}

.about-us__image,
.video-box {
  position: relative;
}

.about-us__image .video-btn,
.video-box .video-btn {
  min-width: 96px;
  max-width: 96px;
  min-height: 96px;
  max-height: 96px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
}

/*
  Home 7
---------------------------------*/
.about-us-section--l7 {
  padding-top: 60px;
}

@media (min-width: 768px) {
  .about-us-section--l7 {
    padding-top: 80px;
    padding-bottom: 10px;
  }
}

@media (min-width: 992px) {
  .about-us-section--l7 {
    padding-top: 125px;
    padding-bottom: 130px;
  }
}

.about-us-section--l7 .section-title {
  margin-bottom: 40px;
}

@media (min-width: 992px) {
  .about-us-section--l7 .section-title {
    margin-bottom: 72px;
  }
}

.about-us-section--l7 .section-title__sub-heading {
  margin-bottom: 22px;
}

.about-us-section--l7 .section-title__heading {
  padding-bottom: 35px !important;
  margin-bottom: 40px !important;
}

.about-us-section--l7 .about-us-left {
  margin-top: 0;
  margin-bottom: 30px;
}

@media (min-width: 992px) {
  .about-us-section--l7 .about-us-left {
    margin-bottom: 0;
  }
}

@media (min-width: 1200px) {
  .about-us-section--l7 .about-us-left {
    margin-right: 50px;
  }
}

.about-us-area--l9 {
  padding-top: 60px;
}

@media (min-width: 768px) {
  .about-us-area--l9 {
    padding-top: 80px;
  }
}

@media (min-width: 992px) {
  .about-us-area--l9 {
    padding-top: 130px;
  }
}

.about-us-area--l9 .about-us-img {
  z-index: 1;
  border-radius: 5px;
  position: relative;
}

@media (min-width: 992px) {
  .about-us-area--l9 .about-us-img {
    width: 107%;
  }
}

.about-us-area--l9 .about-us-content-box {
  padding-left: 30px;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-right: 30px;
  z-index: 0;
}

@media (min-width: 576px) {
  .about-us-area--l9 .about-us-content-box {
    padding-left: 80px;
    padding-top: 80px;
    padding-bottom: 80px;
    padding-right: 80px;
  }
}

@media (min-width: 992px) {
  .about-us-area--l9 .about-us-content-box {
    padding-left: 50px;
    padding-top: 100px;
    padding-bottom: 100px;
    padding-right: 30px;
  }
}

@media (min-width: 1200px) {
  .about-us-area--l9 .about-us-content-box {
    padding-left: 110px;
    padding-top: 145px;
    padding-bottom: 145px;
    padding-right: 90px;
  }
}

.about-us-area--l9 .section-title__heading {
  color: #262729;
}

.about-us-area--l9 .section-title__description {
  color: rgba(38, 39, 41, 0.7);
}

@media (min-width: 768px) {
  .about-us-area--l9 .section-title__description {
    margin-right: 10px;
  }
}

@media (min-width: 992px) {
  .about-us-area--l9 .section-title__description {
    margin-right: 35px;
  }
}

/*
  Home 4 : Pricing Table
---------------------------------*/
.table-section--l4 {
  padding-top: 60px;
  padding-bottom: 40px;
}

@media (min-width: 768px) {
  .table-section--l4 {
    padding-top: 70px;
    padding-bottom: 55px;
  }
}

@media (min-width: 992px) {
  .table-section--l4 {
    padding-top: 120px;
    padding-bottom: 100px;
  }
}

.table-section--l4 .section-title {
  margin-bottom: 45px;
}

@media (min-width: 992px) {
  .table-section--l4 .section-title {
    margin-bottom: 75px;
  }
}

/*
  Home 5 : Pricing Table
---------------------------------*/
.table-section--l5,
.table-section--inner-2 {
  padding-top: 60px;
  padding-bottom: 40px;
}

@media (min-width: 768px) {
  .table-section--l5,
  .table-section--inner-2 {
    padding-top: 70px;
    padding-bottom: 55px;
  }
}

@media (min-width: 992px) {
  .table-section--l5,
  .table-section--inner-2 {
    padding-top: 120px;
    padding-bottom: 100px;
  }
}

.table-section--l5 .table-section-top,
.table-section--inner-2 .table-section-top {
  margin-bottom: 45px;
}

@media (min-width: 992px) {
  .table-section--l5 .table-section-top,
  .table-section--inner-2 .table-section-top {
    margin-bottom: 70px;
  }
}

.table-section--l5 .section-title,
.table-section--inner-2 .section-title {
  margin-bottom: 25px;
}

@media (min-width: 992px) {
  .table-section--l5 .section-title,
  .table-section--inner-2 .section-title {
    margin-bottom: 0px;
  }
}

.table-section--l5 .section-title__sub-heading,
.table-section--inner-2 .section-title__sub-heading {
  margin-bottom: 25px;
}

.table-section--l5 .section-title__heading,
.table-section--inner-2 .section-title__heading {
  font-size: 32px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 1.2;
}

@media (min-width: 768px) {
  .table-section--l5 .section-title__heading,
  .table-section--inner-2 .section-title__heading {
    font-size: 35px;
  }
}

@media (min-width: 992px) {
  .table-section--l5 .section-title__heading,
  .table-section--inner-2 .section-title__heading {
    font-size: 45px;
  }
}

/*
  Home 5 : Pricing Table
---------------------------------*/
.table-section--l6 {
  padding-top: 60px;
  padding-bottom: 40px;
}

@media (min-width: 768px) {
  .table-section--l6 {
    padding-top: 70px;
    padding-bottom: 55px;
  }
}

@media (min-width: 992px) {
  .table-section--l6 {
    padding-top: 120px;
    padding-bottom: 100px;
  }
}

.table-section--l6 .table-section-top {
  margin-bottom: 45px;
}

@media (min-width: 992px) {
  .table-section--l6 .table-section-top {
    margin-bottom: 75px;
  }
}

.table-section--l6 .section-title {
  margin-bottom: 45px;
}

@media (min-width: 992px) {
  .table-section--l6 .section-title {
    margin-bottom: 0;
  }
}

/*
  Home 8 : Pricing Table
---------------------------------*/
.table-section--l8 {
  padding-top: 60px;
  padding-bottom: 40px;
}

@media (min-width: 768px) {
  .table-section--l8 {
    padding-top: 70px;
    padding-bottom: 55px;
  }
}

@media (min-width: 992px) {
  .table-section--l8 {
    padding-top: 120px;
    padding-bottom: 100px;
  }
}

.table-section--l8 .table-section-top {
  margin-bottom: 45px;
}

@media (min-width: 992px) {
  .table-section--l8 .table-section-top {
    margin-bottom: 75px;
  }
}

.table-section--l8 .section-title {
  margin-bottom: 25px;
}

@media (min-width: 992px) {
  .table-section--l8 .section-title {
    margin-bottom: 0px;
  }
}

.table-section--l8 .section-title__sub-heading {
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  line-height: normal;
}

.table-section--l8 .pricing-btn .btn-toggle {
  background-color: #fff !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
}

.table-section--l8 .pricing-btn .btn-toggle:focus {
  border: none;
  outline: none;
  box-shadow: none;
  background-color: #fff !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
}

.table-section--l8 .pricing-btn .btn-toggle:checked {
  background-color: #ff7272 !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

/*
  Home 8 : Pricing Table
---------------------------------*/
@media (min-width: 992px) {
  .table-section--inner-2 {
    padding-top: 135px;
    padding-bottom: 115px;
  }
}

/*
  Home 1 : Footer Section
------------------------------*/
.footer {
  position: relative;
}

.footer__shape-1 {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.terms-area {
  padding-top: 20px;
  padding-bottom: 20px;
}

@media (min-width: 768px) {
  .terms-area {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

@media (min-width: 992px) {
  .terms-area {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

@media (min-width: 1200px) {
  .terms-area .terms-content {
    padding: 0 43px;
  }
}

@media (min-width: 1600px) {
  .terms-area .terms-content {
    padding: 0 53px;
  }
}

.terms-area .terms-content__heading {
  font-size: 21px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 1.42857;
  color: var(--color-headings-2);
  margin-bottom: 15px;
}

@media (min-width: 992px) {
  .terms-area .terms-content__heading {
    margin-bottom: 25px;
  }
}

.terms-area .terms-content__text {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 1.777777;
  color: var(--color-texts-3);
}

@media (min-width: 992px) {
  .terms-area .terms-content__text {
    font-size: 18px;
  }
}

.terms-area .terms-content .terms-content__box {
  padding-top: 10px;
  padding-bottom: 20px;
}

.terms-area .terms-content .terms-list li {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 1.777777;
  color: var(--color-texts-3);
}

@media (min-width: 992px) {
  .terms-area .terms-content .terms-list li {
    font-size: 18px;
  }
}

/*
  Pricing Table 
------------------------------*/
.pricing-table__faqs {
  padding-top: 60px;
  padding-bottom: 40px;
}

@media (min-width: 768px) {
  .pricing-table__faqs {
    padding-top: 75px;
    padding-bottom: 60px;
  }
}

@media (min-width: 992px) {
  .pricing-table__faqs {
    padding-top: 113px;
    padding-bottom: 100px;
  }
}

.pricing-table__faqs .section-title {
  margin-bottom: 58px;
}

.pricing-table__faqs .section-title__heading {
  font-size: 32px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 1.38095;
}

@media (min-width: 768px) {
  .pricing-table__faqs .section-title__heading {
    font-size: 35px;
  }
}

@media (min-width: 992px) {
  .pricing-table__faqs .section-title__heading {
    font-size: 42px;
  }
}

@media (min-width: 1600px) {
  .pricing-table__faqs .faq-body {
    margin: 0 65px;
  }
}

.faqs__single {
  margin-bottom: 40px;
}

@media (min-width: 992px) {
  .faqs__single {
    margin-bottom: 60px;
  }
}

@media (min-width: 1200px) {
  .faqs__single {
    margin-right: 50px;
  }
}

@media (min-width: 1600px) {
  .faqs__single {
    margin-right: 150px;
  }
}

.faqs__single .faqs__icon {
  min-width: 37px;
  max-width: 37px;
  min-height: 37px;
  max-height: 37px;
  background-color: #ff5722;
  color: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
}

.faqs__single .faqs__content__title {
  font-size: 21px;
  font-weight: 500;
  color: var(--color-headings-2);
  letter-spacing: normal;
  line-height: 30px;
}

.faqs__single .faqs__content__text {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 30px;
  color: #747474;
}

/*
  Fag Inner Page
------------------------------*/
.faqs-area--inner {
  padding-top: 50px;
  padding-bottom: 40px;
}

@media (min-width: 768px) {
  .faqs-area--inner {
    padding-top: 70px;
    padding-bottom: 60px;
  }
}

@media (min-width: 992px) {
  .faqs-area--inner {
    padding-top: 105px;
    padding-bottom: 120px;
  }
}

.faqs-area--inner .section-title {
  margin-bottom: 61px;
}

.faq-tabs {
  border: 1px solid rgba(181, 181, 181, 0.2);
  background-color: #fff;
  margin-right: 8px;
  padding-top: 20px;
  padding-bottom: 25px;
}

.faq-tabs .nav-link {
  color: rgba(38, 39, 41, 0.7);
  font-size: 18px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 25px;
  padding: 14px 25px;
}

.faq-tabs .nav-link.active {
  color: #0abfbc;
  background-color: transparent;
}

.tab-content .accordion-item {
  border: none;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  margin-bottom: 20px;
}

.tab-content .accordion-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tab-content .accordion-button {
  border: none;
  padding: 0;
  margin-left: 32px;
  margin-right: 35px;
  font-size: 21px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 30px;
  padding-top: 47px;
  padding-bottom: 24px;
  color: #262729;
  position: relative;
  transition: 0.4s;
}

.tab-content .accordion-button:focus, .tab-content .accordion-button:not(.collapsed) {
  outline: none;
  box-shadow: none;
  background-color: transparent;
}

.tab-content .accordion-button::after {
  content: "";
  color: transparent;
  background-image: none;
}

@media (min-width: 576px) {
  .tab-content .accordion-button::after {
    content: "\f068";
    font-family: "Font Awesome 5 Free";
    position: absolute;
    right: 0;
    font-size: 16px;
    top: 50%;
    transform: translateY(-50%);
    font-weight: 700;
    background-image: none;
    color: #0abfbc;
  }
}

.tab-content .accordion-button.collapsed {
  padding-top: 45px;
  padding-bottom: 45px;
}

.tab-content .accordion-button.collapsed::after {
  content: "\f067";
  font-family: "Font Awesome 5 Free";
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  font-weight: 700;
  background-image: none;
}

.tab-content .accordion-collapse {
  border: none;
}

.tab-content .accordion-body {
  padding-bottom: 43px;
  padding-top: 0;
  border: none;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 32px;
  margin-left: 40px;
  color: rgba(38, 39, 41, 0.7);
  position: relative;
}

@media (min-width: 768px) {
  .tab-content .accordion-body {
    margin-right: 70px;
    font-size: 18px;
  }
}

.tab-content .accordion-body:before {
  content: "";
  position: absolute;
  left: -6px;
  top: 0;
  width: 4px;
  height: 93px;
  background-color: #0abfbc;
}

.buttons p,
.buttons a {
  font-size: 18px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 32px;
}

.buttons p {
  color: var(--color-headings-2);
}

.search-section {
  background: linear-gradient(261.26deg, rgba(253, 52, 110, 0.96) 14.9%, #b81c5c 43.58%, #0abfbc 89.94%);
  padding-top: 52px;
  padding-bottom: 40px;
}

@media (min-width: 768px) {
  .search-section {
    padding-top: 70px;
    padding-bottom: 55px;
  }
}

@media (min-width: 992px) {
  .search-section {
    padding-top: 95px;
    padding-bottom: 90px;
  }
}

@media (min-width: 992px) {
  .search-section .search-form {
    margin: 0 30px;
  }
}

.search-content__heading {
  font-size: 32px;
  font-weight: 500;
  letter-spacing: -0.5px;
  line-height: 1.20689;
  margin-bottom: 35px;
}

@media (min-width: 576px) {
  .search-content__heading {
    font-size: 38px;
  }
}

@media (min-width: 768px) {
  .search-content__heading {
    font-size: 48px;
  }
}

@media (min-width: 992px) {
  .search-content__heading {
    font-size: 58px;
  }
}

.search-content__text {
  font-size: 18px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 32px;
  margin-top: 20px;
}

.form-block .form-img,
.coming-soon .form-img {
  left: 0;
  top: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 40%;
  /* min-height: 100vh; */
}

@media (min-width: 1200px) {
  .form-block .form-img,
  .coming-soon .form-img {
    width: 45%;
  }
}

.form-block .section-title__heading,
.coming-soon .section-title__heading {
  margin-bottom: 18px;
}

@media (min-width: 992px) {
  .form-block .form-box,
  .coming-soon .form-box {
    margin-right: 25px;
  }
}

@media (min-width: 1200px) {
  .form-block .form-box,
  .coming-soon .form-box {
    margin-right: 40px;
  }
}

.form-block .form-box form input,
.coming-soon .form-box form input {
  height: 56px !important;
  margin-bottom: 24px;
}

.form-block .form-box form .form-check,
.coming-soon .form-box form .form-check {
  margin-top: 40px;
}

.form-block .form-box form .form-check-label,
.coming-soon .form-box form .form-check-label {
  font-size: 18px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 32px;
  color: var(--color-headings-2);
  margin-left: 11px;
}

.form-block .form-box .buttons p,
.form-block .form-box .buttons a,
.coming-soon .form-box .buttons p,
.coming-soon .form-box .buttons a {
  font-size: 18px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 32px;
  color: var(--color-headings-2);
  margin-left: 11px;
}

.form-block .form-box--reset,
.coming-soon .form-box--reset {
  text-align: center;
  margin-top: 30px;
}

@media (min-width: 992px) {
  .form-block .form-box--reset,
  .coming-soon .form-box--reset {
    text-align: left;
    margin-top: 0;
    margin-right: 123px;
  }
}

.blog-details {
  padding-top: 50px;
  padding-bottom: 40px;
}

@media (min-width: 768px) {
  .blog-details {
    padding-top: 70px;
    padding-bottom: 60px;
  }
}

@media (min-width: 992px) {
  .blog-details {
    padding-top: 125px;
    padding-bottom: 110px;
  }
}

.blog-title {
  padding-bottom: 30px;
}

@media (min-width: 992px) {
  .blog-title {
    padding-bottom: 55px;
  }
}

.blog-title__heading {
  font-size: 25px;
  font-weight: 500;
  letter-spacing: -1px;
  line-height: 1.3;
  margin-bottom: 20px;
}

@media (min-width: 576px) {
  .blog-title__heading {
    font-size: 32px;
  }
}

@media (min-width: 768px) {
  .blog-title__heading {
    font-size: 35px;
  }
}

@media (min-width: 992px) {
  .blog-title__heading {
    font-size: 48px;
  }
}

.blog__metainfo {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.blog__metainfo__author-name {
  font-size: 13px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 32px;
  color: var(--color-texts-3);
  padding-right: 15px;
  padding-left: 55px;
  position: relative;
}

@media (min-width: 768px) {
  .blog__metainfo__author-name {
    padding-left: 70px;
    padding-right: 25px;
    font-size: 18px;
  }
}

.blog__metainfo__author-name::before {
  content: "";
  position: absolute;
  width: 41px;
  height: 1px;
  left: 0;
  top: 50%;
  background-color: #ff5722;
}

.blog__metainfo__date {
  font-size: 13px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 32px;
  color: var(--color-texts-3);
  position: relative;
  padding-left: 30px;
}

@media (min-width: 768px) {
  .blog__metainfo__date {
    padding-right: 25px;
    font-size: 18px;
  }
}

.blog__metainfo__date::before {
  content: "";
  position: absolute;
  width: 1px;
  height: 21px;
  left: 0;
  top: 0;
  background-color: rgba(38, 39, 41, 0.7);
}

.blog-content__box {
  padding-top: 5px;
}

.blog-content__img {
  margin-bottom: 35px;
}

@media (min-width: 992px) {
  .blog-content__img {
    margin-bottom: 55px;
  }
}

.blog-content__heading {
  font-size: 21px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 30px;
  margin-bottom: 17px;
}

.blog-content__text {
  font-size: 18px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 32px;
  margin-bottom: 30px;
}

.blog-content__list {
  padding: 0;
  margin: 0;
  margin-left: 20px;
}

.blog-content__list li {
  font-size: 18px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: 32px;
  margin-bottom: 8px;
}

.qoute__content {
  font-size: 21px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 34px;
  margin-left: 45px;
  position: relative;
  color: var(--color-headings-2);
  margin-bottom: 35px;
}

.qoute__content::before {
  content: "\f10d";
  font-family: "Font Awesome 5 Free";
  position: absolute;
  font-weight: 700;
  font-size: 25px;
  left: -45px;
  top: 0;
  color: #ff5722;
}

@media (min-width: 992px) {
  .post-tags-section {
    padding-top: 20px;
  }
}

.post-tags-section__title {
  font-size: 21px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 30px;
  margin-bottom: 0;
  margin-right: 25px;
  margin-top: 10px;
}

.post-tags-section .post-tags li {
  display: inline-flex;
  align-items: center;
}

.post-tags-section .post-tags li a {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 75px;
  min-height: 50px;
  border-radius: 5px;
  border: 1px solid rgba(22, 22, 22, 0.12);
  background-color: #fff;
  color: rgba(38, 39, 41, 0.7);
  padding: 0 23px;
  margin-right: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
  flex-wrap: wrap;
  transition: 0.4s;
}

.post-tags-section .post-tags li a:hover {
  background-color: #5034fc;
  color: #fff;
}

.post-social-share {
  padding-bottom: 20px;
}

@media (min-width: 992px) {
  .post-social-share {
    padding-bottom: 0;
    padding-top: 10px;
  }
}

.post-social-share__title {
  font-size: 21px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 30px;
  margin-bottom: 0;
  margin-right: 25px;
}

.post-social-share .social-share {
  margin-top: 8px;
}

.post-social-share .social-share li {
  display: inline-block;
}

.post-social-share .social-share li a {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: var(--color-texts-3);
  padding: 0 15px;
  flex-wrap: wrap;
  transition: 0.4s;
  font-size: 21px;
}

.post-social-share .social-share li a:hover {
  color: #5034fc;
}

@media (min-width: 992px) {
  .next-prev-btn {
    padding-top: 85px;
  }
}

.next-prev-btn .btn-link {
  font-size: 21px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 30px;
  display: flex;
  align-items: center;
  color: var(--color-headings-2);
  transition: 0.4s;
}

.next-prev-btn .btn-link:hover {
  color: #5034fc;
}

.next-prev-btn .btn-link--prev i {
  margin-right: 10px;
}

.next-prev-btn .btn-link--next i {
  margin-left: 10px;
}

.blogs-area--right-sidebar,
.blogs-area--left-sidebar,
.blog-area--reguler {
  padding-top: 60px;
  padding-bottom: 60px;
}

@media (min-width: 768px) {
  .blogs-area--right-sidebar,
  .blogs-area--left-sidebar,
  .blog-area--reguler {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

@media (min-width: 992px) {
  .blogs-area--right-sidebar,
  .blogs-area--left-sidebar,
  .blog-area--reguler {
    padding-top: 110px;
    padding-bottom: 120px;
  }
}

.blogs-area--right-sidebar .section-title,
.blogs-area--left-sidebar .section-title,
.blog-area--reguler .section-title {
  padding-bottom: 30px;
}

@media (min-width: 992px) {
  .blogs-area--right-sidebar .section-title,
  .blogs-area--left-sidebar .section-title,
  .blog-area--reguler .section-title {
    padding-bottom: 50px;
  }
}

.blogs-post {
  position: relative;
  margin-bottom: 24px;
  border-radius: 10px;
  overflow: hidden;
}

.blogs-post--big {
  border-radius: 10px;
  min-height: 190px;
}

.blogs-post--small {
  width: 100%;
}

.blogs-post .hover-content {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  border-radius: 10px;
  background-image: linear-gradient(360deg, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0.67) 45%, rgba(0, 0, 0, 0) 100%);
  padding-left: 20px;
  padding-bottom: 20px;
}

@media (min-width: 576px) {
  .blogs-post .hover-content {
    padding-left: 30px;
  }
}

@media (min-width: 992px) {
  .blogs-post .hover-content {
    padding-left: 40px;
  }
}

.blogs-post .hover-content__badge {
  font-size: 11px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 20px;
  min-width: 78px;
  height: 26px;
  border-radius: 15px;
  margin-right: 12px;
}

.blogs-post .hover-content__badge:hover {
  color: #fff;
}

.blogs-post .hover-content__date {
  margin-bottom: 0;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 22px;
  color: #fff;
  transition: 0.4s;
}

.blogs-post .hover-content__date:hover {
  color: #ff5722;
}

.blogs-post .hover-content__title {
  font-size: 18px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 25px;
  color: #fff;
  margin-bottom: 19px;
  display: block;
  transition: 0.4s;
}

.blogs-post .hover-content__title:hover {
  color: #ff5722;
}

.blogs-post .hover-content__post-meta li a {
  color: #fff;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 26px;
  padding-right: 30px;
  position: relative;
  transition: 0.4s;
}

.blogs-post .hover-content__post-meta li a:hover {
  color: #ff5722;
}

.blogs-post .hover-content__post-meta li a:before {
  content: "";
  position: absolute;
  width: 1px;
  height: 14px;
  background-color: #fff;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
}

.blogs-post .hover-content__post-meta li a:last-child::before {
  content: "";
  position: absolute;
  width: 1px;
  height: 14px;
  background-color: transparent;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
}

.blogs-post .hover-content__post-meta li a i {
  font-weight: 500;
  margin-right: 10px;
  color: #ff5722;
}

.blogs-post .hover-content__top {
  margin-bottom: 20px;
}

.pagination ul li {
  display: inline-flex;
  align-items: center;
}

.pagination ul li a {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  border: 1px solid rgba(38, 39, 41, 0.1);
  background-color: #fff;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 25px;
  color: #262729;
  transition: 0.4s;
  margin: 0 5px;
}

.pagination ul li a:hover {
  background-color: #5034fc;
  color: #fff;
}

/*
  Home 9
---------------------------------*/
.blog-area--l9 {
  padding-top: 60px;
  padding-bottom: 25px;
}

@media (min-width: 768px) {
  .blog-area--l9 {
    padding-top: 60px;
    padding-bottom: 45px;
  }
}

@media (min-width: 992px) {
  .blog-area--l9 {
    padding-top: 115px;
    padding-bottom: 225px;
  }
}

.blog-area--l9 .section-title {
  margin-bottom: 30px;
}

@media (min-width: 992px) {
  .blog-area--l9 .section-title {
    margin-bottom: 63px;
  }
}

.blog-area--l9 .section-title__sub-heading {
  padding-left: 0 !important;
}

.blog-area--l9 .section-title__sub-heading::before {
  display: none;
}

.blog-area--l9 .blogs-post .hover-content {
  transition: 0.4s;
  padding-top: 23px;
  padding-bottom: 10px;
  padding-left: 25px;
  padding-right: 30px;
}

.blog-area--l9 .blogs-post .hover-content__badge:hover {
  color: #fff;
}

.blog-area--l9 .blogs-post .hover-content__post-meta li a {
  font-size: 13px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 22px;
}

.comments-area {
  padding-top: 60px;
}

@media (min-width: 768px) {
  .comments-area {
    padding-top: 80px;
  }
}

@media (min-width: 992px) {
  .comments-area {
    padding-top: 120px;
    padding-bottom: 60px;
  }
}

.comment-meta-box {
  margin-bottom: 33px;
}

.comment-meta-box__author-img {
  margin-right: 40px;
  width: 90px;
  height: 90px;
  border-radius: 5px;
}

.comment-meta-box__name {
  font-size: 21px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 30px;
  color: var(--color-headings-2);
  margin-bottom: 0;
}

.comment-meta-box__text {
  font-size: 18px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 32px;
  color: var(--color-texts-3);
}

.comment-meta-box__date, .comment-meta-box__time {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 30px;
  color: var(--color-texts-3);
}

.comment-meta-box__reply-btn {
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 28px;
}

.comment-meta-box__reply-btn i {
  margin-right: 5px;
}

.sub-comment-meta-box {
  margin-left: 125px;
}

.comments-form {
  margin-bottom: 60px;
}

.comments-form .section-title__heading {
  font-size: 36px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 54px;
}

.comments-form .form-check {
  margin-top: 40px;
  margin-bottom: 45px;
}

.comments-form .form-check .form-check-label {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 30px;
  color: var(--color-headings-2);
}

.coming-soon {
  padding-top: 60px;
  padding-bottom: 60px;
}

@media (min-width: 768px) {
  .coming-soon {
    padding-top: 0px;
    padding-bottom: 0px;
  }
}

@media (min-width: 992px) {
  .coming-soon .section-title {
    margin-bottom: 90px !important;
  }
}

.coming-soon .section-title__heading {
  margin-bottom: 33px;
}

.coming-soon .section-title__description {
  font-size: 18px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 32px;
}

@media (min-width: 992px) {
  .coming-soon .countdown-bar {
    margin-bottom: 80px;
  }
}

@media (min-width: 992px) {
  .countdown-bar ul {
    margin-right: -30px;
    margin-left: 30px;
  }
}

.countdown-bar ul li {
  text-align: center;
  width: 50%;
  flex: 0 0 50%;
  margin-bottom: 20px;
}

@media (min-width: 576px) {
  .countdown-bar ul li {
    width: 25%;
    flex: 0 0 25%;
    margin-bottom: 0;
  }
}

.countdown-bar ul li h2 {
  font-size: 45px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 54px;
  color: #ff5722;
  position: relative;
}

.countdown-bar ul li h2:before {
  content: ":";
  font-size: 45px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 54px;
  color: #ff5722;
  right: 0;
  top: 0;
  position: absolute;
}

.countdown-bar ul li p {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 28px;
  color: var(--color-texts-3);
  margin-bottom: 0;
}

.countdown-bar ul li:nth-child(2) h2:before {
  content: ":";
  font-size: 45px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 54px;
  color: transparent;
  right: 0;
  top: 0;
  position: absolute;
}

@media (min-width: 576px) {
  .countdown-bar ul li:nth-child(2) h2:before {
    content: ":";
    font-size: 45px;
    font-weight: 500;
    letter-spacing: normal;
    line-height: 54px;
    color: #ff5722;
    right: 0;
    top: 0;
    position: absolute;
  }
}

.countdown-bar ul li:last-child h2:before {
  content: ":";
  font-size: 45px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 54px;
  color: transparent;
  right: 0;
  top: 0;
  position: absolute;
  display: none;
}

.counterup__newsletter {
  margin-top: 10px;
}

@media (min-width: 992px) {
  .counterup__newsletter {
    margin-left: 100px;
  }
}

@media (min-width: 1200px) {
  .counterup__newsletter {
    margin-left: 157px;
  }
}

.counterup__newsletter__offer {
  font-size: 18px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 32px;
}

.error-page {
  padding-top: 60px;
  padding-bottom: 50px;
}

@media (min-width: 768px) {
  .error-page {
    padding-top: 80px;
    padding-bottom: 70px;
  }
}

@media (min-width: 992px) {
  .error-page {
    padding-top: 100px;
    padding-bottom: 110px;
  }
}

.error-page__image {
  padding: 0 30px;
}

.error-page__code {
  font-size: 50px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: -2px;
  line-height: 1.27272;
  color: #fd346e;
  margin-bottom: 0;
}

@media (min-width: 576px) {
  .error-page__code {
    font-size: 60px;
  }
}

@media (min-width: 768px) {
  .error-page__code {
    font-size: 80px;
  }
}

@media (min-width: 992px) {
  .error-page__code {
    font-size: 110px;
  }
}

.error-page__heading {
  font-size: 32px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 1.2;
  margin-bottom: 15px;
}

@media (min-width: 768px) {
  .error-page__heading {
    font-size: 35px;
  }
}

@media (min-width: 992px) {
  .error-page__heading {
    font-size: 45px;
  }
}

.error-page__text {
  font-size: 18px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: 1.777777;
  margin-bottom: 33px;
}

.video-section .section-title {
  margin-right: 30px;
}

.video-section .section-title__heading {
  margin-bottom: 25px !important;
}

.video-section .section-title__description {
  margin-bottom: 25px;
}

@media (min-width: 1200px) {
  .video-box {
    margin-left: 110px;
  }
}

.career-details {
  padding-top: 55px;
  padding-bottom: 60px;
}

@media (min-width: 768px) {
  .career-details {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

@media (min-width: 992px) {
  .career-details {
    padding-top: 120px;
    padding-bottom: 120px;
  }
}

@media (min-width: 992px) {
  .career-deatils__content {
    margin-right: 65px;
  }
}

.career-deatils__content .career-details-box {
  padding-top: 10px;
}

.career-deatils__content .career-details-box .btn {
  width: 243px;
}

@media (min-width: 992px) {
  .career-deatils__content .career-details-box {
    padding-top: 45px;
  }
}

.career-deatils__content__heading {
  font-size: 24px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 40px;
  color: var(--color-headings-2);
  margin-bottom: 20px;
}

.career-deatils__content__text {
  font-size: 18px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 32px;
  color: var(--color-texts-3);
}

.career-deatils__content__list {
  padding: 0;
  margin: 0;
}

.career-deatils__content__list li {
  font-size: 18px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 32px;
  list-style: none;
  padding-left: 25px;
  position: relative;
  margin-bottom: 15px;
}

.career-deatils__content__list li:before {
  content: "";
  position: absolute;
  left: 0;
  top: 12px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  display: inline-block;
  background-color: #5034fc;
}

.pormo--inner-career {
  box-shadow: 0 44px 74px rgba(37, 55, 63, 0.1);
  background-color: #e4f2f0;
  padding-top: 65px;
  padding-bottom: 65px;
}

.pormo--inner-career .newsletter--inner-career {
  margin: 0 13px;
}

.pormo--inner-career .promo-content .section-title__heading {
  font-size: 28px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 1.5;
  margin-bottom: 35px;
  color: #262729;
}

@media (min-width: 768px) {
  .pormo--inner-career .promo-content .section-title__heading {
    font-size: 30px;
  }
}

@media (min-width: 992px) {
  .pormo--inner-career .promo-content .section-title__heading {
    font-size: 36px;
  }
}

.pormo--inner-career .promo-content .section-title__description {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 1.777777;
  margin-top: 15px;
  color: rgba(38, 39, 41, 0.7);
}

@media (min-width: 992px) {
  .pormo--inner-career .promo-content .section-title__description {
    font-size: 18px;
  }
}

.video-section--career {
  padding-top: 60px;
  padding-bottom: 10px;
}

@media (min-width: 768px) {
  .video-section--career {
    padding-top: 80px;
    padding-bottom: 30px;
  }
}

@media (min-width: 992px) {
  .video-section--career {
    padding-top: 120px;
    padding-bottom: 120px;
  }
}

.service-area--inner {
  padding-top: 55px;
  padding-bottom: 55px;
}

@media (min-width: 768px) {
  .service-area--inner {
    padding-top: 75px;
    padding-bottom: 80px;
  }
}

@media (min-width: 992px) {
  .service-area--inner {
    padding-top: 120px;
    padding-bottom: 125px;
  }
}

.jobs-area {
  padding-top: 55px;
  padding-bottom: 60px;
}

@media (min-width: 768px) {
  .jobs-area {
    padding-top: 75px;
    padding-bottom: 80px;
  }
}

@media (min-width: 992px) {
  .jobs-area {
    padding-top: 110px;
    padding-bottom: 95px;
  }
}

.jobs-filter__title {
  font-size: 21px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 1.4285;
  margin-bottom: 30px;
}

.jobs-filter__menu {
  padding: 0;
  margin: 0;
  border-right: 1px solid rgba(181, 181, 181, 0.2);
  display: block;
}

.jobs-filter__menu button {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 28px;
  color: var(--color-headings-2);
  display: block;
  transition: 0.4s;
  position: relative;
  padding-left: 20px;
  margin-bottom: 17px;
  background-color: transparent;
  border: none;
}

.jobs-filter__menu button::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 7px;
  height: 7px;
  background-color: #262729;
  border-radius: 50%;
  transition: 0.4s;
}

.jobs-filter__menu button:hover {
  color: #5034fc;
}

.jobs-filter__menu button:hover::before {
  background-color: #5034fc;
}

.jobs-area__tab .card {
  display: inherit;
}

.jobs-area__tab .card--single {
  border-radius: 10px;
  border: 1px solid rgba(181, 181, 181, 0.2);
  border-radius: 10px;
  background-color: #fff;
  margin-bottom: 25px;
  padding-top: 35px;
  padding-bottom: 40px;
  padding-left: 50px;
  padding-right: 35px;
}

.jobs-area__tab .card--single:hover {
  box-shadow: 0 4px 56px rgba(0, 0, 0, 0.07);
  border: 1px solid rgba(181, 181, 181, 0.2);
}

.jobs-area__tab .card--single .btn {
  min-width: 127px;
  height: 50px;
  display: inline-flex;
  margin-top: 7px;
}

.jobs-area__tab .card__heading {
  font-size: 28px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 1.57142;
  margin-bottom: 25px;
  color: #262729;
}

.jobs-area__tab .card__list li {
  font-size: 18px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 1.777777;
  margin-bottom: 23px;
  color: rgba(38, 39, 41, 0.7);
}

.jobs-area__tab .card__list li i {
  margin-right: 10px;
}

/* ---------------------------------
-------> THEME CUSTOM STYLES 
---------------------------------
*/
/* Underline animation 
-----------------------*/
mark {
  color: inherit;
  padding: 0;
  background: 0 0;
  background-image: #ff5722;
  background-repeat: no-repeat;
  background-size: 0 0.3em;
  background-position: 0 102%;
  position: relative;
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

mark[data-aos="highlight-text"].aos-animate {
  background-size: 100% 6px;
}

.border-bottom-dotted-3 {
  border-bottom: 3px dotted rgba(37, 55, 63, 0.15);
}

/* ====== Home Page 2 ===== */
.circle-126 {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  min-width: 106px;
  max-width: 106px;
  min-height: 106px;
  max-height: 106px;
}

@media (min-width: 768px) {
  .circle-126 {
    min-width: 126px;
    max-width: 126px;
    min-height: 126px;
    max-height: 126px;
  }
}

.circle-91 {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  min-width: 91px;
  max-width: 91px;
  min-height: 91px;
  max-height: 91px;
}

.circle-103 {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  min-width: 90px;
  max-width: 90px;
  min-height: 90px;
  max-height: 90px;
}

@media (min-width: 768px) {
  .circle-103 {
    min-width: 103px;
    max-width: 103px;
    min-height: 103px;
    max-height: 103px;
  }
}

.circle-98 {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  min-width: 98px;
  max-width: 98px;
  min-height: 98px;
  max-height: 98px;
}

.circle-99 {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  min-width: 99px;
  max-width: 99px;
  min-height: 99px;
  max-height: 99px;
}

@media (min-width: 768px) {
  .circle-99 {
    min-width: 99px;
    max-width: 99px;
    min-height: 99px;
    max-height: 99px;
  }
}

.circle-95 {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  min-width: 85px;
  max-width: 85px;
  min-height: 85px;
  max-height: 85px;
}

@media (min-width: 768px) {
  .circle-95 {
    min-width: 95px;
    max-width: 95px;
    min-height: 95px;
    max-height: 95px;
  }
}

.circle-113 {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  min-width: 95px;
  max-width: 95px;
  min-height: 95px;
  max-height: 95px;
}

@media (min-width: 768px) {
  .circle-113 {
    min-width: 113px;
    max-width: 113px;
    min-height: 113px;
    max-height: 113px;
  }
}

.circle-88 {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  min-width: 78px;
  max-width: 78px;
  min-height: 78px;
  max-height: 78px;
}

@media (min-width: 768px) {
  .circle-88 {
    min-width: 88px;
    max-width: 88px;
    min-height: 88px;
    max-height: 88px;
  }
}

.circle-108 {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  min-width: 98px;
  max-width: 98px;
  min-height: 98px;
  max-height: 98px;
}

@media (min-width: 768px) {
  .circle-108 {
    min-width: 108px;
    max-width: 108px;
    min-height: 108px;
    max-height: 108px;
  }
}

/* Animations Sonar */
.sonar-emitter {
  position: relative;
}

.sonar-emitter::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  content: "";
  background-color: #ff5722;
  z-index: -1;
  pointer-events: none;
  opacity: 0;
}

.sonar-emitter::after {
  -webkit-animation: sonarWave 2s linear infinite;
          animation: sonarWave 2s linear infinite;
}

.sonar-emitter-2 {
  position: relative;
}

.sonar-emitter-2::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  content: "";
  background-color: #fff;
  z-index: -1;
  pointer-events: none;
  opacity: 0;
}

.sonar-emitter-2::after {
  -webkit-animation: sonarWave 2s linear infinite;
          animation: sonarWave 2s linear infinite;
}

.menu-social-share {
  padding: 0;
  margin: 0;
}

.menu-social-share li {
  display: inline-flex;
  align-items: center;
}

.menu-social-share li a {
  display: inline-flex;
  padding: 0 10px;
  color: var(--color-headings-2);
  transition: 0.4s;
}

@media (min-width: 1600px) {
  .menu-social-share li a {
    padding: 0 20px;
  }
}

.menu-social-share li a:hover {
  color: #5034fc;
}

.menu-sep-left {
  position: relative;
}

@media (min-width: 992px) {
  .menu-sep-left {
    margin-left: 20px;
  }
  .menu-sep-left::before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 2px;
    height: 26px;
    border-left: 2px solid var(--border-color-5);
  }
}

.menu-sep-right {
  position: relative;
}

@media (min-width: 992px) {
  .menu-sep-right {
    margin-right: 20px;
  }
  .menu-sep-right::before {
    content: "";
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 2px;
    height: 26px;
    border-right: 2px solid var(--border-color-6);
  }
}

.reveal-header .menu-sep-left::before {
  border-left: 2px solid rgba(255, 255, 255, 0.3);
}

/*# sourceMappingURL=maps/main.css.map */


.brand-logo img{max-width:200px;height:200px;}