
.table_search{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .table_search .input_icons{
    position: relative;
    width: 25%;
  }
  .table_search .input_icons img{
    left: 5px;
  }
  .table_search .input_icons input{
    background: #F3F5F6;
    padding: 14px;
    font-size: 16px;
    border: none;
    outline: none;
    font-weight: 400;
    border-radius: 8px;
    padding-left: 45px;
  }
  .table_search button{
    font-size: 16px;
    font-weight: 400;
    border: 1px solid #3D4143;
    color: #3D4143;
    border-radius: 8px;
    padding: 14px 30px;
    background: white;
    margin-left: 10px;
  }
  .table_search .GA2-btn{
    background-color: #005CB9;
    color: white;
    border: none;
  }
  .table>:not(:first-child){
    border-top: 1px solid #E8EEEC !important;
  }
  
  .table thead tr th{
    font-size: 16px;
    font-weight: 500;
    color: #B3B7BB;
    padding: 15px 0;
  }
  .table tbody tr td{
    font-size: 16px;
    font-weight: 400;
    color: #2D3648;
    padding: 15px 0;
  
  }
  .table tbody tr th{
    color: #2F7FD1;
    font-size: 16px;
    font-weight: 400;
    vertical-align: middle;
  }
  .table tr{
    margin-left: 20px;
  }
  .table .green-text{
    color:#27AE60;
  }
  .pagination-content{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    /* flex-direction: row-reverse; */
  }
  
  #wrapper {
    overflow-x: auto;
  }
  
  #wrapper th{
    min-width: 150px;
  }

  .roundPill{
    color: #fff;
    padding: 3px 12px;
    border-radius: 16px;
  }
  
  .green-bg{
    background-color: #198754;
  }
  
  .red-bg{
    background-color: #b62c38;
  }

  .yellow-bg{
    background-color: #FF861C;
  }

  .ga2-manage-questions-btn {
    margin: 20px 0;
  }

  .ga2-questions-item {
    border: 1px solid #f3f3f3;
    margin: 10px 0;
    padding: 10px;
    background-color: #fff;
    cursor: grab;
    border-radius: 8px;
  }

  .ga2-questions-item:hover {
    border-color: #ddd;
  }

  .ga2-questions-item-actions img {
    cursor: pointer;
    margin-left: 14px;
  }