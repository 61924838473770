.assessment-item-row .inspection-issue-row {
    min-height: 45px;
}

.assessments-main-container .container {
    width: 100%;
    max-width: 100%;
}

.assessment-row-comments,
.inspection-issue-hazard,
.inspection-issue-resp-required,
.inspection-issue-risk-factor {
    width: calc( ( 100% - 430px ) / 4 );
}

.assessment-row-rr,
.assessment-row-severity,
.assessment-row-likelihood {
    width: 30px;
    padding: 0 2px !important;
}

.assessment-row-save {
    width: 100px;
}

.assessment-row-actions {
    width: 100px;
}

.assessment-item-row .assessment-row-rr span,
.assessment-item-row .assessment-row-severity span,
.assessment-item-row .assessment-row-likelihood span {
    border: 1px solid #ddd;
    display: flex;
    background: #fff;
    width: 100%;
    height: 30px;
    align-items: center;
    justify-content: center;
}

.matrix-score-red,
.assessment-item-row span.matrix-score-red {
    background: #dc3644;
    color: #fff;
}

.matrix-score-green,
.assessment-item-row span.matrix-score-green {
    background-color: #198754;
    color: #fff;
}

.matrix-score-yellow,
.assessment-item-row span.matrix-score-yellow {
    background-color: #f59e0b;
    color: #fff;
}

.assessment-row-save button {
    border: none;
    padding: 0;
    opacity: 0.8;
    background: none;
    cursor: pointer;
}

.assessment-row-save button:hover {
    opacity: 1;
}

.risk-revise-btn img {
    width: 20px;
}

.inspection-issue-actions button {
    padding: 0;
    min-width: inherit;
    margin-right: 10px;
    color: #000;
    cursor: pointer;
}

button.btn.btn-sm.btn-disabled {
    cursor: default;
}

button.btn.btn-sm.btn-add-action,
button.btn.btn-sm.take-action {
    background: #dc3644;
    color: #fff;
    font-size: 12px;
    padding: 4px 5px;
    height: initial !important;
    min-height: initial;
}

.revised-rr > span {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 45px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.inspection-issue-details-item .revised-rr > span {
    width: 35px;
    height: 25px;
}

.inspection-issue-revised-details {
    margin-bottom: 20px;
}

button.risk-revise-btn.revised {
    mix-blend-mode: exclusion;
    opacity: 1;
    cursor: default;
}

.complete-inspection .btn {
    height: 50px;
}

.pdf-download-btn {
    height: 50px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.assessment-item-row .inspection-issue-actions .btn-sm {
    width: 90px;
    margin-right: 5px;
}

button.btn.btn-outline-success.btn-edit-action {
    margin-top: 5px;
    padding: 5px;
    height: 34px;
    width: auto;
    min-width: initial;
    font-size: 12px;
}

.assessment-issue-closure-signature {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
}