
.table_search{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .table_search .input_icons{
    position: relative;
    width: 25%;
  }
  .table_search .input_icons img{
    left: 5px;
  }
  .table_search .input_icons input{
    background: #F3F5F6;
    padding: 14px;
    font-size: 16px;
    border: none;
    outline: none;
    font-weight: 400;
    border-radius: 8px;
    padding-left: 45px;
  }
  .table_search button{
    font-size: 16px;
    font-weight: 400;
    border: 1px solid #3D4143;
    color: #3D4143;
    border-radius: 8px;
    padding: 14px 30px;
    background: white;
    margin-left:10px;
  }
  .table_search .GA2-btn{
    background-color: #005CB9;
    color: white;
    border: none;
  }
  .table>:not(:first-child){
    border-top: 1px solid #E8EEEC !important;
  }
  
  .table thead tr th{
    font-size: 16px;
    font-weight: 500;
    color: #B3B7BB;
    padding: 15px 0;
  }
  .table tbody tr td{
    font-size: 16px;
    font-weight: 400;
    color: #2D3648;
    padding: 15px 0;
  
  }
  .table tbody tr th{
    color: #2F7FD1;
    font-size: 16px;
    font-weight: 400;
    vertical-align: middle;
  }
  .table tr{
    margin-left: 20px;
  }
  .table .green-text{
    color:#27AE60;
  }
  .pagination-content{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    /* flex-direction: row-reverse; */
  }
  
  #wrapper {
    overflow-x: auto;
  }
  
  #wrapper th{
    min-width: 150px;
  }
  

  .page-link{
    height: 39px;
    color:#2D3648;
  }

  
  .page-link:focus,.page-link:hover{
    color:#2D3648;
  }

  .page-entries{
    display: flex;
    align-items: center;
    gap:10px;
  }
  @media screen and (max-width: 1111px) {
  .table_search button{
    padding: 8px 18px;
    margin-left: 8px;
  }
  .table_search .input_icons input{
    padding: 8px;
    padding-left: 30px;
  }
  .input_icons img{
    padding: 5px;
  }
  .table_search .input_icons img{
    width: 25px;
  }
}
@media screen and (max-width: 911px){
  .table_search .input_icons{
    width: 100%;
    margin-bottom: 10px;
  }
  .table_search{
    flex-direction: column;
  }
}