.header-hotworks-view{
    margin: auto;
    border: 1px solid #e2e6ea;
    padding: 10px 10px;
    background-color: #e2e6ea;
    font-size: 15px;
    border-radius: 8px;
}
.dl-hotworks{
    background-color: #e2e6ea33;
    /* padding: 6px 15px; */
    font-size: 13px;
}
.dd-hotworks{
    padding: 6px 15px;
    border: 1px solid #ebebeb;
    margin-right: 3px;
}
.main-restriction-div{
    font-size:  14px;
}
.signature-div{
    display: flex;
    justify-content: start;
}
.signature-div-inner{
    border: 1px solid #ebebeb;
}