body {
  background-color: #e9edee !important;
}

a {
  text-decoration: none !important;
}
.cursor-pointer{
  cursor: pointer;
}
.container {
  display: flex;
  flex-direction: column;
}

.link {
  text-decoration: none;
  color: inherit;
}

.flex {
  display: flex;
}

.divider img{display: block;margin:25px auto;}

span.MuiButton-endIcon{
  background-color: rgba(255,255,255,0.5) !important;
padding: 4px !important;
border-radius: 10px !important;
}
#pageHeaderIcon {
  color: white !important;
  padding: 8px !important;
  border-radius: 8px !important;
  font-size: 36px !important;
  margin-right: 10px;
  width: 65px !important;
  height: 65px !important;
  margin-top: 10px;
}
.css-1tgyln{box-shadow: none !important; background-color: #e9edee !important;}
/* .css-vubbuv{color:#151d48 !important} */
/* .css-1k455el.css-1yxmbwk{color:#151d48 !important} */
.css-pio6kb{background-color: #ffffff !important; color:#151d48 !important; border-radius: 30px !important; margin-right: 35px !important;}
.pageHeader {
  display: flex !important;
  align-items: center !important;
  justify-content: left !important;
  margin:  10px 0px !important;
}

#pageHeaderIcon .MuiSvgIcon-root {
  height: 45px !important;
  width: 45px !important;
}

.pageHeaderLogin {
  display: flex !important;
  align-items: center !important;
  justify-content: left !important;
  margin: 20px 0px 20px 0px !important;
  width:100%;
  max-width: 600px;
}

.pageHeaderText {
  display: flex !important;
  flex-direction: column !important;
}

.pageHeaderTitle {
  font-weight: 500 !important;
  color: #ffffff !important;
  font-size: clamp(16px, 0.8vw, 24px) !important;
  margin-bottom: 2px !important;
}


.loginTitle {
  font-weight: 500 !important;
  color: #1D2124 !important;
  font-size: clamp(16px, 0.8vw, 24px) !important;
  margin-bottom: 2px !important;
}

.pageHeaderDescription {
  font-weight: 300 !important;
  margin-bottom: 0px !important;
  color: #ffffff !important;
  line-height: 20px !important;
  font-size: 16px !important;
  white-space: pre-line !important;
}

.pageTableTitle {
  font-weight: 600 !important;
  color: #1d2740 !important;
  font-size: 20px !important;
}

.pageTableTitleNoButton {
  font-weight: 600 !important;
  color: #1d2740 !important;
  font-size: 20px !important;
  margin-bottom: 15px !important;
}

.pageTableTopTitle {
  font-weight: 600 !important;
  color: #1d2740 !important;
  font-size: 20px !important;
  margin-bottom: 15px !important;
}

.MuiDataGrid-root {
  border: 1px solid #e6e7eb !important;
  border-radius: 20px !important;
  height: auto !important;
}

.MuiDataGrid-columnHeaderWrapper {
  background-color: #f9fafc !important;
  /* border-radius: 20px 20px 0px 0px; */
}

.userListActions a {
  text-decoration: none !important;
}

.addButtonContainer a {
  text-decoration: none !important;
}

.addButtonText {
  width: 100% !important;
  max-width: 150px !important;
  justify-content: center !important;
  text-decoration: none !important;
  font-size: 18px !important;
  line-height: 20px !important;
  text-transform: none !important;
}


.addButtonTextLarge {
  width: 100% !important;
  max-width: 210px !important;
  justify-content: center !important;
  text-decoration: none !important;
  font-size: 18px;
  line-height: 20px;
  padding:0px 10px;
  text-transform: none !important;
}
.addButtonTextSmall {
  width: 95px;
  justify-content: center;
  text-decoration: none !important;
  text-transform: none !important;
}

.addElementButton {
  background-color: #1d2740;
  padding: 8px 5px 8px 15px;
  font-size: 20px;
  display: flex;
  align-items: center;
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  width: 150px;
  text-decoration: none !important;
}

.addElementIcon {
  text-decoration: none !important;
  background-color: rgba(255, 255, 255, 0.6);
  color: white;
  font-size: 30px !important;
  padding: 5px;
}

.addElementButtonPurple {
  background-color: #a700ff !important;
  padding: 8px 5px 8px 15px !important;
  font-size: 20px !important;
  display: flex !important;
  align-items: center !important;
  border: none !important;
  border-radius: 5px !important;
  color: white !important;
  cursor: pointer !important;
  width: 150px !important;
  text-decoration: none !important;
  margin-top: 30px !important;
  float: right !important;
}

.addElementButtonPurple:hover {
  background-color: rgba(167, 0, 255, 0.6);
}





.addElementButtonPurpleLarge {
  background-color: #a700ff !important;
  padding: 8px 5px 8px 5px !important;
  font-size: 20px !important;
  display: flex !important;
  align-items: center !important;
  border: none !important;
  border-radius: 5px !important;
  color: white !important;
  cursor: pointer !important;
  width: 270px;
  text-decoration: none !important;
  margin-top: 0px !important;
  float: right !important;
  text-transform: none !important;
}

.addElementButtonPurpleLarge:hover {
  background-color: rgba(167, 0, 255, 0.6) !important;
}

.addElementButtonPurpleLarge.padding {
  padding: 8px 10px !important;
}

.addElementButtonPurple.padding {
  padding: 10px 10px !important;
}

.addElementButtonPurpleSmall.padding {
  padding: 10px 10px!important;
}

.addElementButtonPurpleMedium.padding {
  padding: 10px 10px!important;
}

.addElementButtonPurpleSmall {
  background-color: #a700ff !important;
  padding: 8px 5px 8px 5px !important;
  font-size: 20px !important;
  display: flex !important;
  align-items: center !important;
  border: none !important;
  border-radius: 5px !important;
  color: white !important;
  cursor: pointer !important;
  width: 150px;
  text-decoration: none !important;
  margin-top: 30px !important;
  float: right !important;
  text-transform: none !important;
}

.addElementButtonPurpleSmall:hover {
  background-color: rgba(167, 0, 255, 0.6) !important;
}

.addElementButtonPurpleMedium {
  background-color: #a700ff !important;
  padding: 8px 5px 8px 5px !important;
  font-size: 20px !important;
  display: flex !important;
  align-items: center !important;
  border: none !important;
  border-radius: 5px !important;
  color: white !important;
  cursor: pointer !important;
  width: 195px;
  text-decoration: none !important;
  margin-top: 30px !important;
  float: right !important;
  text-transform: none !important;
}

.addElementButtonPurpleMedium:hover {
  background-color: rgba(167, 0, 255, 0.6) !important;
}






.addElementButtonGreen {
  background-color: #22c55d !important;
  padding: 8px 5px 8px 5px !important;
  font-size: 20px !important;
  display: flex !important;
  align-items: center !important;
  border: none !important;
  border-radius: 5px !important;
  color: white !important;
  cursor: pointer !important;
  width: 100% !important;
  max-width: 210px;
  text-decoration: none !important;
  margin-top: 0px !important;
  float: right !important;
  text-transform: none !important;
}

.addElementButtonGreen:hover {
  background-color: rgba(34, 197, 93, 0.6) !important;
}


.addElementButtonGreenSmall.padding {
  padding: 10px 10px!important;
}

.addElementButtonGreenSmall {
  background-color: #22c55d!important;
  padding: 8px 5px 8px 5px !important;
  font-size: 20px !important;
  display: flex !important;
  align-items: center !important;
  border: none !important;
  border-radius: 5px !important;
  color: white !important;
  cursor: pointer !important;
  width: 150px;
  text-decoration: none !important;
  margin-top: 30px !important;
  float: right !important;
  text-transform: none !important;
}

.addElementButtonGreenSmall:hover {
  background-color: rgba(34, 197, 93, 0.6) !important;
}


.addElementButtonGreenLarge {
  background-color: #22c55d!important;
  padding: 8px 5px 8px 5px !important;
  font-size: 20px !important;
  display: flex !important;
  align-items: center !important;
  border: none !important;
  border-radius: 5px !important;
  color: white !important;
  cursor: pointer !important;
  width: 270px;
  text-decoration: none !important;
  margin-top: 0px !important;
  float: right !important;
  text-transform: none !important;
}

.addElementButtonGreenLarge:hover {
  background-color: rgba(34, 197, 93, 0.6) !important;
}

.addElementButtonGreenLarge.padding {
  padding: 8px 10px !important;
}



.addElementButtonYellow.padding {
  padding: 8px 15px !important;
}


.addElementButtonRed.padding {
  padding: 8px 15px !important;
}

.addElementButtonRed.padding.margintop{
  padding: 8px 15px !important;
  margin-top:30px !important;
}

.addElementButtonRedLarge.padding {
  padding: 8px 10px !important;
}

.addElementButtonRedMedium.padding {
  padding: 8px 10px !important;
}



.addElementButtonRed {
  background-color: #EF4444!important;
  padding: 8px 5px 8px 5px !important;
  font-size: 18px !important;
  display: flex !important;
  align-items: center !important;
  border: none !important;
  border-radius: 5px !important;
  color: white !important;
  cursor: pointer !important;
  width: 100% !important;
  max-width: 210px;
  text-decoration: none !important;
  margin-top: 0px !important;
  /* float: right !important; */
  text-transform: none !important;
  font-size: 18px;
  line-height: 20px;
}

.addElementButtonRed:hover {
  background-color: rgba(239, 68, 68, 0.6) !important;
}



.addElementButtonRedLarge {
  background-color: #EF4444!important;
  padding: 8px 5px 8px 5px !important;
  font-size: 20px !important;
  display: flex !important;
  align-items: center !important;
  border: none !important;
  border-radius: 5px !important;
  color: white !important;
  cursor: pointer !important;
  width: 270px;
  text-decoration: none !important;
  margin-top: 0px !important;
  float: right !important;
  text-transform: none !important;
}

.addElementButtonRedLarge:hover {
  background-color: rgba(239, 68, 68, 0.6) !important;
}


.addElementButtonRedMedium {
  background-color: #EF4444!important;
  padding: 8px 5px 8px 5px !important;
  font-size: 20px !important;
  display: flex !important;
  align-items: center !important;
  border: none !important;
  border-radius: 5px !important;
  color: white !important;
  cursor: pointer !important;
  width: 195px;
  text-decoration: none !important;
  margin-top: 0px !important;
  float: right !important;
  text-transform: none !important;
}

.addElementButtonRedMedium:hover {
  background-color: rgba(239, 68, 68, 0.6) !important;
}

.addElementButtonRedSmall.padding {
  padding: 8px 15px !important;
}

.addElementButtonRedSmall {
  background-color: #EF4444!important;
  padding: 8px 5px 8px 5px !important;
  font-size: 20px !important;
  display: flex !important;
  align-items: center !important;
  border: none !important;
  border-radius: 5px !important;
  color: white !important;
  cursor: pointer !important;
  width: 150px;
  text-decoration: none !important;
  margin-top: 30px !important;
  float: right !important;
  text-transform: none !important;
}

.addElementButtonRedSmall:hover {
  background-color: rgba(239, 68, 68, 0.6) !important;
}


.addElementButtonYellow.padding {
  padding: 8px 15px !important;
}

.addElementButtonYellowSmall.padding {
  padding: 8px 15px !important;
}


.addElementButtonYellowLarge.padding {
  padding: 8px 10px !important;
}


.addElementButtonYellow {
  background-color: #f59e0b !important;
  padding: 8px 5px 8px 5px !important;
  font-size: 18px !important;
  display: flex !important;
  align-items: center !important;
  border: none !important;
  border-radius: 5px !important;
  color: white !important;
  cursor: pointer !important;
  width: 100% !important;
  max-width: 210px;
  text-decoration: none !important;
  margin-top: 0px !important;
  /* float: right !important; */
  text-transform: none !important;
  font-size: 18px;
  line-height: 20px;
}

.addElementButtonYellow:hover {
  background-color: rgba(245, 158, 11, 0.6) !important;
}

.addElementButtonYellowSmall {
  background-color: #f59e0b !important;
  padding: 8px 5px 8px 5px !important;
  font-size: 20px !important;
  display: flex !important;
  align-items: center !important;
  border: none !important;
  border-radius: 5px !important;
  color: white !important;
  cursor: pointer !important;
  width: 150px;
  text-decoration: none !important;
  margin-top: 30px !important;
  float: right !important;
  text-transform: none !important;
}

.addElementButtonYellowSmall:hover {
  background-color: rgba(245, 158, 11, 0.6) !important;
}

.addElementButtonYellowMedium {
  background-color: #f59e0b !important;
  padding: 8px 5px 8px 5px !important;
  font-size: 20px !important;
  display: flex !important;
  align-items: center !important;
  border: none !important;
  border-radius: 5px !important;
  color: white !important;
  cursor: pointer !important;
  width: 195px;
  text-decoration: none !important;
  margin-top: 30px !important;
  float: right !important;
  text-transform: none !important;
}

.addElementButtonYellowMedium:hover {
  background-color: rgba(245, 158, 11, 0.6) !important;
}

.leftmargin{margin-left:30px !important;}


.addElementButtonYellowLarge {
  background-color: #f59e0b !important;
  padding: 8px 5px 8px 5px !important;
  font-size: 20px !important;
  display: flex !important;
  align-items: center !important;
  border: none !important;
  border-radius: 5px !important;
  color: white !important;
  cursor: pointer !important;
  width: 270px;
  text-decoration: none !important;
  margin-top: 0px !important;
  float: right !important;
  text-transform: none !important;
}

.addElementButtonYellowLarge:hover {
  background-color: rgba(245, 158, 11, 0.6) !important;
}




.addElementIconPurple.css-i4bv87-MuiSvgIcon-root {
  text-decoration: none !important;
  color: #ffffff !important;
  font-size: 30px !important;
  padding: 5px;
  border-radius: 6px;
  stroke-width: 1 !important;
}

.addElementIconPurple.css-i4bv87-MuiSvgIcon-root:hover {
  color: #ffffff !important;
}


.addElementIconRed.css-i4bv87-MuiSvgIcon-root {
  text-decoration: none !important;
  color: #ffffff !important;
  font-size: 30px !important;
  padding: 5px;
  border-radius: 6px;
  stroke-width: 1 !important;
}

.addElementIconRed.css-i4bv87-MuiSvgIcon-root:hover {
  color: #ffffff !important;
}


.addElementIconYellow.css-i4bv87-MuiSvgIcon-root {
  text-decoration: none !important;
  color: #ffffff !important;
  font-size: 30px !important;
  padding: 5px;
  border-radius: 6px;
  stroke-width: 1 !important;
}

.addElementIconYellow.css-i4bv87-MuiSvgIcon-root:hover {
  color: #ffffff !important;
}

.addElementIconGreen.css-i4bv87-MuiSvgIcon-root {
  text-decoration: none !important;
  color: #ffffff !important;
  font-size: 30px !important;
  padding: 5px;
  border-radius: 6px;
  stroke-width: 1 !important;
}

.addElementIconGreen.css-i4bv87-MuiSvgIcon-root:hover {
  color: #ffffff !important;
}

.addElementIconYellowSmall.css-i4bv87-MuiSvgIcon-root {
  text-decoration: none !important;
  color: #ffffff !important;
  font-size: 30px !important;
  padding: 5px;
  border-radius: 6px;
  stroke-width: 1 !important;
}

.addElementIconYellowSmall.css-i4bv87-MuiSvgIcon-root:hover {
  color: #ffffff !important;
}

.pageSubHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0px;
}

.actionButtonText {
  width: auto;
  margin-left: 5px;
  justify-content: center;
  text-decoration: none;
}

.actionElementButton {
  background-color: transparent;
  padding: 2px;
  font-size: 16px;
  display: flex;
  align-items: center;
  border: none;
  border-radius: 5px;
  color: #5A5D5E;
  cursor: pointer;
  margin-right: 10px;
  text-decoration: none !important;

}

.actionElementButton:hover {
  opacity: 1;
}

.MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer {
  padding: 0px 6px 0px 10px !important;
}

.actionElementIcon {
  color: #1d2740;
  font-size: 20px !important;
  text-decoration: none;
}

.templateButtonContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.templateButtonText {
  justify-content: center;
  text-decoration: none;
}

.templateElementButton {
  background-color: #1d2740;
  padding: 10px;
  font-size: 16px;
  display: flex;
  align-items: center;
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  text-decoration: none !important;
}

.css-13cymwt-control{min-height:50px !important;}
.popupElementButton {
  background-color: #1d2740;
  padding: 5px 15px;
  font-size: 16px;
  display: flex;
  align-items: center;
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  text-decoration: none !important;
}

.templateElementIcon {
  color: white;
  font-size: 20px !important;
  text-decoration: none;
  margin-right: 10px;
}

.popupTitle {
  color: #1d2740;
  font-weight: 600;
  font-size: 22px;
  text-align: center;
  margin-top: 34px;
  margin-bottom: 20px;
}

.popupDropdown {
  width: 100%;
  height: 50px;
  padding: 5px 5px 5px 10px;
  border: 1px solid #cfd2db;
  border-radius: 5px;
  background-color: #f9fafb;
  font-size: 16px;
}

.popupText {
  width: 100%;
  height: 50px;
  padding: 5px 0px 5px 15px;
  border: 1px solid #cfd2db;
  border-radius: 5px;
  background-color: #f9fafb;
  font-size: 16px;
  max-width: calc(100% - 15px) !important;
}

.passwordContainer {
  display: flex;
  margin-top: 15px;
}

.logoutButton {
  width: 100%;
  border-radius: 5px;
  background-color: rgb(254, 95, 95);
  border: none;
  margin-top: 30px;
  cursor: pointer;
  font-weight: 600;
  padding: 7px 10px;
  color: white;
  height: 46px;
  font-size: 18px;
}

.keepLoggedInButton {
  width: 100%;
  border-radius: 5px;
  background-color: #1d2740;
  border: none;
  margin-top: 30px;
  cursor: pointer;
  font-weight: 600;
  padding: 7px 10px;
  color: white;
  height: 46px;
  font-size: 18px;
  margin-right: 5px;
}

.buttonContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

h4 {
  font-size: 22px;
  text-align: center;
}

.timerContainer #mypopup {
  display: none !important;
}

.MuiDataGrid-root
  .MuiDataGrid-columnHeaderCheckbox
  .MuiDataGrid-columnHeaderTitleContainer {
  display: none;
}

.MuiDataGrid-root .MuiDataGrid-columnHeaderCheckbox,
.MuiDataGrid-root .MuiDataGrid-cellCheckbox {
  display: none !important;
}

.sidebar-container {
  position: absolute;
  top: 0;
  left: 0;
  background: red;
  width: 40vw;
  height: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;
  color: #fff;
}

.overlay {
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
}

.App {
  position: relative;
  text-align: center;
}

* {
  box-sizing: border-box;
}

.css-ciy9n4-MuiPaper-root-MuiAppBar-root {
  background-color: #e9edee !important;
  box-shadow: none !important;
}

.css-134qg7o-MuiButtonBase-root-MuiIconButton-root {
  margin-left: 0px !important;
  padding-top: 0px !important;
  background-color: #ffffff !important;
  padding-top: 8px !important;
  margin-right: 30px !important;
}

.css-78trlr-MuiButtonBase-root-MuiIconButton-root {
  margin-left: 30px !important;
  padding-top: 0px !important;
  background-color: #f6f6f6 !important;
  color: #2d264b !important;
  padding-top: 8px !important;
}

.css-i4bv87-MuiSvgIcon-root {
  color: #2d264b !important;
  stroke: #ffffff;
  stroke-width: 1;
}

.css-i4bv87-MuiSvgIcon-root:hover {
  color: #ffffff !important;
  stroke: #ffffff;
  stroke-width: 1;
}

.css-i4bv87-MuiSvgIcon-root.active {
  color: #ffffff !important;
  stroke: #ffffff;
  stroke-width: 1;
}

button .css-i4bv87-MuiSvgIcon-root:hover {
  color: #00aeef !important;
  stroke: #ffffff;
  stroke-width: 1;
}

button .css-i4bv87-MuiSvgIcon-root.active {
  color: #00aeef !important;
  stroke: #00aeef;
  stroke-width: 1;
}

li#smssafetyid:hover {
  background-color: #ffa412;
}

li#smssafetyid.active .css-i4bv87-MuiSvgIcon-root {
  color: #ffffff !important;
  stroke: #ffa412;
  stroke-width: 1;
}

li#smssafetyid:hover .css-i4bv87-MuiSvgIcon-root {
  color: #ffffff !important;
  stroke: #ffa412;
  stroke-width: 1;
}

li#inductionsid:hover {
  background-color: #a700ff;
}

li#inductionsid.active .css-i4bv87-MuiSvgIcon-root {
  color: #ffffff !important;
  stroke: #a700ff;
  stroke-width: 1;
}

li#inductionsid:hover .css-i4bv87-MuiSvgIcon-root {
  color: #ffffff !important;
  stroke: #a700ff;
  stroke-width: 1;
}

li#fireid:hover {
  background-color: #ef4444;
}

li#fireid.active .css-i4bv87-MuiSvgIcon-root {
  color: #ffffff !important;
  stroke: #ef4444;
  stroke-width: 1;
}

li#fireid:hover .css-i4bv87-MuiSvgIcon-root {
  color: #ffffff !important;
  stroke: #ef4444;
  stroke-width: 1;
}

li#foodid:hover {
  background-color: #22c55e;
}

li#foodid.active .css-i4bv87-MuiSvgIcon-root {
  color: #ffffff !important;
  stroke: #22c55e;
  stroke-width: 1;
}

li#foodid:hover .css-i4bv87-MuiSvgIcon-root {
  color: #ffffff !important;
  stroke: #22c55e;
  stroke-width: 1;
}

li#remoteid:hover {
  background-color: #2f80ed;
}

li#remoteid.active .css-i4bv87-MuiSvgIcon-root {
  color: #ffffff !important;
  stroke: #2f80ed;
  stroke-width: 1;
}

li#remoteid:hover .css-i4bv87-MuiSvgIcon-root {
  color: #ffffff !important;
  stroke: #2f80ed;
  stroke-width: 1;
}

.MuiCollapse-wrapperInner a#smssafetyid li {
  padding-left: 20px !important;
}
.MuiCollapse-wrapperInner a#inductionsid li {
  padding-left: 20px !important;
}
.MuiCollapse-wrapperInner a#foodid li {
  padding-left: 20px !important;
}
.MuiCollapse-wrapperInner a#fireid li {
  padding-left: 20px !important;
}
.MuiCollapse-wrapperInner a#overviewid li {
  padding-left: 20px !important;
}
.MuiCollapse-wrapperInner a#remoteid li {
  padding-left: 20px !important;
}

li#smssafetyid:hover {
  background-color: #ffa412 !important;
}

li#smssafetyid.active {
  background-color: #ffa412 !important;
}

li#inductionsid:hover {
  background-color: #a700ff !important;
}

li#inductionsid.active {
  background-color: #a700ff !important;
}

li#fireid:hover {
  background-color: #ef4444 !important;
}

li#fireid.active {
  background-color: #ef4444 !important;
}

li#foodid:hover {
  background-color: #22c55e !important;
}

li#foodid.active {
  background-color: #22c55e !important;
}

li#remoteid:hover {
  background-color: #2f80ed !important;
}

li#remoteid.active {
  background-color: #2f80ed !important;
}

.sidebarlistItem:hover .css-i4bv87-MuiSvgIcon-root {
  color: #ffffff !important;
  stroke: #ffffff;
  stroke-width: 1;
}

#foodid .sidebarlistItem:hover .css-i4bv87-MuiSvgIcon-root {
  color: #ffffff !important;
  stroke: #22c55e;
  stroke-width: 1;
}

#foodid .sidebarlistItem.active .css-i4bv87-MuiSvgIcon-root {
  color: #ffffff !important;
  stroke: #22c55e;
  stroke-width: 1;
}

#fireid .sidebarlistItem.active .css-i4bv87-MuiSvgIcon-root {
  color: #ffffff !important;
  stroke: #ef4444;
  stroke-width: 1;
}

#fireid .sidebarlistItem:hover .css-i4bv87-MuiSvgIcon-root {
  color: #ffffff !important;
  stroke: #ef4444;
  stroke-width: 1;
}

#overviewid .sidebarlistItem.active .css-i4bv87-MuiSvgIcon-root {
  color: #ffffff !important;
  stroke: #00aeef;
  stroke-width: 1;
}

#overviewid .sidebarlistItem:hover .css-i4bv87-MuiSvgIcon-root {
  color: #ffffff !important;
  stroke: #00aeef;
  stroke-width: 1;
}

#smssafetyid .sidebarlistItem.active .css-i4bv87-MuiSvgIcon-root {
  color: #ffffff !important;
  stroke: #ffa412;
  stroke-width: 1;
}

#smssafetyid .sidebarlistItem:hover .css-i4bv87-MuiSvgIcon-root {
  color: #ffffff !important;
  stroke: #ffa412;
  stroke-width: 1;
}

#inductionsid .sidebarlistItem.active .css-i4bv87-MuiSvgIcon-root {
  color: #ffffff !important;
  stroke: #a700ff;
  stroke-width: 1;
}

#inductionsid .sidebarlistItem:hover .css-i4bv87-MuiSvgIcon-root {
  color: #ffffff !important;
  stroke: #a700ff;
  stroke-width: 1;
}


#stripeid .sidebarlistItem.active .css-i4bv87-MuiSvgIcon-root {
  color: #ffffff !important;
  stroke: #a700ff;
  stroke-width: 1;
}

#stripeid .sidebarlistItem:hover .css-i4bv87-MuiSvgIcon-root {
  color: #ffffff !important;
  stroke: #a700ff;
  stroke-width: 1;
}

#remoteid .sidebarlistItem.active .css-i4bv87-MuiSvgIcon-root {
  color: #ffffff !important;
  stroke: #2f80ed;
  stroke-width: 1;
}

#remoteid .sidebarlistItem:hover .css-i4bv87-MuiSvgIcon-root {
  color: #ffffff !important;
  stroke: #2f80ed;
  stroke-width: 1;
}

a#usersid .sidebarlistItem.active .css-i4bv87-MuiSvgIcon-root {
  color: #ffffff !important;
  stroke: #00aeef;
  stroke-width: 1;
}

#usersid .sidebarlistItem:hover .css-i4bv87-MuiSvgIcon-root {
  color: #ffffff !important;
  stroke: #00aeef;
  stroke-width: 1;
}

.css-mrxozk-MuiDrawer-docked {
  width: 0px !important;
}

.css-1ij2uzp-MuiPaper-root-MuiAppBar-root {
  background-color: #e9edee !important;
  box-shadow: none !important;
}

.css-dyjd4v-MuiDrawer-docked {
  width: auto !important;
}



.css-wuia68 {
  width: auto !important;
}

.css-pbatbg {
  background-color:transparent !important;
  box-shadow: none !important;
}

.buttonscontainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.buttonscontainerfw {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 12px;
  padding: 20px;
}


.buttonscontainerfwct {
  display: flex;
  flex-direction: column;

}

.buttonbox {
  flex: 2;
  background: #ffffff;
  border-radius: 12px;
  padding: 20px;
  margin-bottom: 40px;
  border: 1px solid #ffffff;
}

.buttonboxfw {
  flex: 2;
  background: #ffffff;
  border-radius: 12px;
  padding: 20px;
  margin-bottom: 20px;
  border: 1px solid #ffffff;
}


.buttonboxfwgreen {
  background: #ffffff;
  border-radius: 12px;
  padding: 20px;
  margin-bottom: 20px;
  border: 1px solid #ffffff;
}

.buttonboxlarge {
  background: #ffffff;
  border-radius: 12px;
  padding: 20px;
  margin-bottom: 40px;
  border: 1px solid #ffffff;
}



.buttonboxlarge .template-notes {
  display: flex;
 justify-content: space-between;
}


.buttonbox:nth-child(odd) {
  margin-right: 40px !important;
}

.buttonbox:last-child {
  margin-right: 0px;
}

.buttonboxfw:nth-child(odd) {
  margin-right: 0px;
}

.buttonboxfw:last-child {
  margin-right: 0px;
}

.buttonbox.purple:hover {
  background-color: #f5f3ff;
  border: 1px solid #a78bfa;
  cursor: pointer;
}

.buttonboxfw.purple:hover {
  background-color: #f5f3ff;
  border: 1px solid #a78bfa;
  cursor: pointer;
}

.buttonbox.green:hover {
  background-color: #f0fdf4;
  border: 1px solid #86efac;
  cursor: pointer;
}

.buttonbox.yellow:hover {
  background-color: #fefce8;
  border: 1px solid #f59e0b;
  cursor: pointer;
}

.buttonbox.darkblue:hover {
  background-color: #eff6ff;
  border: 1px solid #006be4;
  cursor: pointer;
}

.buttonboxheader {
  display: flex;
  align-items: center;
}

.buttonbox.purple .buttonboxheader .buttonboxicon {
  margin-right: 20px;
  width: 50px;
  height: 50px;
  stroke: #ede9fe;
  stroke-width: 1;
  background-color: #ede9fe;
  border-radius: 6px;
  padding: 8px;
  color: #444a6d !important;
}

.buttonboxfw.purple .buttonboxheader .buttonboxicon {
  margin-right: 20px;
  width: 50px;
  height: 50px;
  stroke: #ede9fe;
  stroke-width: 1;
  background-color: #ede9fe;
  border-radius: 6px;
  padding: 8px;
  color: #444a6d !important;
}


.buttonboxfw.green{
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 12px;
  padding: 20px;
}

.buttonscontainerfwmain{
  display: flex;
  background-color: #F9FAFB;
  border-radius: 12px;
  padding:50px;
  justify-content: space-between;
}



.buttonscontainerfwtransparent{
  display: flex;
  background-color: transparent;
  border-radius: 12px;
  padding:50px;
  justify-content: space-between;
}


.buttonboxfw.green .buttonboxheader .buttonboxicon {
  margin-right: 20px;
  width: 50px;
  height: 50px;
  stroke: #f0fdf4;
  stroke-width: 1;
  background-color: #e2fff3;
  border-radius: 6px;
  padding: 8px;
  color: #444a6d !important;
}


.buttonbox.green .buttonboxheader .buttonboxicon {
  margin-right: 20px;
  width: 50px;
  height: 50px;
  stroke: #f0fdf4;
  stroke-width: 1;
  background-color: #e2fff3;
  border-radius: 6px;
  padding: 8px;
  color: #444a6d !important;
}

.buttonbox.yellow .buttonboxheader .buttonboxicon {
  margin-right: 20px;
  width: 50px;
  height: 50px;
  stroke: #fef9c3;
  stroke-width: 1;
  background-color: #fef9c3;
  border-radius: 6px;
  padding: 8px;
  color: #444a6d !important;
}

.buttonbox.darkblue .buttonboxheader .buttonboxicon {
  margin-right: 20px;
  width: 50px;
  height: 50px;
  stroke: #dbeafe;
  stroke-width: 1;
  background-color: #dbeafe;
  border-radius: 6px;
  padding: 8px;
  color: #444a6d !important;
}

.buttonboxheader .buttonboxtitle {
  color: #151d48;
  font-weight: 500;
  font-size: 22px;
  line-height: 28px;
}

.buttonbox.purple:hover .buttonboxheader .buttonboxtitle {
  color: #a700ff;
}

.buttonbox.purple:active .buttonboxheader .buttonboxtitle {
  color: #a700ff;
}

.buttonbox.purple:hover .buttonboxheader .buttonboxicon {
  color: #a700ff !important;
}

.buttonboxfw.purple:hover .buttonboxheader .buttonboxtitle {
  color: #a700ff;
}

.buttonboxfw.purple:active .buttonboxheader .buttonboxtitle {
  color: #a700ff;
}

.buttonboxfw.purple:hover .buttonboxheader .buttonboxicon {
  color: #a700ff !important;
}

/* green */

.buttonbox.green:hover .buttonboxheader .buttonboxtitle {
  color: #4bd469;
}

.buttonbox.green:active .buttonboxheader .buttonboxtitle {
  color: #4bd469;
}

.buttonbox.green:hover .buttonboxheader .buttonboxicon {
  color: #4bd469 !important;
}

/* yellow */

.buttonbox.yellow:hover .buttonboxheader .buttonboxtitle {
  color: #ffa412;
}

.buttonbox.yellow:active .buttonboxheader .buttonboxtitle {
  color: #ffa412;
}

.buttonbox.yellow:hover .buttonboxheader .buttonboxicon {
  color: #ffa412 !important;
}

/* darkblue */

.buttonbox.darkblue:hover .buttonboxheader .buttonboxtitle {
  color: #006be4;
}

.buttonbox.darkblue:active .buttonboxheader .buttonboxtitle {
  color: #006be4;
}

.buttonbox.darkblue:hover .buttonboxheader .buttonboxicon {
  color: #006be4 !important;
}

.buttonboxbody {
  margin-top: 20px;
  color: #737791;
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 100px;
}

.buttonboxbody h2 {
  color: #151d48;
  font-size: 22px;
  font-weight: 400;
  width: 100%;
  margin-bottom: 5px;
}

.buttonboxbody p {
  margin-bottom: 20px;
  width: 100%;
}

.buttonboxlarge .buttonboxbody {
  align-items: center;
  justify-items: center;
  display: flex;
  margin-bottom: 20px;
}

.buttonboxlarge .buttonboxbody input[type="checkbox"] {
  width: 28px;
  height: 28px;
  border: 1px solid #737791;
  border-radius: 12px;
  margin-right: 20px;
}

.buttonbox.purple:hover .buttonboxbody {
  color: #444a6d;
}

.buttonbox.green:hover .buttonboxbody {
  color: #444a6d;
}

.buttonbox.yellow:hover .buttonboxbody {
  color: #444a6d;
}

.buttonbox.darkblue:hover .buttonboxbody {
  color: #444a6d;
}

#inductionsid li:hover {
  background-color: #a700ff;
}

#inductionsid li.active {
  background-color: #a700ff !important;
}

#fireid li:hover {
  background-color: #ef4444;
}

#fireid li.active {
  background-color: #ef4444 !important;
}

#foodid li:hover {
  background-color: #22c55e;
}

#foodid li.active {
  background-color: #22c55e !important;
}

#smssafetyid li:hover {
  background-color: #ffa412;
}

#smssafetyid li.active {
  background-color: #ffa412 !important;
}

#remoteid li:hover {
  background-color: #2f80ed;
}

#remoteid li.active {
  background-color: #2f80ed !important;
}

.uploaddoccontainerbottom {
  display: flex;
}
.uploaddoccontainertop {
  display: flex;
}
.uploaddoccontainerleft {
  flex: 2;
}
.uploaddoccontainerright {
  flex: 2;
}
.UploadNowForm {
  max-width: 1240px;
}

.dashboardboxsmallcontainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dashboardboxsmall {
  padding: 20px;
  /* display: flex; */
  flex-direction: column;
  align-items: center;
  justify-items: center;
  width: 100%;
  margin: 10px;
  background: #ffffff;
  display: block;
  text-align: center;

  border-radius: 10px;
}

.dashboardboxsmall img {
  width: 100%;
  max-height: 70px;
}

.dashboardboxsmall:first-child {
  margin-left: 0px;
}

.dashboardboxsmall:last-child {
  margin-right: 0px;
}

.dashboardboxsmall h2 {
  color: #151d48;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  margin: 10px 0px;
}

.dashboardboxsmalllarge {
  background: #ffffff;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 40px 0px;
  padding: 40px;
}
/* dashboard charts */
.dashboardboxsmalllarge .dashboard-charts-section {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  width: 100%;
}
.dashboardboxsmalllarge .dashboard-charts-section .dashboard-charts-inner {
  flex: 1 1 0;
  margin: 25px 0px;
}
.dashboardboxsmalllarge
  .dashboard-charts-section
  .dashboard-charts-inner
  .dashboard-charts-section
  .chartjs-render-monitor {
  width: 220px !important;
  height: 80px !important;
  margin: auto;
}
.dashboard-pie-chart .chartjs-render-monitor{
  width: 180px !important;
  height: 180px !important;
  margin: auto;
}
.dashboard-charts-section .chart-status {
  font-size: 12px;
  line-height: 14px;
  color: #a1a5b6;
  width: 100%;
  text-align: center;
  margin-bottom: 5px;
}
.chart-low-high {
  display: flex;
  width: 100%;
  margin-top: 5px;
}
.chart-low-high span {
  font-size: 10px;
  line-height: 12px;
  color: #a1a5b6;
  flex: 1;
  text-align: center;
}
.chart-title {
  margin-top: 15px;
}
.chart-title h4 {
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: #151d48;
  margin-bottom: 5px;
}
.chart-title p {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #737791;
}
/* dashboard charts */

.dashboardbox {
  background: #ffffff;
  border-radius: 10px;
  padding: 10px 20px;
  margin-bottom: 40px;
}

.dashboardboxsmallcontainerbottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.content-block-view{
  background: #ffffff;
  border-radius: 10px;
}

.dashboardboxsmallmed {
  background: #ffffff;
  border-radius: 10px;
  border-radius: 10px;
  /* display: flex; */
  margin: 25px 0px;
  width: 100%;
  padding: 40px;
}

.dashboardboxsmallmed:first-child {
  margin-right: 40px;
}

.datatablecontainer {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 12px;
  padding-bottom: 40px;
}
.datatablecontainerBox {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 12px;
  padding-bottom: 40px;
}

.safety-table .MuiDataGrid-root {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 12px !important;
}

.safety-table .MuiDataGrid-root .MuiDataGrid-columnsContainer {
  border-radius: 12px 12px 0px 0px !important;
}

.safety-table .MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer {
  padding: 10px !important;
  color: #151d48;
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
}

.safety-table .MuiDataGrid-root .MuiDataGrid-cell {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
  padding: 0px 18px !important;
  letter-spacing: 0.00938em;
  color: rgba(0, 0, 0, 0.87);
}









.inductions-table .MuiDataGrid-root {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 12px !important;
}

.inductions-table .MuiDataGrid-root .MuiDataGrid-columnsContainer {
  border-radius: 12px 12px 0px 0px !important;
}

.inductions-table .MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer {
  padding: 10px !important;
  color: #151d48;
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
}

.inductions-table .MuiDataGrid-root .MuiDataGrid-cell {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
  padding: 0px 18px !important;
  letter-spacing: 0.00938em;
  color: rgba(0, 0, 0, 0.87);
}



.datatable td {
  padding: 15px 30px;
  border-bottom: 1px solid #e2e2e2;
  border-left: 1px solid #e2e2e2;
  font-size: 16px;
}
.datatable tr.parent td {
  padding: 15px 30px;
  font-size: 18px;
}
.datatable td:first-child {
  border-left: 0px;
}
.datatable th {
  border-left: 1px solid #e2e2e2;
}
.datatable th:first-child {
  border-left: 0px;
}
.datatable tr {
}
.datatable tr.parent {
  background-color: #ffffff;
  color: #151d48;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
}
.datatable tr.parent.purple {
  background-color: #f5f3ff;
  color: #151d48;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
}
.datatable tr.parent.red {
  background-color: #FEF2F2;
  color: #151d48;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
}

.datatable tr.parent.yellow {
  background-color: #fefce8;
  color: #151d48;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
}

.datatable tr.parent td {
  padding: 10px 30px;
}
.datatable th {
  padding: 30px 30px;
  color: #151d48;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
}
.datatable th.smallHeader {
  padding: 30px 30px;
  color: #151d48;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
}
.datatable th:first-child {
  text-align: left;
}

.datatable td.extraheight {
  height: 800px;
  vertical-align: baseline;
}
.datatable.signature th:first-child {
  background-color: #F9FAFB;
  border: none;

}


.datatable.signature th {
  background-color: #F9FAFB;
  border-bottom: 1px solid #e2e2e2;

}

.datatable.signature td:first-child {
  background-color: #F9FAFB;
  border: none;

}


.datatable.signature td:first-child {
  border: none;

}

.datatable {
  width: 100% !important;
}


.datatable.grey thead tr {
  width: 100% !important;
  background-color: #F9FAFB;
  border-bottom: 1px solid #e2e2e2;
}



.datatable.grey thead tr th {
  border-bottom: 1px solid #e2e2e2 !important;
}

.datatable.grey td{
  border-left: 1px solid #e2e2e2 !important;
  border-bottom: 1px solid #e2e2e2 !important;
}

.buttoncontainers{
  display: flex;
  justify-content: space-evenly;
}


.confirmation-modal-inner{
  border-radius: 12px;
  background-color: #ffffff;
}


.confirmation-modal-inner .header-title h3{
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 28px !important;
  color: #151d48 !important;
}

.confirmation-modal-footer button.addElementIconYellow {
  text-decoration: none !important;
  background-color: #f59e0b !important;
  color: #ffffff !important;
  font-size: 18px !important;
  padding: 5px;
  border-radius: 6px;
  padding:10px 30px !important;
  border: 0px;

}

.confirmation-modal-footer button.addElementIconYellow:hover {
  opacity: 0.6;
}



.confirmation-modal-footer button.addElementIconRed {
  text-decoration: none !important;
  background-color: #EF4444 !important;
  color: #ffffff !important;
  font-size: 18px !important;
  padding: 5px;
  border-radius: 6px;
  padding:10px 30px !important;
  border: 0px;

}

.confirmation-modal-footer button.addElementIconRed:hover {
  opacity: 0.6;
}




.createentryitem {
  width: calc(100% - 20px);
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 0px;
}
.createentryitem > label {
  margin-bottom: 15px;
  font-weight: 600;
  color: #1d2740;
  font-size: 16px;
}

.createentryitem .templateButtonContainer > label {
  margin-bottom: 0px;
  font-weight: 600;
  color: #1d2740;
  font-size: 16px;
}

.createentryitem span.mandatory {
  margin-bottom: 10px;
  font-weight: 600;
  color: red;
  font-size: 16px;
}
.createentryitem > input {
  height: 50px;
  padding: 5px 5px 5px 10px;
  border: 1px solid #cfd2db;
  border-radius: 5px;
  background-color: #f9fafb;
  font-size: 16px;
  margin-right: 20px;
}





/* .createentryitem.nomarginright > select {

  margin-right: 0px !important;
} */



.createentryitem .popupDropdown{width: calc(100% - 20px);}

.createentryitem > textarea {
  height: 250px;
  padding: 5px 5px 5px 10px;
  border: 1px solid #cfd2db;
  border-radius: 5px;
  background-color: #f9fafb;
  font-size: 16px;
  width: calc(100% - 20px);
}

.nomarginright{margin-right: 0px !important;}
.margin40right{margin-right:40px !important;}
.padding40right{padding-right:40px !important;}

.newUserItem select{height:50px;padding: 5px 5px 5px 10px;
  border: 1px solid #cfd2db;
  border-radius: 5px;
  background-color: #ffffff;
  font-size: 16px;
  font-weight: 300;}



  @media only screen and (max-width: 768px){
    .makeStyles-drawerClose-7{
  
      width:48px !important;
    
    }
    .dashboardboxsmalllarge{padding:5px; margin:0px; justify-content: center;}
    #logoutid svg.MuiSvgIcon-root{margin-left:0px !important; }
    .MuiListItem-gutters{padding-left:8px !important;}

}


/* stripe  */
.paymentBody {

  height: 100vh;
  overflow-y: scroll;
  margin-top: 65px;
}
.header {
  align-items: center;
  text-align: center;
  line-height: 1.7;
}
.heading {
  font-weight: bold;
  color: #151d48;
}
.trial {
  color: #737791;
  font-size: 10.5px;
}
.paybuttons {
  display: flex;
  justify-content: center;
}
.paybutton {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  /* font-family: 'Manrope'; */
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 24px;
border: 1px solid #D8D5D6;
border-radius: 10px;
  background-color: white;
  padding: 5px;
}
.pays {
  background: #e1251b;
border-radius: 8px;
color: #ffffff;
  cursor: pointer;
  width: 150px;
height: 56px;
/* font-family: 'Manrope'; */
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 54px;
margin: 5px;
}
.pays1 {
  background: #FFEDF0;
  border-radius: 8px;
  color:  #e1251b;;
    cursor: pointer;
    width: 150px;
  height: 56px;
  /* font-family: 'Manrope'; */
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  margin: 5px;
  line-height: 54px;
}
.secondHeading {
  color: #737791;
  font-weight: 700;
  font-size: 10.5px;
}
.home-styles .items {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 40px;
  margin: 30px;
  justify-content: center;
  max-width: fit-content;
  margin: 50px auto 0px auto;
}
.home-styles .items .card:nth-child(1) {
  border: 1.5px solid #c026d3 !important;
  border-top: 20px solid #c026d3 !important;
}
.home-styles .items .card:nth-child(1) .card__footer {
  background-color: #c026d3;
}
.home-styles .items .card:nth-child(2) {
  border: 1.5px solid #3b82f6 !important;
  border-top: 20px solid #3b82f6 !important;
}
.home-styles .items .card:nth-child(2) .card__footer {
  background-color: #3b82f6;
}
.home-styles .items .card:nth-child(2) .features_list_item img {
  filter: invert(42%) sepia(77%) saturate(2657%) hue-rotate(203deg) brightness(100%) contrast(94%);
  color: #b5b3b4 !important;
}
.home-styles .items .card:nth-child(3) {
  border: 1.5px solid #fb923c !important;
  border-top: 20px solid #fb923c !important;
}
.home-styles .items .card:nth-child(3) .card__footer {
  background-color: #fb923c;
}
.home-styles .items .card:nth-child(3) .features_list_item img {
  filter: invert(60%) sepia(90%) saturate(1101%) hue-rotate(334deg) brightness(105%) contrast(97%);
  color: #b5b3b4 !important;
}
.home-styles .items .card:nth-child(4) {
  border: 1.5px solid #4f46e5 !important;
  border-top: 20px solid #4f46e5 !important;
}
.home-styles .items .card:nth-child(4) .card__footer {
  background-color: #4f46e5;
}
.home-styles .items .card:nth-child(4) .features_list_item img {
  filter: invert(23%) sepia(72%) saturate(2894%) hue-rotate(237deg) brightness(94%) contrast(92%);
  color: #b5b3b4 !important;
}
.home-styles .card {
  width: 100%;
  height: 100%;
  display: flex;
  max-width: 450px;
  border: 1.5px solid #e1251b !important;
  border-top: 20px solid #e1251b !important;
  border-radius: 15px !important;
  padding: 20px;
  background-color: white;
}
.home-styles .card__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.home-styles .card__heading {
  /* font-family: 'Manrope'; */
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  text-align: center;
  width: 100%;
  line-height: 28px;
  text-transform: uppercase;
 /* identical to box height, or 140% */
 /* Greys/Blue Grey/900 */
  color: #2c2126;
}
.home-styles .card__header p {
  font-size: 9px;
  padding: 2px;
  font-weight: 900;
  color: #151d48;
}
.home-styles .card__body__price {
  display: flex;
  justify-content: center;
  align-items: baseline;
}
.home-styles .card__body__price__heading {
  /* font-family: 'Manrope'; */
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 40px;
 /* identical to box height, or 111% */
 /* Greys/Blue Grey/900 */
  color: #2c2126;
}
.home-styles .card__body__price__amount {
  /* font-family: 'Manrope'; */
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 40px;
 /* identical to box height, or 111% */
 /* Greys/Blue Grey/900 */
  color: #2c2126;
}
.home-styles .card__body__price__duration {
  /* font-family: 'Manrope'; */
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
 /* or 156% */
 /* Greys/Blue Grey/800 */
  color: #4c4448;
}
.home-styles .card__body__discription__heading {
  /* font-family: 'Manrope'; */
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  line-height: 24px;
 /* identical to box height, or 150% */
 /* Greys/Blue Grey/700 */
  color: #62585d;
}
.home-styles .card__body__discription__heading {
  /* font-family: 'Manrope'; */
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  line-height: 24px;
 /* identical to box height, or 150% */
 /* Greys/Blue Grey/700 */
  color: #62585d;
}
.home-styles .card__body__discription__heading_sub {
  /* font-family: 'Manrope'; */
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  text-align: left;
  line-height: 24px;
 /* identical to box height, or 150% */
 /* Greys/Blue Grey/700 */
  color: #62585d;
  margin: 15px 0px;
}
.home-styles .card__footer {
  background: #e1251b;
  border-radius: 10px;
  /* font-family: 'Manrope'; */
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  display: block;
  margin: 0px auto;
  height: 56px;
  line-height: 24px;
 /* identical to box height, or 150% */
 /* Basic / White */
  color: #fff;
}
.home-styles .card__footer__button {
  /* font-family: 'Manrope'; */
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  min-width: 200px;
  width: 100%;
  background-color: transparent;
  border: 0px;
  height: 56px;
  line-height: 24px;
 /* identical to box height, or 150% */
 /* Basic / White */
  color: #fff;
  cursor: pointer;
}
.home-styles .include, .home-styles .features {
  min-height: 300px;
}
.home-styles .include__heading {
  /* font-family: 'Manrope'; */
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  text-align: left;
  line-height: 24px;
 /* identical to box height, or 150% */
 /* Greys/Blue Grey/700 */
  color: #62585d;
  margin: 15px 0px;
}
.home-styles .include__heading .features__heading {
  /* font-family: 'Manrope'; */
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  text-align: left;
  line-height: 24px;
 /* identical to box height, or 150% */
 /* Greys/Blue Grey/700 */
  color: #62585d;
  margin: 15px 0px;
}
.home-styles .include li, .home-styles .features li {
  list-style-type: none;
  line-height: 40px;
  margin: 10px auto;
}
.home-styles .include span, .home-styles .features span {
  /* font-family: 'Manrope'; */
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
 /* identical to box height, or 150% */
  color: #b5b3b4;
}
.home-styles .tick {
  margin-right: 10px;
}
.home-styles .include_list_item, .home-styles .features_list_item {
  display: flex;
  gap: 5px;
  justify-content: flex-start;
  align-items: center;
}
@media only screen and (max-width: 768px) {

  .userLoginRight{width:100%;padding: 0px 20px !important;}
  
  .home-styles .items {
    grid-template-columns: repeat(1, 1fr);
 }
  .home-styles .makeStyles-drawerClose-7 {
    width: 70px !important;
 }
  .home-styles .overview {
    padding: 90px 10px 90px 65px !important;
 }
  .home-styles .dashboardboxsmalllarge {
    padding: 5px;
    margin: 0px;
    justify-content: center;
 }
  .home-styles #logoutid svg.MuiSvgIcon-root {
    margin-left: 0px !important;
 }
  .home-styles .MuiListItem-gutters {
    padding-left: 8px !important;
 }
  .home-styles .dashboardboxsmall:first-child {
    margin-left: 10px;
 }
  .home-styles .dashboardboxsmall:last-child {
    margin-right: 10px;
 }
  .home-styles .dashboardboxsmalllarge {
    margin-right: 10px;
    margin-top: 10px;
 }
  .home-styles .dashboardboxsmallmed:first-child {
    margin-right: 10px;
    margin-top: 20px;
 }
  .home-styles .dashboardboxsmallmed {
    margin-top: 10px;
    margin-right: 10px;
 }
}
.home-styles #gSigninButton {
  width: 100% !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-styles .divider img {
  display: block;
  margin: 25px auto;
}
.home-styles .MuiPaper-elevation4 {
  box-shadow: none !important;
}
.home-styles .tooltip-trigger .tooltip {
  width: 100%;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  opacity: 0;
  transition: opacity 1s;
  position: absolute;
  z-index: 1;
}
.text-danger{
  color: #dc3545;
}
textarea{
  border: 1px solid #D2D8DA !important;
  padding: 10px;
  font-size: 16px;
  font-weight: 400;
  border-radius: 8px;
  width: 100%;
}
textarea:focus-visible{
  outline: 1px solid #D2D8DA;
}
.obj-fit-cover {
  object-fit: cover;
}

.viewBoxWrapper{margin:10px 20px;}
.viewBoxWrapper label{margin:10px 0px;}
.viewBoxWrapper .MuiFormControl-root{margin:0px 0px 20px 0px;}
.viewBoxWrapper .MuiFormControl-root label{margin:0px 0px;}

.checkboxToggle input[type="checkbox"] + span {
	display: inline-block;
	width: 40px;
	height: 20px;
	position: relative;
	background: #cb0009;
	border-radius: 20px;
}

.checkboxToggle input[type="checkbox"] + span:after {
	position: absolute;
	width: 12px;
	height: 12px;
	left: 2px;
	top: 50%;
	margin-top: -6px;
	background: #fff;
	border-radius: 50%;
	content: '';
	transition: left 0.25s;
}


.checkboxToggle input[type="checkbox"]:checked + span {
	background: #1b8756;
}

.reverseColors input[type="checkbox"]+ span {
  background: #1b8756;
}

.reverseColors input[type="checkbox"]:checked + span {
  background: #cb0009;
}

.checkboxToggle input[type="checkbox"]:checked + span:after {
	left: 25px;
}

.text-left {
  text-align: left;
}

.content-image-group--l2-2 img {
	max-width: 100%;
	height: auto;
}

.userlogin .brand-logo {
	width: 100%;
  text-align: center !important;
}

.userloginLeft img {
	width: 100%;
	height: auto;
}

.userLoginContainer {
	align-items: center;
}

@media all and (max-width: 768px) {
  .userlogin .brand-logo {
    display: none !important;
  }
}
@media all and (max-width: 767px) {
  .form_inner.table_search {
    flex-wrap: wrap;
  }

  .table_search .input_icons {
    min-width: 100%;
  }

  .table_search button[class*="btn"] {
    min-width: 100%;
    margin-left: 0 !important;
    margin-top: 10px;
  }

  .page-entries {
    flex-wrap: wrap;
    width: 100%;
    text-align: center;
  }

  .page-entries > nav {
    width: 100%;
  }

  .pagination-content {
    flex-wrap: wrap;
  }

  header.header-left {
    flex-wrap: wrap;
  }
  
  .header-left .header {
    margin-bottom: 30px;
    order: -1;
  }

  .main_container .col-2,
  .main_container .col-3,
  .main_container .col-5,
  .main_container .col-6,
  .main_container .col-7,
  .main_container .col-8,
  .main_container .col-4 {
    min-width: 100%;
  }
  
  .table_search button:not([class]) {
    display: none;
  }
  
  ul.pagination .page-item {
    width: 100%;
  }
  
  ul.pagination .page-item .page-link {
    width: 100%;
  }

  header.header-left > .d-flex > div {
    width: 100%;
    margin-left: -40px;
    width: calc(100% + 40px);
  }

  .submit_accident .breadcrumb {
    font-size: 11px;
  }

  .d-flex.dl-access {
    flex-wrap: wrap;
  }

  ul.pagination.page-item {
    width: 100%;
  }
  
  .pagination-content nav {
    width: 100%;
    text-align: center;
  }
  
  ul.pagination .page-item:not(:first-child):not(:last-child) {
    display: none;
  }
}

.preview-upload-files div img {
  position: static;
}

/* Inspections */
.inspection-response-definitions {
	font-size: 12px;
  line-height: 1.2;
}

.inspection-tag {
	display: inline-flex;
	background: #333;
	color: #fff;
	border-radius: 10px;
	padding: 4px 8px;
	font-size: 12px;
}

.inspection-response-definitions > .d-flex {
	margin-bottom: 8px;
	align-items: center;
  padding-bottom: 6px;
}

.inspection-tag.inspection-tag-urgent {
	background: #e13a45;
}

.inspection-tag.inspection-tag-immediate {
	background: #f18f27;
}

.inspection-tag.inspection-tag-asap {
	background: #2860fa;
}

.inspection-tag.inspection-tag-on-going {
	background: #16e6eb;
}

.inspection-tag-good-practice {
  background-color: #198754;
}

.inspection-tag-left {
  width: 90px;
  min-width: 90px;
  max-width: 90px;
}

.add-new-issue-button .btn {
  height: initial;
  margin-top: 14px;
}
.inspection-issue-row {
	display: flex;
	flex-wrap: wrap;
	border: 1px solid #ddd;
	margin-top: -1px;
	align-items: center;
}

.inspection-issue-number {
	width: 100px;
	position: relative;
}

.inspection-issue-risk-factor {
  width: 80px;
}

.inspection-issue-hazard, .inspection-issue-resp-required,.inspection-issue-risk-factor {
	width: calc( ( 100% - 290px ) / 3 );
}

.inspection-issue-row > div {
	padding: 5px 8px;
}

.inspection-issue-row.inspection-issue-row-header {
	font-weight: 600;
}

.inspection-issues {
	font-size: 13px;
}

.inspection-issue-item:nth-child(2n) .inspection-issue-row {
	background: #f0f0f0;
}

.inspection-issue-toggle {
	position: absolute;
	width: 18px;
	height: 18px;
	right: 0;
	top: 4px;
	border: 1px solid #ddd;
}

.inspection-issue-toggle span {
	width: 6px;
	height: 6px;
	border-right: 2px solid #333;
	display: block;
	border-bottom: 2px solid #333;
	position: absolute;
	top: 31%;
	left: 48%;
	transform: rotate(45deg) translate(-50%, 12%);
}

.inspection-issue-item.active .inspection-issue-toggle {
	transform: rotate(-180deg);
}

.inspection-issue-details-basic strong {
  display: block;
}

.inspection-issue-details {
	border: 1px solid #ddd;
	margin: 10px 0;
	padding: 10px;
}

.inspection-issue-details-item {
	margin-bottom: 20px;
}

.inspection-issue-details-files {
  width: 100%;
  padding: 10px;
  background: #f0f0f0;
}

.inspection-issue-details {
  display: none;
}

.inspection-issue-item.active .inspection-issue-details {
  display: block;
}

.popup-inner {
	max-height: calc(100vh - 30px);
	overflow-y: auto;
}

.inspection-issue-details-files-grid .image-fixed {
	width: 70px;
}

.inspection-issue-details-files-grid .image-fixed img {
	width: 100%;
	height: auto;
}

.inspection-score {
	background: #e2e6ea;
	border-radius: 8px;
	text-align: center;
	padding: 5px;
	margin-bottom: 10px;
	font-size: 14px;
  color: #fff;
}

.red-text {
  color: #e03949;
}

.green-text {
  color: #02b602;
}

.complete-inspection {
  text-align: right;
}
.complete-inspection .btn {
  height: auto;
  display: inline-flex;
  align-items: center;
}

.add-issue-popup .input_field {
  text-align: left;
}

.pdf-download-btn {
  width: auto !important;
  height: 40px;
}

.downloadable-qr-code {
  padding: 15px;
  border: 1px solid #ddd;
  margin: 20px 0;
}

.qr-code-popup-actions .btn {
  margin: 0 5px 10px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-20 {
  margin-bottom: 20px;
}

.justify-center {
  justify-content: center;
}

.flex-spaced {
  justify-content: space-between;
}

.downloadable-qr-code {
  max-width: 360px;
}

.qr-code-header .qr-code-logo {
	width: 50%;
	max-width: 100px;
}

.qr-code-header .qr-code-logo img {
	width: 100%;
	height: auto;
}

.qr-code-header {
	align-items: center;
	margin-bottom: 14px;
}

.qr-code-footer {
  text-align: center;
  font-size: 12px;
  padding: 10px 0 0;
}

.qrcode-download-bt .btn {
  display: inline-flex;
}

.downloadable-qr-code canvas {
  max-width: 100% !important;
  height: auto !important;
  aspect-ratio: 1/1;
}

.send-password-reset {
  border: none;
  background: #f3f3f3;
  border-radius: 6px;
  padding: 4px 8px;
}

.send-password-reset:hover {
  background-color: #ddd;
}

.popup-warning-with-border {
  border: 1px solid #ddd;
  margin: 30px 0;
  padding: 10px;
}

.popup-warning-notice p {
	color: #cb0009;
	font-weight: bold;
}

.rdt_TableHeadRow .rdt_TableCol {
  font-size: 16px;
	font-weight: 500;
	color: #B3B7BB;
	padding: 15px 0;
}

.rdt_TableBody .rdt_TableCell {
  font-size: 16px;
	font-weight: 400;
	color: #2D3648;
	padding: 15px 5px 15px 0;
}

.rdt_Pagination {
  font-size: 16px;
}

.dt-sort-icon {
  width: 12px;
  height: 12px;
  background: url('images/up-down-arrow.svg') no-repeat center center;
  background-size: contain;
  display: inline-block;
  margin: 0 4px;
}

.lists-id-col {
  cursor: pointer;
  color: #005CB9;
  width: 100%;
  display: inline-block;
}

.list-pdf-download-btn {
  font-size: 16px;
  font-weight: 400;
  border: 1px solid #3D4143;
  color: #3D4143;
  border-radius: 8px;
  padding: 14px 30px;
  background: white;
  margin-left: 10px;
}

.rdt_Pagination button:not(:disabled) {
	color: #005CB9;
	fill: #005CB9;
}

.popup .popup-inner {
  max-height: calc(100vh - 30px);
  overflow-y: auto;
}

.popup.wide-popup .popup-inner {
	max-width: 1340px;
}

.dig-approval-form-wrapper {
  margin-bottom: 20px;
}

.dig-approval-form-wrapper .inspected_radio_btn > div {
  margin: 0;
}

.top-notification-bar {
	background: #c1d9f1;
	border-radius: 5px;
	padding: 4px 16px;
	margin: 0 0 16px;
}

.top-notification-bar a:hover {
	color: #000;
}

.return-to-site-message {
	font-size: 14px;
}

.return-to-site-message p {
	color: #000;
}

.company-actions-col button {
  margin-left: 10px;
}

.password-with-visibility .input-group-addon a {
  background-color: #DDEEF4;
  padding: 5px 10px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.password-with-visibility .input-group-addon {
  display: flex;
}

.manageable-list {
  padding: 12px 20px !important;
}

.manageable-list-item {
  border: 1px solid #f3f3f3;
  margin: 10px 0;
  padding: 10px;
  background-color: #fff;
  border-radius: 8px;
}

.manageable-list-item .manangeable-list-item-delete {
  cursor: pointer;
  margin-left: 14px;
  color: rgb(13,110,253);
  font-size: 12px;
}

.list-add-btn {
  padding: 20px 10px 10px 30px !important;
  text-align: right;
}

.risk-matrix-table td {
  text-align: center;
  border: 1px solid #ddd;
  padding: 5px;
  font-size: 13px;
}
.risk-matrix-table td strong {
  display: block;
}

.risk-matrix-table tr:first-child td:first-child {
  border-bottom: none;
}
.risk-matrix-table tr:nth-child(1) td:nth-child(2) {
  text-align: left;
}
.risk-matrix-table tr:nth-child(2) td:first-child {
  border-top: none;
}

.risk-negligible {
  background-color: #fff;
}

.risk-tolerable {
  background-color: #198754;
  color: #fff;
}

.risk-undesirable {
  background-color: #f59e0b;
}

.risk-intolerable {
  background-color: #dc3545;
  color: #fff;
}

.no-questions-found {
  padding: 10px 10px 20px;
}

.no-questions-found h4 {
  font-size: 18px;
}

.add-training-question {
  border-top: 1px solid #ddd;
  padding-top: 14px;
  display: flex;
  justify-content: flex-end;
}

.add-training-question button {
  height: auto;
  padding: 10px 0;
}

.answers-choice-item {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.answers-choice-item label {
  display: block;
  width: 40px;
  height: 40px;
  position: relative;
  cursor: pointer;
}

.answers-choice-item label input {
  width: 0;
  height: 0;
  opacity: 0;
}

.answers-choice-item input + span {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #aaa;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  transform: scale(0.6);
  border-radius: 50%;
}

.answers-choice-text {
  width: calc(100% - 40px);
}

.answers-choice-item input + span:before {
  display: block;
  width: 100%;
  height: 100%;
  background: #aaa;
  position: absolute;
  content: '';
  border-radius: 50%;
  transform: scale(0);
  transition: transform 0.25s;
}

.answers-choice-item input:checked + span:before {
  transform: scale(0.75);
}

.training-question {
  display: block;
  border: 1px solid #ddd;
  margin: 16px 0;
}

.training-questions-header {
  background: #ddd;
  padding: 10px;
}

.training-questions-header button {
  cursor: pointer;
  border: none;
  background: none;
}

.training-questions-answer {
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.training-questions-answer:last-child {
  border: none;
}

.training-questions-answer.correct-answer {
  background: #2a875426;
}

.training-stats {
  display: flex;
  flex-wrap: wrap;
}

.training-stat {
  display: block;
  width: 24%;
  border: 1px solid #ddd;
  margin: 10px 0.5% 10px;
  padding: 10px;
  text-align: center;
  background: #f1f1f1;
}

.training-stat > span {
  display: block;
}

@media all and (min-width: 851px) and (max-width: 1300px) {
  .inspection-issue-row {
    min-width: 800px;
  }
  
  .inspection-issues {
    overflow-x: auto;
  }

  .assessments-main-container .inspection-issues {
    min-height: 430px;
    border: 1px solid #ddd;
  }

  .assessments-main-container .inspection-issue-row {
    min-width: 990px;
  }
}
@media all and (max-width: 850px) {
  .main_container.inspections-main-container {
    padding: 5px 0;
  }
  
  .inspection-issue-row {
    flex-wrap: wrap;
    margin-top: 20px;
  }
  
  .inspection-issue-row > div {
    width: 100%;
  }
  
  .inspection-issue-number:before {
    content: '#';
  }
  
  .inspection-issue-row.inspection-issue-row-header {
    display: none;
  }
  
  .inspection-issue-toggle {
    right: 8px;
  }
  
  .inspection-issue-details-item {
    width: 100%;
  }
  
  .inspection-issue-details-files {
    flex-wrap: wrap;
  }
  
  .inspection-issue-details-files > div {
    margin-bottom: 20px;
  }
  
  .inspection-issue-actions {
    order: -1;
  }
  
  .inspection-issue-row > div.inspection-issue-number {
    order: -1;
    width: calc(100% - 200px);
  }
  
  .inspection-issue-row > div.inspection-issue-actions {
    width: 180px;
    text-align: right;
  }
  
  .inspection-issue-actions .btn {
    padding: 5px;
    min-width: inherit;
    font-size: 12px;
  }
}

.checklists-question-text {
  width: calc(100% - 240px);
}

.checklist-question-other-detail-item {
  padding: 5px;
  background: #f0f0f0;
  width: 100%;
  display: block;
  mix-blend-mode: multiply;
  margin: 10px 0 0 10px;
  font-size: 14px;
}

.checklist-question-other-detail-item > div:first-child {
  font-weight: 500;
}

.checklist-question-closure-file {
  display: flex;
  flex-wrap: wrap;
}

.score-status {
  padding-left: 5px;
}

span.score-status.score-status-pass {
  color: #00a944;
}

span.score-status.score-status-fail {
  color: #cb0009;
}

button.btn.user-switch-admin {
  padding: 5px;
  width: auto;
  min-width: auto;
  height: auto;
  margin-left: 5px;
}


.is-answer-checked.is-answer-correct {
  background: #2a875426;
}
.is-answer-checked.is-answer-incorrect {
  background: #cb000926;
}

span.all-deps-name {
  display: block;
  width: 100%;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 8px;
  opacity: 0.7;
}

.training-take-action {
  margin-top: 30px;
}


.training-enrollment-status {
  text-align: center;
  padding: 5px;
  margin-bottom: 10px;
  border-radius: 5px;
  background: #cb0009;
  color: #fff;
}
.training-enrollment-status[data-type="Completed"] {
  background: #005cb9;
}


.training-action-taken {
  border: 1px solid #ddd;
  margin-top: 30px;
  border-radius: 8px;
  padding: 10px;
}
.training-action-comment > strong {
  display: block;
}
.training-action-signature > strong {
  display: block;
}
.training-action-signature {
  margin-top: 30px;
}
.training-action-signature img {
  border: 1px solid #ddd;
  margin-top: 6px;
}


.training-open-btn {
  background: #005cb9;
  color: #fff;
  white-space: nowrap;
  border-radius: 4px;
  padding: 5px 10px;
  cursor: pointer;
  transition: background 0.2s;
}

.training-open-btn:hover {
    background: #00458a;
}

.comments-length-note {
  text-align: right;
  color: #aaa;
}

.save-progress-button {
  position: fixed;
  bottom: 0;
  right: 0;
  background: #fff;
  text-align: right;
  padding: 10px 15px;
  border-top: 1px solid #ddd;
  border-left: 1px solid #ddd;
}

.save-progress-button .btn {
  min-width: 100%;
}

@media all and (max-width: 992px) {
  .checklists-question {
    flex-wrap: wrap;
  }

  .checklists-question-text {
    width: 100%;
  }

  .checklists-question-radios {
    width: 100%;
    margin-top: 15px;
  }

  .checklist-question-other-detail-item {
    margin: 5px 0 0;
  }
}

.rdt_Table input[type="checkbox"] {
  display: initial;
}

.select-all-type {
  margin-top: -50px;
  font-size: 14px;
}