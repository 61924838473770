.checklists-section {
	margin: 20px 0;
}

.checklists-section-title {
	font-weight: 500;
}

.checklists-question {
	display: flex;
	justify-content: space-between;
	padding: 10px;
	align-items: flex-start;
}

.checklists-question:nth-child(2n) {
	background: #f3f3f3;
}

.section-score {
	display: inline-block;
	margin: 10px;
	padding: 4px;
	border-radius: 4px;
	color: #fff;
}