html {
  background-color: #e2e6ea;
}

* {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif !important;
}

a {
  text-decoration: none !important;
}

.sidebar {
  height: 100vh !important;
  overflow: auto;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  transition: left 0.5s ease;
}

.right_nav > div {
  flex-shrink: 1;
}
.right_nav .notification {
  width: 30px;
  height: 30px;
}

.middle_page {
  position: absolute;
  top: 0;
  left: 334px;
  right: 0;
  bottom: 0;
  transition: all 0.5s ease;
  background-color: #e2e6ea;
  width: calc(100% - 334px);
  padding: 18px 20px;
  height: max-content;
}

input[type="checkbox"] {
  display: none;
}

input:checked ~ .middle_page {
  left: 0;
  width: 100%;
}

input:checked ~ .sidebar {
  display: none;
}

/* input:checked ~ .sidebar{
  display: none;
} */
input:checked ~ #toggler {
  left: 0;
}

#toggler {
  z-index: 2;
  position: absolute;
  top: 0;
  left: 334px;
  transition: left 0.5s ease;
  padding-top: 25px;
  padding-left: 20px;
}

/* Header */
.header-left {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
}
.table tbody tr th {
  color: black;
}

.header .logo-div {
  display: none;
}

.header .second_logo {
  background-color: white;
  border-radius: 8px;
  padding: 5px;
  width: fit-content;
}
.second_logo img {
  height: 43px;
  width: auto;
  max-width: 200px;
}


.header .notification {
  margin-right: 16px;
}

.header .notify_div img {
  position: relative;
  justify-content: center;
  display: flex;
  align-items: center;
}

/* .notify_popup{
  position: absolute;
} */
.notify_popup p {
  color: #75797b;
}
.notify_popup h4 {
  color: #000;
}

.header-left .header {
  width: 60%;
  justify-content: space-between;
}
.view_user_content > div {
  width: 49.5%;
  border: 1px solid #f2f2f2;
  margin-bottom: 20px;
  padding-bottom: 20px;
}
.statistic-table {
  width: 48%;
  border-style: hidden;
}
.statistic-table td {
  text-align: left;
  border-right: 1px solid #cfcbcb6e;
  border-left: 1px solid #cfcbcb6e;
  padding-left: 7px !important;
  padding-right: 17px !important;
  /* text-align: center; */
}
.statistic-table th {
  text-align: right;
}

.breadcrumb-item + .breadcrumb-item::before {
  content: url("../images/right-arrow.svg");
  width: 7.2px;
  height: 12px;
  margin-right: 10px;
  margin-top: 2px;
}
.view_site_button {
  /* background-color: #8481bb; */
  background-color: #005cb9;
  border: none;
  padding: 10px 20px;
  border-radius: 3px;
}
@media screen and (min-width: 1750px) {
  .header-left .header {
    width: 50%;
  }
}

@media screen and (max-width: 1500px) {
  .header-left .header {
    width: 70%;
  }
}

.header-left .header .header-saftey {
  position: relative;
  justify-content: center;
}
.notify_popup p button{margin-left:20px;}
.safaty_popup,
.notify_popup {
  position: absolute;
  border-radius: 16px;
  width: 360px !important;
  max-height: 240px !important;
  display: flex;
  flex-direction: column;
  background-color: white;
  overflow: auto;
  top: 75px;
  z-index: 100;
  /* left: -5px; */
  justify-content: space-around;
  box-shadow: 0px 50px 80px rgba(19, 77, 135, 0.2);
}

.safaty_popup{
  height: 240px!important;
}

.overlay-popup {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
}

.notify_popup {
  padding: 10px;
  align-items: flex-start !important;
  justify-content: unset !important;
  z-index: 1;
}

.notify_popup hr {
  border-top: 0.58px solid#D2D8DA;
  width: 100%;
  margin-bottom: 8.9px;
  margin-top: 8.9px;
}

.notify_popup h4 {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 8.9px;
  margin-top: 10px;
}

.notify_popup p {
  font-size: 14px;
  display: flex;
  align-items: center;
  margin-bottom: 8.9px;
  color: #75797b;
}

.notify_popup p img {
  margin-right: 5px;
}

.notify_popup h6 {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  font-size: 14px;
  font-weight: 400;
  margin-top: 15px;
}

.safaty_popup .profile {
  background: #e1eefa;
  color: #4f90d2;
}

.safaty_popup .logout {
  background: transparent;
  color: #eb5757;
}

.safaty_popup button {
  padding: 4px 8px;
  border-radius: 4px;
  border: none;
  font-size: 17px;
  font-weight: 400;
}

.safaty_popup button img {
  margin-right: 4px;
}

.header-left section {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-end;
}

.header-left span {
  margin-left: 10px;
  font-size: 16px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}

.submit_accident {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
}

.submit_accident h6 {
  font-size: 18px;
  font-weight: 500;
}

.submit_accident .breadcrumb p {
  font-size: 16px;
  font-weight: 400;
  padding-right: 10px;
}

.breadcrumb {
  margin-bottom: 0px !important;
}

.submit_accident .breadcrumb p span {
  color: #5a5d5e;
  font-weight: 600;
}

.main_container {
  padding: 24px;
  background-color: white;
  border-radius: 8px;
}

.main_container .legal {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  width: 100%;
  text-align: right;
}

.welcome {
  background: #f0fff6;
  width: 100%;
  padding: 10px;
  display: flex;
  align-items: center;
  margin-top: 20px;
  justify-content: space-between;
}

.accident {
  width: 100%;
  padding: 10px;
  display: flex;
  align-items: center;
  margin-top: 20px;
  justify-content: space-between;
  background: #e1eefa;
  margin-bottom: 30px;
}

.accident .accident_text {
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
  color: #1d2124;
}

.accident i {
  padding-left: 10px;
}

.welcome .welcome_text {
  display: flex;
  align-items: center;
}

.welcome .cross_icon {
  color: red;
  padding-left: 10px;
}

.welcome span i {
  color: #27ae60;
}

.welcome h5 {
  font-size: 16px;
  font-weight: 500;
  color: #27ae60;
  margin-left: 16px;
}

.main_container h6 {
  font-size: 18px;
  font-weight: 600;
  color: #1d2124;
  margin: 28px 0;
}

.main_container form {
  display: flex;
  align-items: center;
}

.main_container form label {
  font-size: 16px;
  font-weight: 500;
  margin-right: 10px;
}

.main_container form input,.main_container form select {
  border: 1px solid #d2d8da;
  padding: 10px;
  font-size: 16px;
  font-weight: 400;
  border-radius: 8px;
  width: 13%;
}

.small_cards {
  display: flex;
  align-items: stretch;
  margin-top: 20px;
  justify-content: flex-start;
  flex-wrap: wrap;
  /* flex: 0 0 24%; */
}

.small_cards .green_card,
.small_cards .orange_card,
.small_cards .pink_card,
.small_cards .blue_card {
  display: flex;
  flex: 0 0 24%;
  min-width: 24%;
  flex-direction: column;
  /* flex-grow: 1; */
  transition: all 0.5s;
}

@media screen and (max-width: 1556px) {
  .small_cards .green_card,
  .small_cards .orange_card,
  .small_cards .pink_card,
  .small_cards .blue_card {
    flex: 0 0 33%;
  }
}

@media screen and (max-width: 1250px) {
  .small_cards .green_card,
  .small_cards .orange_card,
  .small_cards .pink_card,
  .small_cards .blue_card {
    flex: 0 0 50%;
  }
  .main_container form input {
    width: 25%;
  }
}
@media screen and (max-width: 1111px) {
}

/* .small_cards {
} */

.small_cards .small_card_1 {
  height: 100%;
  padding: 20px;
  display: flex;
  border-radius: 16px;
  margin: 5px 8px;
}

.small_cards .small_card_1 span {
  padding: 24px;
  border-radius: 8px;
  display: flex;
  align-items: center;
}

.small_cards .green_small {
  background-color: #e2fff3;
}

.small_cards .green_small span {
  background: #3cd856;
}

.small_cards .orange_card span {
  background: #ffa412;
}

.small_cards .orange_small {
  background: #fef6e6;
}

.small_cards .blue_card span {
  background: #a700ff;
}

.small_cards .blue_small {
  background: #fbf1ff;
}

.small_cards .pink_card span {
  background: #ff74c4;
}

.small_cards .pink_small {
  background: #fff1fe;
}

.small_cards .small_card_1 .card_content {
  padding-left: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.small_cards .small_card_1 .card_content h5 {
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  color: #1d2124;
}

.small_cards .small_card_1 .card_content p {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #3d4143;
}

.small_cards .small_card_1 .card_content a {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #005cb9;
}

.small_cards .small_card_1 .card_content a i {
  color: #005cb9;
  font-weight: 500;
}

.main_page .sidebar {
  width: 334px;
  background: #fff;
}

.main_page {
  display: flex;
}

.main_page .sidebar {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: max-content;
}

.main_page .sidebar .logo {
  padding: 10px 0;
}

.main_page .sidebar .logo img {
  width: 184px;
  height: 81px;
}

.main_page .sidebar ul {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: 0;
}

.main_page .sidebar ul li {
  list-style-type: none;
}

.sidebar .first_menu {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  align-items: center;
  background-color: rgba(2, 93, 186, 0.12);
  margin-bottom: 15px;
  padding-left: 16px;
}

.sidebar .first_menu a {
  color: #005cb9;
  font-size: 14px;
  font-weight: 500;
}

.second_menu {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.second_menu div {
  padding-bottom: 15px;
}

.second_menu div img {
  margin-right: 20px;
}

.second_menu_head {
  padding: 0 16px !important;
}

.second_menu_head li {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding-bottom: 15px;
}

.second_menu_head li .second_menu div a {
  color: #5a5d5e !important;
  font-weight: 400;
}

.third_menu span {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #a8acae;
  margin-right: 8px;
}

.third_menu li {
  display: flex;
  flex-direction: row;
  padding-bottom: 16px;
  padding-left: 30px;
  justify-content: flex-start;
  align-items: center;
}

.third_menu li a {
  font-size: 14px;
  font-weight: 400;
  color: #a8acae !important;
}

.company_ovrviw {
  display: flex;
}

.company_table {
  margin-top: 20px;
}

.company_container {
  padding: 24px;
  background-color: white;
  border-radius: 8px;
  width: 100%;
  margin-left: 23px;
}

.company_container_tab {
  padding: 24px;
  background-color: white;
  border-radius: 8px;
  width: 100%;
  margin-left: 23px;
  height: fit-content;
}

.company_container img {
  border-style: dashed;
  height: 150px;
  width: 150px;
  border: 2px dashed #d6cfcf;
  object-fit: contain;
}

@media only screen and (max-width: 768px) {
  .main_container {
    flex-direction: column;
  }

  .small_cards {
    flex-direction: column;
  }

  .small_cards .green_card,
  .small_cards .orange_card,
  .small_cards .blue_card,
  .small_cards .pink_card {
    width: 100%;
  }
  /* input:checked~.middle_page {
    display: block;
  
  }
  .main_page .sidebar{
    width: 100%;
  }
  .middle_page{
    display: none;
  }
  #toggler{
    transition: 0s;
  } */
  input:not(:checked) ~ .middle_page {
    display: block;
    width: 100%;
    left: 0;
  }

  input:checked ~ .middle_page {
    display: none;
  }
  input:not(:checked) ~ .sidebar {
    display: none;
  }
  input:checked ~ .sidebar {
    display: flex;
  }
  .main_page .sidebar {
    width: 100%;
  }
  /* .middle_page{
    display: none;
  } */
  #toggler {
    transition: 0s;
  }
  input:checked ~ #toggler {
    /* left: 334px; */
    left: 87vw;
    margin-top: 15px;
  }
  input:not(:checked) ~ #toggler {
    left: 0;
  }
  .sidebar .logo {
    margin-right: auto;
    margin-left: 20px;
  }
}

@media only screen and (max-width: 768px) {
  .main_container form input {
    width: 100%;
  }
  .main_container form {
    flex-direction: column;
    align-items: flex-start;
  }

  /* .header .notification{
  width: 30px !important;
  height: 30px !important;
} */
}

/* Page 2 */
.input_icons {
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: flex-end;
}

.input_icons img {
  position: absolute;
  padding: 10px;
}

.input_field {
  width: 100%;
  text-align: center;
}

.form-1 {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}

.form-1 .form {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
}

.form_inner,
.desc_form {
  display: flex;
  width: 32%;
  flex-direction: column;
}

.form_inner label {
  font-size: 16px;
  font-weight: 500;
}

.form_inner .input_icons input,
.desc_form .form_inner {
  width: 100%;
  outline: none;
}

.form_inner select,
.form-1 .form .desc-area textarea {
  border: 1px solid #d2d8da;
  padding: 10px;
  font-size: 16px;
  font-weight: 400;
  border-radius: 8px;
}

.form-1 .form .desc-area {
  width: 66%;
}

.form-1 .form .desc-area textarea {
  height: 100%;
}

.permission-container {
  width: 33%;
}

/* .upload_file{
  margin-bottom: ;
} */
.upload_file button {
  padding: 14px 60px;
  font-size: 16px;
  font-weight: 400;
  color: white;
  background: #005cb9;
  border-radius: 8px;
  outline: none;
  border: none;
  display: flex;
  align-items: center;
}

.upload_file button img {
  margin-right: 10px;
}

.upload_file h6 {
  margin: 20px 0 10px 0;
}

.signature {
  display: flex;
  flex-wrap: wrap;
}

.signature .sign_content {
  display: flex;
  flex-direction: column;
  margin-right: 40px;
  margin-bottom: 20px;
}

.signature .sign_content .sign_file {
  position: relative;
  background: #f3f5f6;
  border-radius: 8px;
  width: 160px;
  height: 190px;
}

.signature .sign_content .sign_file img {
  position: absolute;
  right: -14px;
  top: -12px;
}

.sign_btns {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sign_btns div {
  display: flex;
  align-items: center;
}

.sign_btns div button {
  padding: 14px 30px;
  margin-right: 20px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 400;
}

.sign_btns div .submit {
  background-color: #005cb9;
  border: none;
  color: white;
}

.sign_btns div .reset {
  border: 1px solid #3d4143;
  color: #3d4143;
  background: transparent;
}

.sign_btns div .cancel {
  border: 1px solid #f87171;
  color: #f87171;
  background: transparent;
}

@media only screen and (max-width: 745px) {
  .header-left {
    justify-content: space-between;
  }

  .header .logo-div {
    display: block;
  }

  .second_logo {
    display: none;
  }
  .second_logo img {
    height: 20px;
    width: 91px;
  }

  .header-left .header {
    width: 100%;
    justify-content: space-between;
    padding-left: 25px;
    flex-direction: row-reverse;
  }

  /* .header-left div{
  width: 30% !important;
} */
  /* .header-left .header .header-saftey .safaty_popup{
  left: -142px;
} */
  #toggler {
    padding-top: 20px;
  }

  .submit_accident {
    flex-direction: column-reverse;
    align-items: flex-start;
  }

  .submit_accident .breadcrumb p {
    font-size: 12px;
    color: #a8acae;
  }

  .submit_accident .breadcrumb {
    padding: 10px 0;
  }

  .main_container .legal {
    font-size: 14px;
    color: #3d4143;
    text-align: left;
  }

  .accident .accident_text {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
  }

  .header-left .safety_span {
    display: none;
  }

  .right_nav {
    width: 14% !important;
  }
  .right_nav .notification {
    width: 24px;
    height: 24px;
  }
  .right_nav .safty-icon {
    width: 35px;
    height: 35px;
  }
  .right_nav .safty-icon {
    width: 32px;
    height: 32px;
  }

  .header-left .header .header-saftey {
    justify-content: right;
  }

  .form_inner,
  .desc_form {
    width: 100%;
  }

  .input_icons,
  .form_inner select,
  .form-1 .form .desc-area textarea {
    margin-bottom: 20px;
    margin-top: 10px;
  }

  .form-1 .form {
    flex-direction: column;
  }

  .form-1 .form .desc-area {
    width: 100%;
  }

  .desc-2-form {
    flex-direction: column-reverses;
  }

  .sign_btns {
    align-items: flex-start;
    flex-direction: column;
  }

  .sign_btns div {
    flex-wrap: wrap;
  }

  .sign_btns div button {
    margin-bottom: 20px;
  }

  .form-1 .form .desc-area textarea {
    height: 100px;
  }

  .notify_popup p,
  .notify_popup h6,
  .notify_popup h4 {
    font-size: 12px;
  }
}

/* @media only screen and (max-width: 745px){
  
} */
.table_search {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.table_search .input_icons {
  position: relative;
  width: 25%;
}

.table_search .input_icons img {
  left: 0;
}

.table_search .input_icons input {
  border: 1px solid #d2d8da;
  padding: 10px;
  font-size: 16px;
  font-weight: 400;
  border-radius: 8px;
  padding-left: 35px;
}

.table_search button {
  font-size: 16px;
  font-weight: 400;
  border: 1px solid #3d4143;
  color: #3d4143;
  border-radius: 8px;
  padding: 14px 30px;
  background: white;
  margin-left: 10px;
}

.table_search .GA2-btn {
  background-color: #005cb9;
  color: white;
  border: none;
}

.table > :not(:first-child) {
  border-top: 1px solid #e8eeec !important;
}

.table thead tr th {
  font-size: 16px;
  font-weight: 500;
  color: #b3b7bb;
  padding: 15px 0;
}

.table tbody tr td {
  font-size: 16px;
  font-weight: 400;
  color: #2d3648;
  padding: 15px 0;
}

.table tbody tr th {
  color: #2f7fd1;
  font-size: 16px;
  font-weight: 400;
  vertical-align: middle;
}

.table .green-text {
  color: #27ae60;
}

.pagination-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.sub-permission-check:checked {
  background-color: #023c77 !important;
  border-color: #023c77 !important;
}

.listing-buttons {
  display: flex;
  align-items: center;
}