.form_inner{
    flex:0 0 100% 
}

#grid-view>div{
    flex: 49%;
    flex-grow: 0;
}


.popup {
    z-index: 5 !important;
}

@media (max-width: 1024px) {
    #grid-view > div {
      flex: 100% 1;
      flex-grow: 0;
    }
}