.addfirecheck_first_container {
  background-color: white;
  border-radius: 8px;
  border: 1px solid #e2e6ea;
}

.general_details {
  margin: auto;
  border: 1px solid #e2e6ea;
  padding: 10px;
  background-color: #e2e6ea;
}

.general_details_fields {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.redText{color: red;}
.textArea{margin:10px;}
.addfirecheck_second_container {
  background-color: white;
  border-radius: 8px;
  border: 1px solid #e2e6ea;
  margin-top: 20px;
}

.restriction_radio_btn {
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

.defects_found_radio_btn {
  justify-content: space-between;
  padding: 10px;
}

.addfirecheck_third_container {
  background-color: white;
  border-radius: 8px;
  border: 1px solid #e2e6ea;
  margin-top: 20px;
}

.addfirecheck_fourth_container {
  background-color: white;
  border-radius: 8px;
  border: 1px solid #e2e6ea;
  margin-top: 20px;
}

.addfirecheck_fifth_container {
  background-color: white;
  border-radius: 8px;
  border: 1px solid #e2e6ea;
  margin-top: 20px;
}

.addfirecheck_sixth_container {
  background-color: white;
  border-radius: 8px;
  border: 1px solid #e2e6ea;
  margin-top: 20px;

  label {
    font-size: 12px;
    color: red;
  }
}

.location_firecheck input {
  border: 1px solid #d2d8da;
  padding: 10px;
  font-size: 16px;
  font-weight: 400;
  border-radius: 8px;
  width: 100%;
}

.sign_image {
  margin-top: 25px;
  width: 263px;
  height: 270px;
  padding: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.sign_image  canvas{
  border: 1px solid #d0cbcb;
  /* margin-top: 12px; */
  width: 263px;
  height: 270px;
}

.type_of_firecheck_checkbox {
  display: flex;
  justify-content: space-between;
  padding: 15px;
}

.inspected_radio_btn {
  display: flex;
  flex-wrap: wrap;
  margin-right: 10px;
  margin-left: 10px;

  div {
    width: 300px;
    margin: 5px;
  }
}

.Inspected_radio_second {
  display: flex;
  justify-content: start;
  padding: 10px;
  justify-content: flex-start;
}

.inspected_label {
  /* font-size: 10px; */
}


.remove_files label {
  background-color: #b62c38!important;
}

.select_files label {
  background: #005CB9;
  margin: 10px;
  border-radius: 5px;
  padding: 8px;
  color: white;
  font-size: small;

  img {
    width: 20px;
    margin-right: 8px;
   
  }
}

.select_all_files {
  font-weight: bold;
  margin-left: 10px;
  color: #898888;
}

.hold_down {
  margin-left: 10px;
  color: gray;
}

.submit_btn {
  background: #27c627;
  font-size: small;
  padding: 5px;
  color: white;
  border-style: none;
  border-radius: 5px;
}

.reset_btn {
  padding: 4px;
  font-size: small;
  margin-left: 5px;
  margin-right: 5px;
  border: 2px solid gray;
  border-radius: 5px;
}

.cancel_btn {
  background: none;
  border-style: none;
  margin-left: 5px;
}

.sign_save_reset_btn {
  margin-top: 20px;

  .save_btn {
    margin-right: 10px;
    border-radius: 10px;
    padding: 3px;
  }
  .clear_btn {
    border-radius: 10px;
    padding: 3px;
  }
}

.require-label{

  font-weight: 500;
}
.cancel-custom{
  position: absolute;
  top: -5%;
  right: -5%;
  font-size: 27px;
}