/* .sub-permission-check:checked{
    background-color: #005cb9ad !important;
    border-color: #005cb9ad !important;
  } */
.form-check-input-color:checked{
    background-color: #005CB9;
    border-color: #005CB9;
}
.text-danger{
    color: #dc3545;
}